import { uniq } from "lodash";
import { convertToLocalTime } from "../../functions/convertToLocalTime";
import {
  CloudAssociatedResourceProblem,
  ParsedCloudIssueRow,
} from "../../functions/hooks/useApplicationIssues";
import { copyToClipboardWithSnackbar } from "../../functions/utilities";
import { Dispatch, useMemo } from "react";
import { CVEChip, PopupBody } from "../../views/issues/exposure/Details";
import { SelectedIssue } from "../../App";
import { PopupHeading } from "../../views/issues/exposure/Details";

const cveRegex = /CVE-\d{4}-\d{4,7}/;

export function Details({
  problem,
  time_created,
  setSelectedIssue,
}: {
  problem?: CloudAssociatedResourceProblem;
  time_created?: Date;
  outerProblem: ParsedCloudIssueRow;
  setSelectedIssue: Dispatch<SelectedIssue>;
}): JSX.Element {
  const mentionedCves = useMemo(
    () =>
      uniq([
        ...(cveRegex.exec(problem?.shortDescription ?? "") ?? []).map((x) =>
          x.toUpperCase(),
        ),
        ...(cveRegex.exec(problem?.description ?? "") ?? []).map((x) =>
          x.toUpperCase(),
        ),
        ...(cveRegex.exec(problem?.longDescription ?? "") ?? []).map((x) =>
          x.toUpperCase(),
        ),
        ...(cveRegex.exec(problem?.remediation ?? "") ?? []).map((x) =>
          x.toUpperCase(),
        ),
        ...(cveRegex.exec(problem?.resourceLocation ?? "") ?? []).map((x) =>
          x.toUpperCase(),
        ),
      ]),
    [problem],
  );

  if (!problem) {
    return (
      <div>
        <p className="dark:text-white">More details coming soon.</p>
      </div>
    );
  }

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-2">
        <PopupHeading s="Title" />
        <PopupBody className="mt-1">{problem.shortDescription}</PopupBody>
      </div>
      <div className="sm:col-span-2">
        <PopupHeading s="Description" />
        <PopupBody className="mt-1">{problem.description}</PopupBody>
      </div>
      {problem.longDescription != null &&
      problem.longDescription !== "" &&
      problem.longDescription !== "Unavailable" ? (
        <div className="sm:col-span-2">
          <PopupHeading s="Long Description" />
          <PopupBody className="mt-1">{problem.longDescription}</PopupBody>
        </div>
      ) : null}
      <div className="sm:col-span-1">
        <PopupHeading s="Location" />
        <PopupBody className="mt-1">{problem.resourceLocation}</PopupBody>
      </div>
      <div className="sm:col-span-1">
        <PopupHeading s="Created On" />
        <PopupBody className="mt-1">
          {convertToLocalTime(time_created)?.substring(0, 16)}
        </PopupBody>
      </div>
      <div className="sm:col-span-1">
        <PopupHeading s="Cloud Resource Type" />
        <PopupBody className="mt-1">{problem.resourceType}</PopupBody>
      </div>
      {problem.associatedServices.length ? (
        <div className="sm:col-span-1">
          <PopupHeading s="Associated Resource Details" />
          <PopupBody className="mt-1">
            <div className="flex gap-2 flex-row flex-wrap break-words">
              {problem.associatedServices.map((x, i) => (
                <div key={i}>
                  <p className="text-gray-400" title="Service Name">
                    {x.name}
                  </p>
                  <p
                    className="dark:text-white bg-light-primary dark:bg-dark-nav rounded-md px-2 cursor-pointer"
                    title={`Click to copy the resource details for ${x.name}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboardWithSnackbar(
                        problem.service?.serviceInfo,
                        `resource details for ${problem.service?.name}`,
                      );
                    }}
                  >
                    {x.serviceInfo}
                  </p>
                </div>
              ))}
            </div>
          </PopupBody>
        </div>
      ) : null}
      {problem.service?.name && problem.service?.serviceInfo ? (
        <div className="sm:col-span-1">
          <PopupHeading s="Resource Details" />
          <PopupBody className="mt-1">
            <div className="flex gap-2 flex-row flex-wrap break-words">
              <p className="text-gray-400" title="Service Name">
                {problem.service.name}
              </p>
              <p
                className="dark:text-white bg-light-primary dark:bg-dark-nav rounded-md px-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboardWithSnackbar(
                    problem.service?.serviceInfo,
                    `resource details for ${problem.service?.name}`,
                  );
                }}
                title="Service Info"
              >
                {problem.service.serviceInfo}
              </p>
            </div>
          </PopupBody>
        </div>
      ) : null}
      {(mentionedCves?.length ?? 0) > 0 ? (
        <div className="sm:col-span-1">
          <PopupHeading s="Mentioned CVEs" />
          <PopupBody className="mt-1">
            <div className="flex flex-row flex-wrap gap-2">
              {mentionedCves?.map((cid, i) => (
                <CVEChip
                  key={i}
                  cwveId={cid}
                  setSelectedIssue={setSelectedIssue}
                />
              ))}
            </div>
          </PopupBody>
        </div>
      ) : null}
    </dl>
  );
}
