import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { SnackbarAction, SnackbarKey, useSnackbar } from "notistack";
import { useState } from "react";
import { AiFillSecurityScan } from "react-icons/ai";
import { BsClouds, BsCodeSlash } from "react-icons/bs";
import { MdOutlineScreenSearchDesktop, MdSecurity } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ScanType } from "../../app-types/scanTypes";
import { useUser } from "../../functions/hooks/useUser";
import { trimTargetUrl } from "../../functions/trimTargetUrl";
import { joinClassNames } from "../../functions/utilities";
import { CreateFCloudAnalysis } from "../../graphql/mutations/CreateFCloudAnalysis";
import { CREATE_SBOM_AUTOMATIC } from "../../graphql/mutations/CreateSBOMAuto";
import { EXPOSURE_ANALYSIS } from "../../graphql/mutations/ExposureAnalysis";
import { SCA_AUTO } from "../../graphql/mutations/ScaAuto";
import { STOP_ANALYSIS } from "../../graphql/mutations/StopAnalysis";
import styles from "./AnalyzeButton.module.css";
import { Button } from "./Button";
import { QueryResult } from "@apollo/client";
import {
  Application,
  ApplicationStatusQuery,
  ApplicationStatusQueryVariables,
} from "../../__generated__/graphql";
import { ApplicationStatus } from "../../functions/hooks/useAppStatus";
import { AnalyzeTrivyFE } from "../../graphql/mutations/AnalyzeContainer";
import { SiLinuxcontainers } from "react-icons/si";

enum SCAN_TYPE_INDEX {
  EXPOSURE,
  CODE,
  CLOUD,
  SBOM,
  CONTAINER,
}

enum SCAN_TYPE {
  EXPOSURE = "Exposure",
  CODE = "Code",
  CLOUD = "Cloud",
  SBOM = "SBOM",
  Container = "Container",
}

enum SCAN_RESULTS {
  NO_SCANS_LEFT = "OUT OF DEEPSCAN COUNT",
}

const disabledButtonStyle =
  "px-4 justify-center shadow-gray-400 dark:shadow-none bg-none font-medium hover:bg-none hover:cursor-not-allowed text-sm text-gray-300 border-gray-700 rounded-md";

const buttonStyle =
  "text-lg dark:bg-transparent hover:dark:text-green-400 dark:shadow-none dark:hover-text-green-400 text-black shadow-none bg-transparent hover:text-green-400 dark:text-gray-300 hover:bg-transparent dark:hover:bg-transparent";

export function AnalyzeButton({
  appId,
  applicationStatusQuery,
  applicationStatus,
  appStatusData,
}: {
  appId: string;
  applicationStatusQuery: QueryResult<
    ApplicationStatusQuery,
    ApplicationStatusQueryVariables
  >;
  applicationStatus: ApplicationStatus;
  appStatusData: Application | undefined;
}): JSX.Element {
  const user = useUser();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { push } = useHistory();
  const [selection, setSelection] = useState(0);
  const [analysisError, setAnalysisError] = useState(false);
  const sub = user?.selectedOrg;
  const { loading, error, startPolling, refetch } = applicationStatusQuery;

  const {
    scanningRecon: isScanningRecon,
    scanningSca: isScanningSCA,
    scanningBsca: isScanningBetaSca,
    scanningCloud: isScanningCloud,
    scanningSbom: isScanningSbom,
    scanningContainer: isContainerScanning,
  } = applicationStatus;

  const isAppConfigured = (scanType: SCAN_TYPE) => {
    if (!loading && !error) {
      if (scanType === SCAN_TYPE.EXPOSURE && appStatusData?.target != null) {
        return true;
      }
      if (scanType === SCAN_TYPE.CODE && appStatusData?.scm != null) {
        return true;
      }
      if (
        scanType === SCAN_TYPE.Container &&
        appStatusData?.containerlist != null &&
        appStatusData?.containerlist.length > 0
      ) {
        return true;
      }
      if (
        scanType === SCAN_TYPE.SBOM &&
        (appStatusData?.scm != null ||
          (appStatusData?.f_account_id != null &&
            appStatusData?.target != null))
      ) {
        return true;
      }
      if (scanType === SCAN_TYPE.CLOUD && appStatusData?.f_account_id != null) {
        return true;
      }
    }
    return false;
  };

  const [stopAnalysis] = useMutation(STOP_ANALYSIS);

  const scanTypes = [
    {
      name: "Exposure",
      disabled: !isAppConfigured(SCAN_TYPE.EXPOSURE),
      isScanning: isScanningRecon,
      icon: MdSecurity,
      disabledMessage: "No target configured",
    },
    {
      name: "Code",
      disabled: !isAppConfigured(SCAN_TYPE.CODE),
      isScanning: isScanningSCA || isScanningBetaSca,
      icon: BsCodeSlash,
      disabledMessage: "No SCM configured",
    },
    {
      name: "Cloud",
      disabled: !isAppConfigured(SCAN_TYPE.CLOUD),
      isScanning: isScanningCloud,
      icon: BsClouds,
      disabledMessage: !isAppConfigured(SCAN_TYPE.CLOUD)
        ? "No cloud account configured"
        : !appStatusData?.target && "No target configured",
    },
    {
      name: "Container",
      disabled: !isAppConfigured(SCAN_TYPE.Container),
      isScanning: isContainerScanning,
      icon: SiLinuxcontainers,
      disabledMessage: "No Container configured",
    },
    {
      name: "SBOM",
      disabled: !isAppConfigured(SCAN_TYPE.SBOM),
      isScanning: isScanningSbom,
      icon: MdOutlineScreenSearchDesktop,
      disabledMessage: "No SCM configured for SBOM",
    },
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(scanTypes.length).fill(false),
  );

  const handleOnChange = (position: number, name: string) => {
    setAnalysisError(false);

    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);

    if (name === SCAN_TYPE.EXPOSURE) {
      handleSelection(
        updatedCheckedState,
        SCAN_TYPE_INDEX.EXPOSURE,
        ScanType.Exposure,
      );
    }

    if (name === SCAN_TYPE.CODE) {
      handleSelection(updatedCheckedState, SCAN_TYPE_INDEX.CODE, ScanType.Code);
    }

    if (name === SCAN_TYPE.Container) {
      handleSelection(
        updatedCheckedState,
        SCAN_TYPE_INDEX.CONTAINER,
        ScanType.Container,
      );
    }

    if (name === SCAN_TYPE.CLOUD) {
      handleSelection(
        updatedCheckedState,
        SCAN_TYPE_INDEX.CLOUD,
        ScanType.Cloud,
      );
    }

    if (name === SCAN_TYPE.SBOM) {
      handleSelection(updatedCheckedState, SCAN_TYPE_INDEX.SBOM, ScanType.SBOM);
    }
  };

  const handleSelection = (checkedState: any, idx: number, bitIdx: number) => {
    if (checkedState[idx]) {
      setSelection(selection | bitIdx);
    } else {
      setSelection((currSelection) => currSelection - bitIdx);
    }
  };

  const dismissAction = (key: SnackbarKey | undefined) => (
    <button
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      Dismiss
    </button>
  );

  const seePlansAction = (key: SnackbarKey | undefined) => (
    <button
      onClick={() => {
        closeSnackbar(key);
        push("/settings/plans");
      }}
    >
      See Plans
    </button>
  );

  const [triggerCloudAnalysis, { loading: cloudLoading }] = useMutation(
    CreateFCloudAnalysis,
    {
      variables: {
        org_id: sub,
        domain: trimTargetUrl(appStatusData?.target ?? ""),
        cloud_account_id: appStatusData?.f_account_id,
        applicationId: appId,
      },
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setSelection(0);
        startPolling(6000);
        refetch();
      },
      onError: (error) => {},
    },
  );

  const [triggerExposureAnalysis, { loading: exposureLoading }] = useMutation(
    EXPOSURE_ANALYSIS,
    {
      variables: {
        hostname: appStatusData?.target,
        scantype: "full",
        applicationId: appId,
        sub,
        email: user?.email,
      },

      onCompleted: (data) => {
        setCheckedState(new Array(scanTypes.length).fill(false));
        setSelection(0);
        if (data.exposure.message === SCAN_RESULTS.NO_SCANS_LEFT) {
          enqueueSnackbar(
            "Out of scans. Please upgrade your plan to increase the amount of analyses you can perform",
            {
              action: seePlansAction,
              variant: "error",
              persist: false,
            },
          );
        }

        startPolling(6000);
        refetch();
      },
    },
  );

  const [triggerContainerAnalysis, { loading: containerLoading }] = useMutation(
    AnalyzeTrivyFE,
    {
      variables: {
        applicationId: appId,
      },

      onCompleted: (data) => {
        setCheckedState(new Array(scanTypes.length).fill(false));
        setSelection(0);
        startPolling(6000);
        refetch();
      },
    },
  );

  const [triggerCodeAnalysis, { error: scaError, loading: codeLoading }] =
    useMutation(SCA_AUTO, {
      variables: {
        applicationId: appId,
        orgId: sub ?? "",
      },

      onCompleted: (data) => {
        setCheckedState(new Array(scanTypes.length).fill(false));
        setSelection(0);
        startPolling(6000);
        refetch();
      },
      onError: (error) => {
        setCheckedState(new Array(scanTypes.length).fill(false));
        setSelection(0);
        if (error.message === "Out of Count") {
          enqueueSnackbar(
            "Out of scans. Please upgrade your plan to increase the amount of analyses you can perform",
            {
              action: seePlansAction,
              variant: "error",
              persist: false,
            },
          );
          stopAnalysis({
            variables: {
              orgId: user?.selectedOrg ?? "",
              applicationId: appId,
              analysisType: "code",
            },
          });
          push("/settings/plans");
        } else {
          enqueueSnackbar(scaError?.message, {
            variant: "error",
            persist: false,
            preventDuplicate: true,
            action: dismissAction,
          });
          stopAnalysis({
            variables: {
              orgId: user?.selectedOrg ?? "",
              applicationId: appId,
              analysisType: "code",
            },
          });
        }
      },
    });

  const [triggerSbomAnalysis, { error: sbomAutoError, loading: sbomLoading }] =
    useMutation(CREATE_SBOM_AUTOMATIC, {
      variables: {
        applicationId: appId,
        orgId: user?.selectedOrg ?? "",
      },
      onError: (e) => {
        const outOfScans: boolean = e.message.includes("Out");
        enqueueSnackbar(
          `Error while starting SBOM: ${e.message}.${
            outOfScans
              ? " Please upgrade your plan to increase the amount of analyses you can perform"
              : ""
          }`,
          {
            action: outOfScans ? seePlansAction : dismissAction,
            variant: "error",
            persist: false,
          },
        );
      },
      onCompleted: (data) => {
        const action: SnackbarAction = (snackbarId) => (
          <div className="flex flex-row flex-nowrap gap-2">
            <button
              onClick={() => {
                push(`/sbom/${appId}/history`);
                closeSnackbar(snackbarId);
              }}
            >
              View Progress
            </button>
            <button
              onClick={() => {
                closeSnackbar(snackbarId);
              }}
            >
              Dismiss
            </button>
          </div>
        );
        enqueueSnackbar("SBOM Started", {
          action,
          variant: "success",
          persist: false,
        });
        startPolling(6000);
        refetch();
      },
    });

  const onAnalyseClick = () => {
    selection & ScanType.Exposure && triggerExposureAnalysis();
    selection & ScanType.Code && triggerCodeAnalysis();
    selection & ScanType.Cloud && triggerCloudAnalysis();
    selection & ScanType.Container && triggerContainerAnalysis();
    selection & ScanType.SBOM && triggerSbomAnalysis();
  };

  const isApplicationScanning =
    isScanningRecon ||
    isScanningSCA ||
    isScanningBetaSca ||
    isScanningCloud ||
    isContainerScanning ||
    isScanningSbom;

  const isWaiting: boolean =
    applicationStatusQuery.loading ||
    exposureLoading ||
    sbomLoading ||
    cloudLoading ||
    containerLoading ||
    codeLoading;

  function ScanTypes(): JSX.Element {
    return (
      <ul className="flex">
        {scanTypes.map((item, index) => (
          <label
            key={index}
            title={joinClassNames(
              item.disabled
                ? item.disabledMessage
                : `Start SecureStack ${item.name} Analysis`,
            )}
            className="relative space-x-2"
            id={`scanwell-${item.name}`}
          >
            <input
              key={index}
              className="hidden"
              disabled={item.disabled}
              type="checkbox"
              id={`custom-checkbox-${index}`}
              name={item.name}
              value={item.name}
              checked={checkedState[index]}
              onChange={() => handleOnChange(index, item.name)}
            />
            <item.icon
              className={joinClassNames(
                styles.active,
                checkedState[index] && "group-hover:scale-110 transition",
              )}
            />
          </label>
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-row justify-between rounded-md bg-coolGray-300 dark:bg-dark-primary px-3 m-2">
      <div className="flex group py-2.5 items-center">
        {isApplicationScanning ? (
          <div className="flex items-center" title="stop analysis">
            <Button
              onClick={() =>
                stopAnalysis({
                  variables: {
                    orgId: user?.selectedOrg ?? "",
                    applicationId: appId,
                    analysisType: "all",
                  },
                })
              }
              outlined
              label="STOP SCAN"
            />
            <AiFillSecurityScan className="animate-bounce ml-2 h-6 w-6 text-indigo-600 transition" />
          </div>
        ) : isWaiting ? (
          <p>Loading</p>
        ) : (
          <button
            disabled={isApplicationScanning}
            onClick={() => {
              selection !== 0 && onAnalyseClick();
            }}
            className={joinClassNames(
              isApplicationScanning ? disabledButtonStyle : buttonStyle,
            )}
            id="scanwell-Analyze"
          >
            Analyze
          </button>
        )}

        {analysisError && (
          <p className="text-red-600 text-xs">
            select an option before analyzing
          </p>
        )}
        <div className="my-auto">{!isApplicationScanning && <ScanTypes />}</div>
      </div>
    </div>
  );
}
