import { gql } from "../../__generated__/gql";

export const GET_ALL_ISSUES = gql(`
  query GetAllIssues($applicationId: String, $betaScaId: String) {
    dashboard(applicationId: $applicationId, betaSCAId: $betaScaId) {
      exposure {
        data {
          scannedtime
          Problem {
            problem1
            problem2
            problem3
            problem4
            problem5
            problem6
            problem7
            problem8
            problem9
          }
          Issues {
            scanreference
            murl
            source
            issue
            template
            severity
            description
            info
            endpoint
            lastscanned
            CVE
            active

            cveId
            cweId
            extractedResults
            host
            ip
            matchedAt
            name
            reference
            remediation
            tags
            timestamp
            verified
          }
        }
      }
      cloud {
        score {
          critical
          high
          medium
          low
        }
        data {
          state
          resources {
            location
            previous_state
            problems {
              solutions
              resource_type
              resource_location
              description
              extra_details
              time_created
              time_updated
              associated_services {
                name
                service_info
              }
              service {
                name
                service_info
              }
              inspector {
                title
                severity
                recommendation
                description
              }
            }
            id
            type
            native_id
            state
            state_reason
            previous_state_reason
            previous_problems
            time_created
            extra_parameters
          }
          id
          time_created
        }
      }
      container {
        app_id
        trivy_finding
        time_created
        state_reason
        state
        org_id
        id
      }
      betaSCA {
        advisory
        analysisId
        applicationId
        associatedDependency
        cveId
        cvssScore
        cvssVector
        details
        fixedVersions
        id
        orgId
        osvId
        packageName
        publishedDate
        scanId
        scannedtime
        source
        state
        stateReason
        summary
        severity
        vulnerableVersion
        rawData {
          name
          severity
          isDirect
          range
          fixAvailable {
            name
            version
            isSemVerMajor
          }
          nodes
          effects
          via {
            source
            name
            dependency
            title
            url
            severity
            range
            cvss {
              score
              vectorString
            }
            cwe
          }
        }
      }
      secrets {
        data {
          scannedtime
          id
          filename
          sub
          lineNumber
          repoName
          branchName
          credentialRuleName
          commitId
          authorName
          authorEmail
          offenderEntropy
          commitDate
          repoURL
          leakURL
          commitTags
          ip
        }
      }
    }
  }
`);
