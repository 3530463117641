import { gql } from "../../__generated__/gql";

export const APPLICATION_STATUS = gql(`
  query ApplicationStatus($applicationId: String, $orgId: String) {
    dashboard(applicationId: $applicationId, orgId: $orgId) {
      application {
        cloud
        sbomStatus
        reconStatus
        scaStatus
        cloudStatus
        scanreference
        assetStatus
        target
        scm
        f_account_id
        applicationId
        status
        name
        containerStatus
        containerlist
      }
    }
  }
`);
