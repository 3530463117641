import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useSnackbar } from "notistack";
import { MouseEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { TheOneCombobox } from "../../../components/buttons/TheOneCombobox";
import { useHistoryState, usePathAppId } from "../../../functions/hooks";
import { useUser } from "../../../functions/hooks/useUser";
import { joinClassNames } from "../../../functions/utilities";
import { CreateFCloudAnalysis } from "../../../graphql/mutations/CreateFCloudAnalysis";
import { CREATE_OR_UPDATE_APP } from "../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { ListFAWSAccounts } from "../../../graphql/queries/ListFAWSCloudAccounts";
import { CreateAppLayout } from "../single/Layout";
import { APPLICATION_SETTINGS } from "../../../graphql/queries/ApplicationSettingsQuery";
import { APPLICATION_STATUS } from "../../../graphql/queries/ApplicationStatus";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";
import logGraphQLError from "../../../functions/logGraphQLError";

type CloudData = {
  id: string;
  name: string;
};

export function CloudSettings({
  isEditing,
  closeEditModal,
  targetFromSettings,
}: {
  isEditing?: boolean;
  closeEditModal?: () => void;
  targetFromSettings?: string;
}): JSX.Element {
  const [awsAccount, setAwsAccount] = useHistoryState("awsAccount");
  const [awsAccountName, setAwsAccountName] = useHistoryState("awsAccountName");

  const [, setId] = useHistoryState("id");
  const [queryData, setQueryData] = useState<CloudData[]>([]);
  const [appId] = useHistoryState("appId");
  const [appTarget] = useHistoryState("appTarget");
  const user = useUser();
  const appIdToEdit = usePathAppId();
  const { error, loading, refetch } = useQuery(ListFAWSAccounts, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
    onError: logGraphQLError,
    onCompleted: ({ dbfCloudAccounts }) => {
      setQueryData(
        dbfCloudAccounts?.map((i: any) => {
          return {
            id: i.id,
            name: i.name,
          };
        }) ?? []
      );
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const [triggerCloudScan] = useMutation(CreateFCloudAnalysis, {
    variables: {
      org_id: user?.selectedOrg,
      domain: appTarget,
      cloud_account_id: awsAccount,
      applicationId: appId,
    },
    onCompleted: (data) => {
      setId("5");
    },
  });

  const [
    createCloudSettings,
    { loading: creationLoader, error: creationError },
  ] = useMutation(CREATE_OR_UPDATE_APP, {
    refetchQueries: [APPLICATION_SETTINGS, APPLICATION_STATUS, GET_APPLICATIONS],
    onCompleted: (data) => {
      if (data?.createOrUpdateApplication) {
        if (isEditing) {
          enqueueSnackbar("Successfully updated application", {
            variant: "success",
            persist: false,
          });
          closeEditModal && closeEditModal();
          awsAccount ? triggerCloudScan({
            variables: {
              org_id: user?.selectedOrg,
              domain: appTarget ?? targetFromSettings,
              cloud_account_id: awsAccount,
              applicationId: appId ?? appIdToEdit,
            }
          }).catch((e) => { enqueueSnackbar(`Failed to start cloud scan: ${e?.message}`, { variant: "error", persist: false }) }) : enqueueSnackbar(`Unable to start cloud scan`, {
            variant: "error",
            persist: false,
          });
          push(`/settings/applications/${appIdToEdit}`);
        } else {
          awsAccount && triggerCloudScan();
        }
      }
    },
  });

  const goNext: MouseEventHandler<HTMLButtonElement> = () => {
    createCloudSettings({
      variables: {
        applicationDetails: {
          applicationId: appId,
          f_account_id: awsAccount,
        },
      },
    });
  };

  const save = () => {
    return createCloudSettings({
      variables: {
        applicationDetails: {
          applicationId: appIdToEdit,
          f_account_id: awsAccount ?? null,
        },
      },
    });
  };

  const isComplete = !!awsAccount;

  return (
    <>
      <CreateAppLayout isEditing={isEditing}>
        <h2 className="pb-6 text-2xl  tracking-tight text-gray-900  dark:text-white ">
          {!isEditing && "4."} Connect your cloud account.
        </h2>
        <div className="space-y-2">
          <div className="flex">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              AWS Account
            </label>
          </div>
          {!error ? (
            <TheOneCombobox
              key="cloud"
              primaryKey="id"
              valueKey="name"
              defaultValue="Select cloud account"
              isLoading={loading}
              items={queryData}
              selected={{ id: awsAccountName, name: awsAccount } as any}
              onSelection={(i: any) => {
                setAwsAccountName(i.name);
                setAwsAccount(i.id);
              }}
            />
          ) : (
            <p className="text-red-600" onClick={() => void refetch()}>Error retrieving accounts. Click to retry.</p>
          )}
        </div>
        <CloudScanning />
      </CreateAppLayout>

      {isEditing ? (
        <div className="text-right space-x-2">
          <Button
            outlined
            label="Cancel"
            onClick={() => closeEditModal && closeEditModal()}
          />

          <Button primary label="Save" disabled={!isComplete} onClick={save} />
        </div>
      ) : (
        <div className="mt-4 justify-between flex ml-0 md:ml-[260px] mb-20">
          <Button outlined={true} label="Back" onClick={() => setId("3")} />
          <div>
            <Button
              className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
              onClick={() => {
                save().then(() => push(`/dashboard/${appId}`));
              }}
              label="Finish"
            />
            <Button
              className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
              onClick={() => {
                setAwsAccount("");
                setAwsAccountName("");
                setId("5");
              }}
              label="Skip"
            />

            <Button
              title={joinClassNames(
                !appTarget && "Cloud scanning requires a target"
              )}
              primary={true}
              disabled={!isComplete || !appTarget}
              isLoading={creationLoader}
              label="Next"
              onClick={goNext}
            />
          </div>
        </div>
      )}
      {creationError && (
        <div className="mt-4 ml-0 md:ml-[260px]">
          <ErrorAlert
            title={
              "Error creating cloud settings, please try again or contact support"
            }
            isShowing={true}
          />
        </div>
      )}
    </>
  );
};

export function CloudScanning(): JSX.Element {
  return (
    <div
      id="alert-additional-content-5"
      className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
      role="alert"
    >
      <div className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Info</span>
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
          Cloud Scanning
        </h3>
      </div>
      <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
        If you have setup an integration with your AWS Account, we show the list
        of accounts you've setup below. By attaching your integration to your
        cloud account we can check what resources we detect inside your cloud
        account and compare it with what is available at the endpoint you
        specified earlier. We also do checks to ensure you've performed steps
        like turned on your Web Application Firewall and other security best
        practices.
      </div>
    </div>
  );
};
