import {
  AiFillAppstore,
  AiOutlineBug,
  AiOutlineDashboard,
  AiOutlineFileSearch,
} from "react-icons/ai";
import { BsClipboardCheck, BsTerminal } from "react-icons/bs";
import { FaExchangeAlt, FaNetworkWired } from "react-icons/fa";
import { FiLink, FiSettings } from "react-icons/fi";
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useState, useContext } from "react";
import { DesktopSidebar } from "./DesktopSidebar";
import { NavBar } from "./NavBar";
import { MobileNavBar } from "./MobileNavBar";
import { titleCase } from "../../../functions/utilities";
import HeaderBanner from "../../banners/HeaderBanner";
import { SidebarNavigationItem } from "./types";
import { LayoutContext } from "../../../functions/components/LayoutContext";
import { PageHeader } from "../../headers/PageHeader";
import { IoIosArrowBack } from "react-icons/io";
import { usePathAppId } from "../../../functions/hooks";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { OnHoverToolTip } from "../../tooltips/OnHoverToolTip";
import { BiSupport } from "react-icons/bi";
import { QueryResult } from "@apollo/client";
import { Application, ApplicationStatusQuery, ApplicationStatusQueryVariables } from "../../../__generated__/graphql";
import { ApplicationStatus } from "../../../functions/hooks/useAppStatus";

enum URL_PARAMS {
  EXPOSURE = "exposure",
  CODE = "code",
  CLOUD = "cloud",
  EXPOSURE_CODE_CLOUD = "exposure&code&cloud",
  EXPOSURE_CODE = "exposure&code",
}

export function Main({ children }: { children: any }): JSX.Element {
  const { pathname } = useLocation();

  const { location, push } = useHistory();
  const { showTitle } = useContext(LayoutContext);
  const urlParams = [
    URL_PARAMS.EXPOSURE,
    URL_PARAMS.CODE,
    URL_PARAMS.CLOUD,
    URL_PARAMS.EXPOSURE_CODE_CLOUD,
    URL_PARAMS.EXPOSURE_CODE,
  ];

  const currentParamsArr = pathname.split("/").filter((x: any) => x);
  const appId = usePathAppId();
  const match = useRouteMatch<{ action?: string }>("/:action");

  const noHeaderForUrls = [
    "integrations",
    "compliance",
    "new",
    "download-cli",
    "dashboard",
    "dashboard1",
    "jira",
    "issues",
    "assets",
    "changes",
    "applications",
    "applications1",
    "package-explorer",
    "settings",
    "history",
    "sbom",
    "support",
    "create",
  ];

  const isViewingHistory = (): boolean => {
    if (currentParamsArr.includes("dashboard")) {
      const isViewingHistoricalRoute = currentParamsArr.map(
        (param: URL_PARAMS) => urlParams.includes(param)
      );
      return isViewingHistoricalRoute.includes(true);
    }
    return false;
  };

  const isViewingEditApp = () => {
    if (currentParamsArr.includes("application")) {
      const isViewingEditAppRoute = currentParamsArr.map((param: any) =>
        ["general", "repo", "cloud"].includes(param)
      );
      return isViewingEditAppRoute.includes(true);
    } else return false;
  };

  const shouldShowTitle =
    !noHeaderForUrls.includes(match?.params?.action || "-") && showTitle;

  return (
    <div className="md:ml-28">
      <HeaderBanner appId={appId ?? ""} isShowing={isViewingHistory()} />
      <main className="h-auto mt-16 px-2">
        {shouldShowTitle ? (
          <div className="mx-auto px-4 lg:max-w-[2000px]">
            {isViewingEditApp() ? (
              <PageHeader
                showBreadCrumbs={false}
                icon={
                  location.key && (
                    <OnHoverToolTip title="Click to go back">
                      <div>
                        <IoIosArrowBack
                          onClick={() => push(`/dashboard/${appId}`)}
                          className="w-16 h-16 mx-auto text-gray-500 hover:text-gray-600 cursor-pointer"
                        />
                      </div>
                    </OnHoverToolTip>
                  )
                }
                pageTitle={
                  <h2 className="text-xl font-extrabold text-gray-900 tracking-tight sm:text-4xl dark:text-gray-200">
                    Settings Application
                  </h2>
                }
              />
            ) : (
              <div>
                <h2 className="text-xl  font-extrabold text-gray-900 tracking-tight sm:text-4xl dark:text-gray-200">
                  {titleCase(match?.params?.action || "")}
                </h2>
              </div>
            )}
          </div>
        ) : null}

        <div className="mx-auto lg:max-w-[2000px]">
          <div className="py-4 px-4">{children}</div>
        </div>
      </main>
    </div>
  );
};

export function TopbarLayout({ children, appStatusData, applicationStatus, applicationStatusQuery }: { children: any; applicationStatusQuery: QueryResult<ApplicationStatusQuery, ApplicationStatusQueryVariables>; applicationStatus: ApplicationStatus; appStatusData: Application | undefined; }): JSX.Element {
  const { pathname } = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { push } = useHistory();
  const [showTitle, setShowTitle] = useState(true);
  const applicationId = usePathAppId();
  const match = useRouteMatch<{ action?: string }>("/:action");

  const navItems: SidebarNavigationItem[] = [
    {
      name: "Dashboard",
      href: "/dashboard",
      appId: applicationId,
      icon: AiOutlineDashboard,
    },
    {
      name: "Issues",
      href: "/issues",
      appId: applicationId,
      icon: AiOutlineBug,
    },
    {
      name: "Assets",
      href: "/assets",
      appId: applicationId,
      icon: FaNetworkWired,
    },
    {
      name: "Compliance",
      href: "/compliance",
      appId: applicationId,
      icon: BsClipboardCheck,
    },
    {
      name: "SBOM",
      appId: applicationId,
      href: "/sbom",
      icon: MdOutlineScreenSearchDesktop,
    },

    {
      name: "Changes",
      appId: applicationId,
      href: "/changes",
      icon: FaExchangeAlt,
    },
  ];

  const secondaryNavItems: SidebarNavigationItem[] = [
    {
      name: "Applications",
      href: "/applications",
      appId: applicationId,
      icon: AiFillAppstore,
    },
    {
      name: "Explorer",
      href: "/package-explorer",
      icon: AiOutlineFileSearch,
    },
    {
      name: "Integrations",
      href: "/integrations",
      icon: FiLink,
    },
    {
      name: "CLI",
      href: "/download-cli",
      icon: BsTerminal,
    },

    {
      name: "Settings",
      href: "/settings",
      icon: FiSettings,
    },
    {
      name: "Support",
      href: "/support",
      icon: BiSupport,
    },
  ];

  const setCurrentApplication = (appId: string) => {
    if (pathname.startsWith("/settings/applications")) {
      push(
        generatePath("/settings/applications/:appId", {
          appId,
        })
      );
      return;
    }

    if (!appId) {
      push(
        generatePath("/:action", {
          action: match!.params!.action!,
        })
      );
      return;
    }

    push(
      generatePath("/:action/:appId", {
        action: match!.params!.action!,
        appId: appId,
      })
    );
  };

  const disabledPaths = [
    "/integrations",
    "/download-cli",
    "/profile",
    "/jira",
    "/new/general",
    "/settings",
    "/create",
    "/applications",
    "/package-explorer",
  ];
  const enabledPaths = ["/settings/applications"];

  const startsWithPath = (v: string) => pathname.startsWith(v);
  const isDisabled =
    disabledPaths.some(startsWithPath) && !enabledPaths.some(startsWithPath);

  return (
    <LayoutContext.Provider value={{ showTitle, setShowTitle }}>
      <>
        <MobileNavBar
          secondaryNavItems={secondaryNavItems}
          navItems={navItems}
          currentPath={pathname}
          isOpen={sidebarOpen}
          setOpen={setSidebarOpen}
        />
        <DesktopSidebar
          navItems={navItems}
          currentPath={pathname}
          secondaryNavItems={secondaryNavItems}
        />

        <div className="flex flex-col h-full bg-gray-200 dark:bg-dark-primary min-h-screen">
          <NavBar
            appStatusData={appStatusData}
            applicationStatus={applicationStatus}
            applicationStatusQuery={applicationStatusQuery}
            pathname={pathname}
            disabled={isDisabled}
            setCurrentApp={setCurrentApplication}
            onMenuItemClicked={setSidebarOpen}
          />

          <Main children={children} />
        </div>
      </>
    </LayoutContext.Provider>
  );
};
