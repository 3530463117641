import { gql } from "@apollo/client/core";

export const ListAWSAccounts = gql`
  query ListAWSAccounts_1($orgId: String) {
    listAWSAccounts (
      orgId: $orgId
    ) {
      name
      id
    }
  }
`;
