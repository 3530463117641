import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useSnackbar } from "notistack";
import { MouseEventHandler, useState } from "react";
import { AiFillAppstore, AiOutlineInfoCircle } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { Modal } from "../../../components/dialogs/Modal";
import { useHistoryState, usePathAppId } from "../../../functions/hooks";
import { joinClassNames } from "../../../functions/utilities";
import { CREATE_OR_UPDATE_APP } from "../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { CreateAppLayout } from "../single/Layout";
import { modalDataMap } from "../single/modalDataMap";
import { ApplicationName } from "./general/ApplicationName";
import { ApplicationTagsField } from "./general/ApplicationTags";
import { APPLICATION_SETTINGS } from "../../../graphql/queries/ApplicationSettingsQuery";
import { APPLICATION_STATUS } from "../../../graphql/queries/ApplicationStatus";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";

export function GeneralSettings({
  isEditing,
  savedTags,
  closeEditModal,
  appNameFromUI,
}: {
  isEditing?: boolean;
  savedTags?: string[];
  closeEditModal?: () => void;
  appNameFromUI?: string;
}): JSX.Element {
  const [appName,] = useHistoryState("appName");
  const [appNameInput, setAppNameInput] = useState<string>(appNameFromUI ?? appName);
  const [, setAppId] = useHistoryState("appId");
  const [isInfoModalShowing, setIsInfoModalShowing] = useState(false);
  const [tags, setTags] = useHistoryState("appTags");
  const [info, setInfo] = useState("");
  const [, setId] = useHistoryState("id");
  const [tagsArr, setTagsArr] = useState(
    savedTags ?? tags?.split(",").filter((element) => element) ?? []
  );
  const appIdToEdit = usePathAppId();

  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const [createGeneralSettings, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_APP,
    {
      refetchQueries: [APPLICATION_SETTINGS, APPLICATION_STATUS, GET_APPLICATIONS],
      onCompleted: (data) => {
        if (data?.createOrUpdateApplication) {
          if (isEditing) {
            enqueueSnackbar("Successfully updated application", {
              variant: "success",
              persist: false,
            });
            closeEditModal && closeEditModal();
            push(`/settings/applications/${appIdToEdit}`);
          } else {
            setAppId(data?.createOrUpdateApplication.applicationId ?? "");
            setId("2");
          }
        }
      },
    }
  );

  const updateAppNameOnChange = (e: { target: { value: string } }) => {
    let value = e.target.value;
    if (value?.length > 256) {
      value = value.slice(0, 256);
    }
    setAppNameInput(value);
  };

  const modalData = modalDataMap.get(info);

  const openModal = (id: string) => {
    setInfo(id);
    setIsInfoModalShowing(true);
  };

  const goNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    createGeneralSettings({
      variables: {
        applicationDetails: {
          name: appNameInput,
          tags: tagsArr,
        },
      },
    });
  };

  const save: MouseEventHandler<HTMLButtonElement> = () => {
    createGeneralSettings({
      variables: {
        applicationDetails: {
          applicationId: appIdToEdit,
          name: appNameInput,
          tags: tagsArr ?? [],
        },
      },
    });
  };

  return (
    <>
      <CreateAppLayout isEditing={isEditing}>
        <h2 className="pb-6 text-2xl tracking-tight text-gray-900 dark:text-white">
          {!isEditing && "1."} Please provide your application name and any
          tag(s).
        </h2>

        <div className="space-y-2">
          <div className="flex">
            <div className="w-full flex justify-between text-gray-700 dark:text-gray-400">
              <div className="flex-grow inline-flex">
                <label className="block text-sm font-medium">
                  Application Name<span className="text-red-500">*</span>
                </label>
                <AiOutlineInfoCircle
                  id="name"
                  title="Open Application Name Information Dialog"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>
              <div title={`Number of characters allowed for App Name. Using ${appNameInput?.length ?? 0} out of 256.`}>{appNameInput?.length ?? 0}/256</div>
            </div>
          </div>
          <ApplicationName
            name={appNameInput}
            onChange={updateAppNameOnChange}
          />
          <div className="flex pt-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Application Tags
            </label>
            <AiOutlineInfoCircle
              id="tags"
              title="Open Application Tags Information Dialog"
              onClick={(e) => openModal(e.currentTarget.id)}
              className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
            />
          </div>

          <ApplicationTagsField
            editTags={tagsArr}
            pushData={(data) => {
              if (data.length === 0) {
                setTags("");
                isEditing && setTagsArr([]);
              } else {
                setTags(data);
                setTagsArr(data);
              }
            }}
          />
        </div>

        <ProTip />
      </CreateAppLayout>

      {isEditing ? (
        <div className="text-right space-x-2">
          <Button
            outlined
            label="Cancel"
            onClick={() => {
              setTags("");
              closeEditModal && closeEditModal();
            }}
          />

          <Button primary label="Save" onClick={save} className="mb-20" />
        </div>
      ) : (
        <div className="mt-4 justify-end flex ml-0 md:ml-[260px] mb-20">
          <Button
            title={joinClassNames(!appNameInput && "Requires application name")}
            id="1"
            disabled={!appNameInput}
            primary={true}
            isLoading={loading}
            label="Next"
            type="submit"
            onClick={goNext}
          />
        </div>
      )}

      {error && (
        <div className="ml-0 md:ml-[260px]">
          <ErrorAlert
            title={
              "Error creating application, please try again or contact support"
            }
            isShowing={true}
          />
        </div>
      )}
      <Modal
        noVerticalMargin
        closeWhenClickingBackground
        isOpen={isInfoModalShowing}
        onClose={() => setIsInfoModalShowing(false)}
      >
        <div
          id="alert-additional-content-5"
          className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
              Information
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            {modalData?.info}
          </div>
        </div>
      </Modal>
    </>
  );
};

export function ProTip(): JSX.Element {
  return (
    <div
      className="flex p-2 text-xs text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 lg:w-fit"
      role="alert"
    >
      <svg
        aria-hidden="true"
        className="w-5 h-5 mr-2 my-auto"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only my-auto">Info</span>
      <div className="my-auto leading-5">
        <span className="italic">Tip: </span> Add tags above to categorize this App.<br />Search your apps using the new <Link className="underline" to="/applications"><span><AiFillAppstore className="w-4 h-4 my-auto inline" />Applications</span></Link> page.
      </div>
    </div>
  );
};
