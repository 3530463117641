import { CloudAssociatedResourceProblem } from "../../functions/hooks/useApplicationIssues";

declare const window: any;

export function Solution({ currentResourceProblem }: { currentResourceProblem?: CloudAssociatedResourceProblem }): JSX.Element {
  const { HubSpotConversations } = window;

  return (currentResourceProblem?.remediation ?
    <div className="flex flex-col flex-nowrap gap-4 dark:bg-dark-main bg-white py-7 px-7 dark:text-gray-400">
      <p className="text-gray-500">{currentResourceProblem?.shortDescription}</p>
      <div>{currentResourceProblem?.remediation?.split('\n').map(x => <p>{x}</p>)}</div>
    </div>
    :
    <div className="dark:bg-dark-main bg-white py-7 px-7 dark:text-gray-400 text-lg">
      Solutions is currently being worked on, if you would like further support
      please use our contact widget.
      {/* {HubSpotConversations?.widget?.open && typeof HubSpotConversations.widget.open === "function" && HubSpotConversations.widget.open()} */}
    </div>
  );
};
