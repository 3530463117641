import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Dispatch, useState } from "react";
import { useUser } from "../../../../../functions/hooks/useUser";
import { useHistoryState, usePathAppId } from "../../../../../functions/hooks";
import { GET_AZURE_DEVOPS_REPO_STRUCTURE } from "../../../../../graphql/queries/FolderStructure";
import { AutoFileDetection } from "./AutoDetection";
import { MouseEventHandler } from "react";
import { deriveFilesFromPackageManager } from "../../../../../functions/deriveFilesFromPackageManager";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { CREATE_OR_UPDATE_APP } from "../../../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { Modal } from "../../../../../components/dialogs/Modal";
import { HomeIcon } from "@heroicons/react/solid";
import { Loader } from "../../../../../components/loading/Loader";
import { Button } from "../../../../../components/buttons/Button";
import logGraphQLError from "../../../../../functions/logGraphQLError";

enum FILE_TYPE {
  BAD = 0,
  COMMIT = 1,
  TREE = 2,
  TAG = 4,
}

type BreadCrumb = {
  path: string;
  sha: string;
};

export function AzureFileStructureExplorer({
  files,
  path,
  breadCrumbs,
  buildPath,
  isLoading,
  closeFileExplorer,
  codePath, setCodePath,
}: {
  files: {
    commitId: string;
    gitObjectType: number;
    objectId: string;
    path: string;
    url: string;
  }[];
  path: string;
  breadCrumbs: BreadCrumb[];
  isLoading: boolean;
  buildPath: MouseEventHandler<HTMLDivElement>;
  codePath: string;
  setCodePath: Dispatch<string>;
  closeFileExplorer: () => void;
}): JSX.Element {
  const [appId] = useHistoryState("appId");
  const [packageManager] = useHistoryState("packageManager");
  const appIdToEdit = usePathAppId();

  const [setFilePath, { loading }] = useMutation(CREATE_OR_UPDATE_APP, {
    onCompleted: (data) => {
      if (data?.createOrUpdateApplication) {
        closeFileExplorer();
      }
    },
  });

  const fileFilterArr = [".gitignore", "README.md", "public", ".github"];

  const filteredFiles = files
    .filter((i) => !fileFilterArr.includes(i.path))
    .reverse();

  const constructPathForQuery = breadCrumbs.map((i) => {
    return i.path === "" ? "" : i.path.concat("/");
  });

  const finalString = constructPathForQuery.join("");

  return (
    <Modal noVerticalMargin isOpen={true} onClose={closeFileExplorer} title="Path builder">
      <>
        <div className="text-gray-400 dark:text-gray-300 mb-2">
          <p className="text-sm dark:text-gray-400 text-gray-700">
            Locate which folder contains the package files you would like to
            use.
          </p>
        </div>

        <nav
          className="flex mb-2 border-b dark:border-b-gray-500 "
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-4">
            <li>
              <div
                id={""}
                onClick={buildPath}
                className="text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </div>
            </li>
            {breadCrumbs.map((crumb, idx) => {
              return crumb.path === "" ? null : (
                <li key={crumb.sha}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <div
                      id={`${crumb.path},${crumb.sha}`}
                      onClick={buildPath}
                      className="cursor-pointer  flex ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {idx === breadCrumbs.length - 1 && (
                        <FaFolderOpen className=" mr-1 mt-0.4 w-4 h-4 dark:text-gray-500 text-gray-600" />
                      )}
                      {crumb.path}
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
        {isLoading ? (
          <Loader w={5} h={5} />
        ) : (
          filteredFiles.map((file) => (
            <div key={file.path}>
              {file.gitObjectType !== FILE_TYPE.TREE ? (
                <span className="cursor-pointer group flex items-center dark:text-gray-500 text-gray-600">
                  <BsFillFileEarmarkTextFill className=" mr-1 w-4 h-4 dark:text-gray-500 text-gray-600" />

                  {file.path}
                </span>
              ) : (
                <div
                  id={`${file.path},${file.commitId}`}
                  onClick={buildPath}
                  className="cursor-pointer  group flex items-center dark:text-gray-400 text-gray-800"
                >
                  <FaFolder className="mr-1 w-4 h-4 group-hover:hidden dark:text-gray-400 text-gray-800" />
                  <FaFolderOpen className="group-hover:block hidden mr-1 w-4 h-4 dark:text-gray-500 text-gray-600" />
                  {file.path}
                </div>
              )}
            </div>
          ))
        )}

        <div
          id="alert-additional-content-5"
          className="p-4 mt-2  border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-blue-700 dark:text-blue-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
              {isLoading
                ? "Detecting files..."
                : deriveFilesFromPackageManager(files, packageManager)
                  ?.length === 0
                  ? "No files found within folder"
                  : "Files found within folder"}
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            {!isLoading &&
              deriveFilesFromPackageManager(files, packageManager)?.length ===
              0 ? (
              <p className="ml-4 ">No files detected</p>
            ) : (
              !isLoading &&
              deriveFilesFromPackageManager(files, packageManager)?.map(
                (file) => (
                  <h1 key={file} className="flex mt-1.5 items-center">
                    <BsFillFileEarmarkTextFill className="w-4 h-4 mr-1 dark:text-gray-500 text-gray-600" />
                    {file}
                  </h1>
                )
              )
            )}
          </div>
        </div>
      </>
      <div className="mt-2 text-right flex justify-end items-center ">
        <Button
          isLoading={loading}
          onClick={() => {
            setCodePath(finalString);
            setFilePath({
              variables: {
                applicationDetails: {
                  applicationId: appId ?? appIdToEdit,
                  path: finalString,
                },
              },
            })
          }}
          primary={true}
          label={`use ${path}`}
        />
      </div>
    </Modal>
  );
};


export default function AzureDevOpsFileStructure({ codePath, setCodePath }: { codePath: string, setCodePath: Dispatch<string> }): JSX.Element {
  const [folderStructure, setFolderStructure] = useState<{
    commitId: string;
    gitObjectType: number;
    objectId: string;
    path: string;
    url: string;
  }[]>([]);
  const [currentPath, setCurrentPath] = useState<string>("");
  const user = useUser();
  const [isViewingFileExplorer, setIsViewingFileExplorer] = useState<boolean>(false);
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const [, setCurrentSha] = useState<string>("");
  const [repository] = useHistoryState("repository");
  const [branch] = useHistoryState("branch");
  const [Azorganization] = useHistoryState("organization");
  const [packageManager] = useHistoryState("packageManager");
  const { loading, error, refetch } = useQuery(GET_AZURE_DEVOPS_REPO_STRUCTURE, {
    variables: {
      path: currentPath,
      page: "1",
      azureRepo: repository,
      organizationAz: Azorganization,
      azureBranch: branch,
      orgId: user?.selectedOrg,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: logGraphQLError,
    onCompleted: (data) => {
      // @ts-expect-error folderStructure has values
      setFolderStructure(data?.folderStructure?.values.map((x: {
        commitId: string;
        gitObjectType: number;
        objectId: string;
        path: string;
        url: string;
      }) => ({ commitId: x.commitId, gitObjectType: x.gitObjectType, objectId: x.objectId, url: x.url, path: x.path === '/' ? x.path : x.path.substring(1) })));
    },
  });
  if (error) {
    return <p className="text-red-600 cursor-pointer" onClick={() => void refetch()}>Error getting folder structure. Retry?</p>;
  }

  const pathBuilder = (value: string) => {
    const derivePathFromValue = value.split(",")[0];
    const deriveShaFromValue = value.split(",")[1];

    if (!value) {
      setCurrentPath("");
      setBreadCrumbs([]);
    }

    const breadCrumbArr = breadCrumbs.map((i) => i.path);

    const indexOfPath = breadCrumbArr.indexOf(derivePathFromValue);
    //Condition for going back files
    if (indexOfPath >= 0) {
      setCurrentPath(derivePathFromValue);
      setCurrentSha(deriveShaFromValue);

      setBreadCrumbs(
        breadCrumbs.filter((crumb, index) => index <= indexOfPath)
      );
      return;
    }

    setBreadCrumbs((prevCrumbs) => [
      ...prevCrumbs,
      { path: derivePathFromValue, sha: deriveShaFromValue },
    ]);

    setCurrentPath(derivePathFromValue);
    setCurrentSha(deriveShaFromValue);
  };

  const onClose = () => {
    setIsViewingFileExplorer(false);
  };

  if (loading) { return <></>; }

  return (isViewingFileExplorer ?
    <AzureFileStructureExplorer
      files={folderStructure}
      path={currentPath}
      breadCrumbs={breadCrumbs}
      isLoading={loading}
      buildPath={(event) => pathBuilder(event.currentTarget.id)}
      closeFileExplorer={onClose}
      codePath={codePath}
      setCodePath={setCodePath}
    />
    :
    <AutoFileDetection files={(folderStructure && packageManager) ? deriveFilesFromPackageManager(folderStructure, packageManager) : []} viewExplorer={() => setIsViewingFileExplorer(true)} isLoading={loading} />
  );
}