import { Button, useMediaQuery, IconButton, MenuItem } from "@material-ui/core";
//import AccountCircle from "@material-ui/icons/AccountCircle";
import { authBtnStyles } from "./button-styles";
import { useHistory } from "react-router-dom";

export function LoginButton(): JSX.Element {
  const history = useHistory();
  const classes = authBtnStyles();
  const mediumScreen = useMediaQuery("(min-width:960px)");
  const goToLogin = () => {
    history.push("/auth/login");
  };

  return (
    <>
      {!mediumScreen ? (
        <MenuItem onClick={goToLogin}>
          <IconButton color="inherit">
            {/* <AccountCircle /> */}
          </IconButton>
          <p className="dark:text-gray-500 text-gray-600"> Log In</p>
        </MenuItem>
      ) : (
        <Button className={classes.button} onClick={goToLogin}>
          Log In
        </Button>
      )}
    </>
  );
};
