import { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { CloudAccountWizard } from "../../../components/cloud/CloudAccountWizard";
import { CloudWizardProgress } from "../../../components/cloud/CloudWizardProgress";
import { ExclamationIcon } from "@heroicons/react/outline";

declare const window: any;

export function Cloud(): JSX.Element {
  const { path, url } = useRouteMatch();
  const [currentStep, setCurrentStep] = useState<string>("");

  return (
    <div className="relative max-w-6xl mx-auto md:px-8 xl:px-0 mt-10 ">
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/setup`} />
        </Route>
        <Route path={`${path}/setup`}>
          <main className="grid grid-cols-1 md:grid-cols-2 gap-2 mx-auto cloud-integration-selector">
            <div>
              <BetaBanner />
              <CloudWizardProgress step={currentStep} />
            </div>
            <CloudAccountWizard setCurrentStep={setCurrentStep} />
          </main>
        </Route>
      </Switch>
    </div>
  );
};

export function BetaBanner(): JSX.Element {
  const { HubSpotConversations } = window;
  return (
    <div className="bg-white  dark:bg-dark-primary dark:shadow-lg rounded-lg border-l-4 border-red-600 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <span className="font-medium  text-gray-900 dark:text-gray-300 ">
            The Cloud feature is currently in Beta. If you encounter any bugs or
            issues please{" "}
            <span
              onClick={() => HubSpotConversations.widget.open()}
              className="cursor-pointer text-blue-600 text-sm inline hover:text-blue-700"
            >
              contact support.
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
