import "./onboarding-stepper.css";
import dashboardImage from "../../assets/securestack-code-cloud-app-transparent.png";
import { ChangeEvent, Dispatch, MouseEventHandler, useCallback, useEffect, useState, lazy, Suspense } from "react";
import BBlogo from "../../assets/integration-images/bitbucket-logo.svg";
import GHlogo from "../../assets/integration-images/github-logo.svg";
import GLlogo from "../../assets/integration-images/new/gitlab-logo.svg";
import AZlogo from "../../assets/integration-images/new/azure-devops.svg";
import { Button } from "../../components/buttons/Button";
import { Toggle } from "../../functions/toggle";
import { isFeatureEnabled } from "../../functions/utilities";
import { useHistoryState } from "../../functions/hooks";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { ApolloError } from "@apollo/client/errors";
import { MutationFunctionOptions, MutationResult } from "@apollo/client/react/types/types";
import { User } from "../../apollo/LocalizedSchema";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { CREATE_OR_UPDATE_APP } from "../../graphql/queries/CREATE_OR_UPDATE_APP";
import { ApplicationTagsField } from "../create/categories/general/ApplicationTags";
import { CodeSettings } from "../create/categories/CodeSettings";
import { AiOutlineCheckCircle, AiOutlineCode, AiOutlineLoading } from "react-icons/ai";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { FaAws } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { FetchUserCredBBGH } from "../../graphql/queries/FetchUserCredBBGH";
import { supabaseClient } from "../../auth/supabase";
import { useHistory } from "react-router";
import ConciergeErrorBoundary from "./ConciergeErrorBoundary";
import { useUser } from "../../functions/hooks/useUser";
import { EXPOSURE_ANALYSIS } from "../../graphql/mutations/ExposureAnalysis";
import { REQUEST_BB_USER_IDENTITY, REQUEST_GH_USER_IDENTITY, REQUEST_GL_USER_IDENTITY, REQUEST_AZ_USER_IDENTITY } from "../../graphql/queries/RequestUserSCMIdentity";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import useTitle from "../../functions/hooks/useTitle";
import { TermsAndConditions } from "../TermAndConditionsView";
import logGraphQLError from "../../functions/logGraphQLError";
import { svgImport } from "../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


/* TYPES */
type editingIntentTypes = "add" | "replace";
type ApolloMutation = (
  options?: MutationFunctionOptions | undefined
) => Promise<any>;

export const AvailableJobRoles = [
  "Developer",
  "Security Team",
  "CTO",
  "CISO",
  "Other",
];
export type JobRole = (typeof AvailableJobRoles)[number]; // will be one of the options from AvailableJobRoles as a string literal type.

export const AvailableCompanySizes = [
  "1 - 10",
  "11 - 50",
  "51 - 200",
  "201 - 500",
  "501 - 2000",
  "2000+",
];
export type CompanySize = (typeof AvailableCompanySizes)[number]; // will be one of the options from AvailableCompanySizes as a string literal type.
/* /TYPES */

/* DEPENDENCIES */
export function usePersistedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<T>] {
  const [state, setState] = useState<T>(
    () => {
      try { return JSON.parse(window.localStorage.getItem(key)!) ?? defaultValue; }
      catch { return defaultValue; }
    }
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

function selectPage(which: number) {
  if (which - 1 < 0) {
    return;
  }

  const children = document.querySelectorAll(
    "#slider-outer > .slider-inner"
  ) as NodeListOf<HTMLElement>;

  Array.from(children).forEach((c: HTMLElement, i: number) => {
    if (i === which - 1) {
      c.style.opacity = "100";
      setTimeout(() => {
        c.style.display = "flex";
      }, 200);
    } else {
      c.style.opacity = "0";
      setTimeout(() => {
        c.style.display = "none";
      }, 200);
    }
  });
}

function stepStep(
  step: "forward" | "back" | "beginning" | "set",
  stepVal: number,
  setStep: Function,
  numberToMove: () => number
) {
  if (step === "forward") {
    const m = numberToMove();
    setStep(stepVal + m);

    selectPage(stepVal + 1);
  } else if (step === "back") {
    if (stepVal - 1 > 0) {
      setStep(stepVal - 1);
      selectPage(stepVal - 1);
    }
  } else if (step === "beginning") {
    setStep(1);
    selectPage(1);
  } else if (step === "set") {
    setStep(numberToMove());
  }
}
/* /DEPENDENCIES */

/* COMPONENTS */
function Card({
  name,
  Image,
  onClick,
  description,
  isChecked,
  testId,
}: {
  name: string;
  Image: any;
  onClick: MouseEventHandler<HTMLDivElement>;
  description?: string;
  isChecked?: boolean;
  testId: string;
}) {
  return (
    <div
      onClick={(e) => void onClick(e)}
      className="p-6 relative bg-white rounded-lg border border-gray-200 shadow-md dark:bg-dark-primary dark:border-gray-800 dark:hover:bg-gray-700 hover:bg-gray-100 cursor-pointer flex flex-col justify-start"
      data-test-id={`${testId}-target`}
    >
      {isChecked != null && isChecked ? (
        <CheckCircleIcon
          data-test-id={`${testId}-ischecked`}
          className={"h-6 w-6 text-green-400 absolute top-1 right-1"}
        />
      ) : (
        <AiOutlineCheckCircle
          data-test-id={`${testId}-isunchecked`}
          className={"h-6 w-6 text-gray-400 absolute top-1 right-1"}
        />
      )}
      <div className="mx-auto">{Image}</div>
      <div>
        <h5 className="pt-2 mb-2 text-2xl font-semibold tracking-tighter text-gray-900 dark:text-gray-300 text-center">
          {name}
        </h5>
        {description && (
          <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center">
            {description}
          </p>
        )}
      </div>
    </div>
  );
}

const StartOverButton = ({
  setEditingIntent,
  fullResetInputs,
  setStep,
  pushHistory,
  stepStep,
  stepVal,
}: {
  setEditingIntent: Function;
  fullResetInputs: Function;
  setStep: Function;
  pushHistory: Function;
  stepStep: Function;
  stepVal: number;
}) => {
  return (
    <>
      <div className="flex gap-3 absolute bottom-3 right-3">
        <Button
          outlined
          label="Exit to SecureStack &rarr;"
          data-test-id="exit-to-securestack-btn"
          className="dark:text-white"
          onClick={() => {
            setTimeout(() => (window.location.href = "/dashboard"), 100);
          }}
        />
      </div>
      <div className="flex gap-3 absolute bottom-3 left-3">
        <Button
          outlined
          data-test-id="concierge-logout-btn"
          className="text-black dark:text-white cursor-pointer"
          onClick={() => pushHistory("/auth/logout")}
          label="Logout"
        />
      </div>
      <div className="flex gap-3 absolute top-3 left-3">
        <Button
          label="&larr; Restart and Edit"
          className="dark:text-white"
          primary={true}
          data-test-id="back-edit-btn"
          onClick={() => {
            if (stepVal !== 1) {
              stepStep("set", 2, setStep, () => 2);
              setEditingIntent("replace");
            }
          }}
        />
        <Button
          label="&#10227; Restart Completely"
          className="dark:text-white"
          outlined
          data-test-id="restart-completely-btn"
          onClick={() => {
            fullResetInputs();
            stepStep("beginning", stepVal, setStep, () => 0xc0ffee);
            setTimeout(
              () =>
              (window.location.href = window.location.href.substring(
                0,
                window.location.href.indexOf("?")
              )),
              100
            );
          }}
        />
      </div>
    </>
  );
};

function CodeWebOrAWSSlide({
  setCodeAnalysis,
  codeAnalysis,
  setExposureAnalysis,
  exposureAnalysis,
  cloudAnalysis,
  setCloudAnalysis,
  stepVal,
  setStep,
  numberToMove,
  onNext,
}: {
  codeAnalysis: boolean;
  setCodeAnalysis: Dispatch<boolean>;
  exposureAnalysis: boolean;
  setExposureAnalysis: Dispatch<boolean>;
  cloudAnalysis: boolean;
  setCloudAnalysis: Dispatch<boolean>;
  stepVal: number;
  setStep: Function;
  numberToMove: () => number;
  onNext: Function;
}) {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  return (
    <div
      className="slider-slide space-y-2 m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="text-2xl text-center dark:text-white align-middle mb-5">
        Your App...
      </h1>
      <p
        className="dark:text-light-primary text-dark-secondary text-center text-md"
        style={{ marginBottom: "60px" }}
      >
        We recommend choosing "Has Code" and "Has a Website" if applicable.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 select-none mt-10">
        <Card
          name="Has Code"
          testId="code-picker"
          description="Github, BitBucket, GitLab and Azure DevOps available."
          Image={<AiOutlineCode className="w-10 h-10 fill-code-icon-active" />}
          onClick={(e) => {
            setCodeAnalysis(!codeAnalysis);
          }}
          isChecked={codeAnalysis}
        />
        <Card
          name="Has a Website"
          testId="exposure-picker"
          description="A public URL"
          Image={<BsGlobe className="w-10 h-10 fill-exposure-icon-active" />}
          onClick={(e) => {
            setExposureAnalysis(!exposureAnalysis);
          }}
          isChecked={exposureAnalysis}
        />
        <Card
          name="Uses AWS"
          testId="aws-picker"
          description="You'll need your Account ID, Name and Region"
          Image={<FaAws className="w-10 h-10 fill-cloud-icon-active" />}
          onClick={(e) => {
            setCloudAnalysis(!cloudAnalysis);
          }}
          isChecked={cloudAnalysis}
        />
      </div>
      <br />
      <div className="flex pt-10">
        <Button
          className="h-16 w-80 text-center m-auto"
          primary={true}
          data-test-id="code-web-aws-next-btn"
          label="Next"
          isLoading={nextButtonDisabled}
          onClick={() => {
            setNextButtonDisabled(true);
            onNext();
            stepStep("forward", stepVal, setStep, numberToMove);
            setNextButtonDisabled(false);
          }}
        />
      </div>
    </div>
  );
}

function SCMIntegrationSlide({
  stepVal,
  setStep,
  bitbucketIsIntegrated,
  setBitbucketIsIntegrated,
  githubIsIntegrated,
  setGithubIsIntegrated,
  gitlabIsIntegrated,
  setGitlabIsIntegrated,
  azureIsIntegrated,
  setAzureIsIntegrated,
  numberToMove,
  scmIntegrationsLoading,
  getBitbucketIntegrationOauthUrl,
  getGithubIntegrationOauthUrl,
  getGitlabIntegrationOauthUrl,
  getAzureIntegrationOauthUrl,
  onNext,
}: {
  stepVal: number;
  setStep: Function;
  bitbucketIsIntegrated: boolean;
  githubIsIntegrated: boolean;
  setGitlabIsIntegrated: Function;
  setBitbucketIsIntegrated: Function;
  setGithubIsIntegrated: Function;
  azureIsIntegrated: boolean;
  setAzureIsIntegrated: Function;
  numberToMove: () => number;
  gitlabIsIntegrated: boolean;
  scmIntegrationsLoading: boolean;
  getBitbucketIntegrationOauthUrl: Function;
  getGithubIntegrationOauthUrl: Function;
  getGitlabIntegrationOauthUrl: Function;
  getAzureIntegrationOauthUrl: Function;
  onNext: Function;
}) {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  function initiateScmIntegration(type: "gh" | "bb" | "gl" | "az") {
    function handlePopup(url: string) {
      window.location.href = url;
    }

    if (type === "gh") {
      getGithubIntegrationOauthUrl().then(
        (res: { data: { requestGHUserIdentity: string } }) =>
          handlePopup(res.data.requestGHUserIdentity)
      );
    } else if (type === "bb") {
      getBitbucketIntegrationOauthUrl().then(
        (res: { data: { requestBBUserIdentity: string } }) =>
          handlePopup(res.data.requestBBUserIdentity)
      );
    } else if (type === "gl") {
      getGitlabIntegrationOauthUrl().then(
        (res: { data: { requestGLUserIdentity: string } }) =>
          handlePopup(res.data.requestGLUserIdentity)
      );
    } else if (type === "az") {
      getAzureIntegrationOauthUrl().then(
        (res: { data: { requestAZDevopsUserIdentity: string } }) =>
          handlePopup(res.data.requestAZDevopsUserIdentity)
      );
    }
  }

  return (
    <div
      className="slider-slide space-y-2 m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="text-2xl text-center dark:text-white align-middle mb-2">
        Add Source Code Management
      </h1>
      <p className="dark:text-light-primary text-dark-secondary text-center text-md mb-10 pb-10">
        You will be redirected to the SCM platform to sign in. You will be
        redirected back automatically.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 select-none">
        <Card
          name="GitHub"
          testId="github-integrated"
          Image={
            scmIntegrationsLoading ? (
              <AiOutlineLoading className="animate-spin" />
            ) : (
              <img alt="github logo" width={40} height={40} src={GHlogo} />
            )
          }
          onClick={() => initiateScmIntegration("gh")}
          isChecked={githubIsIntegrated}
        />
        <Card
          name="BitBucket"
          testId="bitbucket-integrated"
          Image={
            scmIntegrationsLoading ? (
              <AiOutlineLoading className="animate-spin" />
            ) : (
              <img alt="bitbucket logo" width={40} height={40} src={BBlogo} />
            )
          }
          onClick={() => initiateScmIntegration("bb")}
          isChecked={bitbucketIsIntegrated}
        />
        <Card
          name="GitLab"
          testId="gitlab-integrated"
          Image={
            scmIntegrationsLoading ? (
              <AiOutlineLoading className="animate-spin" />
            ) : (
              <img alt="gitlab logo" width={40} height={40} src={GLlogo} />
            )
          }
          onClick={() => initiateScmIntegration("gl")}
          isChecked={gitlabIsIntegrated}
        />
        <Card
          name="Azure DevOps"
          testId="azure-integrated"
          Image={
            scmIntegrationsLoading ? (
              <AiOutlineLoading className="animate-spin" />
            ) : (
              <img alt="azure devops logo" width={40} height={40} src={AZlogo} />
            )
          }
          onClick={() => azureIsIntegrated ? void null : initiateScmIntegration("az")}
          isChecked={azureIsIntegrated}
        />
      </div>
      <br />
      <div className="flex">
        <Button
          label={
            bitbucketIsIntegrated ||
              githubIsIntegrated ||
              gitlabIsIntegrated ||
              azureIsIntegrated
              ? "Next"
              : "Skip for now"
          }
          data-test-id="scm-integration-next-btn"
          isLoading={nextButtonDisabled}
          onClick={() => {
            setNextButtonDisabled(true);
            onNext();
            stepStep("forward", stepVal, setStep, numberToMove);
            setNextButtonDisabled(false);
          }}
          primary={true}
          className="w-80 hover:animate-none h-16 mx-auto"
        />
      </div>
    </div>
  );
}

function SelectRepoSlide({
  stepVal,
  setStep,
  numberToMove,
  appId,
  gitlabIsIntegrated,
  bitbucketIsIntegrated,
  githubIsIntegrated,
  onNext,
}: {
  stepVal: number;
  setStep: Function;
  numberToMove: () => number;
  appId: string;
  setGitlabIsIntegrated: Function;
  gitlabIsIntegrated: boolean;
  bitbucketIsIntegrated: boolean;
  setBitbucketIsIntegrated: Function;
  setGithubIsIntegrated: Function;
  githubIsIntegrated: boolean;
  onNext: Function;
}): JSX.Element {
  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="dark:text-white text-center text-2xl mb-5">
        Next, select the repo to scan
      </h1>
      <div style={{ minHeight: "560px" }}>
        <CodeSettings
          concierge={true}
          concierge_appId={appId}
          concierge_readyToStep={() => {
            onNext();
            stepStep("forward", stepVal, setStep, numberToMove);
          }}
        />
      </div>
    </div>
  );
}

function AppNameAndTagsSlide({
  applicationName,
  setApplicationName,
  numberToMove,
  setTags,
  setTagsArr,
  tagsArr,
  reactiveUser,
  createGeneralSettings,
  stepVal,
  setStep,
  appId,
  setAppId,
  enqueueSnackbar,
  editingIntent,
  onNext,
}: {
  applicationName: string;
  setApplicationName: Function;
  numberToMove: () => number;
  setTags: Function;
  setTagsArr: Function;
  tagsArr: string[];
  setEndpointUrl: Function;
  endpointUrl: string;
  reactiveUser: User | null;
  stepVal: number;
  setStep: Function;
  createGeneralSettings: Function;
  createGeneralSettingsStatus: MutationResult<any>;
  appId: string;
  setAppId: Function;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  editingIntent: editingIntentTypes;
  setEditingIntent: Dispatch<editingIntentTypes>;
  onNext: Function;
}): JSX.Element {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  function handleNext() {
    if (applicationName && tagsArr) {
      var createSettingsPromise;
      if (editingIntent === "replace") {
        createSettingsPromise = createGeneralSettings({
          variables: {
            applicationDetails: {
              applicationId: appId,
              name: applicationName,
              tags: tagsArr,
            },
          },
        });
      } else {
        createSettingsPromise = createGeneralSettings({
          variables: {
            applicationDetails: {
              name: applicationName,
              tags: tagsArr,
            },
          },
        });
      }

      createSettingsPromise.then((response: any) => {
        if (
          response?.data?.createOrUpdateApplication?.applicationId &&
          reactiveUser &&
          reactiveUser?.selectedOrg &&
          reactiveUser?.email
        ) {
          setAppId(response.data.createOrUpdateApplication.applicationId);
          enqueueSnackbar(
            `Application ${editingIntent === "replace" ? "updated in" : "added to"
            } SecureStack`,
            {
              variant: "success",
              persist: false,
            }
          );
          stepStep("forward", stepVal, setStep, numberToMove);
        } else {
          enqueueSnackbar("Failed to Add Application to SecureStack", {
            variant: "error",
            persist: false,
          });
        }
      });
    }
  }

  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="dark:text-white text-center text-2xl mb-5">
        Add App to SecureStack
      </h1>
      <label className="dark:text-white mb-2">
        Application Name<span className="text-red-600">*</span>
      </label>
      <input
        placeholder="My App"
        className="h-14 block mt-3 dark:text-gray-300 text-black p-2 rounded-lg transition text-base w-full indent-8 focus:shadow-lg shadow-red-300 outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-main outline-offset-2 mb-5"
        defaultValue={applicationName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setApplicationName(e.target.value);
        }}
      />
      <label className="dark:text-white mb-5">Application Tags</label>
      <div className="pt-2 pb-5">
        <ApplicationTagsField
          editTags={tagsArr}
          pushData={(data) => {
            if (data.length === 0) {
              setTags("");
              setTagsArr([]);
            } else {
              setTags(data);
              setTagsArr(data);
            }
          }}
        />
      </div>
      <div className="mt-10 flex">
        <Button
          className="h-16 mx-auto w-80"
          primary={true}
          label={"Next"}
          data-test-id="app-name-tags-next-btn"
          onClick={() => {
            setNextButtonDisabled(true);
            onNext();
            handleNext();
            setNextButtonDisabled(false);
          }}
        />
      </div>
    </div>
  );
}

function SelectWebURLSlide({
  stepVal,
  setStep,
  setEndpointUrl,
  endpointUrl,
  numberToMove,
  enqueueSnackbar,
  createGeneralSettings,
  createGeneralSettingsStatus,
  triggerExposureScan,
  triggerExposureScanStatus,
  appId,
  reactiveUser,
  onNext,
}: {
  stepVal: number;
  setStep: Function;
  setEndpointUrl: Function;
  endpointUrl: string;
  numberToMove: () => number;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  createGeneralSettings: Function;
  createGeneralSettingsStatus: MutationResult<any>;
  triggerExposureScan: ApolloMutation;
  triggerExposureScanStatus: MutationResult<any>;
  appId: string;
  reactiveUser: User | null;
  onNext: Function;
}): JSX.Element {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  function handleNext() {
    if (endpointUrl && appId !== "") {
      createGeneralSettings({
        variables: {
          applicationDetails: {
            applicationId: appId,
            target: endpointUrl,
          },
        },
      }).then((response: any) => {
        if (response instanceof ApolloError) {
          enqueueSnackbar("An error has ocurred", {
            variant: "error",
            persist: false,
          });
          return;
        }
        if (
          appId &&
          reactiveUser &&
          reactiveUser?.selectedOrg &&
          reactiveUser?.email
        ) {
          triggerExposureScan({
            variables: {
              hostname: endpointUrl,
              scantype: "full",
              applicationId: appId,
              sub: reactiveUser.selectedOrg,
              email: reactiveUser.email,
            },
          }).then((data: { data: { exposure: { code: number, message: string, scanreference: string } } }) => {
            if (data?.data?.exposure?.code === 200) {
              enqueueSnackbar(`SecureStack Scan Started for ${endpointUrl}`, {
                variant: "success",
                persist: false,
              });
            } else {
              enqueueSnackbar(
                "An error ocurred. We were unable to start an Exposure scan",
                {
                  variant: "error",
                  persist: false,
                }
              );
            }
          })
        } else {
          enqueueSnackbar(
            "An error ocurred. Your URL has not been saved successfully",
            {
              variant: "error",
              persist: false,
            }
          );
        }
      });
    }

    stepStep("forward", stepVal, setStep, numberToMove);
  }

  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="dark:text-white text-center text-2xl">Web URL</h1>
      <label className="dark:text-white mb-2">URL</label>
      <input
        placeholder="securestack.com"
        className="h-14 block mt-3 dark:text-gray-300 text-black p-2 rounded-lg transition text-base w-full indent-8 focus:shadow-lg shadow-red-300 outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-main outline-offset-2"
        id="concierge-endpoint-url"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setEndpointUrl(e.target.value);
        }}
      />
      <div className="mt-10 flex">
        <Button
          className="h-16 mx-auto w-80"
          primary={true}
          label={endpointUrl.length > 0 ? "Start scan in background" : "Skip"}
          isLoading={nextButtonDisabled}
          data-test-id="exposure-url-next-btn"
          onClick={() => {
            setNextButtonDisabled(true);
            onNext();
            handleNext();
            setNextButtonDisabled(false);
          }}
        />
      </div>
    </div>
  );
}

function DoneSlide({
  appId,
  cloudAnalysis,
  codeAnalysis,
}: {
  appId: string;
  cloudAnalysis: boolean;
  codeAnalysis: boolean;
}): JSX.Element {
  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <h1 className="dark:text-white text-center text-xl mb-2">Quick Start</h1>
      <p className="dark:text-white text-center mb-10">
        Results can take a few minutes to generate.
      </p>
      <div className="columns-2xs grid grid-cols-2 gap-2">
        <div>
          <Button
            className="w-full h-16 mb-5"
            primary={true}
            label={
              cloudAnalysis ? "Finish setting up AWS" : codeAnalysis ? "Add more SCM Integrations" : "Add more integrations"
            }
            onClick={() => {
              if (cloudAnalysis) {
                window.location.href = "/integrations/aws/connect/setup/1";
              } else {
                window.location.href = `/integrations`;
              }
            }}
          />
        </div>
        <div>
          <Button
            className="w-full h-16 mb-5 dark:text-red-400"
            outlined={true}
            label="Go to Dashboard"
            onClick={() => {
              window.location.href = `/dashboard/${appId}`;
            }}
          />
        </div>
      </div>
    </div>
  );
}

function PillPicker({
  title,
  list,
  value,
  setFn,
}: {
  title?: string;
  list: string[];
  value: string;
  setFn: Dispatch<string>;
}): JSX.Element {
  return (
    <div className="my-2">
      {title && (
        <p className="mb-2 dark:text-light-primary text-dark-nav">{title}</p>
      )}
      <div className="w-full pointer flex-col md:flex-row justify-evenly md:flex hidden">
        {list.map((r: string, i: number) => (
          <div
            className={`choice-pill flex ${value === r
              ? "bg-red-600"
              : " bg-light-primary dark:bg-dark-primary"
              } first:rounded-l-xl last:rounded-r-xl sm:rounded-none hover:backdrop-brightness-75 cursor-pointer`}
            key={i}
            onClick={() => setFn(r)}
          >
            <div
              className={`m-auto ${value === r ? "text-white" : "dark:text-white text-gray-800"
                }`}
            >
              {r}
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden visible">
        {list.map((r: string, i: number) => (
          <div
            className={`choice-pill flex ${value === r
              ? "dark:bg-vuln-critical-dark bg-vuln-critical"
              : " bg-light-primary dark:bg-dark-primary"
              } first:rounded-t-xl last:rounded-b-xl sm:rounded-none hover:backdrop-brightness-75 cursor-pointer`}
            key={i}
            onClick={() => setFn(r)}
          >
            <div className="m-auto dark:text-light-primary text-dark-nav">
              {r}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function UserQuestionsSlide({
  stepVal,
  setStep,
  numberToMove,
  jobRole,
  setJobRole,
  companySize,
  setCompanySize,
  jobIndustry,
  setJobIndustry,
  onNext,
}: {
  stepVal: number;
  setStep: Dispatch<number>;
  numberToMove: () => number;
  jobRole: JobRole;
  setJobRole: Dispatch<JobRole>;
  companySize: CompanySize;
  setCompanySize: Dispatch<CompanySize>;
  jobIndustry: string;
  setJobIndustry: Dispatch<string>;
  onNext: Function;
}) {
  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <div className="flex justify-center items-center mb-5">
        <div className="pr-3">
          <Suspense fallback={<></>}><SecureStackLogo width="40" height="40" /></Suspense>
        </div>
        <h1 className="pl-3 dark:text-white text-black text-2xl text-center">
          Welcome to SecureStack!
        </h1>
      </div>
      <p className="dark:text-white text-black text-center mb-3">
        Let's customize your experience. Next we'll get your app set up in
        SecureStack.
      </p>
      <div className="mb-2 pt-2">
        <PillPicker
          title="What is the size of your company?"
          list={AvailableCompanySizes}
          value={companySize}
          setFn={setCompanySize}
        />
        <br />
        <PillPicker
          title="What is your role?"
          list={AvailableJobRoles}
          value={jobRole}
          setFn={setJobRole}
        />
        <br />
        <label className="dark:text-white mb-2">What is your industry?</label>
        <input
          placeholder="cybersecurity"
          className="h-14 block mt-3 dark:text-gray-300 text-black p-2 rounded-lg transition text-base w-full indent-8 focus:shadow-lg shadow-red-300 outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-main outline-offset-2 mb-5"
          defaultValue={jobIndustry}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setJobIndustry(e.target.value);
          }}
        />
      </div>
      <br />
      <div className="flex">
        <Button
          label="Next"
          onClick={() => {
            onNext();
            stepStep("forward", stepVal, setStep, numberToMove);
          }}
          data-test-id="user-questions-next-btn"
          primary={true}
          className="w-80 hover:animate-none h-16 mx-auto"
          loadingLabel="Saving..."
        />
      </div>
    </div>
  );
}
export function UserWelcomeSlide({
  stepVal,
  setStep,
  numberToMove,
  previouslyAccepted,
  setPreviouslyAccepted,
  onNext,
}: {
  stepVal: number;
  setStep: Dispatch<number>;
  previouslyAccepted: boolean;
  setPreviouslyAccepted: Dispatch<boolean>;
  numberToMove: () => number;
  onNext?: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onComplete = () => {
    onNext && onNext();
    setPreviouslyAccepted(true);
    stepStep("forward", stepVal, setStep, numberToMove);
  };

  return (
    <div
      className="slider-slide m-auto w-3/4 h-fit"
      style={{ maxWidth: "800px" }}
    >
      <TermsAndConditions isOpen={isOpen} setIsOpen={setIsOpen} onComplete={onComplete} />
      <div className="flex justify-center items-center mb-5">
        <div className="pr-3">
          <Suspense fallback={<></>}><SecureStackLogo width="40" height="40" /></Suspense>
        </div>
      </div>
      <p className="dark:text-white text-black text-center mb-3">
        This onboarding wizard will walk you through how to set up your first
        managed application
      </p>
      <div className="mb-2 pt-2 flex flex-row">
        <img
          className="shadow-xl rounded-2xl m-auto"
          style={{
            width: "auto",
            height: "350px",
          }}
          src={dashboardImage}
          alt="A graphic illustrating how SecureStack protects code, cloud and web url infrastructure. SecureStack logo with lines connecting to sections for code, cloud and app. The section for code contains logos for github, lambda, javascript, dotnet, nuget, bitbucket, gitlab, npm and python. the section for cloud contains logos for AWS, google cloud and azure. The section for web url contains a box that reads 'https://' and a green encryption lock logo"
        />
      </div>
      <br />
      <div className="flex">
        <Button
          label="Get Started"
          data-test-id="get-started-btn"
          onClick={() => {
            setIsOpen(true);
          }}
          primary={true}
          className="w-80 hover:animate-none h-16 mx-auto"
          loadingLabel="Saving..."
        />
      </div>
    </div>
  );
}

export default function OnboardingStepper(): JSX.Element {
  useTitle("Interactive Onboarding", true);
  if (!isFeatureEnabled("onboarding-stepper")) {
    window.location.href = "/dashboard";
  }

  const reactiveUser = useUser();

  /* useHistoryState */
  const [tags, setTags] = useHistoryState("appTags");
  /* /useHistoryState */

  /* useState */
  const [editingIntent, setEditingIntent] = useState<editingIntentTypes>("add");
  const [stepNumber, setStepNumber] = usePersistedState(
    "concierge-stepNumber",
    1
  );
  const [codeAnalysis, setCodeAnalysis] = usePersistedState<boolean>(
    "concierge-codeAnalysis",
    false
  );
  const [exposureAnalysis, setExposureAnalysis] = usePersistedState(
    "concierge-exposureAnalysis",
    false
  );
  const [cloudAnalysis, setCloudAnalysis] = usePersistedState(
    "concierge-cloudAnalysis",
    false
  );
  const [endpointUrl, setEndpointUrl] = usePersistedState(
    "concierge-endpointUrl",
    ""
  );
  const [applicationName, setApplicationName] = usePersistedState<string>(
    "concierge-applicationName",
    ""
  );
  const [appId, setAppId] = usePersistedState<string>("concierge-appId", "");
  const [tagsArr, setTagsArr] = usePersistedState(
    "concierge-tagsArr",
    tags?.split(",").filter((element) => element)
  );
  const [bitbucketIsIntegrated, setBitbucketIsIntegrated] = usePersistedState(
    "concierge-bitbucketIsIntegrated",
    false
  );
  const [githubIsIntegrated, setGithubIsIntegrated] = usePersistedState(
    "concierge-githubIsIntegrated",
    false
  );
  const [gitlabIsIntegrated, setGitlabIsIntegrated] = usePersistedState(
    "concierge-gitlabIsIntegrated",
    false
  );
  const [azureIsIntegrated, setAzureIsIntegrated] = usePersistedState(
    "concierge-azureIsIntegrated",
    false
  );

  const [previouslyAccepted, setPreviouslyAccepted] = usePersistedState<boolean>("user-accepted-eula", false);

  const [jobRole, setJobRole]: [JobRole, Dispatch<JobRole>] =
    usePersistedState<JobRole>("concierge-jobRole", AvailableJobRoles[0]);
  const [companySize, setCompanySize]: [CompanySize, Dispatch<CompanySize>] =
    usePersistedState<CompanySize>(
      "concierge-companySize",
      AvailableCompanySizes[0]
    );
  const [jobIndustry, setJobIndustry]: [string, Dispatch<string>] =
    usePersistedState<string>("concierge-jobIndustry", "");
  /* /useState */

  function fullReset() {
    setStepNumber(1);
    setCodeAnalysis(false);
    setExposureAnalysis(false);
    setCloudAnalysis(false);
    setEndpointUrl("");
    setApplicationName("");
    setAppId("");
    setTagsArr([]);
    setGithubIsIntegrated(false);
    setGitlabIsIntegrated(false);
    setAzureIsIntegrated(false);
    setBitbucketIsIntegrated(false);
    setJobIndustry("");
    setCompanySize(AvailableCompanySizes[0]);
    setJobRole(AvailableJobRoles[0]);
  }

  const setScmInfo = useCallback((
    oauthBB: boolean,
    oauthGH: boolean,
    oauthGL: boolean,
    oauthAZ: boolean,
  ) => {
    if (oauthBB != null && oauthBB) {
      setBitbucketIsIntegrated(true);
    } else {
      setBitbucketIsIntegrated(false);
    }
    if (oauthGH != null && oauthGH) {
      setGithubIsIntegrated(true);
    } else {
      setGithubIsIntegrated(false);
    }
    if (oauthGL != null && oauthGL) {
      setGitlabIsIntegrated(true);
    } else {
      setGitlabIsIntegrated(false);
    }
    if (oauthAZ != null && oauthAZ) {
      setAzureIsIntegrated(true);
    } else {
      setAzureIsIntegrated(false);
    }
  }, [setBitbucketIsIntegrated, setGithubIsIntegrated, setGitlabIsIntegrated, setAzureIsIntegrated]);

  const { push: pushHistory } = useHistory();

  /* useEffect */
  useEffect(() => {
    selectPage(stepNumber);
  }, [stepNumber]);

  useEffect(() => {
    if (previouslyAccepted && stepNumber === 1) {
      stepStep("forward", 1, setStepNumber, () => 1);
    }
  }, [previouslyAccepted, stepNumber, setStepNumber]);
  /* /useEffect */

  /* Apollo */
  const scmQuery = useQuery(FetchUserCredBBGH, {
    variables: { sub: reactiveUser?.selectedOrg ?? "" },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
    onError: logGraphQLError,
    onCompleted: (data) => {
      if (data.fetchUserCredBBGH?.code === 200) {
        setScmInfo(
          data.fetchUserCredBBGH?.data?.oauthBB ?? false,
          data.fetchUserCredBBGH?.data?.oauthGH ?? false,
          data.fetchUserCredBBGH?.data?.oauthGL ?? false,
          (data.fetchUserCredBBGH?.data?.oauthAZ ?? false) || (data.fetchUserCredBBGH?.data?.patAZ ?? false)
        );
      }
    },
  });
  const [createGeneralSettings, createGeneralSettingsStatus] =
    useMutation(CREATE_OR_UPDATE_APP);

  const [triggerExposureScan, triggerExposureScanStatus] =
    useMutation(EXPOSURE_ANALYSIS);

  const [getBitbucketIntegrationOauthUrl] = useLazyQuery(
    REQUEST_BB_USER_IDENTITY,
    {
      variables: {
        token: `${reactiveUser?.token}:concierge`,
        orgId: reactiveUser?.selectedOrg,
      },
    }
  );

  const [getGithubIntegrationOauthUrl] = useLazyQuery(
    REQUEST_GH_USER_IDENTITY,
    {
      variables: {
        token: `${reactiveUser?.token}:concierge`,
        orgId: reactiveUser?.selectedOrg,
      },
    }
  );

  const [getGitlabIntegrationOauthUrl] = useLazyQuery(
    REQUEST_GL_USER_IDENTITY,
    {
      variables: {
        token: `${reactiveUser?.token}:concierge`,
        orgId: reactiveUser?.selectedOrg,
      },
    }
  );

  const [getAzureIntegrationOauthUrl] = useLazyQuery(REQUEST_AZ_USER_IDENTITY, {
    variables: {
      token: `${reactiveUser?.token}:concierge`,
      orgId: reactiveUser?.selectedOrg,
    },
  });

  useEffect(() => {
    if (!scmQuery.loading) {
      setScmInfo(
        scmQuery.data?.fetchUserCredBBGH?.data?.oauthBB ?? false,
        scmQuery.data?.fetchUserCredBBGH?.data?.oauthGH ?? false,
        scmQuery.data?.fetchUserCredBBGH?.data?.oauthGL ?? false,
        (scmQuery.data?.fetchUserCredBBGH?.data?.oauthAZ ?? false) || (scmQuery.data?.fetchUserCredBBGH?.data?.patAZ ?? false)
      );
    }
  }, [setScmInfo, scmQuery]);
  /* /Apollo */

  /* SUPABASE */
  async function updateUserMeta() {
    await supabaseClient.auth.updateUser({
      data: {
        concierge_stepNumber: stepNumber,
        concierge_usingCodeAnalysis: codeAnalysis,
        concierge_usingExposureAnalysis: exposureAnalysis,
        concierge_usingCloudAnalysis: cloudAnalysis,
        concierge_bitbucketIsIntegrated: bitbucketIsIntegrated,
        concierge_githubIsIntegrated: githubIsIntegrated,
        concierge_gitlabIsIntegrated: gitlabIsIntegrated,
        concierge_azureIsIntegrated: azureIsIntegrated,
        concierge_jobRole: jobRole,
        concierge_companySize: companySize,
        concierge_jobIndustry: jobIndustry,
      },
    });

    // TODO, logging if supabase call fails
  }
  /* /SUPABASE */

  /* snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /* /snackbar */

  const slides = [
    <UserWelcomeSlide
      stepVal={stepNumber}
      setStep={setStepNumber}
      numberToMove={() => 1}
      previouslyAccepted={previouslyAccepted}
      setPreviouslyAccepted={setPreviouslyAccepted}
    />,
    <UserQuestionsSlide
      stepVal={stepNumber}
      setStep={setStepNumber}
      numberToMove={() => 1}
      jobRole={jobRole}
      setJobRole={setJobRole}
      companySize={companySize}
      setCompanySize={setCompanySize}
      jobIndustry={jobIndustry}
      setJobIndustry={setJobIndustry}
      onNext={() => updateUserMeta()}
    />,
    <CodeWebOrAWSSlide
      codeAnalysis={codeAnalysis}
      setCodeAnalysis={setCodeAnalysis}
      exposureAnalysis={exposureAnalysis}
      setExposureAnalysis={setExposureAnalysis}
      cloudAnalysis={cloudAnalysis}
      setCloudAnalysis={setCloudAnalysis}
      stepVal={stepNumber}
      setStep={setStepNumber}
      numberToMove={() => 1}
      onNext={() => updateUserMeta()}
    />,
    <AppNameAndTagsSlide
      applicationName={applicationName}
      setApplicationName={setApplicationName}
      numberToMove={() => {
        if (codeAnalysis) {
          return 1;
        }
        if (exposureAnalysis && cloudAnalysis) {
          return 3;
        }
        if (!exposureAnalysis && cloudAnalysis) {
          return 4;
        }
        if (exposureAnalysis && !cloudAnalysis) {
          return 3;
        }
        return 4;
      }}
      setTags={setTags}
      setTagsArr={setTagsArr}
      tagsArr={tagsArr}
      endpointUrl={endpointUrl}
      setEndpointUrl={setEndpointUrl}
      reactiveUser={reactiveUser}
      createGeneralSettings={createGeneralSettings}
      createGeneralSettingsStatus={createGeneralSettingsStatus}
      stepVal={stepNumber}
      setStep={setStepNumber}
      appId={appId}
      setAppId={setAppId}
      enqueueSnackbar={enqueueSnackbar}
      editingIntent={editingIntent}
      setEditingIntent={setEditingIntent}
      onNext={() => updateUserMeta()}
    />,
    <SCMIntegrationSlide
      stepVal={stepNumber}
      setStep={setStepNumber}
      bitbucketIsIntegrated={bitbucketIsIntegrated}
      setBitbucketIsIntegrated={setBitbucketIsIntegrated}
      setGithubIsIntegrated={setGithubIsIntegrated}
      githubIsIntegrated={githubIsIntegrated}
      numberToMove={() => {
        if (codeAnalysis) {
          return 1;
        } else if (exposureAnalysis && cloudAnalysis) {
          return 2;
        } else if (cloudAnalysis) {
          return 3;
        } else if (exposureAnalysis) {
          return 2;
        }
        return 3;
      }}
      setGitlabIsIntegrated={setGitlabIsIntegrated}
      gitlabIsIntegrated={gitlabIsIntegrated}
      azureIsIntegrated={azureIsIntegrated}
      setAzureIsIntegrated={setAzureIsIntegrated}
      scmIntegrationsLoading={scmQuery.loading}
      getBitbucketIntegrationOauthUrl={getBitbucketIntegrationOauthUrl}
      getGitlabIntegrationOauthUrl={getGitlabIntegrationOauthUrl}
      getGithubIntegrationOauthUrl={getGithubIntegrationOauthUrl}
      getAzureIntegrationOauthUrl={getAzureIntegrationOauthUrl}
      onNext={() => updateUserMeta()}
    />,
    <SelectRepoSlide
      stepVal={stepNumber}
      setStep={setStepNumber}
      appId={appId}
      numberToMove={() => {
        if (exposureAnalysis) {
          return 1;
        } else if (cloudAnalysis) {
          return 2;
        }
        return 2;
      }}
      setGitlabIsIntegrated={setGitlabIsIntegrated}
      gitlabIsIntegrated={gitlabIsIntegrated}
      bitbucketIsIntegrated={bitbucketIsIntegrated}
      setBitbucketIsIntegrated={setBitbucketIsIntegrated}
      setGithubIsIntegrated={setGithubIsIntegrated}
      githubIsIntegrated={githubIsIntegrated}
      onNext={() => updateUserMeta()}
    />,
    <SelectWebURLSlide
      stepVal={stepNumber}
      setStep={setStepNumber}
      setEndpointUrl={setEndpointUrl}
      endpointUrl={endpointUrl}
      numberToMove={() => {
        return 1;
      }}
      enqueueSnackbar={enqueueSnackbar}
      createGeneralSettings={createGeneralSettings}
      createGeneralSettingsStatus={createGeneralSettingsStatus}
      triggerExposureScan={triggerExposureScan}
      triggerExposureScanStatus={triggerExposureScanStatus}
      appId={appId}
      reactiveUser={reactiveUser}
      onNext={() => updateUserMeta()}
    />,
    <DoneSlide appId={appId} cloudAnalysis={cloudAnalysis} codeAnalysis={codeAnalysis} />,
  ];

  return (
    <ConciergeErrorBoundary fullReset={fullReset}>
      <div
        id="stepper-body"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          minWidth: "100vw",
          maxWidth: "100vw",
        }}
        className="dark:bg-dark-secondary w-screen h-screen"
      >
        <StartOverButton
          pushHistory={pushHistory}
          setEditingIntent={setEditingIntent}
          fullResetInputs={fullReset}
          setStep={setStepNumber}
          stepStep={stepStep}
          stepVal={stepNumber}
        />
        <div className="absolute top-3 right-3">
          <div className="w-fit h-fit">
            <Toggle />
          </div>
        </div>

        <div id="slider-outer" className="w-screen h-screen">
          {slides.map((s: JSX.Element, i: number) => {
            return (
              <div
                className="slider-inner flex w-screen h-screen overflow-y-scroll py-4"
                key={i}
                style={{
                  transition: "opacity 100ms 0s linear",
                }}
              >
                {s}
              </div>
            );
          })}
        </div>
      </div>
    </ConciergeErrorBoundary>
  );
}
/* /COMPONENTS */
