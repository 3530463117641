import { AuthChangeEvent, createClient, Session } from "@supabase/supabase-js";
import { User, user } from "../apollo/LocalizedSchema";
import { useEffect } from "react";

const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_URL!,
  process.env.REACT_APP_SUPABASE_TOKEN!
);

const setupUser = (event: AuthChangeEvent, session: Session | null) => {
  const updatedUserObject: User = session ? {
    id: session?.user?.id,
    roles: session?.user?.app_metadata?.roles || {},
    email: session?.user?.email,
    selectedOrg:
      user()?.selectedOrg || session?.user?.user_metadata?.defaultOrg?.id,
    status: event,
    mfa: false,
    token: session?.access_token,
    rawAppData: session?.user?.user_metadata,
  } : {
    id: undefined,
    roles: {},
    email: undefined,
    rawAppData: undefined,
    token: undefined,
    mfa: false,
    status: "SIGNED_OUT",
  };
  // this updates the reactiveVar `user` in gql local cache
  // this functionality has been extended to use localstorage to persist the value as well
  user(updatedUserObject);
  return updatedUserObject;
};

export function SupabaseAuth(): JSX.Element {
  useEffect(() => {
    const { data } = supabaseClient.auth.onAuthStateChange((event, session) => {
      setupUser(event, session);
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return <></>;
}

export { supabaseClient };
