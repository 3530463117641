import { gql } from "../../__generated__/gql";

export const GET_APPLICATIONS = gql(`
  query GetApplications($sub: String!) {
    getApplications(orgId: $sub) {
      name
      applicationId
      target
      scm
      exposureProblemsCount
      cloudProblemsCount
      codeProblemsCount
      secretsProblemsCount
      containersProblemsCount
      region
      f_account_id
      scannedtime
      containerlist
      containerStatus
      branch
      workspace
      organization
      language
      reponame
      exposureAnalyses {
        countryhost
      }
      Tags {
        id
        name
      }
      assetScan
    }
  }
`);
