import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { uniq, uniqBy } from "lodash";
import { useState } from "react";
import {
  CloudProblem,
  NewScasData,
  NucleiResponse,
  Problems,
  Secrets,
  SolutionsQueryQuery,
  SourceCodeAnalysis,
  TrivyResponse,
  cloudAnalysisResource,
} from "../../__generated__/graphql";
import { GET_ALL_ISSUES } from "../../graphql/queries/GET_ALL_ISSUES";
import { codeIssueRegex } from "../../partials/application/enriched-rows/AllIssuesEnrichedDataRow";
import { LastScanned } from "../../views/dashboard_2/dashboard2";
import dashboardDigest, {
  cloudDateRaw,
  exposureDate,
  notEmpty,
} from "../../views/dashboard_2/dashboardDigest";
import { mapIntoIssue } from "../../views/issues/exposure/useIssueMap";
import { calculateSeverityRating } from "../calculateSeverityRating";
import { useApplicationStatus } from "./useApplicationStatus";
import logGraphQLError from "../logGraphQLError";

/* export enum SeverityType {
  CRITICAL = "Critical",
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  NONE = "Low",
} */

export type Severity = "critical" | "high" | "medium" | "low" | "invalid";

// represents the minimal set of data needed to display any issue in the UI
export type IssueCommon = {
  issueTitle: string;
  severity: Severity;
  source: "exposure" | "cloud" | "secrets" | "code" | "containers";
  formattedTitle?: JSX.Element;
};

export type ParsedTrivyMainIssueRow = {
  source: string;
  scan_target: string;
  vulnerabilites: ParsedTrivyIssueRow[];
};

export type ParsedTrivyIssueRow = IssueCommon & {
  cve_id: string;
  package_name: string;
  installed_version: string;
  fixed_version: string;
  criticality: string;
  title: string;
  description: string;
  cwe_id: string[];
  cvss2: number;
  cvss3: number;
  source: string;
  scanTarget: string;
};

export type CloudAssociatedResourceProblem = {
  resourceType: string;
  resourceLocation: string;
  shortDescription: string;
  longDescription: string;
  description: string;
  remediation: string | null;
  service?: {
    name: string;
    serviceInfo: string;
  };
  associatedServices: {
    name: string;
    serviceInfo: string;
  }[];
};

// represents the data to display in the UI for any cloud issue
export type ParsedCloudIssueRow = IssueCommon & {
  cloudType: string;
  location: string;
  associatedResources: CloudAssociatedResourceProblem[];
  originalIssue: cloudAnalysisResource;
  timeCreated: LastScanned;
};

// represents the data to display in the UI for any secrets issue
export type ParsedSecretsIssueRow = IssueCommon & {
  //TODO: need from backend: SCM string to deep link as result is tied to config at that time
  filename: string;
  lineNumber: string;
  repoName: string;
  branchName: string;
  credentialRuleName: string;
  commitId: string;
  offenderEntropy: number;
  commitTags: string[];
  scanInitiatorIP: string;
  authorName: string;
  timestamp: Date | null; // scannedtime
  originalIssue: Secrets;
};

// represents the data to display in the UI for any code issue (betasca and onwards)
export type ParsedCodeIssueRow = IssueCommon & {
  packageName: string;
  remediation: string;
  cvssScore: number;
  osvId: string | null;
  cveId: string | null;
  longIssueDescription: string | null; // details
  fixedVersions: string;
  cvssVector: string;
  cveExists: boolean;
  associatedCveIds: string[];
  associatedCweIds: string[];
  associatedGhaIds: string[];
  vulnerableVersion: string;
  //active: boolean;
  details: string | null;
  summary: string | null;
  // timestamp : new Date(i.scannedtime)
  originalIssue: NewScasData;
  longIssueDescriptionFormatted?: JSX.Element;
};

// represents the data to display in the UI for any exposure issue (nuclei and onwards)
export type ParsedExposureIssueRow = IssueCommon & {
  longIssueDescription: string;
  remediation: string;
  cveExists: boolean;
  active: boolean;
  //source: "Exposure";
  //issueGenerator: "Nu";
  endpoint: string; // matchedAt
  info: string; // info
  //timestamp: Date | null; // new Date(i.timestamp)
  cweIds: string[];
  cveIds: string[];
  tags: string[];
  version: string;
  extractedResults: string[];
  lastscanned: LastScanned;
  verified: boolean;
  originalIssue: string | NucleiResponse;
};

function deriveVersionFromIssueString(issue: string): string | "not found" {
  // updated regex from /(\d+\.)(\d+\.)(\d+\.)(\d)/g
  const matches = issue?.match(
    /([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?/g,
  );

  if (!matches || matches.length === 0) {
    return "not found";
  }
  return matches[0];
}

export const createExposureArr = (problems?: Problems): string[] => {
  if (!problems) return [];

  return [
    problems.problem1,
    problems.problem2,
    problems.problem3,
    problems.problem4,
    problems.problem5,
    problems.problem6,
    problems.problem7,
    problems.problem8,
    problems.problem9,
  ]
    .filter(notEmpty)
    .filter((problem) => problem !== "Problems" && problem !== "");
};

export const calculateStuff = (score?: string): Severity => {
  if (score == null) {
    return "invalid";
  }
  const parsedNumericalScore = Number(score);
  if (!Number.isNaN(parsedNumericalScore)) {
    return calculateSeverityRating(parsedNumericalScore);
  }

  return "invalid";
};

function displayIssueTitle(row: NewScasData): string {
  const issues = row.advisory?.replaceAll("'", '"');
  if (!issues) {
    return `${row.packageName} vulnerability`;
  }

  const matches = Array.from(issues.matchAll(codeIssueRegex))
    .map((match) => match.at(0))
    .filter(notEmpty);

  if (matches && matches.length === 2) {
    var vulnFrom: any, effected: any;
    try {
      vulnFrom = (() => {
        try {
          return JSON.parse(matches.at(0) ?? "");
        } catch {
          return "";
        }
      })();
      effected = (() => {
        try {
          return JSON.parse(matches.at(1) ?? "");
        } catch {
          return "";
        }
      })();
      var etext = effected.join(", ");

      if (vulnFrom?.length && vulnFrom.length > 0) {
        if (typeof vulnFrom[0] === "object") {
          if (effected?.length !== 0) {
            return `${row.packageName}: ${vulnFrom[0]?.title} vulnerability (effects ${etext})`;
          }
          return `${row.packageName}: ${vulnFrom[0]?.title}`;
        }
      }
      return `${row.packageName}: ${vulnFrom.join(", ")} vulnerability${
        etext === "" ? "" : ` effects ${etext}`
      }`;
    } catch {
      if (!vulnFrom) {
        vulnFrom = matches.at(0);
      }
      if (!effected) {
        effected = matches.at(1);
      }
    }
  }

  return row.packageName
    ? `${row.packageName} Vulnerability Found`
    : "Code Vulnerability";
}

const codeFormatStyle =
  "px-2 py-1 bg-light-primary dark:bg-dark-nav rounded-md";
function displayFormattedIssueTitle(row: NewScasData): JSX.Element {
  const issues = row.advisory?.replaceAll("'", '"');
  if (!issues) {
    return (
      <p>
        <code className={codeFormatStyle}>{row.packageName}</code> vulnerability
      </p>
    );
  }

  const matches = Array.from(issues.matchAll(codeIssueRegex))
    .map((match) => match.at(0))
    .filter(notEmpty);

  if (matches && matches.length === 2) {
    var vulnFrom: any, effected: any;
    try {
      vulnFrom = (() => {
        try {
          return JSON.parse(matches.at(0) ?? "");
        } catch {
          return "";
        }
      })();
      effected = (() => {
        try {
          return JSON.parse(matches.at(1) ?? "");
        } catch {
          return "";
        }
      })();
      var etext = effected.join(", ");

      if (vulnFrom?.length && vulnFrom.length > 0) {
        if (typeof vulnFrom[0] === "object") {
          if (effected?.length !== 0) {
            return (
              <p>
                <code className={codeFormatStyle}>{row.packageName}</code>:{" "}
                <code className={codeFormatStyle}>{vulnFrom[0]?.title}</code>{" "}
                vulnerability (effects <code>{etext}</code>)
              </p>
            );
          }
          return (
            <p>
              <code className={codeFormatStyle}>{row.packageName}</code>:{" "}
              <code className={codeFormatStyle}>{vulnFrom[0]?.title}</code>
            </p>
          );
        }
      }
      return (
        <p>
          <code className={codeFormatStyle}>{row.packageName}</code>:{" "}
          <code className={codeFormatStyle}>{vulnFrom.join(", ")}</code>{" "}
          vulnerability
          {etext === "" ? (
            ""
          ) : (
            <span>
              {" "}
              effects <code className={codeFormatStyle}>{etext}</code>
            </span>
          )}
        </p>
      );
    } catch {
      if (!vulnFrom) {
        vulnFrom = matches.at(0);
      }
      if (!effected) {
        effected = matches.at(1);
      }
    }
  }

  return (
    <p>
      {row.packageName ? (
        <span>
          <code className={codeFormatStyle}>{row.packageName}</code>{" "}
          Vulnerability Found
        </span>
      ) : (
        "Code Vulnerability"
      )}
    </p>
  );
}

export function parseOneCodeIssue(problem: NewScasData): ParsedCodeIssueRow {
  const cvssScoreNum = Number(problem?.cvssScore);
  const readCvssVector: string | null =
    problem.rawData?.via
      ?.filter(
        (x) =>
          x?.name != null &&
          x?.cvss?.vectorString != null &&
          x?.cvss?.vectorString !== "",
      )
      ?.flatMap((x) =>
        x?.name ? `${x?.name} vector: ${x?.cvss?.vectorString}` : [],
      )
      ?.join(", ") ?? null;

  let foundCveIds: string[] = [];
  if (problem.cveId) {
    foundCveIds = foundCveIds.concat(problem.cveId);
  }

  let foundCweIds: string[] = [];
  if (problem.cweId) {
    foundCweIds = foundCweIds.concat(
      problem.cweId.map((x) => x?.cwe_id).filter(notEmpty),
    );
  }
  if (problem.rawData?.via != null && (problem.rawData?.via?.length ?? 0) > 0) {
    foundCweIds = foundCweIds.concat(
      problem.rawData.via.flatMap((x) => x?.cwe).filter(notEmpty) ?? [],
    );
  }

  let foundGhaIds: string[] = [];
  if (problem.osvId) {
    foundGhaIds = foundGhaIds.concat(problem.osvId);
  }

  const o: ParsedCodeIssueRow = {
    associatedCveIds: uniq(foundCveIds),
    associatedCweIds: uniq(foundCweIds),
    associatedGhaIds: uniq(foundGhaIds),
    cvssScore: Number.isNaN(cvssScoreNum)
      ? problem.severity === "low"
        ? 3
        : problem.severity === "medium"
        ? 6
        : problem.severity === "high"
        ? 8
        : problem.severity === "critical"
        ? 10
        : -1
      : cvssScoreNum,
    cvssVector:
      problem?.cvssVector ??
      ((readCvssVector?.length ?? 0) > 0 ? readCvssVector : null) ??
      "Unavailable",
    details: problem?.details ?? null,
    summary: problem?.summary ?? null,
    vulnerableVersion:
      problem?.rawData?.range ?? problem?.vulnerableVersion ?? "Unavailable",
    cveExists:
      foundCveIds.length > 0 ||
      foundCweIds.length > 0 ||
      foundGhaIds.length > 0,
    longIssueDescription:
      problem.details ??
      ((problem.rawData?.effects?.length ?? 0) > 0
        ? `Affected Packages: [${problem.rawData?.effects?.join(", ")}]`
        : null) ??
      null,
    longIssueDescriptionFormatted:
      problem.rawData?.effects != null ? (
        (problem.rawData?.effects?.length ?? 0) > 0 ? (
          <div className="flex flex-col md:flex-row flex-wrap items-center gap-2">
            <p>Affected Packages:</p>
            <div className="flex flex-row flex-wrap gap-2">
              {problem.rawData?.effects?.map((effect) => (
                <p className="px-2 py-1 rounded-md bg-light-primary dark:bg-dark-secondary">
                  {effect}
                </p>
              ))}
            </div>
          </div>
        ) : undefined
      ) : undefined,
    remediation:
      (problem?.rawData?.fixAvailable != null &&
      problem.rawData.fixAvailable.name != null &&
      problem.rawData.fixAvailable.version != null
        ? `Fix available via npm: Update ${problem.rawData.fixAvailable.name} to ${problem.rawData.fixAvailable.version}.`
        : null) ?? "Unavailable",
    fixedVersions:
      problem?.fixedVersions ??
      problem?.rawData?.fixAvailable?.version ??
      "Unavailable",
    osvId: problem?.osvId ?? "Unavailable",
    severity:
      problem.severity === "low" ||
      problem.severity === "medium" ||
      problem.severity === "high" ||
      problem.severity === "critical"
        ? problem.severity
        : problem.cvssScore === "critical"
        ? "critical"
        : problem.cvssScore === "high"
        ? "high"
        : problem.cvssScore === "medium" || problem.cvssScore === "moderate"
        ? "medium"
        : problem.cvssScore === "low"
        ? "low"
        : cvssScoreNum < 4
        ? "low"
        : cvssScoreNum < 7
        ? "medium"
        : cvssScoreNum < 9
        ? "high"
        : cvssScoreNum <= 10
        ? "critical"
        : "invalid",
    issueTitle: displayIssueTitle(problem),
    formattedTitle: displayFormattedIssueTitle(problem),
    packageName: problem?.packageName ?? "Unidentified Package",
    source: "code",
    originalIssue: problem,
    cveId: problem.cveId ?? null,
  };

  return o;
}

export function upgradeLegacyScaObjects(
  scas: SourceCodeAnalysis[],
): ParsedCodeIssueRow[] {
  return (
    scas
      .filter(notEmpty)
      .map(
        (problem): ParsedCodeIssueRow => ({
          cveExists: false,
          issueTitle: problem.package ?? "Unavailable",
          longIssueDescription: "Unavailable",
          remediation: problem.advisory,
          cvssScore: Number.isNaN(Number(problem?.cvss2))
            ? Number.isNaN(Number(problem?.cvss3))
              ? -1
              : Number(problem?.cvss3)
            : Number(problem?.cvss2),
          cvssVector: "Unavailable",
          details: "Unavailable",
          fixedVersions: "Unavailable",
          osvId: "Unavailable",
          packageName: problem.package,
          severity:
            problem.criticality === "critical"
              ? "critical"
              : problem.criticality === "high"
              ? "high"
              : problem.criticality === "medium" ||
                problem.criticality === "moderate"
              ? "medium"
              : problem.criticality === "low"
              ? "low"
              : problem?.criticality === "low"
              ? "low"
              : "invalid",
          source: "code",
          summary: "", // legacy: problem?.advisory?.toLowerCase()
          vulnerableVersion: problem.vulnerableVersion,
          originalIssue: { ...problem, __typename: "NewScasData" },
          cveId: null,
          associatedCveIds: [],
          associatedCweIds: [],
          associatedGhaIds: [],
        }),
      )
      ?.filter(notEmpty) ?? []
  );
}

export function parseOneExposureIssue(
  problem: NucleiResponse,
  solutionData?: ParsedSolutionData,
): ParsedExposureIssueRow {
  const { details, solution } = mapIntoIssue(
    problem.description ?? problem.issue ?? undefined,
  );
  const lowercaseProblemIssue = problem.issue?.toLowerCase();

  return {
    active: problem.active === 1,
    cveExists: problem.CVE === 1,
    cveIds: problem.cveId?.filter(notEmpty) ?? [],
    cweIds: problem.cweId?.filter(notEmpty) ?? [],
    endpoint:
      problem.matchedAt ?? problem.host ?? problem.endpoint ?? "Unavailable",
    extractedResults: problem.extractedResults?.filter(notEmpty) ?? [],
    info: problem.info ?? "Unavailable",
    issueTitle: problem.issue ?? problem.template ?? "Exposure Issue",
    longIssueDescription: problem.description ?? details ?? "Unavailable",
    remediation:
      exposureSolutionFor(
        problem.issue?.toLowerCase() ?? undefined,
        solutionData,
      ) ??
      solution ??
      problem.remediation ??
      "Unavailable",
    severity:
      problem.severity === "low" ||
      problem.severity === "medium" ||
      problem.severity === "high" ||
      problem.severity === "critical"
        ? problem.severity
        : lowercaseProblemIssue?.includes("critical")
        ? "critical"
        : lowercaseProblemIssue?.includes("high")
        ? "high"
        : lowercaseProblemIssue?.includes("medium")
        ? "medium"
        : lowercaseProblemIssue?.includes("low")
        ? "low"
        : "invalid",
    source: "exposure",
    version: problem?.issue
      ? deriveVersionFromIssueString(problem.issue)
      : "Unavailable",
    tags: problem.tags?.filter(notEmpty) ?? [],
    verified: problem.verified ?? false,
    lastscanned: exposureDate(problem.lastscanned),
    originalIssue: problem,
    /* id: idx.toString(),
    severity: getSeverity(problem instanceof Array ? "" : (problem ?? ""))?.toLowerCase(),
    problem: problem,
    issue: getIssueFromProblemString(problem instanceof Array ? "" : (problem ?? ""))?.toLowerCase(),
    version: deriveVersionFromIssueString(problem instanceof Array ? "" : (problem ?? "")),
    source: "Exposure", */
  };
}

function exposureSolutionFor(
  issue?: string,
  solutionData?: ParsedSolutionData,
): string | null {
  if (issue != null) {
    if (issue === "no waf detected") {
    }
  }
  return null;
}

function cloudSolutionFor(
  subproblem: CloudProblem,
  solutionData?: ParsedSolutionData,
): string | null {
  if (solutionData) {
    if (
      (subproblem.description === "AWS WAF Not enabled" ||
        subproblem.description ===
          "WAF not attached to Cloudfront distribution") &&
      solutionData.isUsingAmazon
    ) {
      if (!solutionData.isUsingALB && !solutionData.isUsingCloudFront) {
        return "Install ModSecurity and add OWASP ModSecurity Core Rule Set (CRS) ruleset to the WAF";
      } else if (solutionData.isUsingALB && !solutionData.isUsingCloudFront) {
        return "Enable WAF on the ALB and then add the OWASP ModSecurity Core Rule Set to the WAF";
      } else if (!solutionData.isUsingALB && solutionData.isUsingCloudFront) {
        return "Enable WAF on the Cloudfront distribution and then add ModSecurity with the OWASP ModSecurity Core Rule Set to the WAF";
      }
    }
  }

  return null;
}

export function extractFiltersFromCloudProblems(
  cloudProblems: ParsedCloudIssueRow[],
): string[] {
  const ret: string[] = [];
  return uniq(
    cloudProblems
      .map((x) => x.cloudType)
      .concat(cloudProblems.map((x) => x.location))
      .concat(
        cloudProblems.flatMap((x) =>
          x.associatedResources.map((y) => y.resourceLocation),
        ),
      ),
  ).sort();
}

const markupLinksRegex = /\[(.*?)\]\(\1\)/g;
export function cleanInlineMarkupLinks(s?: string | null): string | null {
  if (!s) {
    return null;
  }
  return s.replaceAll(markupLinksRegex, "$1");
}

export function parseOneCloudIssue(
  problem: cloudAnalysisResource,
  solutionData?: ParsedSolutionData,
): ParsedCloudIssueRow {
  return {
    /* id: problem.id,
    severity: "High",
    issue: problem.type,
    location: problem.location,
    problems: problem.problems,
    description: problem.problems.map((p: any) => p.description),
    source: "Cloud", */
    associatedResources:
      problem?.problems
        ?.filter(notEmpty)
        ?.map((subProblem): CloudAssociatedResourceProblem => {
          return {
            shortDescription:
              (subProblem.resource_type === "inspector"
                ? subProblem.inspector?.title ?? null
                : null) ??
              subProblem.resource_type ??
              "Associated AWS Issue",
            description:
              (subProblem.resource_type === "inspector"
                ? cleanInlineMarkupLinks(
                    subProblem.inspector?.description ?? null,
                  )
                : null) ??
              cleanInlineMarkupLinks(subProblem.description) ??
              "Unavailable",
            longDescription:
              cleanInlineMarkupLinks(subProblem.extra_details) ?? "Unavailable",
            resourceLocation: subProblem.resource_location ?? "Unavailable",
            resourceType: subProblem.resource_type ?? "Unavailable",
            remediation:
              cloudSolutionFor(subProblem, solutionData) ??
              cleanInlineMarkupLinks(subProblem.inspector?.recommendation) ??
              cleanInlineMarkupLinks(
                subProblem.solutions?.filter(notEmpty).join(". "),
              ) ??
              null,
            service:
              subProblem?.service?.name != null &&
              subProblem?.service?.service_info != null
                ? {
                    name: subProblem.service.name,
                    serviceInfo: subProblem.service.service_info,
                  }
                : undefined,
            associatedServices:
              subProblem?.associated_services
                ?.map((x) =>
                  x?.name && x?.service_info
                    ? { name: x?.name, serviceInfo: x?.service_info }
                    : [],
                )
                ?.flat() ?? [],
          };
        }) ?? [],
    cloudType: problem?.type ?? "Cloud Issue Detected",
    issueTitle:
      (problem?.type === "aws_account"
        ? "AWS Account Issues"
        : (problem?.problems?.filter(notEmpty).length ?? 0) === 1
        ? problem?.problems?.filter(notEmpty).at(0)?.description
        : null) ??
      (problem?.type === "cloudfront_dist"
        ? "AWS Cloudfront"
        : problem?.type === "aws_account"
        ? "AWS Account Issues"
        : problem?.type === "s3"
        ? "AWS S3"
        : problem?.type === "alb"
        ? "AWS ALB"
        : problem?.type === "ec2"
        ? "AWS EC2"
        : problem?.type === "frontend"
        ? "AWS Frontend"
        : (problem?.problems?.filter(notEmpty).length ?? 0) === 1
        ? problem?.problems?.filter(notEmpty).at(0)?.description
        : null) ??
      "Cloud Issue Detected", //problem?.type === "cloudfront_dist" ? "AWS Cloudfront" : problem?.type === "aws_account" ? "AWS Account" : problem?.type === "s3" ? "AWS S3" : problem?.type === "alb" ? "AWS ALB" : problem?.type === "ec2" ? "AWS EC2" : problem?.type === "frontend" ? "AWS Frontend" : ((problem?.problems?.filter(notEmpty).length ?? 0) === 1 ? problem?.problems?.filter(notEmpty).at(0)?.description : null) ?? problem?.type ?? "Cloud Issue Detected",
    location: problem?.location ?? "Unavailable",
    severity: "high",
    source: "cloud",
    originalIssue: problem,
    timeCreated: cloudDateRaw(problem.time_created),
  };
}

export function parseOneSecretsIssue(problem: Secrets): ParsedSecretsIssueRow {
  const o: ParsedSecretsIssueRow = {
    authorName: problem?.authorName ?? "Unattributable",
    branchName: problem?.branchName ?? "Unattributable",
    commitId: problem?.commitId ?? "Unattributable",
    commitTags: problem?.commitTags?.split(", ") ?? [],
    credentialRuleName: problem?.credentialRuleName ?? "Unattributable",
    filename: problem?.filename ?? "Unattributable",
    issueTitle: problem?.credentialRuleName ?? "Secret Identified",
    lineNumber: problem?.lineNumber ?? "Unattributable",
    offenderEntropy: problem?.offenderEntropy ?? -1,
    repoName: problem?.repoName ?? "Unattributable",
    scanInitiatorIP: problem?.ip ?? "Unattributable",
    severity: "high",
    source: "secrets",
    timestamp: problem?.scannedtime ?? null,
    originalIssue: problem,
    /* id: problem?.id,
    severity: calculateCVSSRating(problem?.offenderEntropy ?? 0),
    issue: problem?.filename,
    credentialRuleName: problem?.credentialRuleName,
    ip: problem?.ip,
    offenderEntropy: problem?.offenderEntropy,
    lineNumber: problem?.lineNumber,
    commitTags: problem?.commitTags,
    scannedTime: problem?.scannedtime,
    source: "Secret", */
  };
  return o;
}
export function parseOneConatinerIssue(
  trivyFinding: ParsedTrivyMainIssueRow[],
): any {
  if (!trivyFinding || trivyFinding?.length === 0) {
    return [];
  }

  //  const trivyFindings = trivyFinding.map((x) => x.vulnerabilites ?? []);

  const trivyFindings = trivyFinding.flatMap((x) =>
    (x.vulnerabilites ?? []).map((vulnerability) => ({
      ...vulnerability,
      scanTarget: x.scan_target,
    })),
  );

  if (trivyFindings.length === 0) {
    return [];
  }

  //flatten the array
  const vul = trivyFindings.flat();

  const vuls = vul?.map((vuln) => {
    const containerVuls: any = {
      scanTarget: vuln?.scanTarget ?? "Unavailable",
      cve_id: vuln?.cve_id ?? "Unavailable",
      package_name: vuln?.package_name ?? "Unavailable",
      installed_version: vuln?.installed_version ?? "Unavailable",
      fixed_version: vuln?.fixed_version ?? "Unavailable",
      criticality: vuln?.criticality ?? "Unavailable",
      title: vuln?.title ?? "Unavailable",
      description: vuln?.description ?? "Unavailable",
      cwe_id: vuln?.cwe_id ?? ["Unavailable"],
      cvss2: vuln?.cvss2 ?? -1,
      cvss3: vuln?.cvss3 ?? -1,
      source: "containers",
      issueTitle: vuln?.package_name ?? "Unavailable",
      longIssueDescription: vuln?.description ?? "Unavailable",

      severity:
        vuln.criticality === "CRITICAL"
          ? "critical"
          : vuln.criticality === "HIGH"
          ? "high"
          : vuln.criticality === "MEDIUM" || vuln.criticality === "moderate"
          ? "medium"
          : vuln.criticality === "LOW"
          ? "low"
          : vuln?.criticality === "low"
          ? "low"
          : "invalid",
      formattedTitle: (
        <p>
          <code className={codeFormatStyle}>{vuln?.title}</code>
        </p>
      ),
    };

    return containerVuls;
  });

  return vuls;

  // const vuls = trivyFinding?.vulnerabilites?.map((vuln) => {
  //   const containerVuls: ParsedTrivyIssueRow = {
  //     cve_id: vuln?.cve_id ?? "Unavailable",
  //     package_name: vuln?.package_name ?? "Unavailable",
  //     installed_version: vuln?.installed_version ?? "Unavailable",
  //     fixed_version: vuln?.fixed_version ?? "Unavailable",
  //     criticality: vuln?.criticality ?? "Unavailable",
  //     title: vuln?.title ?? "Unavailable",
  //     description: vuln?.description ?? "Unavailable",
  //     cwe_id: vuln?.cwe_id ?? ["Unavailable"],
  //     cvss2: vuln?.cvss2 ?? -1,
  //     cvss3: vuln?.cvss3 ?? -1,
  //     source: "containers",
  //     issueTitle: vuln?.title ?? "Unavailable",
  //     severity:
  //       vuln.criticality === "critical"
  //         ? "critical"
  //         : vuln.criticality === "high"
  //         ? "high"
  //         : vuln.criticality === "medium" || vuln.criticality === "moderate"
  //         ? "medium"
  //         : vuln.criticality === "low"
  //         ? "low"
  //         : vuln?.criticality === "low"
  //         ? "low"
  //         : "invalid",
  //     formattedTitle: (
  //       <p>
  //         <code className={codeFormatStyle}>{vuln?.title}</code>
  //       </p>
  //     ),
  //   };

  //   return containerVuls;
  // });
}

export function parseOneNineProblem(
  exposureProblem: string,
  solutionData?: ParsedSolutionData,
): ParsedExposureIssueRow {
  const lowercaseProblem = exposureProblem.toLowerCase();
  const trimmedProblem = exposureProblem?.search("misconfig - ")
    ? exposureProblem?.split(" - ").at(1)
    : exposureProblem;
  const lowercaseTrimmedProblem = trimmedProblem?.toLowerCase();
  const { details, solution } = mapIntoIssue(lowercaseTrimmedProblem);
  return {
    severity: lowercaseProblem.includes("critical")
      ? "critical"
      : lowercaseProblem.includes("high")
      ? "high"
      : lowercaseProblem.includes("medium")
      ? "medium"
      : lowercaseProblem.includes("low")
      ? "low"
      : "invalid",
    active: true,
    cveExists: false,
    cveIds: [],
    cweIds: [],
    endpoint: "Unavailable",
    version: "Unavailable",
    extractedResults: [],
    info: "Unavailable",
    issueTitle: trimmedProblem ?? exposureProblem ?? "Exposure Issue",
    longIssueDescription:
      (details && details !== "" ? details : null) ?? "Unavailable",
    remediation:
      exposureSolutionFor(lowercaseTrimmedProblem, solutionData) ??
      (solution && solution !== "" ? solution : null) ??
      "Unavailable",
    tags: [],
    lastscanned: {
      date: new Date(),
      valid: false,
      olderThan24H: false,
      olderThan48H: false,
    },
    originalIssue: exposureProblem,
    verified: false,
    //problem: exposureProblem,
    //issue: getIssueFromProblemString(exposureProblem instanceof Array ? "" : (exposureProblem ?? ""))?.toLowerCase(),
    //version: deriveVersionFromIssueString(exposureProblem instanceof Array ? "" : (exposureProblem ?? "")),
    source: "exposure",
  };
}
type ParsedSolutionData = {
  valid: boolean;
  isUsingAmazon: boolean;
  isUsingALB: boolean;
  isUsingCloudFront: boolean;
};
function parseSolutionData(
  solutionData?: SolutionsQueryQuery,
): ParsedSolutionData {
  const digestedDash = dashboardDigest(solutionData ?? {});
  return {
    valid: solutionData != null,
    isUsingAmazon: digestedDash.exposure.exposureAmazon,
    isUsingALB: digestedDash.exposure.exposureAwsAlb,
    isUsingCloudFront: digestedDash.exposure.exposureCloudfrontCdn,
  };
}

export function bundleCloudIssues(
  parsedIssues: ParsedCloudIssueRow[],
): ParsedCloudIssueRow[] {
  const bundledCloudEnrichedIssueObjects: ParsedCloudIssueRow[] = [];

  let bundledRow: ParsedCloudIssueRow | undefined;
  const bundleTypes = ["aws_account"];
  for (const cloudIssueType of bundleTypes) {
    const issuesToBundle = parsedIssues.filter(
      (x) => x.cloudType === cloudIssueType,
    );
    bundledRow = issuesToBundle.at(0);
    if (bundledRow) {
      for (const row of issuesToBundle.slice(1)) {
        for (const resource of row.associatedResources) {
          bundledRow.associatedResources.push(resource);
        }
      }
      bundledRow.associatedResources.sort(
        (a, b) => b.description.length - a.description.length,
      );
      bundledCloudEnrichedIssueObjects.push(bundledRow);
      bundledRow = undefined;
    }
  }

  return bundledCloudEnrichedIssueObjects.concat(
    parsedIssues.filter((x) => !bundleTypes.includes(x.cloudType)),
  );
}

export function useApplicationIssues(appId: string) {
  const { data, loading, error, refetch } = useQuery(GET_ALL_ISSUES, {
    variables: {
      applicationId: appId,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: logGraphQLError,
  });

  const [solutionData] = useState<ParsedSolutionData>(parseSolutionData());
  /* useQuery(SolutionsQuery, {
    variables: { applicationId: appId, },
    onCompleted(data) { setSolutionData(parseSolutionData(data)); },
  }); */

  // keep this page up to date when the latest scan comes in
  useApplicationStatus(undefined, refetch);

  const exposureEnrichedIssueObjects: ParsedExposureIssueRow[] = data?.dashboard
    ?.exposure?.data?.Issues
    ? data.dashboard.exposure.data.Issues.filter(notEmpty).map(
        (problem): ParsedExposureIssueRow =>
          parseOneExposureIssue(problem, solutionData),
      )
    : [];

  const exposureProblemArr = createExposureArr(
    data?.dashboard?.exposure?.data?.Problem ?? undefined,
  );

  if (exposureProblemArr.length > 0) {
    for (const exposureProblem of exposureProblemArr) {
      // need to upgrade these string only objects into the new format
      const constructedItem: ParsedExposureIssueRow = parseOneNineProblem(
        exposureProblem,
        solutionData,
      );
      exposureEnrichedIssueObjects.push(constructedItem);
    }
  }

  const allCodeEnrichedIssueObjects: ParsedCodeIssueRow[] = data?.dashboard
    ?.betaSCA
    ? data?.dashboard?.betaSCA
        ?.filter(notEmpty)
        ?.map((problem): ParsedCodeIssueRow => {
          return parseOneCodeIssue(problem);
        })
    : [];

  const uniqueCodeEnrichedIssueObjects: ParsedCodeIssueRow[] = data?.dashboard
    ?.betaSCA
    ? uniqBy(
        allCodeEnrichedIssueObjects,
        (scaProblem) => scaProblem.packageName,
      )
    : [];

  const cloudEnrichedIssueObjects: ParsedCloudIssueRow[] = data?.dashboard
    ?.cloud?.data?.resources
    ? data?.dashboard?.cloud?.data?.resources
        ?.filter(notEmpty)
        ?.map(
          (problem): ParsedCloudIssueRow =>
            parseOneCloudIssue(problem, solutionData),
        )
    : [];
  const bundledCloudEnrichedIssueObjects: ParsedCloudIssueRow[] =
    bundleCloudIssues(cloudEnrichedIssueObjects);

  const secretEnrichedIssueObjects: ParsedSecretsIssueRow[] = data?.dashboard
    ?.secrets?.data
    ? data?.dashboard?.secrets?.data
        ?.filter(notEmpty)
        ?.map((problem) => parseOneSecretsIssue(problem))
    : [];

  const containerEnrichedIssueObjects: ParsedSecretsIssueRow[] =
    data?.dashboard?.container &&
    data?.dashboard?.container?.trivy_finding?.length
      ? parseOneConatinerIssue(data?.dashboard?.container?.trivy_finding)
      : [];

  const digestedDash = dashboardDigest(data ?? {});

  return {
    loading,
    error,
    exposureEnrichedIssueObjects,
    codeEnrichedIssueObjects: uniqueCodeEnrichedIssueObjects,
    allCodeEnrichedIssueObjects,
    cloudEnrichedIssueObjects: bundledCloudEnrichedIssueObjects,
    secretEnrichedIssueObjects,
    containerEnrichedIssueObjects,
    lastScannedCloud: digestedDash.cloud.lastScannedCloud,
    lastScannedCode: digestedDash.code.lastScannedCode,
    lastScannedExposure: digestedDash.exposure.lastScannedExposure,
    lastScannedSecrets: digestedDash.secrets.lastScannedSecrets,
    lastScannedContainers: digestedDash.containers.lastScannedContainers,
    refetch,
    //oldOrNewCodeIssueObj,
    //nucleiIssues,
  };
}
