/* This example requires Tailwind CSS v2.0+ */
import { Dispatch, Fragment, SetStateAction } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { BanIcon, CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { joinClassNames } from "../../functions/utilities";

type DropDownSelectData = {
  id?: string;
  fileReq?: string[];
  name: string;
  icon?: JSX.Element;
  unavailable?: boolean;
  account?: string;
};

type DropDownSelectProps<D extends object> = {
  selected: D;
  setSelected: Dispatch<SetStateAction<D>>;
  label?: string;
  data: D[];
  testId?: string;
};

export function DropDownSelect<D extends DropDownSelectData>({
  selected,
  setSelected,
  data,
  label,
  testId,
}: DropDownSelectProps<D>): JSX.Element {
  return (
    <Listbox
      by="id"
      value={selected}
      onChange={setSelected}
      name={label}
      data-test-id={testId}
    >
      <div className="relative">
        {/* <Listbox.Label className="block text-sm   font-medium text-gray-700 dark:text-gray-400">
          {label}
        </Listbox.Label> */}
        <Listbox.Button
          className="relative w-full py-2 pl-3 pr-10 text-left bg-white dark:bg-dark-primary rounded-lg shadow-md cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
          data-test-id={`${testId}-button`}
        >
          {selected?.icon && (
            <div className="absolute left-2 top-3">{selected.icon}</div>
          )}
          <span
            className={joinClassNames(
              selected.name === "" && "py-2.5",
              "block z-60 truncate dark:text-gray-300 text-gray-800",
              selected?.icon && "pl-4",
            )}
          >
            {selected?.name ?? selected}{" "}
            {selected?.account && "| " + selected?.account}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="border-gray-500 border absolute overflow-y-scroll z-10 w-full py-1 text-base bg-white dark:bg-dark-main rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            data-test-id={`${testId}-options`}
          >
            {data?.map((row: D, rowIndex: number) => (
              <Listbox.Option
                key={rowIndex}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-10 pr-4 ${
                    active
                      ? "text-white rounded bg-red-600"
                      : "text-gray-900 dark:text-gray-400 bg-white dark:bg-dark-main  "
                  }`
                }
                value={row}
                disabled={row.unavailable}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {row.name}
                      {row?.account && " | " + row?.account}
                      {/* {row.id && rowIndex !== 0 && "| " + row.id} */}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-400">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    )}
                    {rowIndex !== 0 && row.unavailable && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 dark:text-red-600">
                        <BanIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    )}
                    {!selected && !row.unavailable && row?.icon && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-amber-600">
                        {row.icon}
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
