import { useState } from "react";
import { ApplicationSettingsQuery } from "../../__generated__/graphql";
import { AppSettingsData } from "./dashboard2";
import { APPLICATION_SETTINGS } from "../../graphql/queries/ApplicationSettingsQuery";
import { useQuery } from "@apollo/client";
import logGraphQLError from "../../functions/logGraphQLError";

export default function appSettingsDigest(
  data: ApplicationSettingsQuery,
): AppSettingsData {
  const appId: string = data.dashboard?.application?.applicationId ?? "";
  const appName: string = data.dashboard?.application?.name ?? "";
  const exposureConfigured: boolean =
    data.dashboard?.application?.target != null &&
    data.dashboard?.application?.target !== "";
  const codeConfigured: boolean =
    data?.dashboard?.application?.scm != null &&
    data?.dashboard?.application?.scm !== "";
  const cloudConfigured: boolean =
    data?.dashboard?.application?.f_account_id != null &&
    data?.dashboard?.application?.f_account_id !== "";
  const containersConfigured: boolean =
    data?.dashboard?.application?.containerlist != null &&
    data?.dashboard?.application?.containerlist?.length > 0;
  const cloudAccountId: string | null =
    data?.dashboard?.application?.f_account_id ?? null;
  const scmPlatform: string = data.dashboard?.application?.scm ?? "";
  const scmOrg: string =
    data.dashboard?.application?.organization ??
    data.dashboard?.application?.workspace ??
    data?.dashboard?.application?.group ??
    "";
  const scmRepo: string = data.dashboard?.application?.reponame ?? "";
  const scmBranch: string = data.dashboard?.application?.branch ?? "";
  const scmPackageManager: AppSettingsData["code"]["scmInfo"]["packageManager"] =
    (data.dashboard?.application?.language === "npm"
      ? "npm"
      : data.dashboard?.application?.language === "yarn"
      ? "yarn"
      : data.dashboard?.application?.language === "go"
      ? "go"
      : data.dashboard?.application?.language === "maven"
      ? "maven"
      : data.dashboard?.application?.language === "pypi"
      ? "pypi"
      : data.dashboard?.application?.language === "pipenv"
      ? "pipenv"
      : data.dashboard?.application?.language === "nuget"
      ? "nuget"
      : data.dashboard?.application?.language === "cargo"
      ? "cargo"
      : "") ?? "";

  return {
    appId: appId,
    appName: appName,
    exposure: {
      isConfigured: exposureConfigured,
    },
    code: {
      scmInfo: {
        platform: scmPlatform,
        org: scmOrg,
        repo: scmRepo,
        branch: scmBranch,
        packageManager: scmPackageManager,
      },
      isConfigured: codeConfigured,
    },
    cloud: {
      accountId: cloudAccountId,
      isConfigured: cloudConfigured,
    },
    containers: {
      isConfigured: containersConfigured,
    },
    secrets: {},
  };
}

export function useAppSettings(appId: string, orgId?: string) {
  const [appSettings, setAppSettings] = useState<AppSettingsData | null>();

  useQuery(APPLICATION_SETTINGS, {
    variables: {
      applicationId: appId,
      orgId: orgId,
    },
    onCompleted(data) {
      setAppSettings(appSettingsDigest(data));
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: logGraphQLError,
  });

  return appSettings;
}
