import { DocumentNode } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Dispatch, useState } from "react";
import { SCM_SELECTION } from "../../views/create/categories/CodeSettings";
import { useHistoryState } from "../hooks";
import { useUser } from "./useUser";
import { BITBUCKET_BRANCHES, GITHUB_BRANCHES, GITLAB_BRANCHES, AZURE_DEVOPS_BRANCHES } from "../../graphql/queries/Branches";
import { uniq } from "lodash";
import useScmOrgOrWorkspaceHistory from "./useScmOrgOrWorkspaceHistory";
import logGraphQLError from "../logGraphQLError";

export type BranchValue = {
  __typename: string;
  name: string;
  slug: string;
};

const createBitbucketQueryVars = (
  repositoryName: string,
  workspace: string,
  currentPage: number
) => {
  return {
    bitbucketReponame: repositoryName,
    workspace: workspace,
    page: currentPage,
  };
};

const createGroupQueryVars = (
  repository: string,
  group: string,
  currentPage: number
) => {
  return {
    group: group,
    gitlabProject: repository,
    page: currentPage,
  };
};

const createGithubQueryVars = (
  repositoryName: string,
  organization: string,
  currentPage: number
) => {
  return {
    githubReponame: repositoryName,
    organization: organization,
    page: currentPage,
  };
};

const createAzureQueryVars = (
  repositoryName: string,
  organization: string,
  currentPage: number
) => {
  return {
    azureRepo: repositoryName,
    organizationAz: organization,
    page: currentPage,
  };
};

export const useBranchSearchQuery = (pageNumber: number, setCurrentPage: Dispatch<number>) => {
  const { BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;
  const [appScm] = useHistoryState("appScm");
  const user = useUser();
  const [orgOrWorkspace] = useScmOrgOrWorkspaceHistory();
  const [repository] = useHistoryState("repository");

  const [branchData, setBranchData] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const SELECT_QUERY_FROM_SCM = ((): DocumentNode => {
    if (appScm === BITBUCKET) { return BITBUCKET_BRANCHES; }
    if (appScm === GITHUB) { return GITHUB_BRANCHES; }
    if (appScm === GITLAB) { return GITLAB_BRANCHES; }
    if (appScm === AZURE || appScm === "azure") { return AZURE_DEVOPS_BRANCHES; }
    return GITHUB_BRANCHES;
  })();

  const createQueryVars = (() => {
    if (appScm === BITBUCKET) { return createBitbucketQueryVars(repository, orgOrWorkspace, pageNumber); }
    if (appScm === GITHUB) { return createGithubQueryVars(repository, orgOrWorkspace, pageNumber); }
    if (appScm === GITLAB) { return createGroupQueryVars(repository, orgOrWorkspace, pageNumber); }
    if (appScm === AZURE || appScm === "azure") { return createAzureQueryVars(repository, orgOrWorkspace, pageNumber); }

    return createGithubQueryVars(repository, orgOrWorkspace, pageNumber);
  })();

  const { loading, error, refetch } = useQuery(
    SELECT_QUERY_FROM_SCM,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onError: logGraphQLError,
      variables: { ...createQueryVars, orgId: user?.selectedOrg },
      onCompleted: ({ branches }) => {
        let prevBranches = [...branchData];
        let currentBranches = branches.values.map((b: BranchValue) => b.name);
        let concatData = prevBranches.concat(currentBranches);

        let results = concatData.filter((e) => {
          return e != undefined;
        });

        if (appScm === GITHUB) {
          const expectingMore: boolean = branches.values.length > branches.pagelen ||
            branches.values.length !== 0;
          setHasMore(expectingMore);
          if (expectingMore) {
            setCurrentPage(pageNumber + 1);
          }
        }
        else if (appScm === BITBUCKET) {
          const expectingMore: boolean = branches.values?.length !== 0
          setHasMore(expectingMore);
          if (expectingMore) {
            setCurrentPage(pageNumber + 1);
          }
        } else {
          setHasMore(false);
        }
        setBranchData(uniq(results));
      },
    }
  );

  return { loading, error, branchData, hasMore, refetch };
};
