import { supabaseClient } from "../../auth/supabase";
//import { Redirecting } from "../../auth/ProtectedRoute";
import { ApolloClient } from "@apollo/client/core/ApolloClient";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient";
import { Redirect } from "react-router-dom";
import { user } from "../../apollo/LocalizedSchema";
import { useEffect } from "react";

const logout = async (client: ApolloClient<any>) => {
  try {
    await client.clearStore();
    await supabaseClient.auth.signOut();
    user(null);
    window.localStorage.clear();
  } catch (e) {
    console.log("error while logging out", e);
  }
};

export function Logout(): JSX.Element {
  const apolloClient = useApolloClient();

  useEffect(() => {
    return () => { logout(apolloClient); };
  }, [apolloClient]);

  return <Redirect to="/auth/login" />;
};
