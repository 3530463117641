import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { MouseEventHandler, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { Modal } from "../../../components/dialogs/Modal";
import { DropDownSelect } from "../../../components/forms/DropDownSelect";
import { useHistoryState, usePathAppId } from "../../../functions/hooks";
import { titleCase } from "../../../functions/utilities";
import { CREATE_OR_UPDATE_APP } from "../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { CreateAppLayout } from "../single/Layout";
import { modalDataMap } from "../single/modalDataMap";
import { RepositoryStructure } from "./repository/RepositoryStructure";
import { SelectBranchName } from "./repository/SelectBranch";
import { SelectPackageManager } from "./repository/SelectPackageManager";
import { SelectRepository } from "./repository/SelectRepository";
import {
  SelectedWorkspaceOrOrganization,
  WorkspaceState,
} from "./repository/SelectWorkspaceOrOrganization";
import { useHistory } from "react-router-dom";
import { SnackbarKey, useSnackbar } from "notistack";
import { IoReload } from "react-icons/io5";
import { useUser } from "../../../functions/hooks/useUser";
import { getScmProviderDetails } from "../../settings/applications/categories/Code";
import { FETCH_SCM_DETAILS } from "../../../graphql/queries/FetchSCMDetails";
import { SCA_AUTO } from "../../../graphql/mutations/ScaAuto";
import { APPLICATION_SETTINGS } from "../../../graphql/queries/ApplicationSettingsQuery";
import { APPLICATION_STATUS } from "../../../graphql/queries/ApplicationStatus";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";
import logGraphQLError from "../../../functions/logGraphQLError";

export enum SCM_SELECTION {
  NOT_SELECTED = "Select SCM provider",
  BITBUCKET = "bitbucket",
  GITHUB = "github",
  GITLAB = "gitlab",
  AZURE = "azure devops",
}

enum COMPLETION_STATE {
  SCM,
  WORKSPACE_OR_ORGANIZATION,
  REPOSITORY,
  BRANCH,
}

export function CodeSettings({
  isEditing,
  closeEditModal,
  concierge,
  concierge_appId,
  concierge_readyToStep,
}: {
  isEditing?: boolean;
  closeEditModal?: () => void;
  concierge?: boolean;
  concierge_appId?: string;
  concierge_readyToStep?: Function;
}): JSX.Element {
  const user = useUser();
  const { NOT_SELECTED, BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;
  const [isInfoModalShowing, setIsInfoModalShowing] = useState(false);
  const { SCM, WORKSPACE_OR_ORGANIZATION, REPOSITORY } = COMPLETION_STATE;
  const { data, refetch: refetchFetchScmDetails } = useQuery(
    FETCH_SCM_DETAILS,
    {
      variables: {
        orgId: user?.selectedOrg ?? "",
      },
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
      onError: logGraphQLError,
    }
  );
  const [appScm, setAppScm] = useHistoryState("appScm");
  const [info, setInfo] = useState("");
  const [appId] = useHistoryState("appId");
  const [packageManager, setPackageManager] = useHistoryState("packageManager");
  const appIdToEdit = usePathAppId();

  const [scm, setScm] = useState<{
    id: string;
    name: string;
    unavailable: boolean;
  }>({ id: "0", name: "", unavailable: true });
  useEffect(() => {
    let appScmId = "0";
    if (appScm === BITBUCKET) {
      appScmId = "1";
    }
    if (appScm === GITHUB) {
      appScmId = "2";
    }
    if (appScm === GITLAB) {
      appScmId = "3";
    }
    if (appScm === AZURE || appScm === "azure") {
      appScmId = "4";
    }

    if (appScm) {
      setScm({
        id: appScmId,
        name: titleCase(appScm),
        unavailable: false,
      });
    } else if (data) {
      setScm(getScmProviderDetails(data)[0]);
    }
  }, [appScm, data, BITBUCKET, GITHUB, GITLAB, AZURE]);

  const evalScm = (): string => {
    if (appScm === BITBUCKET) {
      return "workspace";
    }
    if (appScm === GITHUB) {
      return "organization";
    }
    if (appScm === GITLAB) {
      return "group";
    }
    if (appScm === AZURE || appScm === "azure") {
      return "organization";
    }
    return "organization";
  };

  const [orgOrWorkspaceOrGroup, setOrgOrWorkspaceOrGroup] = useHistoryState(
    evalScm()
  );

  const [repository, setRepository] = useHistoryState("repository");
  const [branch, setBranch] = useHistoryState("branch");
  const [, setId] = useHistoryState("id");
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [codePath, setCodePath] = useState<string>("");

  const [triggerCodeScan, { loading: scaLoader, error: scaError }] =
    useMutation(SCA_AUTO, {
      variables: {
        applicationId: appId ?? concierge_appId,
        orgId: user?.selectedOrg ?? "",
      },
      onCompleted: () => {
        setId("4");
      },
    });

  const action = (key: SnackbarKey | undefined) => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  if (scaError) {
    enqueueSnackbar(
      scaError?.message ?? "An error ocurred while initiating scan",
      {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        action,
      }
    );
  };

  const [createCodeSettings, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_APP,
    {
      refetchQueries: [APPLICATION_SETTINGS, APPLICATION_STATUS, GET_APPLICATIONS],
      onCompleted: () => {
        if (isEditing) {
          enqueueSnackbar("Successfully updated application", {
            variant: "success",
            persist: false,
          });
          push(`/settings/applications/${appIdToEdit}`);
          branch && repository && appScm ? triggerCodeScan({
            variables: {
              applicationId: appId ?? concierge_appId ?? appIdToEdit,
              orgId: user?.selectedOrg ?? "",
            }
          }).catch((e) => { enqueueSnackbar(`Failed to start SCM scan: ${e?.message}`, { variant: "error", persist: false }) }) : enqueueSnackbar(`Unable to start SCM scan`, {
            variant: "error",
            persist: false,
          });
          closeEditModal && closeEditModal();
        } else {
          if (branch && repository && appScm) {
            triggerCodeScan();
          }
        }
      },
    }
  );

  const clearHistoryState = (index: number) => {
    if (index === -1) {
      setAppScm("");
      setOrgOrWorkspaceOrGroup("");
      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === SCM) {
      setOrgOrWorkspaceOrGroup("");
      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === WORKSPACE_OR_ORGANIZATION) {
      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === REPOSITORY) {
      setBranch("");
      setPackageManager("");
    }
  };

  const isCompleted = (val: string) => {
    if (!val) {
      return false;
    }

    return true;
  };

  const save = () => {
    return createCodeSettings({
      variables: {
        applicationDetails: {
          applicationId: appIdToEdit,
          scm: (appScm === AZURE ? "azure" : appScm) ?? null,
          workspace: orgOrWorkspaceOrGroup ?? null,
          language: packageManager ?? null,
          organization: orgOrWorkspaceOrGroup ?? null,
          group: orgOrWorkspaceOrGroup ?? null,
          reponame: repository ?? null,
          branch: branch ?? null,
          path: codePath ?? "",
        },
      },
    });
  };

  const goNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    save();
  };

  const modalData = modalDataMap.get(info);

  const openModal = (id: string) => {
    setInfo(id);
    setIsInfoModalShowing(true);
  };

  return (
    <>
      <CreateAppLayout isEditing={isEditing} concierge={concierge}>
        {!concierge && (
          <h2 className="pb-6 text-2xl tracking-tight text-gray-900 dark:text-white ">
            {!isEditing && "3."} Connect your source code.
          </h2>
        )}
        {
          <IoReload
            title="Refresh SCM Providers"
            className={`dark:fill-white dark:stroke-white absolute cursor-pointer top-3 ${concierge ? "right-3" : "right-7"
              }`}
            width="50"
            height="50"
            onClick={() => void refetchFetchScmDetails()}
          />
        }
        <div className="space-y-2">
          <div className="flex">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              SCM Provider
            </label>
            <AiOutlineInfoCircle
              title="More information"
              onClick={(e) => openModal(e.currentTarget.id)}
              id="scm"
              className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
            />
          </div>
          <DropDownSelect
            label="SCM Provider"
            testId="scm-provider"
            selected={scm}
            setSelected={(scm) => {
              setScm(scm);
              setAppScm(
                scm.name === NOT_SELECTED ? "" : scm.name.toLowerCase()
              );
              clearHistoryState(SCM);
            }}
            data={(data && getScmProviderDetails(data)) ?? []}
          />

          {isCompleted(appScm) && (
            <>
              <div className="flex">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  {(() => {
                    if (appScm === BITBUCKET) {
                      return "Workspace";
                    }
                    if (appScm === GITLAB) {
                      return "Group";
                    }
                    return "Organization";
                  })()}
                </label>
                <AiOutlineInfoCircle
                  title="more information"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  id={(() => {
                    if (appScm === BITBUCKET) {
                      return "workspace";
                    }
                    if (appScm === GITLAB) {
                      return "group";
                    }
                    return "organization";
                  })()}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>
              <SelectedWorkspaceOrOrganization
                key={appScm}
                selected={{
                  id: orgOrWorkspaceOrGroup,
                  name: orgOrWorkspaceOrGroup,
                }}
                testId="scm-workspace"
                setSelected={(val: WorkspaceState | string) => {
                  if (typeof val === "object") {
                    setOrgOrWorkspaceOrGroup(val.id);
                  } else {
                    setOrgOrWorkspaceOrGroup(val);
                  }
                  setRepository("");
                  clearHistoryState(WORKSPACE_OR_ORGANIZATION);
                }}
                scm={appScm}
              />
            </>
          )}

          {isCompleted(orgOrWorkspaceOrGroup) && (
            <>
              <div className="flex">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Repository
                </label>
                <AiOutlineInfoCircle
                  title="more information"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  id={"repository"}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>
              <SelectRepository
                key={repository}
                selected={{ id: repository, name: repository }}
                setSelected={(repository) => {
                  setRepository(repository?.toString());
                  clearHistoryState(REPOSITORY);
                }}
                testId="scm-repository"
              />
            </>
          )}

          {isCompleted(repository) && (
            <>
              <div className="flex">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Branch
                </label>
                <AiOutlineInfoCircle
                  title="more information"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  id={"branch"}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>
              <SelectBranchName
                key={branch}
                selected={{ id: branch, name: branch }}
                setSelected={(branch) => {
                  setBranch(branch.toString());
                }}
                testId="scm-branch"
              />
            </>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2"></div>

          {isCompleted(branch) && (
            <>
              <div className="flex">
                <label className="ml-0.5 block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Package Manager
                </label>
                <AiOutlineInfoCircle
                  title="more information"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  id={"detected"}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>

              <SelectPackageManager
                selected={packageManager}
                setSelected={(packageManager) => {
                  setPackageManager(packageManager.toString());
                }}
                testId="scm-package-manager"
              />
            </>
          )}

          {isCompleted(packageManager) && (
            <>
              <div className="flex">
                <label className="ml-0.5 block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Detected Files
                </label>
                <AiOutlineInfoCircle
                  title="more information"
                  onClick={(e) => openModal(e.currentTarget.id)}
                  id={"detected"}
                  className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                />
              </div>

              <RepositoryStructure codePath={codePath} setCodePath={setCodePath} />
            </>
          )}
        </div>
        {!appScm && <CodeScanning />}
      </CreateAppLayout>
      <Modal
        noVerticalMargin
        isOpen={isInfoModalShowing}
        onClose={() => setIsInfoModalShowing(false)}
      >
        <div
          id="alert-additional-content-5"
          className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
              Information
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            {modalData?.info}
          </div>
        </div>
      </Modal>

      {!concierge ? (
        isEditing ? (
          <div className="text-right space-x-2">
            <Button
              outlined
              label="Cancel"
              onClick={() => {
                clearHistoryState(-1);
                closeEditModal && closeEditModal();
              }}
            />

            <Button
              primary
              label="Save"
              onClick={save}
              disabled={!isCompleted(packageManager)}
              className="mb-20"
            />
          </div>
        ) : (
          <div className="mt-4 justify-between flex ml-0 md:ml-[260px] mb-20">
            <Button outlined={true} label="Back" onClick={() => setId("2")} />

            <div>
              <Button
                className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
                onClick={() => {
                  save().then(() => setTimeout(() => window.location.assign(`/dashboard/${appId}`), 1000));
                }}
                label="Finish"
              />
              <Button
                className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
                onClick={() => {
                  clearHistoryState(-1);
                  setId("4");
                }}
                label="Skip"
              />
              <Button
                primary={true}
                label="Next"
                disabled={!isCompleted(packageManager)}
                isLoading={loading || scaLoader}
                onClick={goNext}
              />
            </div>
          </div>
        )
      ) : (
        <div className="flex">
          <Button
            label={
              isCompleted(packageManager) &&
                isCompleted(branch) &&
                isCompleted(repository) &&
                isCompleted(orgOrWorkspaceOrGroup) &&
                isCompleted(appScm)
                ? "Next"
                : "Skip"
            }
            onClick={() => {
              if (
                isCompleted(packageManager) &&
                isCompleted(branch) &&
                isCompleted(repository) &&
                isCompleted(orgOrWorkspaceOrGroup) &&
                isCompleted(appScm)
              ) {
                createCodeSettings({
                  variables: {
                    applicationDetails: {
                      applicationId: concierge_appId ?? appId,
                      scm: (appScm === AZURE ? "azure" : appScm) ?? null,
                      workspace: orgOrWorkspaceOrGroup ?? null,
                      language: packageManager ?? null,
                      organization: orgOrWorkspaceOrGroup ?? null,
                      group: orgOrWorkspaceOrGroup ?? null,
                      reponame: repository ?? null,
                      branch: branch ?? null,
                      path: codePath ?? "",
                    },
                  },
                }).then((r) => {
                  if (r.errors) {
                    enqueueSnackbar("Could not save SCM settings", {
                      variant: "error",
                      persist: false,
                    });
                  } else {
                    enqueueSnackbar("Successfully saved SCM settings", {
                      variant: "success",
                      persist: false,
                    });
                    concierge_readyToStep && concierge_readyToStep();
                  }
                });
              } else {
                concierge_readyToStep && concierge_readyToStep();
              }
            }}
            primary={true}
            data-test-id="concierge-scm-next-btn"
            className="w-80 hover:animate-none h-16 mx-auto mt-10"
          />
        </div>
      )}

      {error && (
        <div className="mt-4 ml-0 md:ml-[260px]">
          <ErrorAlert
            title={
              "Error creating repository settings, please try again or contact support"
            }
            isShowing={true}
          />
        </div>
      )}
    </>
  );
}

export function CodeScanning(): JSX.Element {
  return (
    <div
      id="alert-additional-content-5"
      className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
      role="alert"
    >
      <div className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Info</span>
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
          Code Scanning
        </h3>
      </div>
      <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
        SecureStack provides integrations with common centralised git
        repositories such as Bitbucket and GitHub that allow us to find and
        analyse package dependency files for potential vulnerabilities.
        Depending on where your application code is stored, select your Source
        Code Manager (SCM) provider.
      </div>
    </div>
  );
}
