import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Toggle } from "../../../functions/toggle";
import { ApplicationNavigation } from "./ApplicationNavigation";
import { AnalyzeButton } from "../../buttons/AnalyzeButton";
import { PatchNotes } from "../../badge/PatchNotes";
import { usePathAppId } from "../../../functions/hooks";
import { BreadCrumbs } from "../../breadcrumbs/BreadCrumbs";
import { QueryResult } from "@apollo/client";
import { Application, ApplicationStatusQuery, ApplicationStatusQueryVariables } from "../../../__generated__/graphql";
import { ApplicationStatus } from "../../../functions/hooks/useAppStatus";

type NavTopBarProps = {
  onMenuItemClicked: (open: boolean) => void;
  setCurrentApp: (appId: string) => void;
  disabled: boolean;
  pathname: string; applicationStatusQuery: QueryResult<ApplicationStatusQuery, ApplicationStatusQueryVariables>; applicationStatus: ApplicationStatus; appStatusData: Application | undefined;
};

export function NavBar({
  onMenuItemClicked,
  setCurrentApp,
  disabled,
  pathname,
  appStatusData,
  applicationStatus,
  applicationStatusQuery,
}: NavTopBarProps): JSX.Element {
  const appId = usePathAppId();
  return (
    <header className="md:left-28 top-0 z-40 fixed w-full flex-col border-b-dark-primary">
      <div className="relative z-10 flex-shrink-0 h-16 shadow-md bg-gray-200 dark:bg-dark-secondary  border-gray-300 flex">
        <button
          type="button"
          className="z-60 border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => onMenuItemClicked(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* can't use className `justify-between` because it's bugged and is `center` in the deployed css */}
        <div
          style={{
            justifyContent: "space-between",
          }}
          className="flex text-gray-500 ml-2 md:w-[calc(100vw-7rem)] w-full overflow-x-auto overflow-y-hidden md:overflow-hidden"
        >
          {disabled ? (
            <BreadCrumbs path={pathname} />
          ) : (
            <ApplicationNavigation setCurrentApp={setCurrentApp} />
          )}

          {/* <EnvironmentNavigation /> */}
          <div className="px-2 space-x-4 flex flex-row self-center">
            {!disabled && appId && <AnalyzeButton appStatusData={appStatusData} applicationStatus={applicationStatus} applicationStatusQuery={applicationStatusQuery} appId={appId} />}
            <div className="flex-row space-x-2 items-center flex">
              <Toggle />
              {/* <OnboardingCheckList /> */}
              <PatchNotes />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
