import { useUser } from "./useUser";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useState } from "react";
import { GET_AZURE_ORGS } from "../../graphql/queries/GetOrgsOrWorkspacesOrGroups";
import { SCM_SELECTION } from "../../views/create/categories/CodeSettings";
import { uniq } from "lodash";
import logGraphQLError from "../logGraphQLError";

export default function useAzureOrgSearchQuery(scm: string, page: number) {
    const user = useUser();
    const [organizationData, setOrganizationData] = useState<string[]>([]);
    const { AZURE } = SCM_SELECTION;

    const { loading, error, refetch } = useQuery(GET_AZURE_ORGS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            scm: scm === AZURE ? "azure" : scm,
            page: page,
            orgId: user?.selectedOrg,
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
            onError: logGraphQLError,
        },
        onCompleted: (d) => {
            let prevBranches = organizationData;
            let currentOrgs = d?.orgsOrWorkspacesOrGroups?.values?.map(
                (o: { __typename: string; accountName: string, accountId: string }) => o.accountName
            );
            let concatData = prevBranches.concat(currentOrgs);

            let results = concatData.filter((e) => {
                return e !== undefined;
            });

            let uniqueSet = uniq(results);

            setOrganizationData(uniqueSet);
        }
    })

    return { loadingAzOrgs: loading, orgAzError: error, organizationAzData: organizationData, hasMoreAzOrgs: false, orgAzRefetch: refetch };
}