import { useState } from "react";

export function useForm<FormData extends object>(initialState: FormData) {
  const [values, setValues] = useState<FormData>(initialState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    handleInputChange,
  };
};

export function Form({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}): JSX.Element {
  return (
    <form className="grid grid-cols-1 gap-y-6" onSubmit={onSubmit}>
      {children}
    </form>
  );
};
