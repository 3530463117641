import { ReactNode } from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: ReactNode;
}) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    // Use the router's history module to replace the url
    // console.log("APP STATE SHOULD BE LOGGED", appState);
    const returnTo = appState?.returnTo || window.location.pathname;
    history.push(returnTo);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin + "/auth/orgs/link",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
