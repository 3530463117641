import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { HomeIcon } from "@heroicons/react/solid";
import { Dispatch, MouseEventHandler } from "react";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { Button } from "../../../../../components/buttons/Button";
import { Modal } from "../../../../../components/dialogs/Modal";
import { Loader } from "../../../../../components/loading/Loader";
import { deriveFilesFromPackageManager } from "../../../../../functions/deriveFilesFromPackageManager";
import { useHistoryState, usePathAppId } from "../../../../../functions/hooks";
import { CREATE_OR_UPDATE_APP } from "../../../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { GitlabFolderStructure } from "../../../../../__generated__/graphql";

enum FILE_TYPE {
  COMMIT_FILE = "commit_file",
  DIRECTORY_FILE = "directory_file",
  TREE = "tree",
}

export function GitlabFileStructureExplorer({
  files,
  path,
  breadCrumbs,
  buildPath,
  isLoading,
  closeFileExplorer,
  codePath, setCodePath
}: {
  files: GitlabFolderStructure[];
  path: string;
  breadCrumbs: string[];
  isLoading: boolean;
  buildPath: MouseEventHandler<HTMLDivElement>;
  closeFileExplorer: () => void;
  codePath: string; setCodePath: Dispatch<string>;
}): JSX.Element {
  const [appId] = useHistoryState("appId");
  const appIdToEdit = usePathAppId();

  const [packageManager] = useHistoryState("packageManager");

  const [setFilePath, { loading }] = useMutation(CREATE_OR_UPDATE_APP, {
    onCompleted: (data) => {
      if (data?.createOrUpdateApplication) {
        closeFileExplorer();
      }
    },
  });

  const constructPathForQuery = breadCrumbs.map((i) => {
    return i === "" ? "" : i.concat("/");
  });

  const finalString = constructPathForQuery.join("");

  const splitString = (str: string) => {
    return str.substring(str.lastIndexOf("/") + 1, str.length);
  };

  const splitFiles = files.map((i) => {
    return {
      path: splitString(i?.path ?? ""),
      type: i.type,
    } as unknown as GitlabFolderStructure;
  });

  return (
    <Modal noVerticalMargin isOpen={true} onClose={closeFileExplorer} title="Path builder">
      <>
        <div className="text-gray-400 dark:text-gray-300 mb-2">
          <p className="text-sm dark:text-gray-400 text-gray-700">
            Locate which folder contains the package files you would like to
            use.
          </p>
        </div>

        <nav
          className="flex mb-2  border-b dark:border-b-gray-500 "
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-4">
            <li>
              <div
                id={""}
                onClick={buildPath}
                className="text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </div>
            </li>
            {breadCrumbs.map((crumb, idx) => {
              return crumb === "" ? null : (
                <li key={crumb}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <div
                      id={crumb}
                      onClick={buildPath}
                      className="cursor-pointer  flex ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {idx === breadCrumbs.length - 1 && (
                        <FaFolderOpen className=" mr-1 mt-0.4 w-4 h-4 dark:text-gray-500 text-gray-600" />
                      )}
                      {crumb}
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
        {isLoading ? (
          <Loader w={5} h={5} />
        ) : (
          splitFiles.map((file) => (
            <div key={file.path}>
              {file.type === FILE_TYPE.COMMIT_FILE ? (
                <span className="cursor-pointer group flex items-center dark:text-gray-500 text-gray-600">
                  <BsFillFileEarmarkTextFill className=" mr-1 w-4 h-4 dark:text-gray-500 text-gray-600" />

                  {file.path}
                </span>
              ) : (
                <div
                  id={file.path ?? ""}
                  onClick={buildPath}
                  className="cursor-pointer  group flex items-center dark:text-gray-400 text-gray-800"
                >
                  <FaFolder className="mr-1 w-4 h-4 group-hover:hidden dark:text-gray-400 text-gray-800" />
                  <FaFolderOpen className="group-hover:block hidden mr-1 w-4 h-4 dark:text-gray-500 text-gray-600" />
                  {file.path}
                </div>
              )}
            </div>
          ))
        )}

        <div
          id="alert-additional-content-5"
          className="p-4 mt-2  border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-blue-700 dark:text-blue-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
              {isLoading
                ? "Detecting files..."
                : deriveFilesFromPackageManager(splitFiles, packageManager)
                  ?.length === 0
                  ? "No files found within folder"
                  : "Files found within folder"}
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            {!isLoading &&
              deriveFilesFromPackageManager(splitFiles, packageManager)
                ?.length === 0 ? (
              <p className="ml-4 ">No files detected</p>
            ) : (
              !isLoading &&
              deriveFilesFromPackageManager(splitFiles, packageManager)?.map(
                (file) => (
                  <h1 key={file} className="flex mt-1.5 items-center">
                    <BsFillFileEarmarkTextFill className="w-4 h-4 mr-1 dark:text-gray-500 text-gray-600" />
                    {file}
                  </h1>
                )
              )
            )}
          </div>
        </div>
      </>

      <div className="text-right flex justify-end items-center mt-2">
        <Button
          isLoading={loading}
          onClick={() => {
            setCodePath(finalString);
            setFilePath({
              variables: {
                applicationDetails: {
                  applicationId: appId ?? appIdToEdit,
                  path: finalString,
                },
              },
            })
          }}
          primary={true}
          label={`use ${path}`}
        />
      </div>
    </Modal>
  );
};
