import { Dispatch, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SelectedIssue } from "./App";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import RenderFeature from "./components/FeatureFlags/Features";
import GenericSuspenseFallback from "./components/GenericSuspenseFallback";
import { TopbarLayout } from "./components/nav/TopbarLayout";
import { usePathAppId } from "./functions/hooks";
import useAppStatus from "./functions/hooks/useAppStatus";
import { useUser } from "./functions/hooks/useUser";
import { NoApplicationSelected } from "./partials/application/NoApplicationSelected";
import { CreateApplicationRouter } from "./views/CreateApplicationRouter";
import { Cloud } from "./views/integrations/aws/Cloud";
import { SelectedIssuesView } from "./views/issues/SelectedIssuesView";

const PaymentSettingsView = lazy(() => import("./views/settings/PaymentSettingsView"));
const PackagesExplorer = lazy(() => import("./views/PackagesExplorer"));
const Mfa = lazy(() => import("./views/auth/Mfa"));
const NewIssuesView = lazy(() => import("./views/NewIssuesView"));
const DashboardView2 = lazy(() => import("./views/dashboard_2/DashboardView2"));
const ChangesHistoryView = lazy(() => import("./views/ChangesHistoryView"));
const ApplicationAssets = lazy(() => import("./views/AssetsView"));
const IntegrationSettingsView = lazy(() => import("./views/settings/IntegrationSettingsView"));
const ListAccounts = lazy(() => import("./components/cloud/Integration/ListAccounts"));
const SuccessPayment = lazy(() => import("./components/payment/SuccessPayment"));
const FailPayment = lazy(() => import("./components/payment/FailPayment"));
const Cli = lazy(() => import("./views/DownloadCliView"));
const HubSpotSupport = lazy(() => import("./views/HubSpotSupport"));
const Settings = lazy(() => import("./views/SettingsView").then(({ Settings }) => ({ default: Settings })));
const CreateIssue = lazy(() => import("./components/jira/CreateIssue"));
const ConnectAccount = lazy(() => import("./components/cloud/ConnectAccount"));
const ApplicationListView2 = lazy(() => import("./views/application_list_2/ApplicationListView2"));
const Compliance = lazy(() => import("./views/ComplianceView"));
const HistoricalScans = lazy(() => import("./views/HistoricalScansView"));
const SbomView = lazy(() => import("./views/SbomView"));
const HistoricalSecretsIssuesView = lazy(() => import("./views/HistoricalIssuesView").then(({ HistoricalSecretsIssuesView }) => ({ default: HistoricalSecretsIssuesView })));
const HistoricalExposureIssuesView = lazy(() => import("./views/HistoricalIssuesView").then(({ HistoricalExposureIssuesView }) => ({ default: HistoricalExposureIssuesView })));
const HistoricalCloudIssuesView = lazy(() => import("./views/HistoricalIssuesView").then(({ HistoricalCloudIssuesView }) => ({ default: HistoricalCloudIssuesView })));
const HistoricalCodeIssuesView = lazy(() => import("./views/HistoricalIssuesView").then(({ HistoricalCodeIssuesView }) => ({ default: HistoricalCodeIssuesView })));

export function ApplicationStatusWrapper({ selectedIssue, setSelectedIssue }: { selectedIssue: SelectedIssue; setSelectedIssue: Dispatch<SelectedIssue>; }): JSX.Element {
  const user = useUser();
  const appId = usePathAppId();
  const { appStatusData, applicationStatus, applicationStatusQuery } = useAppStatus(user, appId);

  return <>
    <ProtectedRoute path="/">
      <SelectedIssuesView setSelectedIssue={setSelectedIssue} selectedIssue={selectedIssue} />
      <TopbarLayout appStatusData={appStatusData} applicationStatus={applicationStatus} applicationStatusQuery={applicationStatusQuery}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          <Route path="/mfa">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <Mfa />
            </Suspense>
          </Route>

          <Route path="/create" component={CreateApplicationRouter} />

          <Route exact path="/dashboard" component={NoApplicationSelected} />

          <Route exact path="/dashboard/:appId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <DashboardView2 appStatusData={appStatusData} applicationStatus={applicationStatus} applicationStatusQuery={applicationStatusQuery} setSelectedIssue={setSelectedIssue} selectedIssue={selectedIssue} />
            </Suspense>
          </Route>

          <Route exact path="/changes" component={NoApplicationSelected} />
          <Route path={["/changes/:appId/:scanId", "/changes/:appId"]}>
            <Suspense fallback={<GenericSuspenseFallback />}>
              <ChangesHistoryView />
            </Suspense>
          </Route>

          <Route exact path="/assets" component={NoApplicationSelected} />
          <Route path="/assets/:appId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <ApplicationAssets />
            </Suspense>
          </Route>

          <Route exact path="/sbom" component={NoApplicationSelected} />
          <Route path="/sbom/:appId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <SbomView />
            </Suspense>
          </Route>

          <Route path="/applications">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <ApplicationListView2 />
            </Suspense>
          </Route>

          <Route exact path="/issues/history/:appId/code/:scanId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HistoricalCodeIssuesView setSelectedIssue={setSelectedIssue} />
            </Suspense>
          </Route>
          <Route exact path="/issues/history/:appId/cloud/:scanId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HistoricalCloudIssuesView setSelectedIssue={setSelectedIssue} />
            </Suspense>
          </Route>
          <Route exact path="/issues/history/:appId/exposure/:scanId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HistoricalExposureIssuesView setSelectedIssue={setSelectedIssue} />
            </Suspense>
          </Route>
          <Route exact path="/issues/history/:appId/secrets/:scanId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HistoricalSecretsIssuesView setSelectedIssue={setSelectedIssue} />
            </Suspense>
          </Route>

          <Route path="/issues" exact component={NoApplicationSelected} />
          <Route path={["/issues/:appId"]}>
            <Suspense fallback={<GenericSuspenseFallback />}>
              <NewIssuesView setSelectedIssue={setSelectedIssue} selectedIssue={selectedIssue} />
            </Suspense>
          </Route>

          <Route exact path="/history" component={NoApplicationSelected} />
          <Route path="/history/:appId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HistoricalScans />
            </Suspense>
          </Route>

          <Route exact path="/compliance" component={NoApplicationSelected} />
          <Route path={["/compliance/:appId/:complianceName", "/compliance/:appId"]}>
            <Suspense fallback={<GenericSuspenseFallback />}>
              <Compliance />
            </Suspense>
          </Route>

          <Route path="/payment/:plan">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <PaymentSettingsView />
            </Suspense>
          </Route>
          <Route path="/success">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <SuccessPayment />
            </Suspense>
          </Route>
          <Route path="/fail">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <FailPayment />
            </Suspense>
          </Route>

          <Route path="/download-cli">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <Cli />
            </Suspense>
          </Route>

          <Route path="/integrations">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <IntegrationSettingsView />
            </Suspense>
          </Route>

          <Route path="/support">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <HubSpotSupport />
            </Suspense>
          </Route>

          <Route path="/package-explorer">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <PackagesExplorer />
            </Suspense>
          </Route>

          <Route
            path="/jira/createIssue/:problem">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <CreateIssue />
            </Suspense>
          </Route>

          <Route path="/aws-accounts">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <ListAccounts />
            </Suspense>
          </Route>

          <Route path="/settings">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <Settings />
            </Suspense>
          </Route>

          <Route path="/cloud/:applicationId">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <Cloud />
            </Suspense>
          </Route>

          <RenderFeature name="cloud-scanning">
            <Route exact path="/cloud/connect">
              <Suspense fallback={<GenericSuspenseFallback />}>
                <ConnectAccount />
              </Suspense>
            </Route>
          </RenderFeature>
        </Switch>
      </TopbarLayout>
    </ProtectedRoute>
  </>;
}
