import { SnackbarProvider } from "notistack";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import ApolloWrapper from "./apollo/AuthorizedApolloProvider";
import { supabaseClient, SupabaseAuth } from "./auth/supabase";
import { AmplitudeRegisterAuth0User } from "./components/data/Amplitude";
import { Themer } from "./functions/components/Themer";
import "./index.css";

const queryClient = new QueryClient();

// <React.StrictMode> <SupabaseAuthProvider></SupabaseAuthProvider>
render(
  <BrowserRouter>
    <SupabaseAuth />
    <SnackbarProvider maxSnack={3}>
      <ApolloWrapper supabaseClient={supabaseClient}>
        <AmplitudeRegisterAuth0User />
        <QueryClientProvider client={queryClient}>
          <Themer>
            <App />
          </Themer>
        </QueryClientProvider>
      </ApolloWrapper>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
