import { gql } from "../../__generated__/gql";

export const CREATE_OR_UPDATE_APP = gql(`
  mutation CreateOrUpdateApplication(
    $applicationDetails: ApplicationCreateOrUpdateInput!
    $orgId: String
  ) {
    createOrUpdateApplication(
      applicationDetails: $applicationDetails
      orgId: $orgId
    ) {
      name
      group
      path
      Tags {
        name
      }
      target
      language
      description
      applicationId
      scm
      branch
      reponame
      assetScan
    }
  }
`);
