import { DocumentNode } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useState } from "react";
import { SCM_SELECTION } from "../../views/create/categories/CodeSettings";
import { hasMoreDataToQuery } from "../hasMoreDataToQuery";
import { useHistoryState } from "../hooks";
import { useUser } from "./useUser";
import { BITBUCKET_REPOSITORIES, GITHUB_REPOSITORIES, GITLAB_REPOSITORIES, AZURE_DEVOPS_REPOSITORIES } from "../../graphql/queries/Repositories";
import { uniq } from "lodash";
import logGraphQLError from "../logGraphQLError";

const createBitbucketQueryVars = (workspace: string, currentPage: number) => {
  return {
    workspace: workspace,
    page: currentPage,
  };
};

const createGithubQueryVars = (organization: string, currentPage: number) => {
  return {
    organization: organization,
    page: currentPage,
  };
};

const createGitlabQueryVars = (group: string, currentPage: number) => {
  return {
    group: group,
    page: currentPage,
  };
};

const createAzureDevOpsQueryVars = (organization: string, currentPage: number) => {
  return {
    organizationAz: organization,
    page: currentPage,
  };
};

type Value = {
  __typename: string;
  type: string;
  is_private: boolean;
  slug: string;
  name: string;
  path: string;
}

type Repositories = {
  __typename: string;
  pagelen: number;
  size: number;
  values: Value[];
  page: number;
}

type ScmQueryVars = {
  workspace?: string;
  organization?: string;
  group?: string;
  page: number;
}

export const useRepositorySearchQuery = (
  scm: string,
  pageNumber: number,
  orgOrWorkspace: string
) => {
  const user = useUser();
  const { BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;
  const [appScm] = useHistoryState("appScm");
  const [workspace, setWorkspace] = useState("");
  const [organization, setOrganization] = useState("");
  const [group, setGroup] = useState("");
  const [azureOrg, setAzureOrg] = useState<string>("");

  const [repositoryData, setRepositoryData] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const hasChangedWorkspace = workspace !== "" && orgOrWorkspace !== workspace;
  const hasChangedGroup = group !== "" && orgOrWorkspace !== group;
  const hasChangedOrganization = organization !== "" && orgOrWorkspace !== organization;
  const hasChangedAzureOrganization = azureOrg !== "" && orgOrWorkspace !== azureOrg;
  const SELECT_QUERY_FROM_SCM: DocumentNode = ((): DocumentNode => {
    const s = appScm ?? scm;
    if (s === BITBUCKET) {
      return BITBUCKET_REPOSITORIES;
    }
    if (s === GITHUB) {
      return GITHUB_REPOSITORIES;
    }
    if (s === GITLAB) {
      return GITLAB_REPOSITORIES;
    }
    if (s === AZURE || s === "azure") {
      return AZURE_DEVOPS_REPOSITORIES;
    }
    // default: github
    return GITHUB_REPOSITORIES;
  })();

  const validatePageNumber = (pageNumber: number) => {
    if (hasChangedWorkspace || hasChangedOrganization || hasChangedGroup || hasChangedAzureOrganization) {
      return 1;
    } else return pageNumber;
  };

  const createQueryVars: ScmQueryVars = ((): ScmQueryVars => {
    const s = appScm ?? scm;
    if (s === BITBUCKET) {
      return createBitbucketQueryVars(orgOrWorkspace, validatePageNumber(pageNumber));
    }
    if (s === GITHUB) {
      return createGithubQueryVars(orgOrWorkspace, validatePageNumber(pageNumber));
    }
    if (s === GITLAB) {
      return createGitlabQueryVars(orgOrWorkspace, validatePageNumber(pageNumber));
    }
    if (s === AZURE || s === "azure") {
      return createAzureDevOpsQueryVars(orgOrWorkspace, validatePageNumber(pageNumber));
    }
    // default: github
    return createGithubQueryVars(orgOrWorkspace, validatePageNumber(pageNumber));
  })();

  const joinQueryDataToState = (currentRepositories: string[]) => {
    let prevRepositories = [...repositoryData];

    let concatData = prevRepositories.concat(currentRepositories);

    return concatData.filter((e) => {
      return e !== undefined;
    });
  };

  const hasMoreDataToRetrieve = (repositories: Repositories) => {
    const s = appScm ?? scm;
    if (s === GITHUB) {
      setHasMore(
        repositories?.values?.length > repositories?.pagelen ||
        repositories?.values?.length !== 0
      );
    }
    if (s === BITBUCKET) {
      setHasMore(
        hasMoreDataToQuery(
          repositories?.size,
          repositories?.pagelen,
          repositories?.values,
          validatePageNumber(pageNumber)
        )
      );
    }
    if (s === GITLAB) {
      setHasMore(false);
    }
    if (s === AZURE) {
      setHasMore(false);
    }
  };

  const { loading, error, refetch } = useQuery(
    SELECT_QUERY_FROM_SCM,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onError: logGraphQLError,
      variables: { ...createQueryVars, orgId: user?.selectedOrg },
      onCompleted: ({ repositories }) => {
        let currentRepositories: string[];
        const s = appScm ?? scm;

        if (s === GITLAB) {
          currentRepositories = repositories?.values?.map((b: Value) => b.path);
        } else {
          currentRepositories = repositories?.values?.map((b: Value) => b.name);
        }

        if (hasChangedWorkspace) {
          setWorkspace(orgOrWorkspace);
          setOrganization(orgOrWorkspace);
          setGroup(orgOrWorkspace);
          setAzureOrg(orgOrWorkspace);
          setRepositoryData(uniq(currentRepositories));
        } else {
          setWorkspace(orgOrWorkspace);
          setOrganization(orgOrWorkspace);
          setGroup(orgOrWorkspace);
          setAzureOrg(orgOrWorkspace);
          const results = joinQueryDataToState(currentRepositories);
          setRepositoryData(uniq(results));
        }

        hasMoreDataToRetrieve(repositories);
      },
    }
  );

  return { loading, error, repositoryData, hasMore, refetch };
};
