import { useSnackbar } from "notistack";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Button } from "../../../components/buttons/Button";
import { SimpleError } from "../../../errors/SimpleError";
//import { downloadFile } from "../../../functions/downloadFile";
import { useApplicationStatus } from "../../../functions/hooks/useApplicationStatus";
//import { DISPLAY_SBOM } from "../../../graphql/queries/DisplaySBOM";
//import { useLazyQuery } from "@apollo/client";
import { useSbomDownload } from "../History";

export function prettifyXml(sourceXml: string) {
  let resultXml: string = "";
  try {
    const parser: DOMParser = new DOMParser();
    const xmlDoc: XMLDocument = parser.parseFromString(sourceXml, 'text/xml');
    const xsltDoc: XMLDocument = parser.parseFromString([
      '<xsl:stylesheet version="3.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:output omit-xml-declaration="yes" indent="yes"/>',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '</xsl:stylesheet>'
    ].join('\n'), 'text/xml');
    const xsltProcessor: XSLTProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    const fragment = xsltProcessor.transformToFragment(xmlDoc, xsltDoc);
    resultXml = new XMLSerializer().serializeToString(fragment);
  } catch { }
  return resultXml !== "" ? resultXml : sourceXml;
};

export function Download(props: { rootPath: string }): JSX.Element {
  const { appStatusData } = useApplicationStatus();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [downloadSBOM, { error }] = useSbomDownload(appStatusData?.name ?? "", enqueueSnackbar);

  /* const [downloadSBOM, { variables, error }] = useLazyQuery(DISPLAY_SBOM, {
    onCompleted: (data) => {
      enqueueSnackbar("SBOM successfully generated and is ready for download", {
        variant: "success",
        autoHideDuration: 4000,
      });
      downloadFile({
        data: variables?.dataFormat === "json" ? JSON.stringify(data?.fSBOMDisplay, null, 4).toString() : data?.fSBOMDisplay.toString(),
        fileName: `sbom-${appStatusData?.name}.${variables?.dataFormat === "json" ? "json" : "xml"}`,
        fileType: variables?.dataFormat === "json" ? "text/json" : "text/xml",
      });
    },
    onError: () => {
      enqueueSnackbar("Error generating SBOM, please try again", {
        variant: "error",
        autoHideDuration: 4000,
      });
    },
  }); */

  if (error) {
    return <SimpleError error={error.message} />;
  }

  return (
    <div className="text-center  rounded-2xl  px-4 sm:px-6 py-6">
      <MdOutlineScreenSearchDesktop className="mx-auto w-14 h-14 text-gray-300 opacity-70" />
      <h3 className="mt-2 text-md font-medium text-gray-900 dark:text-gray-300">
        Download SBOM
      </h3>
      <p className="my-1 text-sm text-gray-700 dark:text-gray-500">
        Download your SBOM in CycloneDX JSON or XML format.
      </p>
      <p className="my-1 text-sm text-gray-700 dark:text-gray-500">
        SPDX XML format coming soon.
      </p>
      <div className="grid grid-cols-2 grid-rows-2 mt-6 gap-3">
        <Button
          label="Download CycloneDX JSON"
          primary
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            downloadSBOM({
              variables: {
                //orgId: user?.selectedOrg,
                fSbomDisplayId: id,
                dataFormat: "json",
                type: "cyclonedx",
              }
            });
          }}
        />
        <Button
          label="Download CycloneDX XML"
          primary
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            downloadSBOM({
              variables: {
                //orgId: user?.selectedOrg,
                fSbomDisplayId: id,
                dataFormat: "xml",
                type: "cyclonedx",
              }
            });
          }}
        />
        <Button
          label="Download SPDX JSON"
          primary
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            downloadSBOM({
              variables: {
                //orgId: user?.selectedOrg,
                fSbomDisplayId: id,
                dataFormat: "json",
                type: "spdx",
              }
            });
          }}
        />
        <Button
          label="Download SPDX XML"
          primary
          disabled
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            /* downloadSBOM({
              variables: {
                //orgId: user?.selectedOrg,
                fSbomDisplayId: id,
                dataFormat: "xml",
                // type: "cyclonedx",
              }
            }); */
          }}
        />
      </div>
    </div>
  );
}
