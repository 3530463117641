import { DetailedHTMLProps, HTMLAttributes } from "react";

type StackListProps<T> = {
  items?: T[];
  render: (item: T) => React.ReactNode;
  itemClick: (item: T) => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>;

export function StackedList<T,>({
  items,
  render,
  itemClick,
  ...props
}: StackListProps<T>): JSX.Element {
  return (
    <ul className="divide-y divide-gray-200 dark:divide-gray-500" {...props}>
      {items?.map((item, idx) => {
        const renderedItem = render(item);
        return renderedItem == null ? null : (
          <li onClick={() => itemClick(item)} key={idx}>
            {renderedItem}
          </li>
        );
      }
      )}
    </ul>
  );
};
