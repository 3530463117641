import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Dispatch, useState } from "react";
import { deriveFilesFromPackageManager } from "../../../../../functions/deriveFilesFromPackageManager";
import { useHistoryState } from "../../../../../functions/hooks";
import { useUser } from "../../../../../functions/hooks/useUser";
import { AutoFileDetection } from "./AutoDetection";
import { GitlabFileStructureExplorer } from "./GitlabFileStructureExplorer";
import { GET_GITLAB_REPO_STRUCTURE } from "../../../../../graphql/queries/FolderStructure";
import useScmOrgOrWorkspaceHistory from "../../../../../functions/hooks/useScmOrgOrWorkspaceHistory";
import { GitlabFolderStructure } from "../../../../../__generated__/graphql";
import logGraphQLError from "../../../../../functions/logGraphQLError";

export function GitlabStructure({ codePath, setCodePath }: { codePath: string, setCodePath: Dispatch<string> }): JSX.Element {
  const [folderStructure, setFolderStructure] = useState<GitlabFolderStructure[]>([]);
  const [repository] = useHistoryState("repository");
  const [branch] = useHistoryState("branch");
  const [orgOrWorkspaceOrGroup] = useScmOrgOrWorkspaceHistory();
  const [isViewingFileExplorer, setIsViewingFileExplorer] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState<string[]>([]);
  const [packageManager] = useHistoryState("packageManager");
  const user = useUser();
  const { loading, error } = useQuery(GET_GITLAB_REPO_STRUCTURE, {
    variables: {
      path: currentPath,
      page: "",
      group: orgOrWorkspaceOrGroup,
      gitlabProject: repository,
      gitlabBranchname: branch,
      orgId: user?.selectedOrg,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
    onError: logGraphQLError,
    onCompleted: (data) => {
      // @ts-expect-error folderStructure has values
      setFolderStructure(data?.folderStructure?.values);
    },
  });

  if (error) {
    return <p className="text-red-600">Error getting folder structure</p>;
  }

  const pathBuilder = (path: string) => {
    if (!path) {
      setCurrentPath("");
      setBreadCrumbs([]);
    }

    const indexOfPath = breadCrumbs.indexOf(path);
    //Condition for going back files
    if (indexOfPath >= 0) {
      setCurrentPath(path);
      setBreadCrumbs(
        breadCrumbs.filter((crumb, index) => index <= indexOfPath)
      );
      return;
    }

    setBreadCrumbs((prevCrumbs) => [...prevCrumbs, path]);
    setCurrentPath(path);
  };

  const onClose = () => {
    setIsViewingFileExplorer(false);
  };

  const splitString = (str: string) => {
    return str.substring(str.lastIndexOf("/") + 1, str.length);
  };

  const splitFiles = folderStructure.map((i) => {
    return {
      path: splitString(i?.path ?? ""),
      type: i.type,
    } as unknown as GitlabFolderStructure;
  });

  return (
    <>
      <div>
        {!isViewingFileExplorer ? (
          <AutoFileDetection
            isLoading={loading}
            viewExplorer={() => setIsViewingFileExplorer(true)}
            files={deriveFilesFromPackageManager(splitFiles, packageManager)}
          />
        ) : (
          <GitlabFileStructureExplorer
            buildPath={(path) => pathBuilder(path.currentTarget.id)}
            path={currentPath}
            files={folderStructure}
            breadCrumbs={breadCrumbs}
            isLoading={loading}
            closeFileExplorer={onClose}
            codePath={codePath}
            setCodePath={setCodePath}
          />
        )}
      </div>
    </>
  );
};
