import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce, isEqual, delay } from "lodash";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { History } from "history";

type UseFormReturnType<T> = [
  T,
  {
    setValues: Dispatch<SetStateAction<T>>;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    reset: () => void;
    setInitialStateToCurrent: () => void;
    isDirty: boolean;
    lastFocusedElement: MutableRefObject<HTMLInputElement | null>;
  }
];

export const useForm = <FormData extends object>(
  initialState: FormData
): UseFormReturnType<FormData> => {
  const [values, setValues] = useState<FormData>(initialState);
  const [cleanState, setCleanState] = useState<FormData>(initialState);
  const lastFocusedElementRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    lastFocusedElementRef.current = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const setInitialStateToCurrent = () => {
    setCleanState(values);
  };

  const reset = () => {
    setValues(cleanState);
  };

  const isDirty = useMemo(() => {
    return !isEqual(values, cleanState);
  }, [values, cleanState]);

  return [
    values,
    {
      setValues,
      handleInputChange,
      reset,
      isDirty,
      setInitialStateToCurrent,
      lastFocusedElement: lastFocusedElementRef,
    },
  ];
};

type DebounceAnyFunction = (...args: any[]) => any;

export const useDebounce = (
  fn: DebounceAnyFunction,
  delay: number,
  options?: any
) => {
  return useMemo(() => {
    return debounce(fn, delay, options);
  }, [fn, delay, options]);
};

export const useDelay = (
  fn: DebounceAnyFunction,
  wait: number,
  ...args: any[]
) => {
  return useCallback(() => {
    return delay(fn, wait, args);
  }, [fn, wait, args]);
};

export default function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

type ResponseValues<T extends string[]> = {
  [K in T[number]]: string;
};

export const useQueryParams: (
  params: string[]
) => ResponseValues<typeof params> = (params) => {
  const { search } = useLocation();
  return useMemo<ResponseValues<typeof params>>(() => {
    if (!search) {
      return Object.fromEntries(params.map((a) => [a, ""]));
    }

    let paramStore: ResponseValues<typeof params> = {};
    const searchParams = new URLSearchParams(search);
    params.forEach((param) => {
      if (searchParams.has(param)) {
        const value = searchParams.get(param);
        paramStore[param] = value || "";
      }
    });

    return paramStore;
  }, [search, params]);
};

export const useQueryParam = (paramName: string) => {
  const queryParams = useQueryParams([paramName]);
  return queryParams[paramName];
};

export const mergeQueryParams = (
  location: any,
  updateObj: { [key: string]: string }
) => {
  const searchParams = new URLSearchParams(location.search || "");
  Object.entries(updateObj).forEach(([key, value]) => {
    if (!value) {
      searchParams.delete(key);
      return;
    }

    searchParams.set(key, value);
  });

  return searchParams.toString();
};

export const updateSearchValue = (
  history: History,
  key: string,
  value: string
) => {
  history.replace({
    search: mergeQueryParams(history.location, { [key]: value }),
  });
};

type HistoryStateReturn = [string, (v: string) => void];

export const useHistoryState: (param: string) => HistoryStateReturn = (
  param
) => {
  const history = useHistory();
  const value = useQueryParam(param);

  return [value, (v: string) => updateSearchValue(history, param, v)];
};

const v4uuid = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);
export function usePathAppId(): string | undefined {
  const { search } = useLocation();
  const urlSearch = new URLSearchParams(search);
  const urlAppId = urlSearch.get("appId");

  const match = useRouteMatch<{ appId?: string; action?: string }>(
    "/:action/:appId"
  );
  const settings_match = useRouteMatch<{ appId?: string }>(
    "/settings/applications/:appId"
  );
  const historical_issues_match = useRouteMatch<{ appId?: string }>(
    "/issues/history/:appId"
  );
  // See if we can find the app uuid (appId)
  const appId: ReturnType<typeof usePathAppId> = urlAppId
    ? urlAppId
    : (match?.params?.appId || "").match(v4uuid)
      ? match?.params?.appId
      : (settings_match?.params?.appId || "").match(v4uuid)
        ? settings_match?.params?.appId
        : (historical_issues_match?.params.appId ?? "").match(v4uuid)
          ? historical_issues_match?.params.appId
          : undefined;

  return appId;
}
