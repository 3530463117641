import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { NoApplications } from "../onboarding/NoApplications";
import { useNumOfApps } from "../../functions/hooks/useNumOfApps";
import useConciergeRedirect from "../../functions/hooks/useConciergeRedirect";

export function NoApplicationSelected(): JSX.Element {
  const numOfapps = useNumOfApps();
  useConciergeRedirect(numOfapps ?? "loading");
  if (numOfapps === 0) {
    return <NoApplications />
  }
  return (
    <div className="bg-white dark:bg-dark-main rounded-xl mt-5 min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <AiOutlineInfoCircle className="w-20 h-20 text-blue-400 mb-5 md:mb-0" />
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-3xl dark:text-gray-200 font-extrabold text-gray-800 tracking-tight sm:text-5xl">
                No application selected
              </h1>
              <p className="mt-2 text-xl dark:text-gray-300 text-gray-700">
                Please{" "}
                { /* @ts-ignore ts(2339) - use is valid */}
                <button className="text-blue-600 underline" onClick={() => document.querySelector('.application-navigation')?.click()}>select</button>
                {" "}or{" "}
                <Link className="text-blue-600 underline" to="/create">create</Link>
                {" "}an application
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
