import { gql } from "../../__generated__/gql";

export const ListFAWSAccounts = gql(`
  query ListFAWSAccounts_1($orgId: String) {
    dbfCloudAccounts (orgId: $orgId) {
      name
      id
      is_active
      role
    }
  }
`);
