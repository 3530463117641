import { AiOutlineCloud } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { MdOutlineScreenSearchDesktop, MdSecurity } from "react-icons/md";
import GoGistSecret from "../../assets/images/GoGistSecret";
import { DashboardTableSkeleton } from "../../components/skeletons/TableSkeleton";
import { LastScanned } from "./dashboard2";
import { SiLinuxcontainers } from "react-icons/si";

export function formatDate(date: Date) {
  if (
    date.toString() === "Invalid Date" ||
    !date?.toLocaleString ||
    !date?.toLocaleTimeString
  ) {
    return "Unable to find a date for this scan.";
  } else {
    const dateString = date
      .toLocaleString("en-US", { dateStyle: "full" })
      .split(", ");
    dateString.shift();
    return dateString?.join(", ") + " at " + date.toLocaleTimeString("en-US");
  }
}

export function formatDateShort(date: Date) {
  return formatDate(date).split(" at ").at(0);
}

function HistoryPickerElement({
  scanName,
  date,
  icon,
  iconBg,
}: {
  scanName: string;
  date: Date;
  icon: JSX.Element;
  iconBg: string;
}): JSX.Element {
  return (
    <div
      className="flex flex-row flex-nowrap justify-between"
      title={`${scanName} scan performed ${formatDate(date)}`}
    >
      <div className={`my-auto p-2 ${iconBg} rounded-md`}>{icon}</div>
      <div className="my-auto ml-2 truncate">{formatDate(date)}</div>
    </div>
  );
}

export default function HistoryPicker({
  exposureDate,
  cloudDate,
  codeDate,
  secretsDate,
  isLoading,
  sbomDate,
  containersDate,
}: {
  exposureDate: LastScanned;
  codeDate: LastScanned;
  cloudDate: LastScanned;
  secretsDate: LastScanned;
  isLoading: boolean;
  sbomDate?: LastScanned;
  containersDate: LastScanned;
}): JSX.Element | null {
  if (isLoading) {
    return <DashboardTableSkeleton />;
  }

  return !exposureDate.valid &&
    !cloudDate.valid &&
    !codeDate.valid &&
    !secretsDate.valid &&
    !containersDate.valid &&
    (sbomDate == null || !sbomDate.valid) ? null : (
    <>
      <div className="rounded-lg bg-opacity-75 dark:bg-dark-secondary bg-light-seconday p-4 flex flex-col dark:text-gray-500 gap-2">
        {exposureDate.valid && (
          <HistoryPickerElement
            date={exposureDate.date}
            scanName="Exposure"
            icon={<MdSecurity className="w-4 h-4 text-orange-700" />}
            iconBg="bg-orange-100"
          />
        )}
        {codeDate.valid && (
          <HistoryPickerElement
            date={codeDate.date}
            scanName="Code"
            icon={<BsCodeSlash className="w-4 h-4 text-green-700" />}
            iconBg="bg-green-100"
          />
        )}
        {sbomDate && sbomDate.valid ? (
          <HistoryPickerElement
            date={sbomDate.date}
            scanName="SBOM"
            icon={
              <MdOutlineScreenSearchDesktop className="w-4 h-4 text-green-700" />
            }
            iconBg="bg-green-100"
          />
        ) : null}
        {cloudDate.valid && (
          <HistoryPickerElement
            date={cloudDate.date}
            scanName="Cloud"
            icon={<AiOutlineCloud className="w-4 h-4 text-blue-700" />}
            iconBg="bg-blue-100"
          />
        )}
        {secretsDate.valid && (
          <HistoryPickerElement
            date={secretsDate.date}
            scanName="Secrets"
            icon={<GoGistSecret className="w-4 h-4 text-pink-700" />}
            iconBg="bg-pink-100"
          />
        )}
        {containersDate.valid && (
          <HistoryPickerElement
            date={containersDate.date}
            scanName="Containers"
            icon={<SiLinuxcontainers className="w-4 h-4 text-blue-900" />}
            iconBg="bg-blue-100"
          />
        )}
      </div>
    </>
  );
}
