type SolutionProps = {
  solution: string;
};

export function Solution({ solution }: SolutionProps): JSX.Element {
  return (
    <div className="dark:text-gray-300 text-gray-900">
      <code>
        {
          "An automated solution not available for this issue just yet. If you'd like personalized advice on how to resolve issues, please get in touch."
        }
      </code>
    </div>
  );
}
