import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { CreateSingleApplication } from "./create/single/CreateSingleApplication";
import useTitle from "../functions/hooks/useTitle";

export function CreateApplicationRouter(): JSX.Element {
  const { path } = useRouteMatch();
  useTitle("Create App", true);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={`${path}/single`} />
      </Route>
      <Route path={`${path}/single`}>
        <CreateSingleApplication />
      </Route>
      <Redirect to={`${path}/single`} />
    </Switch>
  );
};
