import { Dispatch } from "react";
import { SelectedIssue } from "../../App";
import { CloudProblemSection } from "../../components/cloud/CloudProblemSection";
import { Modal } from "../../components/dialogs/Modal";
import { Loader } from "../../components/loading/Loader";
import { usePathAppId } from "../../functions/hooks";
import {
  ParsedCloudIssueRow,
  ParsedCodeIssueRow,
  ParsedExposureIssueRow,
  ParsedSecretsIssueRow,
  useApplicationIssues,
  ParsedTrivyIssueRow,
} from "../../functions/hooks/useApplicationIssues";
import { SelectedCodeIssueView } from "./code/SelectedCodeIssueView";
import { SelectedExposureIssueView } from "./exposure/SelectedExposureIssueView";
import { SelectedSecretIssue } from "./secrets/SelectedSecretIssue";
import { SelectedContainerIssueView } from "./containers/SelectedContainerIssueView";

export function SelectedIssuesView({
  setSelectedIssue,
  selectedIssue,
}: {
  setSelectedIssue: Dispatch<SelectedIssue>;
  selectedIssue: SelectedIssue;
}): JSX.Element {
  const appId = usePathAppId();
  const { loading, allCodeEnrichedIssueObjects } = useApplicationIssues(
    appId ?? "",
  );

  if (selectedIssue == null) {
    return <></>;
  }

  return (
    <Modal
      noVerticalMargin
      width="max-w-6xl"
      isOpen={selectedIssue != null}
      closeWhenClickingBackground
      onClose={() => {
        setSelectedIssue(null);
      }}
    >
      {selectedIssue.source === "exposure" ? (
        <SelectedExposureIssueView
          key={0}
          setSelectedIssue={setSelectedIssue}
          selectedIssue={selectedIssue as ParsedExposureIssueRow}
        />
      ) : null}
      {selectedIssue.source === "cloud" ? (
        <CloudProblemSection
          key={1}
          setSelectedIssue={setSelectedIssue}
          currentProblem={selectedIssue as ParsedCloudIssueRow}
        />
      ) : null}
      {selectedIssue.source === "code" ? (
        loading ? (
          <Loader key={2} h={6} w={6} />
        ) : (
          <SelectedCodeIssueView
            key={3}
            setSelectedIssue={setSelectedIssue}
            allIssues={allCodeEnrichedIssueObjects
              .filter(
                (allIssue) =>
                  (selectedIssue as ParsedCodeIssueRow)?.packageName ===
                  allIssue.packageName,
              )
              .sort((a, b) => {
                return b.vulnerableVersion.length - a.vulnerableVersion.length;
              })}
            selectedIssue={selectedIssue as ParsedCodeIssueRow}
          />
        )
      ) : null}
      {selectedIssue.source === "secrets" ? (
        <SelectedSecretIssue
          key={4}
          selectedIssue={selectedIssue as ParsedSecretsIssueRow}
        />
      ) : null}
      {selectedIssue.source === "containers" ? (
        <SelectedContainerIssueView
          key={5}
          setSelectedIssue={setSelectedIssue}
          selectedIssue={selectedIssue as ParsedTrivyIssueRow}
        />
      ) : null}
    </Modal>
  );
}
