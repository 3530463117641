import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { Switch } from "@headlessui/react";
import { joinClassNames } from "./utilities";
import { ThemeContext } from "./usePrefersDarkMode";
import { useContext } from "react";

export function Toggle(): JSX.Element {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Switch
      checked={theme}
      onChange={toggleTheme}
      className={joinClassNames(
        theme ? "bg-indigo-600" : "bg-gray-400 ",
        "relative flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 "
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={joinClassNames(
          theme ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      >
        <span
          className={joinClassNames(
            theme
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <MdOutlineLightMode className="h-3 w-3 text-gray-400" />
        </span>
        <span
          className={joinClassNames(
            theme
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <MdOutlineDarkMode className="h-3 w-3 text-indigo-600" />
        </span>
      </span>
    </Switch>
  );
};
