import { gql } from "@apollo/client/core";

export const VerifyFCloudAccount = gql`
  mutation VerifyFCloudAccount_1($id: ID!, $applicationId: ID) {
    verifyFCloudAccount(id: $id, applicationId: $applicationId) {
      id
      name
    }
  }
`;
