import { useSnackbar } from "notistack";
import { AiFillCopy } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { Button } from "../../../components/buttons/Button";
import { formatDate } from "../../dashboard_2/HistoryPicker";
import { LastScanned } from "../../dashboard_2/dashboard2";
import { Link } from "react-router-dom";
import { SelectedIssue } from "../../../App";
import { Dispatch } from "react";
import { BsSearch } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { copyToClipboardWithSnackbar } from "../../../functions/utilities";
//import { FiExternalLink } from "react-icons/fi";

type DetailsProps = {
  details?: string;
  description?: string;
  endpoint?: string;
  infoUrl?: string;
  cveFound?: boolean;
  lastscanned?: LastScanned;
  cveId?: string[];
  cweId?: string[];
  extractedResults?: string[];
  tags?: string[];
  verified?: boolean;
  setSelectedIssue: Dispatch<SelectedIssue>;
};

export function PopupHeading({
  s,
  className,
}: {
  s: string;
  className?: string;
}): JSX.Element {
  return (
    <h3
      title={s}
      className={`font-semibold text-gray-800 dark:text-gray-200 ${className}`}
    >
      {s}
    </h3>
  );
}

export function PopupBody({
  children,
  className,
}: {
  children: null | string | JSX.Element | JSX.Element[];
  className?: string;
}): JSX.Element {
  if (children == null) {
    return <></>;
  }
  return (
    <div
      className={`${className ? className : ""}${
        className ? " " : ""
      }text-gray-600 dark:text-gray-300`}
    >
      {children}
    </div>
  );
}

export function CVEChip({
  cwveId,
  setSelectedIssue,
}: {
  cwveId: string;
  setSelectedIssue: Dispatch<SelectedIssue>;
}): JSX.Element {
  return (
    <div className="flex flex-row gap-2">
      <div className="flex items-center text-sm text-gray-400 flex-row">
        <div className="flex flex-wrap flex-row">
          <p className="dark:text-gray-300 flex-grow">
            <Link
              onClick={() => void (setSelectedIssue && setSelectedIssue(null))}
              to={`/package-explorer?search=${encodeURIComponent(cwveId)}`}
            >
              <Button
                hoverEffect=""
                label={cwveId}
                icon={<BsSearch width={5} height={5} className="mr-1" />}
                secondary
              />
            </Link>
          </p>
        </div>
      </div>
      <div className="my-auto">
        <Button
          icon={<FaCopy className="w-3 h-3 dark:text-white" />}
          onClick={() =>
            void (cwveId ? copyToClipboardWithSnackbar(cwveId, cwveId) : null)
          }
        />
      </div>
    </div>
  );
}

export function Details({
  details,
  description,
  endpoint,
  infoUrl,
  cveFound,
  lastscanned,
  cveId,
  cweId,
  extractedResults,
  tags,
  verified,
  setSelectedIssue,
}: DetailsProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    if (endpoint) {
      navigator.clipboard.writeText(endpoint);
      enqueueSnackbar(`Copied Offending URL: ${endpoint}`, {
        variant: "success",
        persist: false,
      });
    }
  };

  return (
    <div className="dark:text-gray-300 text-gray-900 flex-col flex">
      {lastscanned && lastscanned.valid && (
        <p className="dark:text-white mb-2">
          Issue Date {formatDate(lastscanned.date)}
        </p>
      )}

      <PopupHeading s="Description" className="mt-2 mb-2" />
      {(() => {
        if (
          (!details || details === "" || details === "No details found") &&
          description &&
          description !== "Unavailable"
        ) {
          return (
            <PopupBody>
              {description.split("\n").map((descLine, index) => (
                <p key={index}>{descLine === "" ? <>&nbsp;</> : descLine}</p>
              ))}
            </PopupBody>
          );
        } else {
          return (
            <PopupBody>
              {details != null && details !== "Unavailable"
                ? details
                : null ?? "No details found."}
            </PopupBody>
          );
        }
      })()}

      <div className="mt-4 flex-col flex gap-4">
        {endpoint && endpoint !== "Unavailable" && (
          <div className="grid grid-rows-2 grid-cols-1 gap-2">
            <div className="my-auto flex flex-col md:flex-row flex-nowrap justify-between">
              <PopupHeading s="Affected Endpoint" className="my-auto" />
              <div className="my-auto">
                <Button
                  onClick={onCopy}
                  icon={<AiFillCopy className="mr-2" />}
                  label="Copy Affected URL"
                  primary
                />
              </div>
            </div>
            <PopupBody>
              <p className="my-auto break-words col-span-4 underline">
                {endpoint}
              </p>
            </PopupBody>
          </div>
        )}
        {(extractedResults?.length ?? 0) > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            <PopupHeading s="Offending Results" className="my-auto" />
            <PopupBody>
              <div className="my-auto flex flex-row flex-wrap gap-2 break-words col-span-4">
                {extractedResults?.map((extractedResult, index) => (
                  <p
                    key={index}
                    className="my-auto rounded-full dark:bg-dark-nav bg-light-seconday py-2 px-3"
                  >
                    {extractedResult}
                  </p>
                ))}
              </div>
            </PopupBody>
          </div>
        )}
        {(tags?.length ?? 0) > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            <PopupBody>
              <div className="my-auto flex flex-row flex-wrap gap-2 break-words col-span-4">
                {tags?.map((tag, index) => (
                  <p
                    key={index}
                    className="my-auto rounded-full dark:bg-dark-nav bg-light-seconday py-2 px-3"
                  >
                    {tag}
                  </p>
                ))}
              </div>
            </PopupBody>
          </div>
        )}
        {infoUrl && infoUrl !== "Unavailable" && (
          <div className="flex flex-row flex-nowrap justify-end">
            <PopupBody>
              <a
                className="my-auto"
                href={infoUrl}
                target="_blank"
                rel="noreferrer noopener nofollow external help"
              >
                <Button
                  className="w-[208px]"
                  icon={<BiLinkExternal className="mr-2" />}
                  label="More Info (External Link)"
                  primary
                />
              </a>
            </PopupBody>
          </div>
        )}

        {(cveFound || (cveId?.length ?? 0) > 0 || (cweId?.length ?? 0) > 0) && (
          <div className="flex flex-col md:flex-row flex-nowrap gap-4 mb-2">
            <p className="my-2 w-fit text-[#af3734] rounded-full px-3 py-1">
              An associated CVE/CWE was found
            </p>
            {(cveId?.length ?? 0) > 0 && (
              <div className="flex flex-row flex-wrap gap-2">
                {cveId?.map((cid, i) => (
                  <CVEChip
                    key={i}
                    cwveId={cid?.toUpperCase() ?? cid}
                    setSelectedIssue={setSelectedIssue}
                  />
                ))}
              </div>
            )}
            {(cweId?.length ?? 0) > 0 && (
              <div className="flex flex-row flex-wrap gap-2">
                {cweId?.map((wid, i) => (
                  <CVEChip
                    key={i}
                    cwveId={wid?.toUpperCase() ?? wid}
                    setSelectedIssue={setSelectedIssue}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
