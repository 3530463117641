import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useUser } from "./useUser";
import { GET_APPLICATIONS } from "../../graphql/queries/GetApplications";
import logGraphQLError from "../logGraphQLError";

export const useNumOfApps = () => {
  const user = useUser();

  const { data, error, loading } = useQuery(GET_APPLICATIONS, {
    variables: {
      sub: user?.selectedOrg ?? "",
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: logGraphQLError,
  });

  if (error) {
    return "Error getting applications";
  }

  if (data) {
    return data.getApplications?.length as number ?? 0;
  }

  if (loading) {
    return "loading";
  }
};
