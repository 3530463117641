import { ApolloError } from "@apollo/client";
import { enqueueSnackbar } from "notistack";

export default function logGraphQLError(e: ApolloError): void {
    console.log("error: partial data was returned",
        "client errors", e.clientErrors.map(x => x.message).join(", "),
        "client errors stack", e.clientErrors.map(x => x.stack).join(", "),
        "message", e.message,
        "extraInfo", e.extraInfo,
        "cause", e?.cause,
        "graphQLErrors", e.graphQLErrors.map(x => x.message).join(", "),
        "graphQLErrors stack", e.graphQLErrors.map(x => x.stack).join(", "),
        "name", e.name,
        "networkError", e.networkError?.message,
        "networkError stack", e.networkError?.stack,
        "protocolErrors", e.protocolErrors.map(x => x.message).join(", "),
        "stack", e.stack
    );
    if (e.message === "Not authenticated") {
        window.location.assign('/auth/logout');
    }
    if (e.message.includes("Expected org_id to be set")) { // user hasn't chosen an org yet
        window.location.assign('/auth/orgs');
    }
    if (e.message === "Failed to fetch") {
        enqueueSnackbar("Failed to fetch data", {
            variant: "error",
            persist: false,
        });
    } else {
        enqueueSnackbar("Partial data was returned", {
            variant: "info",
            persist: false,
        });
    }
}
