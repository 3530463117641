import { AiOutlineDashboard } from "react-icons/ai";
import { useHistory } from "react-router-dom";

export default function HeaderBanner({ isShowing, appId }: {
  isShowing: boolean;
  appId: string;
}): JSX.Element {
  const { push } = useHistory();

  if (!isShowing) return <></>;

  return (
    <div className="mt-16 flex p-2 justify-between bg-indigo-900 dark:bg-indigo-900">
      <div className="flex items-center justify-between flex-wrap">
        <span className="flex p-2 rounded-lg bg-indigo-800">
          <AiOutlineDashboard
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        </span>
        <p className="ml-3 font-medium text-white truncate">
          <span>You are currently viewing a historical version of the Dashboard</span>
        </p>
      </div>
      <button
        onClick={() => push(`/dashboard/${appId}`)}
        className="transition-transform cursor-pointer px-4 py-2 border-solid border dark:border-transparent rounded-md shadow-md text-sm font-medium text-white dark:bg-dark-primary"
      >
        Back to Latest Dashboard
      </button>
    </div>
  );
}
