import { ChevronDownIcon } from "@heroicons/react/outline";
import { Collapse } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Dispatch, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { supabaseClient } from "../auth/supabase";
import { Button } from "../components/buttons/Button";
import { Modal } from "../components/dialogs/Modal";
import useTitle from "../functions/hooks/useTitle";
import { Toggle } from "../functions/toggle";
import { joinClassNames } from "../functions/utilities";
import { svgImport } from "../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../assets/small-logo.svg").then(svgImport));


function TermAndConditionDropdown({ term, description }: { term: string, description: JSX.Element }) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return <div key={term} className="pt-6 pb-5">
    <h2 className="text-lg">
      <div className="text-left w-full flex justify-between items-start text-gray-400 cursor-pointer" onClick={() => void setIsOpen(!isOpen)}>
        <span className="font-bold dark:text-white">
          {term}
        </span>
        <span className="ml-6 h-7 flex items-center">
          <ChevronDownIcon
            className={joinClassNames(
              isOpen ? "-rotate-180" : "rotate-0",
              "h-6 w-6 transition-transform"
            )}
            aria-hidden="true"
          />
        </span>
      </div>
    </h2>
    <Collapse in={isOpen}>
      <div className="dark:text-white pt-2">
        {description}
      </div>
    </Collapse>
  </div>
}

export function TermsAndConditions({ isOpen, setIsOpen, onComplete }: { onComplete?: Function, isOpen: boolean, setIsOpen: Dispatch<boolean> }): JSX.Element {
  useTitle("Terms and Conditions", true);
  const { enqueueSnackbar } = useSnackbar();

  async function onAccept() {
    await supabaseClient.auth.updateUser({
      data: {
        user_accepted_eula: true,
        user_accepted_eula_date: new Date(),
      },
    }).then(
      () => { setIsOpen(false); onComplete && onComplete(); }
    ).catch(
      () => void enqueueSnackbar("Unable to accept EULA. Please refresh the page and try again. Contact support if this persists.", {
        variant: "error",
        persist: false,
      })
    );
  }

  return (
    <Modal id="terms" isOpen={isOpen} width="max-w-7xl" closeButtonVisible={false} noVerticalMargin={true}>
      <div className="mx-auto py-2 px-4 sm:py-0 sm:px-6 lg:px-8 bg-transparent">
        <div className="absolute right-2 top-2">
          <Toggle />
        </div>
        <div className="px-3 mx-auto ">
          <main className="sm:flex items-center">
            <span className="h-[75px] w-[75px] py-4 ">
              <Suspense fallback={<></>}><SecureStackLogo /></Suspense>
            </span>

            <div className="mt-5 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-300 tracking-tight sm:text-3xl">
                Terms and Conditions
              </h1>
            </div>
          </main>
        </div>

        <div className="mx-auto divide-y-2 divide-gray-200">
          <p className="text-md text-black text-left mx-auto mt-5 dark:text-gray-400">
            <span className="font-bold">Updated 14th of June 2023</span>
            <br />
            <br />
            Thank you for choosing to use our products and services (Services).
            <br />
            <br />
            This agreement (Agreement) is between SecureStack Pty Ltd
            ("SecureStack", "us," "we," "our" or the "Company") and the person
            or entity agreeing to these terms ("you" or the "Customer"). If you
            are agreeing to this Agreement not as an individual but on behalf of
            your company, then "you" means your company, and you are binding
            your company to this Agreement. <br /> <br />
            This Agreement and its terms shall apply unless otherwise expressly
            replaced by a separate agreement between you and SecureStack. <br />
            <br />
            The "Effective Date" of this Agreement is the date which is the
            earlier of (a) your initial access to or use of the Services (as
            defined below) or (b) the effective date of the first Order
            referencing this Agreement. <br /> <br /> By clicking on the "I
            agree" (or similar button or checkbox) that is presented to you at
            the time of your Order or upon accessing the Services, or by using
            or accessing the Services, you indicate your assent to be bound by
            this Agreement. Alternatively, acceptance of any Xero quote provided
            by SecureStack will indicate your assent to be bound by this
            Agreement and the terms of any Order specified in the Xero quote.
            <br /> <br /> If you do not agree with these Terms, do not continue
            to use or access the Services.
          </p>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {termsAndConditions.map(({ term, description }, index) => (
              <TermAndConditionDropdown key={index} term={term} description={description} />
            ))}
          </dl>
        </div>
        <div className="flex justify-between">
          <Button
            outlined
            label="Decline and Log Out"
            id="acceptTermsAndConditions"
            as={Link}
            to="/auth/logout"
          />
          <Button
            primary
            label="Accept and Continue"
            id="acceptTermsAndConditions"
            onClick={onAccept}
          />
        </div>
      </div>
    </Modal>
  );
};

export const termsAndConditions: {
  term: string;
  description: JSX.Element;
}[] = [
    {
      term: "Scope",
      description: <div>
        <p>
          These terms govern your use of the Services. These Terms include the Privacy Policy, Site Terms and Conditions, any Orders and any other references to SecureStack policies and attachments posted at www.securestack.com (Site) from time to time as though those policies were included in these Terms. If any of the provisions of any applicable SecureStack policy conflict with these Terms, these Terms have priority, solely to the extent such Terms apply to the Service.
        </p>
      </div>,
    },
    {
      term: "Your Account, Authorized Users and Secondary Users",
      description: <div>
        <p>You are required to register for an Account to access the Services (Account Registration). Any registration information that you provide to us must be accurate, current and complete. You must also update your information so that we may send notices, statements and other information to you by email or through your account.</p>
        <br />
        <p>You are responsible for maintaining the confidentiality of your account login credentials including your username and password. You are also responsible for all actions taken through your accounts.</p>
        <br />
        <p>Access to the Services may be subject to approval of your Account Registration by SecureStack. Approval of an Account Registration is at the sole discretion of SecureStack, and SecureStack reserves the right to deny any Account Registration. If your Account Registration is denied, any Fees have been paid will be refunded to you.</p>
        <br />
        <p>Only Authorized Users may access and use the Services.  Some Services may allow you to designate different types of Authorized Users, in which case pricing and functionality may vary according to the type of Authorized User. You are responsible for compliance with this Agreement by all Authorized Users, including what Authorized Users do with your data, and for all fees incurred by Authorized Users (or from adding Authorized Users). All use of Services must be solely for the benefit of you or your Affiliates and must be within the Scope of Use.</p>
        <br />
        <p>Certain Services may be used as part of your own product or services. Subject to the terms and conditions of this Agreement, you may grant your own customers' end users ("Secondary Users") limited rights to use the Services solely so that they may view and interact with such resources.  You may not permit Secondary Users to use the Services for purposes unrelated to supporting your own offerings or grant Secondary Users administrator, configuration or similar use of the Services.</p>
        <br />
        <p>You may not charge Secondary Users a specific fee for use of the Services, but you may charge an overall fee for your own offerings.</p>
        <br />
        <p>You are responsible for all Secondary Users as "Authorized Users" and are otherwise solely responsible for your own products, support offerings and Secondary relationships.  Notwithstanding anything to the contrary in this Agreement, the Company has no direct or indirect warranty, indemnity or other liability or obligations of any kind to Secondary Users.</p></div>,
    },
    {
      term: "Term of Agreement",
      description: <div>
        <p>Except as otherwise specified in your Order, any subscriptions will automatically renew for periods equal to your initial Term (and you will be charged at the then-current rates) unless you cancel your subscription in writing or through your account at the Site.</p>
        <br />
        <p>If you cancel, your subscription will terminate at the end of then-current billing cycle, but you will not be entitled to any credits or refunds for amounts accrued or paid prior to such termination.</p>
        <br />
        <p>If SecureStack does not want the Services to renew, then it will provide you written notice to this effect. This notice of non-renewal will be effective upon the conclusion of the then current Term.</p></div>,
    },
    {
      term: "Payment of Fees",
      description: <div>
        <p>You agree to pay all Fees in accordance with your Order. Unless otherwise specified in your Order, you will pay all amounts at the time you place your Order. All amounts are non-refundable, non-cancellable and non-creditable unless otherwise specified in this Agreement. In making payments, you acknowledge that you are not relying on future availability of the Service beyond the current agreed Term or any Service upgrades or feature enhancements.</p>
        <br />
        <p>You are responsible for any duties, customs fees, or taxes (other than our income tax) associated with the sale of the Services, including any related penalties or interest (Taxes), and you will pay us for the Services without any reduction for Taxes.</p>
        <br />
        <p>If we are obligated to collect or pay Taxes, the Taxes will be invoiced to you, unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing authority. If you are required by law to withhold any Taxes from payments to us, you must provide us with an official tax.</p>
        <br />
        <p>As part of our commitment to customer satisfaction, you may terminate your initial Order of the applicable Software under this Agreement, for no reason or any reason, by providing notice of termination and returning any applicable Software to SecureStack no later than thirty (30) days after the Order date for such Software. This termination and refund right applies only to your initial Order and only if you exercise your termination right within the period specified above and does not apply to any Additional Services as specified in your Order. You understand that SecureStack may change this practice in the future in accordance with the Terms of this Agreement.</p></div>,
    },
    {
      term: "Your License",
      description: <div>
        <p>Subject to these Terms, SecureStack grants you a non-exclusive, non-transferable revocable license to use the Service on a compatible computer, mobile telephone or handheld device (Device) owned or controlled by you for the Designated Period specified in your Order.</p>
        <br />
        <p>Unless permitted by law or as otherwise expressly permitted in these Terms, you must not (nor may you authorise any third person to):</p>
        <br />
        <p className="ml-4">(i) rent, lease, distribute, license, sub license, sell, transfer, assign, distribute or otherwise provide access to the Service to a third party;</p>
        <br />
        <p className="ml-4">(ii) reproduce, modify, adapt, create derivative works of, the Service;</p>
        <br />
        <p className="ml-4">(iii) reverse engineer, disassemble, de compile, transfer, exchange or translate the Service or otherwise seek to obtain or derive the source code or API;</p>
        <br />
        <p className="ml-4">(iv) remove or tamper with any disclaimers or other legal notices;</p>
        <br />
        <p className="ml-4">(v) combine the whole or any part of the Service with any other software, data or material;</p>
        <br />
        <p className="ml-4">(vi) store or use any part of the data you do not own in an archival database or other searchable database. You must promptly notify us in writing of any breach of these conditions of use.</p></div>,
    },
    {
      term: "Intellectual Property Rights",
      description: <div>
        <p>You agree that all intellectual property of any sort in or associated with the Service, including all code, libraries, programs, software, information, data, documentation, content, databases, systems, logos and trademarks are owned either directly by us or by our licensors. You are not authorised to use any of our intellectual property except as is expressly allowed under these Terms.</p>
        <br />
        <p>You grant SecureStack a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, license to use the copyright and other intellectual property rights you have in all information, answers, content, data, and inputs posted, uploaded, and entered into the site during use of the Service (Content) for the limited purpose of operating, developing, providing, and improving the Service and researching and developing new ones. Our license to your Content is subject to your rights under applicable law (such as laws regarding personal data protection to the extent any Content contains personal information as defined by those laws).</p></div>,
    },
    {
      term: "Your Obligations",
      description: <div>
        <p>You agree to use the Services in an acceptable manner (Acceptable Use), which includes agreeing not to, and nor allowing others, to use the Services to:</p>
        <br />
        <p className="ml-4">(i) to violate, or encourage the violation of, the legal rights of others (for example, this may include allowing Users to infringe or misappropriate the intellectual property rights of others in violation of the Digital Millennium Copyright Act);</p>
        <br />
        <p className="ml-4">(ii) to engage in, promote or encourage illegal activity;</p>
        <br />
        <p className="ml-4">(iii) for any unlawful, invasive, infringing, defamatory or fraudulent purpose (for example, this may include phishing, creating a pyramid scheme or mirroring a website);</p>
        <br />
        <p className="ml-4">(iv) to intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive nature;</p>
        <br />
        <p className="ml-4">(v) to interfere with the use of the Services, or the equipment used to provide the Services, by customers, authorized resellers, or other authorized users;</p>
        <br />
        <p className="ml-4">(vi) to disable, interfere with or circumvent any aspect of the Services;</p>
        <br />
        <p className="ml-4">(vii) to generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisings or other solicitations ("spam"); or</p>
        <br />
        <p className="ml-4">(viii) to use the Services, or any interfaces provided with the Services, to access any other SecureStack product or service in a manner that violates the terms of service of such other SecureStack product or service.</p></div>,
    },
    {
      term: "Security",
      description: <div>
        <p>By using the Service, you acknowledge that it is your sole responsibility to ensure the confidentiality and security of any information transmitted from or stored on a Device for the purposes of the Service, for all transactions and other activities in the End User's name, whether authorized or unauthorized. You understand that use of the Service involves transmission of your data over networks that are not owned, operated or controlled by us, and we are not responsible for any of your data lost, altered, intercepted or stored across such networks. We cannot guarantee that our security procedures will be error-free, that transmissions of your data will always be secure or that unauthorized third parties will never be able to defeat our security measures or those of our third-party service providers.</p></div>,
    },
    {
      term: "Privacy Policy",
      description: <div>
        <p>Any information you supply to us when using the Service will be collected and used by us in accordance with our <a href="https://securestack.com/privacy-policy/" target="_blank" rel="noopener">Privacy Policy.</a></p></div>,
    },
    {
      term: "Disclaimer",
      description: <div>
        <p>Except as expressly stated in this Agreement, we do not make any representation or warranty (express or implied) in respect of the Services, any Materials or any other goods or services provided by SecureStack to you, including, without limitation, any implied warranty:</p>
        <br />
        <p className="ml-4">(i) of merchantability;</p>
        <br />
        <p className="ml-4">(ii) of fitness for a particular purpose;</p>
        <br />
        <p className="ml-4">(iii) arising from a course of performance, course of dealing, or usage of trade;</p>
        <br />
        <p className="ml-4">(iv) of non-infringement of third party rights; or</p>
        <br />
        <p className="ml-4">(v) against hidden defects.</p>
        <br />
        <p>The Service and Materials are provided on an "as is", "with all faults" and "as available" basis and without any further warranties of any kind. We make no warranty that operation of the Service or any Materials will be uninterrupted or error free or that all defects will be corrected.</p>
        <br />
        <p>Without limiting the above, you acknowledge that:</p>
        <br />
        <p className="ml-4">(i) you are using the Service at your own risk;</p>
        <br />
        <p className="ml-4">(ii) the Service is not a substitute for professional advice;</p>
        <br />
        <p className="ml-4">(iii) you are solely responsible for the use of the Service and agree that it is your responsibility to review and assess the information, results, findings, and recommended actions provided by the Service; and</p>
        <br />
        <p className="ml-4">(iv) you have not relied on any representation in ordering the Service or any goods and services from us.</p>
        <br />
        <p>To the maximum extent permitted by law, we exclude completely all liability whatsoever for any claims, liability, loss or damage of any kind however caused (including negligence) arising out of or in connection with any goods or services provided by us including the Service and its access, use or performance, including, without limitation, we are not liable for:</p>
        <br />
        <p className="ml-4">(i) misuse of the Service or any Materials;</p>
        <br />
        <p className="ml-4">(ii) use of the Service or any Materials with third party data, software or hardware which is incompatible with the Service and/or not recommended by us;</p>
        <br />
        <p className="ml-4">(iii) reduced performance or non-availability of the Service or any Materials as a result of network connections; or</p>
        <br />
        <p className="ml-4">(iv) errors in the Service or any Materials resulting from your configuration or manipulation of the Service or any Materials, in each case not specifically recommended in writing by us.</p>
        <br />
        <p>Under no circumstances (including but not limited to any act or omission on our part) will we be liable for any loss or damages (including, without limitation, indirect, incidental, special or consequential or punitive damages and damages for loss of profits) whatsoever which result from any use, or any inability to use, the Service or any Materials.</p>
        <br />
        <p>To the maximum extent permitted by law, our liability for breach of any implied warranty or condition which cannot be excluded is limited at our option to supply of the good or service ordered by you again or paying for their resupply.</p>
        <br />
        <p>Notwithstanding the above, to the maximum extent permitted by law, in no event shall our aggregate liability for any claims arising out of or related to these Terms exceed the greater of one hundred Australian dollars ($100) or the amount that you paid, if any, to us for access to or use of the Service during the six months' period immediately prior to the event giving rise to such liability.</p>
        <br />
        <p>You agree to indemnify SecureStack and its related parties, officers, agents and employees (Indemnified Parties) in respect of any claim, action, damage, loss, liability, cost, charge, expense, outgoing or payment (including legal expenses (on a full indemnity basis) arising from or relating to:</p>
        <br />
        <p className="ml-4">(i) your use of the Service or any Materials;</p>
        <br />
        <p className="ml-4">(ii) a breach of these Terms by you; and</p>
        <br />
        <p className="ml-4">(iii) your breach of any applicable law.</p></div>,
    },
    {
      term: "Termination",
      description: <div>
        <p>This Agreement is in effect for the Designed Period, unless sooner terminated as permitted in these Terms. Either party may terminate this Agreement before the expiration of the Designated Period if the other party materially breaches any of these Terms and does not cure the breach within thirty (30) days after written notice of the breach, or if the other party ceases to operate, declares bankruptcy, or becomes insolvent or otherwise unable to meet its financial obligations.</p>
        <br />
        <p>We may terminate this Agreement before the expiration of the Designated Period if you are in material breach of these Terms.</p>
        <br />
        <p>You may terminate this Agreement at any time with notice to SecureStack, but you will not be entitled to any credits or refunds as a result of convenience termination.</p>
        <br />
        <p>Except where an exclusive remedy may be specified in these Terms, the exercise by either party of any remedy, including termination, will be without prejudice to any other remedies it may have under these Terms, by law, or otherwise.</p>
        <br />
        <p>Except as set forth in this Section, once the Agreement terminates, then:</p>
        <br />
        <p className="ml-4">(i) the rights and licenses granted by SecureStack to you will cease immediately (except as set forth in this Section);</p>
        <br />
        <p className="ml-4">(ii) you must cease all use of the Service and any third party Materials;</p>
        <br />
        <p className="ml-4">(iii) you must pay to us any and all outstanding Fees for the Designated Period;</p>
        <br />
        <p className="ml-4">(iv) you undertake not to attempt to access the Service or any data stored in the Service, any third party Materials or the Site after the date of termination.</p>
        <br />
        <p>If we become aware of a breach by you of these Terms, then we may specifically request that you suspend the applicable Account. If you fail to comply with our request to suspend an account, then we may do so. The duration of any suspension by us will be until the applicable End User has cured the breach which caused the suspension.</p></div>,
    },
    {
      term: "Survival",
      description: <div>
        <p>The following provisions will survive any termination or expiration of this Agreement: Intellectual Property Rights; Payment of Fees; Privacy Policy; Disclaimer; Disclaimer; Termination and suspension; General; and any other Sections which by intent or meaning have validity beyond termination or expiration of this Agreement.</p></div>,
    },
    {
      term: "Amendments",
      description: <div>
        <p>We may update or modify these Terms from time to time, including any referenced policies and other documents. If a revision meaningfully reduces your rights, we will use reasonable efforts to notify you (by, for example, sending an email to the billing or technical contact you designate in the applicable Order, posting on our Site, through your account, or in the Service itself). If we modify these Terms during the Term, the modified version will be effective upon your next renewal of the Term, as applicable. In this case, if you object to the updated Terms, as your exclusive remedy, you may choose not to renew, including cancelling any Terms set to auto-renew.</p>
        <br />
        <p>For the avoidance of doubt, any Order is subject to the version of the Terms in effect at the time of the Order.</p></div>,
    },
    {
      term: "General",
      description: <div>
        <p>These Terms are governed by the laws of New South Wales, Australia and the parties agree to submit to the exclusive jurisdiction of the courts in New South Wales, Australia.</p>
        <br />
        <p>If any provision of these Terms is held to be invalid, illegal, or unenforceable that provision shall be deemed omitted to the extent that it is invalid, illegal, or unenforceable and the remainder of the Terms shall be construed in a manner as to give greatest effect to the original intention of these Terms.</p>
        <br />
        <p>The waiver of any right or failure of either of us to exercise in any respect any right provided in these Terms in any instance shall not be deemed to be a waiver of such right in the future or a waiver of any right under these Terms.</p>
        <br />
        <p>Neither party will be liable for inadequate performance to the extent caused by a condition (for example, natural disaster, act of war or terrorism, riot, labor condition, governmental action, and internet disturbance) that was beyond the party's reasonable control (Force Majeure).</p>
        <br />
        <p>Your use of any website or software that is not provided by us to access or download the Service shall be governed by the terms and conditions applicable to that website or software. We are not responsible for any consequences resulting from the use of such website or software, including but not limited to any damage to your property, including your Device, or the transfer of any computer virus or similar malicious code, except to the extent such consequences are caused by the Service.</p>
        <br />
        <p>Any notices to you may either be posted on the Site or given in writing (which may be by email) to the address last notified by you to SecureStack. Any notices to SecureStack, and any questions, concerns or complaints relating to the Service shall be in writing and addressed to:</p>
        <br />
        <p>SecureStack Pty Ltd,</p>
        <p>16 Nexus Way, Southport</p>
        <p>QLD, 4215</p>
        <br />
        <p>Or given by email to: <a className="underline" href="mailto:hello@securestack.com">hello@securestack.com</a></p>
        <br />
        <p>You agree to use your best endeavours to resolve any dispute arising out of or relating to these Terms, with us, prior to resorting to any external dispute resolution process. Please notify us in writing of any dispute you may have.</p>
        <br />
        <p>This Agreement, and any rights and licenses granted hereunder, must not be transferred or assigned by you without our prior express written consent. We may, without restriction, assign this Agreement and our rights and delegate our obligations hereunder to:</p>
        <br />
        <p className="ml-4">(i) any of our affiliates or subsidiaries; or</p>
        <br />
        <p className="ml-4">(ii) a third party participating in a merger, acquisition, sale of shares or assets, change of control, corporate reorganization or similar transaction in which SecureStack is participating.</p>
        <br />
        <p>In respect of the subject matter of the Terms, these Terms contain the entire understanding between the parties. Any previous oral and written communications, agreements, representations, warranties or commitments between the parties in respect of the subject matter are superseded by the Terms and do not affect the interpretation or meaning of the Terms and each of the parties has relied entirely on its own enquiries before entering into the Terms.</p></div>,
    },
  ];
