import { Transition } from "@headlessui/react";
import { Provider } from "@supabase/supabase-js";
import { MouseEventHandler, useEffect, useState, lazy, Suspense } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiFillGithub, AiFillGitlab } from "react-icons/ai";
import { FaBitbucket } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { supabaseClient } from "../../auth/supabase";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { Button } from "../../components/buttons/Button";
import { InputField } from "../../components/forms/InputField";
import { Loader } from "../../components/loading/Loader";
import { useUser } from "../../functions/hooks/useUser";
import { svgImport } from "../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


type IFormInput = {
  email: string;
  password: string;
};

const onboarding = [
  {
    target: "Add your integrations",
    completed: false,
    subSteps: [
      {
        target: "Cloud",
        completed: false,
      },
      {
        target: "Source code management",
        completed: false,
      },
    ],
  },
  {
    target: "Create your first application",
    completed: false,
    subSteps: [],
  },
  {
    target: "Invite users to your team",
    completed: false,
    subSteps: [],
  },
];

const updateUserMeta = async (checkList: any[]) => {
  await supabaseClient.auth.updateUser({
    data: {
      skippedJoyride: false,
      onboarding: checkList,
    },
  });
};

export function SignIn(): JSX.Element {
  const history = useHistory();
  const user = useUser();
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit
  } = useForm<IFormInput>();

  useEffect(() => {
    if (user != null && user?.id != null && user.status === "SIGNED_IN") { history.push("/"); }
  }, [user, history, history.push]);

  const showPasswordFieldOrSignIn: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    const { data: { user, session }, error } = await supabaseClient.auth.signInWithPassword({
      email: data.email.trim(),
      password: data.password,
    });

    if (error) {
      setLoading(false);
      setError(error);
      return;
    }

    if (user && !session) {
      // Email validation enabled, redirect
      history.push("/auth/check-email");
      return;
    }

    if (!user?.user_metadata?.onboarding) {
      await updateUserMeta(onboarding);
    }

    const searchParams = new URLSearchParams(history.location.search);
    handleRedirect(searchParams);
  };

  const handleRedirect = (searchParams: URLSearchParams) => {
    /* if (searchParams.has("redirect_to")) {
      const redirectTo = searchParams.get("redirect_to");
      history.push(redirectTo);
      return;
    } */

    history.push("/");
  };

  const registerWithSocials: MouseEventHandler = async (event) => {
    event.preventDefault();
    let social = event.currentTarget.id as Provider;

    let scopes = "";

    if (social === "github") {
      scopes =
        "repo user read:org read:packages read:gpg_key read:ssh_signing_key secrets";
    } else if (social === "gitlab") {
      scopes = "api read_api read_user read_repository profile email";
    } else if (social === "azure") {
      scopes = "email";
    }

    const { error } = await supabaseClient.auth.signInWithOAuth(
      {
        provider: social,
        options: {
          scopes,
          redirectTo: window.location.origin,
        }
      }
    );

    setLoading(true);

    if (error) {
      setLoading(false);
      setError(error);
      return;
    }
  };

  return (
    <>
      <Header />
      <div className="mt-2 mb-2">
        <ErrorAlert title={error.message} isShowing={error} />
      </div>
      {!loading ? (
        <form
          onSubmit={handleSubmit(showPasswordFieldOrSignIn)}
          className="space-y-4 my-auto"
        >
          <h2 className="text-gray-900 dark:text-gray-300">
            Work email address
          </h2>

          <InputField
            autoFocus
            autoComplete="username"
            type="email"
            placeholder="person@company.co"
            {...register("email", { required: true })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />

          <Transition
            show={true}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <p className="dark:text-gray-300 text-gray-900 pb-1">Password</p>

            <InputField
              autoFocus
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              id="current-password"
              {...register("password")}
            />
            <Link
              to="/auth/forgot-password"
              className="!mt-4 text-sm flex font-medium dark:text-gray-400 hover:text-indigo-500 hover:dark:text-gray-300"
            >
              Forgot your password?
            </Link>
          </Transition>

          <div className="flex flex-row gap-2">
            <Button
              isLoading={loading}
              primary
              className="w-full"
              label="Sign in with password"
              type="submit"
              onClick={handleSubmit(showPasswordFieldOrSignIn)}
            />
          </div>

          <SSOSignin registerWithSocials={registerWithSocials} />
        </form>
      ) : (
        <Loader w={10} h={10} />
      )}
    </>
  );
}

function Header(): JSX.Element {
  return (
    <div className="pb-2">
      <Suspense fallback={<></>}>
        <SecureStackLogo className="h-16 w-auto" />
      </Suspense>
      <h1 className="mt-6 text-3xl font-extrabold tracking-tighter text-gray-900 dark:text-gray-300">
        Sign In to Your Account
      </h1>
      <p className="mt-2 text-sm text-gray-400">
        <span className="mt-4 font-extrabold text-lg text-gray-900 dark:text-gray-300">
          Don't have an account?{" "}
          <Link
            to="/auth/register"
            className="font-medium ml-1 text-blue-700 hover:underline dark:text-indigo-400 hover:text-blue-800 hover:dark:text-indigo-500"
          >
            Sign up
          </Link>
        </span>
      </p>
    </div>
  );
}

export function SSOSignin({
  registerWithSocials,
}: {
  registerWithSocials: MouseEventHandler<HTMLDivElement>;
}) {
  const scmInfo = [
    {
      lower: "gitlab",
      upper: "GitLab",
      icon: <AiFillGitlab className="w-6 h-6 dark:group-hover:text-gray-300" />,
    },
    {
      lower: "bitbucket",
      upper: "Bitbucket",
      icon: (
        <FaBitbucket className="w-6 h-6 p-[2px] dark:group-hover:text-gray-300" />
      ),
    },
    {
      lower: "github",
      upper: "GitHub",
      icon: <AiFillGithub className="w-6 h-6 dark:group-hover:text-gray-300" />,
    },
    /* {
      lower: "azure",
      upper: "Azure",
      icon: (
        <SiMicrosoftazure className="w-6 h-6 dark:group-hover:text-gray-300" />
      ),
    }, */
  ];

  return (
    <div className="mt-6 border-t border-t-gray-500">
      <div className="mt-6 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300 dark:border-none" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white dark:bg-transparent rounded text-gray-500">
            Log in with SCM Provider
          </span>
        </div>
      </div>

      <div className="py-4 grid grid-cols-3 gap-3">
        {scmInfo.map(({ lower, upper, icon }, index) => {
          return (
            <div
              key={index}
              title={`Sign in instantly with ${upper}`}
              onClick={registerWithSocials}
              id={lower}
              className="cursor-pointer group w-full items-center border border-gray-300 dark:border-0 rounded-md shadow-sm bg-white dark:bg-dark-primary text-sm font-medium text-gray-500 flex justify-evenly py-3 hover:scale-110 transition-transform dark:hover:text-white hover:text-black flex-row flex-wrap"
            >
              <span className="sr-only">Sign in with {upper}</span>
              {icon}
              <p>{upper}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
