import { Popover, Transition } from "@headlessui/react";
import { joinClassNames } from "../../../functions/utilities";
import { Fragment } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

export const packageEnrichDataRowFactory = (
  view: any,
  copy: any,
  download: any
) => {
  return (row: any, index: number) => {
    const { push } = useHistory();
    return {
      name: (
        <td
          key={`${index}/name`}
          className="px-3 py-4  truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          <div title={row.name} className="dark:text-gray-400 text-gray-700">
            package:{" "}
            <span className="dark:text-gray-300 text-gray-800">{row.name}</span>
          </div>

          <div className="dark:text-gray-400 text-gray-700" title={row.version}>
            version:{" "}
            <span className="dark:text-gray-300 text-gray-800">
              {row.version}
            </span>
          </div>
          <div className="dark:text-gray-400 text-gray-700" title={row.purl}>
            purl:{" "}
            <span className="dark:text-gray-300 text-gray-800">{row.purl}</span>
          </div>
        </td>
      ),

      sbomId: (
        <td
          title={row.sbomId}
          key={`${index}/sbomId`}
          className="px-2 py-4 truncate  text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.sbomId}
        </td>
      ),
      appId: (
        <td
          title={row.appId}
          key={`${index}/${row.appId}`}
          className="px-2 py-4 truncate text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          <Tooltip title="go to dashboard">
            <span
              className="text-indigo-400 cursor-pointer"
              onClick={() => push(`/dashboard/${row.appId}`)}
            >
              {row.appId}
            </span>
          </Tooltip>
        </td>
      ),

      // purl: (
      //   <td
      //     key={`${index}/purl`}
      //     className="px-3 py-4  truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
      //   >
      //     {row.purl}
      //   </td>
      // ),
      // version: (
      //   <td
      //     key={`${index}/${row.version}`}
      //     className="px-3 py-4 max-w-[150px] truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
      //   >
      //     {row.version}
      //   </td>
      // ),
      created: (
        <td
          title={`${row.created?.substring(0, 10)} ${row.created?.substring(11, 19)}`}
          key={`${index}/created`}
          className="px-3 py-4 max-w-[150px] truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.created?.substring(0, 10)} <br />
          {row.created?.substring(11, 19)}
        </td>
      ),

      licenses: (
        <td
          key={`${index}/version`}
          className="px-6 py-4 max-w-[150px] truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row?.licenses?.map((l: any) => l?.expression ?? l)}
        </td>
      ),
      action: (
        <td
          key={`${index}-action`}
          className="overflow-x-hidden overflow-y-auto  whitespace-nowrap py-4 pl-3 pr-4 space-x-6 text-right text-sm font-medium sm:pr-6"
        >
          <ToolTip
            onDownload={() => download(row.sbomId)}
            onView={() => view(row.appId)}
            onCopy={() => copy(row.appId)}
          />
        </td>
      ),
    };
  };
};

export function ToolTip({
  onDownload,
  onCopy,
  onView,
}: {
  onDownload: () => void;
  onCopy: () => void;
  onView: () => void;
}): JSX.Element {
  const actions = [
    {
      action: onView,
      name: "View application",
      icon: (
        <MdModeEditOutline className="inline-flex cursor-pointer w-4 h-4" />
      ),
    },
    {
      action: onDownload,
      name: "Download sbom",
      icon: (
        <AiOutlineDownload className="inline-flex cursor-pointer w-4 h-4" />
      ),
    },
    {
      action: onCopy,
      name: "Copy app id",
      icon: <FaRegCopy className="inline-flex cursor-pointer w-4 h-4" />,
    },
  ];

  return (
    <Popover>
      {({ open }: { open: boolean }) => (
        <>
          <Popover.Button
            className={joinClassNames(
              open ? "text-gray-900" : "text-gray-500",
              "group  rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 "
            )}
          >
            <BsThreeDots className="cursor-pointer w-5 h-5 text-gray-900 dark:text-gray-400 " />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 transform h-auto -translate-x-1/2   mt-3 px-2  sm:px-0  ">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 overflow-auto bg-white dark:bg-dark-main  px-2 py-3 sm:gap-8 sm:p-8">
                  {actions.map((item, index) => (
                    <span
                      onClick={() => item.action()}
                      key={item.name + index.toString()}
                      className="-m-3 p-1  block items-center rounded-md cursor-pointer hover:bg-gray-50 text-left dark:hover:bg-dark-primary  transition ease-in-out duration-150"
                    >
                      <p className="font-medium   text-xs text-gray-900 dark:text-gray-500">
                        {item.icon} {item.name}
                      </p>
                    </span>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
