//import { gql } from "@apollo/client/core";
import { gql } from "../../__generated__/gql";

export const GetDashboardResults = gql(`
  query GetDashboardResults(
    $applicationId: String
    $scaId: String
    $secretsId: String
    $scanreference: String
    $cloudId: String
    $domainslimit: Int
    $orgId: String
  ) {
    dashboard(
      applicationId: $applicationId
      scaId: $scaId
      cloudId: $cloudId
      secretsId: $secretsId
      scanreference: $scanreference
      domainslimit: $domainslimit
      orgId: $orgId
    ) {
      ssc {
        collaborators {
          collaborators
          members
        }
        local_requirements {
          component
          version
        }
        source_code {
          component
          version
        }
        continuous_integration {
          component
          version
        }
        continuous_deployment {
          component
          version
        }
        runtime {
          component
          version
        }
        services {
          component
          version
        }
        cloud_resources {
          component
          version
        }
        packages {
          date
          packages {
            type
            name
            version
            licenses { expression }
            purl
          }
        }
        untitled {
          component
          version
        }
      }
      application {
        applicationId
        scannedtime
        description
        cloud
        target
        f_account_id
        cloudProblemsCount
        exposureProblemsCount
        codeProblemsCount
        scm
        name
        workspace
        organization
        group
        branch
        reponame
        language
        containerlist
        Tags {
          id
          name
        }
        assetScan
      }
      GitHubMetaForApp {
        id
        scannedtime
        applicationId
        scanId
        orgId
        no_of_workflows
        collaborators
        no_of_collaborators
        no_of_secrets
        no_of_org_secrets
        org_members
        no_of_org_members
        userEmails
        no_of_org_teams
        orgTeams
        gpgKeys
        no_of_gpg_keys
        socialAccounts
        no_of_social_accounts
      }
      BitbucketMeta {
        id
        scannedtime
        applicationId
        scanId
        orgId
        no_of_pipelines
        no_of_ssh_keys
        no_of_groups_on_repo
        workspace_members
        groups_on_repo
        userEmails
      }
      GitlabMeta {
        id
        scannedtime
        applicationId
        scanId
        orgId
        no_of_contributors
        no_of_pipelines
        no_of_gpg_keys
        no_of_ssh_keys
        no_of_group_members
        groupMembers
        contributors
      }
      betaSCA {
        advisory
        analysisId
        applicationId
        associatedDependency
        cveId
        cvssScore
        cvssVector
        details
        fixedVersions
        id
        orgId
        osvId
        packageName
        publishedDate
        scanId
        severity
        scannedtime
        source
        state
        stateReason
        summary
        vulnerableVersion
        rawData {
          name
          severity
          isDirect
          range
          fixAvailable {
            name
            version
            isSemVerMajor
          }
          nodes
          effects
          via {
            source
            name
            dependency
            title
            url
            severity
            range
            cvss {
              score
              vectorString
            }
            cwe
          }
        }
      }

      exposure {
        score {
          critical
          high
          medium
          low
        }

        data {
          murl
          name
          scanreference
          scantype
          iplist
          priorscanref
          priorscanrefdomains
          nginxversion
          stageexists
          tlsversion
          sovereignissue
          sslissue
          nocdnsovereignissue
          numberunencryptedpages
          numberredirects
          notsecure
          ispfull
          latitude
          loadbalanced
          multienvexists
          longitude
          scannedtime
          waf
          waflocation
          wafoutput
          waftech
          xpoweredbytech
          protectedby
          poweredby
          runningon

          Domains {
            rooturl
            lastscanned
            subdomains
            subcountry
            subispfull
            ports
            subip
          }
          Changes {
            fullurl
            NewChange
            murl
            scanreference
            scannedtime
            oldChange
          }
          Otherdomains {
            rooturl
            lastscanned
            domains
          }
          Techstacks {
            murl
            component
            lastscanned
            compversion
          }
          Badlinks {
            murl
            lastscanned
            badlink
          }
          webserver
          poweredby
          isp
          port22
          port443
          port3389
          port3306
          countryhost
          amazon
          redirectdest
          referrerpolicy
          rooturl
          apacheversion
          applicationexists
          awsalb
          azure
          cdn
          csp
          cspdatafull
          cdnbypass
          cdnver
          cloudfrontcdn
          cloudver
          cmsver
          devexists
          elapsedtime
          iisversion
          email
          https_redirect
          hsts
          gitconfigexists
          envfileexists
          securitytxtexists
          runningon
          wordpress
          wordpressver
          xcache
          xcachedata
          Grade {
            scalabilityscore
            securityscore
            finalscore
          }
          Problem {
            problem1
            problem2
            problem3
            problem4
            problem5
            problem6
            problem7
            problem8
            problem9
          }
          Issues {
            scanreference
            murl
            source
            issue
            template
            severity
            info
            endpoint
            lastscanned
            CVE
            active

            cveId
            cweId
            extractedResults
            host
            ip
            matchedAt
            name
            reference
            remediation
            tags
            timestamp
            verified
          }
        }
      }
      code {
        score {
          critical
          high
          medium
          low
        }
        data {
          scaId

          murl
          scannedtime
          package
          advisory
          criticality
          vulnerableVersion
        }
      }
      secrets {
        score {
          critical
          high
          medium
          low
        }
        data {
          scannedtime
          scanreference
          filename
          sub
          lineNumber
          repoName
          branchName
          credentialRuleName
          commitId
          authorName
          authorEmail
          applicationId
          offenderEntropy
          commitDate
          repoURL
          leakURL
          commitTags
          ip
          id
        }
      }
      container {
        app_id
        trivy_finding
        time_created
        state_reason
        state
        org_id
        id
      }
      cloud {
        score {
          critical
          high
          medium
          low
        }
        data {
          state
          resources {
            location
            previous_state
            problems {
              solutions
              resource_type
              resource_location
              description
              extra_details
              time_created
              time_updated
              associated_services {
                name
                service_info
              }
              service {
                name
                service_info
              }
              inspector {
                title
                severity
                recommendation
                description
              }
            }
            id
            type
            native_id
            state
            state_reason
            previous_state_reason
            previous_problems
            time_created
            extra_parameters
          }
          id
          time_created
        }
      }
    }
  }
`);
