//import { useEffect } from "react";
//import { useUser } from "../../functions/hooks/useUser"
//import * as amplitude from "@amplitude/analytics-browser"

export function AmplitudeRegisterAuth0User(): JSX.Element {
  //const user = useUser();
  //useEffect(() => {
    //amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);
  //}, []);
  //if (user) {
    //amplitude.setUserId(user?.id);
    /*amplitude.setUserProperties({
      email: user.email,
    });*/
  //}
  return <></>;
};
