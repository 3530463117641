import { useState } from "react";
import { ErrorAlert } from "../../../../components/alerts/ErrorAlert";
import { TheOneCombobox } from "../../../../components/buttons/TheOneCombobox";
import { useLastElementRef } from "../../../../functions/hooks/useLastElementRef";
import { useOrganizationSearchQuery } from "../../../../functions/hooks/useOrganizationSearchQuery";
import { useWorkspaceSearchQuery } from "../../../../functions/hooks/useWorkspaceSearchQuery";
import { useGroupSearchQuery } from "../../../../functions/hooks/useGroupSearchQuery";
import { SCM_SELECTION } from "../CodeSettings";
import useAzureOrgSearchQuery from "../../../../functions/hooks/useAzureOrgSearchQuery";

export type WorkspaceState = {
  id: string;
  name: string;
}

type SelectWorkspaceOrOrganizationProps<T> = {
  scm: string;
  selected: T;
  setSelected: (value: T) => void;
  testId?: string;
};

export function SelectedWorkspaceOrOrganization({
  scm,
  selected,
  setSelected,
  testId,
}: SelectWorkspaceOrOrganizationProps<WorkspaceState | any>): JSX.Element {
  const { BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;

  const [currentWorkspacePage, setCurrentWorkspacePage] = useState(1);
  const [currentOrgPage, setCurrentOrgPage] = useState(1);
  const [currentGroupPage, setCurrentGroupPage] = useState(1);

  const { loading, error, workspaceData, hasMore, refetch } =
    useWorkspaceSearchQuery(scm, currentWorkspacePage);

  const { loadingGroups, groupError, groupData, hasMoreGroups } =
    useGroupSearchQuery(scm, currentGroupPage);

  const { loadingOrgs, orgError, oganizationData, hasMoreOrgs, orgRefetch } =
    useOrganizationSearchQuery(scm, currentOrgPage);

  const { loadingAzOrgs, organizationAzData } =
    useAzureOrgSearchQuery(scm, currentOrgPage);

  const incrementWorkspacePage = () => {
    setCurrentWorkspacePage((prevPage) => prevPage + 1);
  };

  const incrementGroupPage = () => {
    setCurrentGroupPage((prevPage) => prevPage + 1);
  };

  const incrementOrgPage = () => {
    setCurrentOrgPage((prevPage) => prevPage + 1);
  };

  const lastElementRefGroups = useLastElementRef(
    incrementGroupPage,
    loadingGroups,
    hasMoreGroups
  );

  const lastElementRefWorkspaces = useLastElementRef(
    incrementWorkspacePage,
    loading,
    hasMore
  );

  const lastElementRefOrgs = useLastElementRef(
    incrementOrgPage,
    loadingOrgs,
    hasMoreOrgs
  );

  const lastElementRefAzOrgs = useLastElementRef(
    incrementOrgPage,
    loadingAzOrgs,
    false
  );

  if (error || orgError || groupError) {
    return (
      <p className="text-red-600 cursor-pointer" onClick={() => void refetch()}>
        Error retrieving data. Retry?
      </p>
    );
  }

  if (orgError) {
    return (
      <p
        className="text-red-600 cursor-pointer"
        onClick={() => void orgRefetch()}
      >
        Error retrieving organization data. Retry?
      </p>
    );
  }

  return (
    <div data-test-id={testId}>
      {(!error && !orgError) ? (
        <>
          {((): JSX.Element => {
            if (scm === BITBUCKET) {
              return <TheOneCombobox
                key={selected}
                testId={testId}
                defaultValue="Select workspace"
                lastIdxRef={lastElementRefWorkspaces}
                isLoading={loading}
                items={workspaceData}
                selected={selected}
                onSelection={setSelected}
                primaryKey={"name"}
                valueKey={"id"}
              />;
            }
            if (scm === GITHUB) {
              return <TheOneCombobox
                key={selected}
                testId={testId}
                defaultValue="Select organization"
                lastIdxRef={lastElementRefOrgs}
                isLoading={loadingOrgs}
                items={oganizationData}
                selected={selected}
                onSelection={setSelected}
              />;
            }
            if (scm === GITLAB) {
              return <TheOneCombobox
                key={selected}
                testId={testId}
                defaultValue="Select workspace"
                lastIdxRef={lastElementRefGroups}
                isLoading={loadingGroups}
                items={groupData}
                selected={selected}
                onSelection={setSelected}
                primaryKey={"name"}
                valueKey={"id"}
              />;
            }
            if (scm === AZURE || scm === "azure") {
              return <TheOneCombobox
                key={selected}
                testId={testId}
                defaultValue="Select Azure DevOps organization"
                lastIdxRef={lastElementRefAzOrgs}
                isLoading={loadingAzOrgs}
                items={organizationAzData}
                selected={selected}
                onSelection={setSelected}
              />;
            }
            // default github
            return <TheOneCombobox
              key={selected}
              testId={testId}
              defaultValue="Select organization"
              lastIdxRef={lastElementRefOrgs}
              isLoading={loadingOrgs}
              items={oganizationData}
              selected={selected}
              onSelection={setSelected}
            />;
          })()}
        </>
      ) : (
        <ErrorAlert
          isShowing={Boolean(error || orgError)}
          testId={testId}
          title={`Error retrieving ${scm === BITBUCKET
            ? "workspaces"
            : scm === GITHUB
              ? "organizations"
              : scm === GITLAB ? "gitlab" : "azure"
            }`}
        />
      )}
    </div>
  );
};
