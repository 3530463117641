import { Toggle } from "../../functions/toggle";

export function AuthLayout({ children }: { children: any }): JSX.Element {
  return (
    <>
      <div className="absolute top-0 right-0 py-2 px-2">
        <Toggle />
      </div>
      <div className="dark:bg-dark-primary bg-white h-screen w-screen flex">
        <div className="m-auto w-full md:w-2/5 md:max-w-[500px]">
          <div className="bg-gray-300 dark:bg-dark-main rounded-lg p-10 shadow-xl">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};