import { useState } from "react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../buttons/Button";
import { IssueType } from "./IssueType";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import { InputField } from "../forms/InputField";
import { useUser } from "../../functions/hooks/useUser";
import { CREATE_JIRA_ISSUE } from "../../graphql/mutations/CreateJiraIssue";

export type Inputs = {
  summary: string;
  issuetype: string;
};

export function CreateIssueForm({
  project,
  issueName,
}: {
  project: any;
  issueName?: string;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const user = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      summary: issueName,
    },
  });

  const [createIssue, { loading, error }] = useMutation(CREATE_JIRA_ISSUE, {
    onCompleted: (data) => {
      enqueueSnackbar(`Issue ${data.createJiraIssue?.key ? data.createJiraIssue?.key + " " : ""}created successfully`, {
        variant: "success",
      });

      history.goBack();
    },
    onError: () => {
      enqueueSnackbar(`Issue creation failed`, {
        variant: "error",
      });
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) =>
    selectedIssueType.id !== "" &&
    project.id !== "" &&
    createIssue({
      variables: { issue: issueObject(data), orgId: user?.selectedOrg },
    });

  const [selectedIssueType, setSelectedIssueType] = useState({
    id: "",
    name: "Select Issue Type",
    unavailable: false,
  });

  const issueObject = (data: Inputs) => {
    return {
      fields: {
        summary: data.summary,
        project: { id: project.id },
        issuetype: {
          id: selectedIssueType.id,
        },
      },
    };
  };

  if (error) {
    enqueueSnackbar(`Error creating issue`, {
      variant: "error",
    });
  }

  return (
    <form className="space-y-8 ">
      <div className="mt-10  ">
        <IssueType
          issueTypes={project?.issuetypes}
          selected={selectedIssueType}
          setSelected={setSelectedIssueType}
        />
      </div>

      <div>
        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-black dark:text-gray-400   "
        >
          Summary*
        </label>
        <div className="mt-2">
          <InputField
            {...register("summary")}
            name="summary"
            type="text"
            required={true}
          />
        </div>
        <FormErrorMessage isShowing={errors.summary} />
      </div>

      <div className="pt-5  flex justify-end ">
        <div className="relative">
          <Button
            primary={true}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            label={loading ? "Creating" : "Create"}
          />
          {loading && (
            <CircularProgress
              size={25}
              sx={{
                color: "#fff",
                position: "absolute ",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </div>
      </div>
    </form>
  );
};

const FormErrorMessage = ({
  isShowing,
}: {
  isShowing?: FieldError | Boolean;
}) => {
  return isShowing ? (
    <div className="px-2">
      <span className="text-red-500 text-xs">This field is required</span>
    </div>
  ) : null;
};
