import { AiFillApple, AiFillWindows, AiOutlineSync } from "react-icons/ai";
import { BsDownload, BsFillKeyFill } from "react-icons/bs";
import { FcLinux } from "react-icons/fc";
import { Button } from "../../../components/buttons/Button";
import { useHistoryState } from "../../../functions/hooks";
import { CreateAppLayout } from "../single/Layout";
import { CogIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import GoGistSecret from "../../../assets/images/GoGistSecret";

export function SecretSettings(): JSX.Element {
  const [, setId] = useHistoryState("id");
  const [appId] = useHistoryState("appId");

  return (
    <>
      <CreateAppLayout>
        <h2 className="pb-6 text-2xl  tracking-tight text-gray-900  dark:text-white ">
          5. Setup credential scanning locally.
        </h2>

        <div className="">
          <div className="flex relative   pb-12 sm:items-center">
            <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-14 h-14 bg-gray-100 dark:bg-dark-primary dark:text-gray-400 text-gray-700 rounded-full inline-flex items-center justify-center">
                <BsDownload className="w-7 h-7" />
              </div>
              <div className="flex-grow sm:pl-4 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 dark:text-gray-200 mb-1 text-md">
                  Download binary installer
                </h2>
                <div className="flex space-x-6 mt-3">
                  <AiFillApple
                    onClick={() =>
                      window.open(process.env.REACT_APP_CLI_MACOS_DOWNLOAD_URL)
                    }
                    title="download macos binary"
                    className="cursor-pointer w-6 h-6 text-gray-800 dark:text-gray-300"
                  />
                  <AiFillWindows
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_CLI_WINDOWS_DOWNLOAD_URL,
                      )
                    }
                    title="download windows binary"
                    className="cursor-pointer w-6 h-6 text-gray-800 dark:text-gray-300"
                  />
                  <FcLinux
                    onClick={() =>
                      window.open(process.env.REACT_APP_CLI_LINUX_DOWNLOAD_URL)
                    }
                    title="download linux binary"
                    className="cursor-pointer w-6 h-6 text-gray-800 dark:text-gray-300"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex relative pb-12 sm:items-center ">
            <div className="flex-grow  flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-14 h-14 bg-gray-100 dark:bg-dark-primary dark:text-gray-400 text-gray-700 rounded-full inline-flex items-center justify-center">
                <BsFillKeyFill className="w-7 h-7" />
              </div>
              <div className="flex-grow sm:pl-4 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 dark:text-gray-200 mb-1 text-md">
                  Create API key
                </h2>
                <p className="leading-relaxed text-gray-600 dark:text-gray-400 flex items-center">
                  Go to <CogIcon className="w-4 h-4  ml-1" />
                  Settings/API to create a key
                </p>
              </div>
            </div>
          </div>
          <div className="flex relative pb-12 sm:items-center ">
            <div className="flex-grow  flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-14 h-14 bg-gray-100 dark:bg-dark-primary dark:text-gray-400 text-gray-700 rounded-full inline-flex items-center justify-center">
                <AiOutlineSync className="w-7 h-7" />
              </div>
              <div className="flex-grow sm:pl-4 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 dark:text-gray-200 mb-2 text-md">
                  Initialize API key
                </h2>
                <p className="leading-relaxed text-gray-600 dark:text-gray-400 mb-1">
                  In your terminal run the command:
                </p>
                <code className="text-sm dark:bg-dark-primary rounded dark:text-gray-300 px-2 py-1 bg-gray-100 text-gray-700">
                  bloodhound-cli init -k YOUR_API_KEY_HERE
                </code>
              </div>
            </div>
          </div>
          <div className="flex relative pb-10 sm:items-center">
            <div className="flex-grow  flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-14 h-14 bg-gray-100 dark:bg-dark-primary dark:text-gray-400 text-gray-700 rounded-full inline-flex items-center justify-center">
                <GoGistSecret className="w-7 h-7" />
              </div>
              <div className="flex-grow sm:pl-4 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 dark:text-gray-200 mb-1 text-md">
                  Analyze code for credentials scanning
                </h2>

                <p className="leading-relaxed text-gray-600 dark:text-gray-400 mb-1">
                  Navigate to your code's directory and run:
                </p>
                <code className="text-sm dark:bg-dark-primary rounded dark:text-gray-300 px-2 py-1 bg-gray-100 text-gray-700">
                  bloodhound-cli code -s -a {appId ?? "APPLICATION-ID"}
                </code>
                <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                  After scanning your results will be shown in the terminal, and
                  web portal
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              rel="noreferrer"
              className="shadow-none dark:text-gray-400 text-sm text-blue-600"
              target="_blank"
              href="https://www.notion.so/securestack/CLI-Commands-6e9e93bd70e5488c9f662f62886ae1ef"
            >
              Link to documentation
            </a>
          </div>
        </div>
      </CreateAppLayout>
      <div className="mt-4 justify-between flex ml-0 md:ml-[260px] mb-20">
        <Button outlined={true} label="Back" onClick={() => setId("5")} />
        <Button
          primary={true}
          as={Link}
          to={appId ? `/dashboard/${appId}` : "/dashboard"}
          disabled={false}
          label="Finish"
        />
      </div>
    </>
  );
}
