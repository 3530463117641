import { SCM_SELECTION } from "../../views/create/categories/CodeSettings";
import { SCM_BRANCH_NAMES } from "../../views/create/categories/repository/SelectRepository";
import { useHistoryState } from "../hooks";

export default function useScmOrgOrWorkspaceHistory() {
    const [appScm] = useHistoryState("appScm");

    const { WORKSPACE, ORGANIZATION, GROUP } = SCM_BRANCH_NAMES;
    const { BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;

    return useHistoryState((() => {
      if (appScm === BITBUCKET) { return WORKSPACE; }
      if (appScm === GITHUB) { return ORGANIZATION; }
      if (appScm === GITLAB) { return GROUP; }
      if (appScm === AZURE) { return ORGANIZATION; }
      return ORGANIZATION;
    })());
}