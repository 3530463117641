import { AiOutlineLoading } from "react-icons/ai";
import { joinClassNames } from "../../functions/utilities";

export function Loader({ w, h, label }: { w: number; h: number; label?: string; }): JSX.Element {
  return (
    <div className="bg-transparent dark:bg-transparent py-5 px-7 mx-auto text-center dark:text-white text-black ">
      <AiOutlineLoading
        className={joinClassNames(
          `w${w.toString()} h${h.toString()}`,
          "animate-spin transition w-6 h-6 mx-auto text-red-600 "
        )}
      />
      {label}
    </div>
  );
};

export function LoadingWheel({ w, h }: { w?: number; h?: number; }) : JSX.Element {
  return <AiOutlineLoading className={`animate-spin text-[#af3734] w-[${w ?? 6}px] h-[${h ?? 6}px]`} />
}
