import { Dispatch, useState, lazy, Suspense } from "react";
import { MdSecurity } from "react-icons/md";
import CreateIssue from "../../../components/jira/CreateIssue";
import { ParsedTrivyIssueRow } from "../../../functions/hooks/useApplicationIssues";
import {
  getBorderColor,
  getTextColor,
} from "../../../functions/severityFunctions";
import { joinClassNames, titleCase } from "../../../functions/utilities";
import { Details } from "./Details";
import { Solution } from "./Solution";
import { svgImport } from "../../../partials/application/ApplicationComposition";
import { SelectedIssue } from "../../../App";

const SecureStackLogo = lazy(() =>
  import("../../../assets/small-logo.svg").then(svgImport),
);

const tabs = ["Details", "Solution", "Create Ticket"];

type SelectedExposureViewTabs = (typeof tabs)[number];

export function Tabber({
  tabs,
  currentTab,
  setCurrentTab,
  displayStyle,
  children,
}: {
  tabs: Array<string>;
  currentTab: string;
  setCurrentTab: Dispatch<string>;
  displayStyle: "exposure" | "code" | "cloud" | "secrets" | "containers";
  children?: JSX.Element | JSX.Element[];
}): JSX.Element {
  return (
    <>
      <div className="flex flex-col md:flex-row flex-nowrap gap-4">
        {tabs.map((tab, index) => {
          const handleTabbing = () => void setCurrentTab(tab);
          return (
            <div
              key={index}
              tabIndex={0}
              onKeyDown={function (e) {
                if (e.key === " " || e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  handleTabbing();
                }
              }}
              onClick={handleTabbing}
              className={`px-3 py-2 rounded-md ${
                tab === currentTab
                  ? `${
                      displayStyle === "exposure"
                        ? "text-exposure-icon-active"
                        : displayStyle === "code"
                        ? "text-code-icon-active"
                        : displayStyle === "cloud"
                        ? "text-cloud-icon-active"
                        : displayStyle === "secrets"
                        ? "text-secrets-icon-active"
                        : "dark:text-white"
                    }`
                  : "dark:text-white"
              } dark:bg-dark-nav bg-light-primary select-none cursor-pointer focus:outline-1`}
            >
              {tab}
            </div>
          );
        })}
        {children ? children : null}
      </div>
    </>
  );
}

export function SelectedContainerIssueView({
  selectedIssue,
  setSelectedIssue,
}: {
  selectedIssue?: ParsedTrivyIssueRow;
  setSelectedIssue: Dispatch<SelectedIssue>;
}): JSX.Element {
  const [currentTab, setCurrentTab] =
    useState<SelectedExposureViewTabs>("Details");

  return (
    <div className="flex flex-col dark:bg-dark-main rounded-lg bg-white py-4 px-4 space-y-2">
      <div className="pb-4">
        <Heading
          issueName={selectedIssue?.issueTitle}
          version={selectedIssue?.fixed_version}
          severity={selectedIssue?.severity}
        />
        <Tabber
          displayStyle="containers"
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>
      <div>
        {currentTab === "Details" && (
          <Details
            containerName={selectedIssue?.scanTarget}
            setSelectedIssue={setSelectedIssue}
            details={selectedIssue?.package_name}
            description={selectedIssue?.description}
            cveId={selectedIssue?.cve_id ? [selectedIssue?.cve_id] : ["NA"]}
            cweId={selectedIssue?.cwe_id}
          />
        )}
        {currentTab === "Solution" && (
          <Solution solution={selectedIssue?.fixed_version ?? ""} />
        )}
        {currentTab === "Create Ticket" && (
          <CreateIssue issue={selectedIssue?.issueTitle} />
        )}
      </div>
    </div>
  );
}

export function Heading({
  issueName,
  version,
  severity,
  verified,
}: {
  issueName?: string;
  version?: string;
  severity?: string;
  verified?: boolean;
}): JSX.Element {
  return (
    <div className="flex pb-4 justify-between flex-col gap-2 md:flex-row">
      <div className="my-auto flex flex-row flex-nowrap">
        <div className="pr-4 my-auto">
          <MdSecurity className="w-6 h-6 text-exposure-icon-active" />
        </div>
        {issueName && (
          <h3 className="text-xl font-semibold tracking-tight leading-6 text-gray-900 dark:text-gray-300">
            {issueName}
            {/* {titleCase(issueName)} */}
          </h3>
        )}
      </div>
      <div className="flex flex-row flex-nowrap gap-2">
        {version &&
          version !== "" &&
          version !== "not found" &&
          version !== "Unavailable" && (
            <span className="py-1 px-2 rounded text-gray-400 text-xs font-medium tracking-widest my-auto">
              Version: {version ?? "Not found"}
            </span>
          )}
        {verified && (
          <div className="flex flex-row flex-nowrap w-fit rounded-full dark:bg-dark-nav bg-light-seconday px-4 py-2 gap-2">
            <div className="my-auto">
              <Suspense fallback={<></>}>
                <SecureStackLogo className="w-4 h-4" />
              </Suspense>
            </div>
            <p className="my-auto dark:text-white">Verified</p>
          </div>
        )}
        {severity && (
          <div
            className={joinClassNames(
              getBorderColor(severity),
              getTextColor(severity),
              "flex flex-shrink-0 border-l-2",
            )}
          >
            <span className="pl-3 text-sm my-auto font-medium">
              {titleCase(severity)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
