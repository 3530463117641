import { gql } from "../../__generated__/gql";

export const FETCH_SCM_DETAILS = gql(`
  query FetchSCMDetails($orgId: String!) {
    userSCMDetails(orgId: $orgId) {
      ghowner
      bbusername
      azureUsername
      gitlabUsername
      patAZ
    }
  }
`);
