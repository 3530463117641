import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import { copyText } from "../../../functions/copyText";
import { joinClassNames } from "../../../functions/utilities";

export function sbomEnrichDataRowFactory(download: (sbomId: string, fileType: "xml" | "json", fileFormat: "cyclonedx" | "spdx") => void) {
  return (row: any, index: number) => {
    return {
      id: (
        <td
          title={row.id}
          key={`${index}-id`}
          className="px-3 py-4 truncate whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.id}
        </td>
      ),

      domain: (
        <td
          title={row.domain}
          key={`${index}-domain`}
          className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.domain}
        </td>
      ),

      cloud_account_id: (
        <td
          title={row.cloud_account_id ?? "n/a"}
          onClick={() => copyText(row.cloud_account_id)}
          key={`${index}-cloud-account`}
          className="px-3 py-4 truncate sm:max-w-[150px] whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.cloud_account_id ?? "n/a"}
        </td>
      ),

      state_reason: (
        <td
          title={row.state_reason}
          key={`${index}-state-reason`}
          className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          <span
            className={joinClassNames(
              row.state_reason === "completed"
                ? "bg-green-100 dark:bg-green-200 text-green-800 border-green-500"
                : "bg-orange-900 text-orange-400 border-orange-300",
              "px-2 py-1 text-xs rounded-xl border"
            )}
          >
            {row.state_reason}
          </span>
        </td>
      ),

      time_created: (
        <td
          title={row.time_created.substring(0, 10)}
          key={`${index}-time-created`}
          className="px-3 py-4 truncate sm:max-w-[150px] whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {row.time_created.substring(0, 10)}
        </td>
      ),

      action: (
        <td
          key={`${index}-action`}
          className=" overflow-x-hidden overflow-y-auto  whitespace-nowrap py-4  pr-10 text-right text-sm font-medium sm:pr-10"
        >
          <ToolTip download={download} rowId={row.id} />
        </td>
      ),
    };
  };
}

export function ToolTip({ download, rowId }: { download: (sbomId: string, fileType: "xml" | "json", fileFormat: "cyclonedx" | "spdx") => void, rowId: any }): JSX.Element {
  const actions: {
    action: Function;
    name: string;
    fileFormat: "cyclonedx" | "spdx";
    fileType: "xml" | "json";
    icon: JSX.Element;
  }[] = [
      {
        action: download,
        name: "Download CycloneDX JSON",
        fileFormat: "cyclonedx",
        fileType: "json",
        icon: (
          <AiOutlineDownload className="inline-flex cursor-pointer w-4 h-4" />
        ),
      },
      {
        action: download,
        name: "Download CycloneDX XML",
        fileFormat: "cyclonedx",
        fileType: "xml",
        icon: (
          <AiOutlineDownload className="inline-flex cursor-pointer w-4 h-4" />
        ),
      },
      {
        action: download,
        name: "Download SPDX JSON",
        fileFormat: "spdx",
        fileType: "json",
        icon: (
          <AiOutlineDownload className="inline-flex cursor-pointer w-4 h-4" />
        ),
      },
      /* {
        action: download,
        name: "Download SPDX XML",
        fileFormat: "spdx",
        fileType: "xml",
        icon: (
          <AiOutlineDownload className="inline-flex cursor-pointer w-4 h-4" />
        ),
      }, */
    ];

  return (
    <Popover>
      {({ open }: { open: boolean }) => (
        <>
          <Popover.Button
            className={joinClassNames(
              open ? "text-gray-900" : "text-gray-500",
              "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
            )}
          >
            <BsThreeDots className="cursor-pointer w-5 h-5 text-gray-900 dark:text-gray-400" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-50 transform h-auto -translate-x-1/2 mt-3 px-2 sm:px-0">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white dark:bg-dark-primary px-2 py-3 sm:gap-8 sm:p-8">
                  {actions.map((item, index) => (
                    <span
                      onClick={() => item.action(rowId, item.fileType, item.fileFormat)}
                      key={item.name + index.toString()}
                      className="-m-3 p-1 block items-center rounded-md cursor-pointer hover:bg-gray-50 text-left dark:hover:bg-dark-main  transition ease-in-out duration-150"
                    >
                      <p className="  font-medium text-xs text-gray-900 dark:text-gray-500">
                        {item.icon} {item.name}
                      </p>
                    </span>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
