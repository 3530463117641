/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PaginationProps, usePagination } from "../../functions/hooks/usePagination";
import { joinClassNames } from "../../functions/utilities";

function PaginationRange({ paginationRange, paginate, currentPage }: { currentPage: number, paginationRange: (string | number)[], paginate: Function }): JSX.Element {
  return <>{paginationRange?.map((pageNumber) => {
    return (
      <button
        key={pageNumber}
        aria-label={`Goto Page ${pageNumber}`}
        title={`Goto Page ${pageNumber}`}
        aria-current={currentPage === pageNumber ? "page" : "false"}
        onClick={() => {
          if (pageNumber !== "...") {
            paginate(pageNumber);
          }
        }}
        className={joinClassNames(
          "w-10 cursor-pointer relative inline-flex items-center px-4 py-2 border text-sm font-medium dark:text-gray-300",
          currentPage === pageNumber
            ? "box-border bg-indigo-50 dark:bg-dark-secondary text-black border-black dark:border-green-300 dark:text-teal-300"
            : "bg-white dark:bg-dark-primary border-gray-300 dark:border-dark-secondary text-gray-500 hover:bg-gray-50"
        )}
      >
        {pageNumber}
      </button>
    );
  })}</>
}

export function Pagination({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  siblingCount,
  filterBy,
  setFilterBy,
}: PaginationProps): JSX.Element {
  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    itemsPerPage,
    paginate,
    filterBy,
    setFilterBy,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return <></>;
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const isLastPage = () => {
    return currentPage === totalPages;
  };

  const isFirstPage = () => {
    return currentPage === 1;
  };

  return (
    <div className={`bg-transparent h-16 flex items-center border-gray-200 sm:px-6 flex-row flex-nowrap align-middle px-2 ${setFilterBy ? "justify-between" : "justify-center"}`}>
      <nav className="flex-grow flex md:hidden" role="navigation">
        <button
          onClick={() => isFirstPage() ? null : void paginate(currentPage - 1)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 dark:bg-dark-nav dark:border-gray-500 dark:text-gray-300"
        >
          <p className="hidden md:block">Previous Page</p>
          <p className="md:hidden block">Previous</p>
        </button>
        <p className="ml-1.5 my-auto md:hidden block text-gray-700 dark:text-gray-300">{currentPage}</p>
        <button
          onClick={() => isLastPage() ? null : void paginate(currentPage + 1)}
          className="ml-1.5 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 dark:bg-dark-nav dark:border-gray-500 dark:text-gray-300"
        >
          <p className="hidden md:block">Next Page</p>
          <p className="md:hidden block">Next</p>
        </button>
      </nav>

      <nav className="md:inline-flex shadow-sm -space-x-px justify-between hidden" role="navigation" aria-label="Pagination Navigation">
        <button
          title="Goto first page"
          onClick={() => void paginate(1)}
          className={joinClassNames(
            "relative inline-flex flex-nowrap items-center px-2 py-2 rounded-l-lg border border-gray-300 dark:border-dark-secondary bg-white dark:bg-dark-primary text-sm font-medium text-gray-500 hover:bg-gray-50 dark:text-gray-300",
            isFirstPage() ? "cursor-not-allowed" : "cursor-pointer"
          )}
        >
          <span className="sr-only">First</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          <ChevronLeftIcon className="h-5 w-5 -ml-4" aria-hidden="true" />
        </button>
        <button
          title="Goto previous page"
          onClick={() => isFirstPage() ? null : void paginate(currentPage - 1)}
          className={joinClassNames(
            "relative inline-flex items-center px-2 py-2 border border-gray-300 dark:border-dark-secondary bg-white dark:bg-dark-primary text-sm font-medium text-gray-500 hover:bg-gray-50 dark:text-gray-300",
            isFirstPage() ? "cursor-not-allowed" : "cursor-pointer"
          )}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        <PaginationRange paginationRange={paginationRange} paginate={paginate} currentPage={currentPage} />

        <button
          title="Goto next page"
          onClick={() => isLastPage() ? void null : void paginate(currentPage + 1)}
          className={joinClassNames(
            "relative inline-flex items-center px-2 py-2 border border-gray-300 dark:border-dark-secondary bg-white dark:bg-dark-primary text-sm font-medium text-gray-500 hover:bg-gray-50 dark:text-gray-300",
            isLastPage() ? "cursor-not-allowed" : "cursor-pointer "
          )}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          title="Goto last page"
          onClick={() => isLastPage() ? void null : void paginate(totalPages)}
          className={joinClassNames(
            "relative inline-flex items-center px-2 py-2 rounded-r-lg border border-gray-300 dark:border-dark-secondary bg-white dark:bg-dark-primary text-sm font-medium text-gray-500 hover:bg-gray-50 dark:text-gray-300",
            isLastPage() ? "cursor-not-allowed" : "cursor-pointer "
          )}
        >
          <span className="sr-only">Last</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          <ChevronRightIcon className="h-5 w-5 -ml-4" aria-hidden="true" />
        </button>
      </nav>

      {setFilterBy && <div className="text-black bg-light-primary p-[6px] rounded-md ml-2 dark:text-gray-300 dark:bg-dark-nav">
        <select name="sort-apps-filter" onChange={(e) => void setFilterBy(e.target.value === "recently_added" ? "recently_added" : "name")} className="bg-light-primary px-2 dark:bg-dark-nav md:w-auto w-full" defaultValue={filterBy}>
          <option value="name">Name</option>
          <option value="recently_added">Recently Added</option>
        </select>
      </div>}

    </div>
  );
};
