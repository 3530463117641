import { joinClassNames } from "../../functions/utilities";
import { Pagination } from "../pagination/Pagination";

export function TableSkeleton(): JSX.Element {
  return (
    <>
      <div
        className={
          "py-5 animate-pulse h-[600px] my-auto dark:shadow-2xl dark:bg-dark-main  shadow-[0_35px_40px_-15px_rgba(0,0,0,0.2)] bg-opacity-75 rounded-2xl overflow-hidden text-center"
        }
      />
      <Pagination
        currentPage={1}
        itemsPerPage={15}
        totalItems={15}
        paginate={() => { }}
        siblingCount={1}
      />
    </>
  );
};

export function DashboardTableSkeleton(): JSX.Element {
  return (
    <div
      className={
        "py-5 animate-pulse h-28 my-auto dark:shadow-2xl bg-light-seconday dark:bg-dark-main shadow-[0_35px_40px_-15px_rgba(0,0,0,0.2)] bg-opacity-75 rounded-2xl overflow-hidden text-center"
      }
    >
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          className={joinClassNames(
            "py-2 mt-2 animate-pulse w-3/4 mx-auto transition dark:shadow-2xl bg-light-primary dark:bg-dark-primary shadow-[0_35px_40px_-15px_rgba(0,0,0,0.2)] bg-opacity-75 rounded-2xl overflow-hidden text-center relative"
          )}
        />
      ))}
    </div>
  );
};
