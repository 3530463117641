import { useSnackbar } from "notistack";
import { FaRegCopy } from "react-icons/fa";
import { usePathAppId } from "../../functions/hooks";

export function ApplicationId({ appId }: { appId?: string }): JSX.Element {
  const pathAppId = usePathAppId();

  const { enqueueSnackbar } = useSnackbar();
  return (
    <FaRegCopy
      className="h-5 w-5 inline-flex dark:text-gray-400 dark:hover:text-gray-200 text-gray-400 hover:text-gray-600"
      onClick={async (e) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(appId ?? pathAppId ?? "")
          .then(() => void enqueueSnackbar("Application ID copied to clipboard", { variant: "success", }));
      }}
    />
  );
};
