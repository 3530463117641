import { useEffect } from "react";

export default function useConciergeRedirect(numOfApps: number | "Error getting applications" | "loading") {
    useEffect(() => {
        const flag = "concierge-redirected";
        if (numOfApps === "Error getting applications") {
            return;
        }
        if (numOfApps === "loading") {
            return;
        }
        if (numOfApps != null && numOfApps > 0) {
            return;
        }
        if (window.sessionStorage.getItem(flag)) {
            return;
        }
        window.sessionStorage.setItem(flag, "true");
        window.location.href = "/onboarding";
    }, [numOfApps]);
}