import { gql } from "@apollo/client";

export const APPLICATION_SETTINGS = gql(`
  query ApplicationSettings($applicationId: String!, $orgId: String) {
    dashboard(applicationId: $applicationId, orgId: $orgId) {
      application {
        target
        organization
        scm
        group
        workspace
        f_account_id
        reponame
        branch
        description
        name
        language
        applicationId
        containerlist
        Tags {
          id
          name
        }
        assetScan
      }
    }
  }
`);
