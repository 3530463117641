import { gql } from "@apollo/client/core";

export const CreateFCloudAccount = gql`
  mutation CreateFCloudAccount_1($details: cloudAccountDetails!) {
    createFCloudAccount(details: $details) {
      id
      name
    }
  }
`;
