import { DropDownSelect } from "../forms/DropDownSelect";

import { FormErrorMessage } from "./FormErrorMessage";

type IssueTypeProps = {
  selected: any;
  setSelected: any;
  issueTypes: any;
};

export function IssueType({
  issueTypes,
  selected,
  setSelected,
}: IssueTypeProps): JSX.Element {
  return (
    <div className="mt-2 w-96">
      <p className="dark:text-gray-400">Issue type*</p>
      <DropDownSelect
        selected={selected}
        setSelected={setSelected}
        data={issueTypes?.map((type: any) => type)}
      />
      <FormErrorMessage isShowing={selected.id === ""} />
    </div>
  );
};
