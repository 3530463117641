import { gql } from "@apollo/client/core";

export const EXPOSURE_ANALYSIS = gql`
  mutation ExposureAnalysis(
    $hostname: String!
    $scantype: String!
    $applicationId: String!
    $sub: String
  ) {
    exposure(
      hostname: $hostname
      scantype: $scantype
      applicationId: $applicationId
      orgId: $sub
    ) {
      code
      message
      scanreference
    }
  }
`;