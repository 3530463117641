import { ChangeEventHandler, useMemo, useState } from "react";
import { BsSearch, BsPlusLg } from "react-icons/bs";
import { AiFillCloud, AiOutlineHistory } from "react-icons/ai";
import { MdSecurity } from "react-icons/md";
import { FaCode, FaRegEdit } from "react-icons/fa";
import { NavLink, useHistory } from "react-router-dom";
import { Pagination } from "../../components/pagination/Pagination";
import Fuse from "fuse.js";
import { XIcon } from "@heroicons/react/solid";
import { ApplicationId } from "./ApplicationId";
import { Application } from "../../__generated__/graphql";
import { usePersistedState } from "../../views/onboarding/OnboardingStepper";
import { SiLinuxcontainers } from "react-icons/si";

type AppListProps = {
  setCurrentApp: (appId: string) => void;
  applications: Application[];
  error: boolean | undefined;
  closeFn: () => void;
};

enum FilterOptions {
  NAME = "name",
  TARGET = "target",
  LAST_SCANNED = "scannedtime",
}

const filterOptionsForName = {
  includeScore: true,
  ignoreLocation: true,
  keys: [
    { name: FilterOptions.NAME, weight: 2 },
    { name: FilterOptions.TARGET, weight: 1 },
  ],
};

const filterOptionsForTarget = {
  includeScore: true,
  findAllMatches: true,
  keys: [
    { name: FilterOptions.NAME, weight: 1 },
    { name: FilterOptions.TARGET, weight: 2 },
  ],
};

const nameFuse = new Fuse([] as Application[], filterOptionsForName);
const targetFuse = new Fuse([] as Application[], filterOptionsForTarget);

export function CreateAppBtn({ closeFn }: { closeFn: Function }): JSX.Element {
  return (
    <NavLink
      onClick={() => {
        //tourActive && setState({ stepIndex: stepIndex + 1 });
        localStorage.removeItem("applicationId");
        closeFn();
      }}
      to="/create"
      type="button"
      className="create-application-btn-selector md:ml-3 inline-flex items-center text-white p-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md dark:text-gray-800 bg-[#af3734] focus:outline-none focus:ring-2 group focus:ring-offset-2"
    >
      <BsPlusLg
        className="-ml-0.5 mr-2 h-4 w-4 dark:text-gray-800 fill-white stroke-white dark:fill-black dark:stroke-black group group-hover:-rotate-90 transition-transform duration-500"
        aria-hidden="true"
      />
      Application
    </NavLink>
  );
}

export type PaginationFilterBy = "recently_added" | "name";

export function AppList({
  setCurrentApp,
  error,
  applications,
  closeFn,
}: AppListProps): JSX.Element {
  const { push } = useHistory();
  const [searchApplicationText, setSearchApplicationText] =
    useState<string>("");
  const [currentPage, setCurrentPage] = usePersistedState<number>(
    "app-list-page-number",
    1,
  );
  const [filterBy, setFilterBy] = usePersistedState<PaginationFilterBy>(
    "app-list-sort-by",
    "name",
  );
  const [appsPerPage] = useState(9);

  const indexOfLastApp = currentPage * appsPerPage;
  const indexOfFirstApp = indexOfLastApp - appsPerPage;

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const setCurrentApplicationOnEvent = (appId: string, appName: string) => {
    return (event: any) => {
      setCurrentApp(appId);
    };
  };

  const filteredApplications = useMemo(() => {
    const isURL =
      searchApplicationText.includes(".") ||
      searchApplicationText.includes("//");

    if (!applications) {
      return [] as Application[];
    }

    let results: Fuse.FuseResult<Application>[] = [];

    targetFuse.setCollection(applications);
    nameFuse.setCollection(applications);

    if (searchApplicationText) {
      if (isURL) {
        results = targetFuse.search(searchApplicationText);
      } else {
        results = nameFuse.search(searchApplicationText);
      }
    } else
      results = applications?.map((item, idx) => {
        return {
          refIndex: idx,
          item: item,
          score: 0,
        };
      });

    results?.sort(
      (a: Fuse.FuseResult<Application>, b: Fuse.FuseResult<Application>) => {
        if (searchApplicationText != null && searchApplicationText !== "") {
          if (b?.score != null && a?.score != null) {
            if (b.score === a.score) {
              if (a.item.name == null || b.item.name == null) {
                return 0;
              }
              return a.item.name.localeCompare(b.item.name);
            }
            return a.score < b.score ? -1 : 1;
          }
        }

        if (filterBy === "recently_added") {
          if (a.item.scannedtime === b.item.scannedtime) {
            return 0;
          }
          if (a.item.scannedtime < b.item.scannedtime) {
            return 1;
          }
          return -1;
        }

        if (a?.item?.name && b?.item?.name) {
          return a.item.name.localeCompare(b.item.name);
        }

        return 0;
      },
    );

    return results?.map((i) => i.item);
  }, [searchApplicationText, applications, filterBy]);

  const filterApplicationsOnChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setCurrentPage(1);
    setSearchApplicationText(e.target.value);
  };

  return (
    <div className="bg-white dark:bg-dark-secondary box-border w-full rounded-b-lg">
      <div className="w-full justify-between flex items-center px-5">
        <div className="group dark:border-1 dark:border-dark-secondary py-4 ol-span-4 flex-shrink flex-grow">
          {searchApplicationText ? (
            <XIcon
              className={`w-5 h-5 text-gray-400 absolute top-[25px] text-base left-7 cursor-pointer ${
                searchApplicationText ? "block" : "hidden"
              }`}
              onClick={() => setSearchApplicationText("")}
            />
          ) : (
            <BsSearch className="absolute top-[27px] text-base left-7 text-slate-400 dark:text-slate-500 group-focus-within:text-slate-500" />
          )}
          <input
            type="text"
            className="dark:text-gray-300 text-black h-[38px] rounded-lg transition text-base w-full indent-8 focus:shadow-md shadow-red-300 outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-primary outline-offset-2"
            placeholder="Search Applications"
            value={searchApplicationText}
            autoFocus
            onChange={filterApplicationsOnChange}
          />
        </div>
        <CreateAppBtn closeFn={closeFn} />
      </div>
      <div className="bg-gray-50 dark:bg-dark-primary w-full">
        <Pagination
          isFiltered={searchApplicationText.length > 0}
          currentPage={currentPage}
          itemsPerPage={appsPerPage}
          siblingCount={1}
          totalItems={applications?.length}
          paginate={paginate}
          numFilteredItems={filteredApplications?.length}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
        />
      </div>
      <div className="box-border px-5">
        <ul className="py-3">
          {!error &&
            filteredApplications
              ?.slice(indexOfFirstApp, indexOfLastApp)
              .map((app: Application, index: number) => (
                <li
                  onClick={setCurrentApplicationOnEvent(
                    app?.applicationId ?? "",
                    app?.name ?? "",
                  )}
                  key={`${app.applicationId}-${index}`}
                  className="px-3 py-3 grid grid-cols-5 cursor-pointer hover:bg-slate-100 dark:hover:bg-dark-primary rounded-xl transition grid-flow-row items-center"
                >
                  <div className="text-md font-medium dark:text-gray-300 space-x-2 col-span-4 md:col-span-3 w-full">
                    <p
                      className={`whitespace-nowrap text-ellipsis overflow-x-hidden`}
                      title={`App Name "${app.name}"`}
                    >
                      {app.name}
                      {app?.target && (
                        <span
                          className="pl-4 text-gray-400 dark:text-gray-700"
                          title={`${app?.target}`}
                        >
                          {app?.target}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-span-1 md:col-span-2 flex md:justify-between justify-end">
                    <div className="justify-evenly flex-grow align-middle hidden md:flex">
                      <MdSecurity
                        className={`h-5 w-5 ${
                          app.target
                            ? "text-orange-400 md:visible dark:text-orange-400"
                            : "fill-gray-200 dark:fill-gray-600"
                        }`}
                        title={
                          app.target
                            ? `SecureStack Exposure Analysis Enabled for ${app.target}`
                            : "SecureStack Exposure Analysis Not Enabled"
                        }
                      />
                      <FaCode
                        className={`h-5 w-5 ${
                          app.scm
                            ? "text-green-400 dark:text-green-300"
                            : "fill-gray-200 dark:fill-gray-600"
                        }`}
                        title={
                          app.scm
                            ? `SecureStack SCM Analysis Enabled (${app.scm})`
                            : "SecureStack SCM Analysis Not Enabled"
                        }
                      />
                      <AiFillCloud
                        className={`h-5 w-5 ${
                          app.f_account_id
                            ? "h-5 w-5 text-blue-400"
                            : "fill-gray-200 dark:fill-gray-600"
                        }`}
                        title={
                          app.f_account_id
                            ? `SecureStack Cloud Analysis Enabled for ID ${app.f_account_id}`
                            : "SecureStack Cloud Analysis Not Enabled"
                        }
                      />

                      <SiLinuxcontainers
                        className={`h-5 w-5 ${
                          app.containerlist && app.containerlist.length > 0
                            ? "text-blue-600 md:visible dark:text-blue-600"
                            : "fill-gray-200 dark:fill-gray-600"
                        }`}
                        title={
                          app.containerlist && app.containerlist.length > 0
                            ? `SecureStack Exposure Analysis Enabled for ${app.target}`
                            : "SecureStack Exposure Analysis Not Enabled"
                        }
                      />
                    </div>

                    <div className="flex flex-row flex-nowrap w-fit justify-evenly">
                      <div title="Copy App ID">
                        <ApplicationId
                          appId={
                            app.applicationId ??
                            "Sorry, unable to copy app ID..."
                          }
                        />
                      </div>

                      <div title="Edit this App" className="px-2 md:px-5">
                        <FaRegEdit
                          onClick={(e) => {
                            push(`/settings/applications/${app.applicationId}`);
                            e.stopPropagation();
                            closeFn();
                          }}
                          className="h-5 w-5 inline-flex dark:text-gray-400 dark:hover:text-gray-200 text-gray-400 hover:text-gray-600"
                        />
                      </div>

                      <div title="App History - View Past Scan Results">
                        <AiOutlineHistory
                          onClick={(e) => {
                            push(`/history/${app.applicationId}`);
                            e.stopPropagation();
                            closeFn();
                          }}
                          className="h-5 w-5 inline-flex dark:text-gray-400 dark:hover:text-gray-200 text-gray-400 hover:text-gray-600"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          {error && (
            <div className="text-red-500">
              Error retrieving application list
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
