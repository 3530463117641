import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Dispatch, useState } from "react";
import { deriveFilesFromPackageManager } from "../../../../../functions/deriveFilesFromPackageManager";
import { useHistoryState } from "../../../../../functions/hooks";
import { useUser } from "../../../../../functions/hooks/useUser";
import { AutoFileDetection } from "./AutoDetection";
import { GithubFileStructureExplorer } from "./GitHubFileStructureExplorer";
import { GET_GITHUB_REPO_STRUCTURE } from "../../../../../graphql/queries/FolderStructure";
import useScmOrgOrWorkspaceHistory from "../../../../../functions/hooks/useScmOrgOrWorkspaceHistory";
import { GitHubFolderStructure } from "../../../../../__generated__/graphql";
import logGraphQLError from "../../../../../functions/logGraphQLError";

type BreadCrumb = {
  path: string;
  sha: string;
};

export function GithubStructure({ codePath, setCodePath }: { codePath: string, setCodePath: Dispatch<string> }): JSX.Element {
  const [folderStructure, setFolderStructure] = useState<GitHubFolderStructure[]>([]);
  const [isViewingFileExplorer, setIsViewingFileExplorer] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const [currentSha, setCurrentSha] = useState("");
  const [repository] = useHistoryState("repository");
  const [branch] = useHistoryState("branch");
  const [orgOrWorkspace] = useScmOrgOrWorkspaceHistory();
  const [packageManager] = useHistoryState("packageManager");
  const user = useUser();
  const { loading, error, refetch } = useQuery(GET_GITHUB_REPO_STRUCTURE, {
    variables: {
      organization: orgOrWorkspace,
      githubReponame: repository,
      githubBranchname: branch,
      path: currentSha,
      orgId: user?.selectedOrg,
    },
    errorPolicy: "all",
    onError: logGraphQLError,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // @ts-expect-error folderStructure has values
      let values = data?.folderStructure?.values;

      let sorted =
        values &&
        [...values]?.sort((a: any, b: any) => {
          if (a.type !== "tree") {
            return -1;
          }

          if (b.type === "tree") {
            return 1;
          }

          return 0;
        });
      setFolderStructure(sorted);
    },
  });

  if (error) {
    return <p className="text-red-600 cursor-pointer" onClick={() => void refetch()}>Error getting folder structure. Retry?</p>;
  }

  const pathBuilder = (value: string) => {
    const derivePathFromValue = value.split(",")[0];
    const deriveShaFromValue = value.split(",")[1];

    if (!value) {
      setCurrentPath("");
      setBreadCrumbs([]);
    }

    const breadCrumbArr = breadCrumbs.map((i) => i.path);

    const indexOfPath = breadCrumbArr.indexOf(derivePathFromValue);
    //Condition for going back files
    if (indexOfPath >= 0) {
      setCurrentPath(derivePathFromValue);
      setCurrentSha(deriveShaFromValue);

      setBreadCrumbs(
        breadCrumbs.filter((crumb, index) => index <= indexOfPath)
      );
      return;
    }

    setBreadCrumbs((prevCrumbs) => [
      ...prevCrumbs,
      { path: derivePathFromValue, sha: deriveShaFromValue },
    ]);

    setCurrentPath(derivePathFromValue);
    setCurrentSha(deriveShaFromValue);
  };

  const onClose = () => {
    setIsViewingFileExplorer(false);
  };

  return (
    <div>
      {!isViewingFileExplorer ? (
        <AutoFileDetection
          isLoading={loading}
          viewExplorer={() => setIsViewingFileExplorer(true)}
          files={deriveFilesFromPackageManager(folderStructure, packageManager)}
        />
      ) : (
        <GithubFileStructureExplorer
          buildPath={(path) => pathBuilder(path.currentTarget.id)}
          path={currentPath}
          files={folderStructure}
          breadCrumbs={breadCrumbs}
          isLoading={loading}
          closeFileExplorer={onClose}
          codePath={codePath}
          setCodePath={setCodePath}
        />
      )}
    </div>
  );
};
