import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SignIn } from "./auth/SignIn";
import { Register } from "./auth/Register";
import { ForgotPassword } from "./auth/ForgotPassword";
import { OrgSelector } from "./auth/OrgSelector";
import { Logout } from "./auth/Logout";
import { ProtectedRoute } from "../auth/ProtectedRoute";
import { AuthLayout } from "../components/layouts/AuthLayout";
import { CheckEmail, CheckEmailForMagicLink } from "./auth/CheckEmail";
import { ChangePassword } from "./auth/ChangePassword";
import Mfa from "./auth/Mfa";

export function Auth(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* WARNING: All routes that aren't under a protected route are available to the public */}
      <Route path={`${path}/mfa`} exact>
        <AuthLayout>
          <Mfa />
        </AuthLayout>
      </Route>
      <Route path={`${path}/login`} exact>
        <AuthLayout>
          <SignIn />
        </AuthLayout>
      </Route>
      <Route path={`${path}/forgot-password`} exact>
        <AuthLayout>
          <ForgotPassword />
        </AuthLayout>
      </Route>
      <Route path={`${path}/register`} exact>
        <AuthLayout>
          <Register />
        </AuthLayout>
      </Route>
      <ProtectedRoute path={`${path}/logout`} component={Logout} exact />
      <ProtectedRoute path={`${path}/orgs`} component={OrgSelector} exact />
      <ProtectedRoute path={`${path}/change-password`} exact>
        <ChangePassword onPasswordChange={() => { }} />
      </ProtectedRoute>
      <Route path={`${path}/check-email`} component={CheckEmail} exact />
      <Route exact
        path={`${path}/check-magiclink`}
        component={CheckEmailForMagicLink}
      />
    </Switch>
  );
};
