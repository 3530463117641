import { useState } from "react";
import { TheOneCombobox } from "../../../../components/buttons/TheOneCombobox";
import { useLastElementRef } from "../../../../functions/hooks/useLastElementRef";
import { useRepositorySearchQuery } from "../../../../functions/hooks/useRepositorySearchQuery";
import useScmOrgOrWorkspaceHistory from "../../../../functions/hooks/useScmOrgOrWorkspaceHistory";

export enum SCM_BRANCH_NAMES {
  WORKSPACE = "workspace",
  ORGANIZATION = "organization",
  GROUP = "group",
}

type SelectRepositoryProps<T> = {
  selected: T;
  setSelected: (value: T) => void;
  testId?: string;
};

export function SelectRepository({
  selected,
  setSelected,
  testId,
}: SelectRepositoryProps<any>): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const [orgOrWorkspace] = useScmOrgOrWorkspaceHistory();

  const { loading, error, repositoryData, hasMore, refetch } = useRepositorySearchQuery("", currentPage, orgOrWorkspace);

  const incrementPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const lastElementRef = useLastElementRef(incrementPage, loading, hasMore);

  if (error) {
    return (
      <p className="text-red-600 cursor-pointer" onClick={() => void refetch()}>
        Error retrieving repositories. Retry?
      </p>
    );
  }

  return (
    <TheOneCombobox
      key={selected?.name ?? selected}
      testId={testId}
      defaultValue="Select repository"
      lastIdxRef={lastElementRef}
      isLoading={loading}
      items={repositoryData}
      selected={selected}
      onSelection={setSelected}
    />
  );
};
