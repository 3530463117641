import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { useHistoryState, usePathAppId } from "../../../functions/hooks";
import { CREATE_OR_UPDATE_APP } from "../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { CreateAppLayout } from "../single/Layout";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useUser } from "../../../functions/hooks/useUser";
import { EXPOSURE_ANALYSIS } from "../../../graphql/mutations/ExposureAnalysis";
import { InputField } from "../../../components/forms/InputField";
import { APPLICATION_STATUS } from "../../../graphql/queries/ApplicationStatus";
import { APPLICATION_SETTINGS } from "../../../graphql/queries/ApplicationSettingsQuery";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";
import { GetDashboardResults } from "../../../graphql/queries/GetDashboardResults";

const maxExposureLength = 120;

export function ExposureSettings({
  isEditing,
  closeEditModal,
  appUriTarget,
  optedOutOfAssetScan,
}: {
  isEditing?: boolean;
  closeEditModal?: () => void;
  appUriTarget?: string;
  optedOutOfAssetScan?: 0 | 1;
}): JSX.Element {
  const [appTarget, setAppTarget] = useHistoryState("appTarget");
  useEffect(() => {
    if ((!appTarget || appTarget === "") && appUriTarget) {
      setAppTarget(appUriTarget);
    }
  });
  const [, setId] = useHistoryState("id");
  const [appId] = useHistoryState("appId");
  const user = useUser();
  const appIdToEdit = usePathAppId();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const updateAppTargetOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    let value = e.target.value.trim();
    if (value.length > maxExposureLength) {
      value = value.slice(0, maxExposureLength);
    }
    setAppTarget(value);
  };
  const [wantsOptOutAssetScan, setWantsOptOutAssetScan] = useState<boolean>(optedOutOfAssetScan === 1);

  const [triggerExposureScan] = useMutation(EXPOSURE_ANALYSIS, {
    variables: {
      hostname: appTarget,
      scantype: "full",
      applicationId: appId,
      sub: user?.selectedOrg,
      email: user?.email,
    },
    onCompleted() {
      setId("3");
    },
  });

  const [createExposureSettings, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_APP,
    {
      refetchQueries: [APPLICATION_SETTINGS, APPLICATION_STATUS, GET_APPLICATIONS],
      onCompleted: (data) => {
        if (data?.createOrUpdateApplication) {
          if (isEditing) {
            enqueueSnackbar("Successfully updated application", {
              variant: "success",
              persist: false,
            });
            (appTarget && (appId || appIdToEdit)) ? triggerExposureScan({
              variables: {
                hostname: appTarget,
                scantype: "full",
                applicationId: appId ?? appIdToEdit,
                sub: user?.selectedOrg,
                email: user?.email,
              }
            }).catch((e) => { enqueueSnackbar(`Failed to start Exposure scan: ${e?.message}`, { variant: "error", persist: false }) }) : enqueueSnackbar(`Unable to start scan for ${appTarget}`, {
              variant: "error",
              persist: false,
            });
            push(`/settings/applications/${appIdToEdit}`);
            closeEditModal && closeEditModal();
          } else {
            appTarget && triggerExposureScan();
          }
        }
      },
    }
  );

  const goNext: MouseEventHandler<HTMLButtonElement> = () => {
    createExposureSettings({
      variables: {
        applicationDetails: {
          applicationId: appId,
          target: appTarget,
        },
      },
    });
  };

  const save = () => {
    return createExposureSettings({
      variables: {
        applicationDetails: {
          applicationId: appIdToEdit,
          target: appTarget,
          assetScan: wantsOptOutAssetScan ? 1 : 0,
        },
      },
      refetchQueries: [GetDashboardResults, APPLICATION_STATUS]
    });
  };

  const isComplete = !!appTarget;

  return (
    <>
      <CreateAppLayout isEditing={isEditing}>
        <h2 className="pb-6 text-2xl  tracking-tight text-gray-900  dark:text-white ">
          {!isEditing && "2."} Add an endpoint for your application.
        </h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
              Application Target
            </label>
            <p className="text-gray-700 dark:text-gray-400">{appTarget?.length ?? 0}/120</p>
          </div>
          <InputField
            name="appTarget"
            type="text"
            defaultValue={appUriTarget ?? appTarget ?? ""}
            maxLength={maxExposureLength}
            onChange={updateAppTargetOnChange}
            placeholder="niftybank.org"
            required={true}
          />
          {
            isEditing ? <div className="flex flex-row flex-nowrap content-start">
              <input
                id="optOutAssetScanning"
                checked={wantsOptOutAssetScan}
                onChange={() => void setWantsOptOutAssetScan(!wantsOptOutAssetScan)}
                type="checkbox"
                className="ml-1 mr-2 border-gray-300 border placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-red-500 focus:red-indigo-500 sm:text-sm dark:text-gray-300 rounded-lg transition text-base outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-primary outline-offset-2 w-4 h-4"
              />
              <label htmlFor="optOutAssetScanning" className="block text-xs font-medium text-gray-700 dark:text-gray-400 my-auto">
                Opt Out of Asset Scanning
              </label>
            </div> : null
          }
        </div>
        <ExposureScanning />
      </CreateAppLayout>

      {isEditing ? (
        <div className="text-right space-x-2">
          <Button
            outlined
            label="Cancel"
            onClick={() => closeEditModal && closeEditModal()}
          />

          <Button primary label="Save" onClick={save} className="mb-20" />
        </div>
      ) : (
        <div className="mt-4 justify-between flex ml-0 md:ml-[260px] mb-20">
          <Button outlined={true} label="Back" onClick={() => setId("1")} />
          <div>
            <Button
              className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
              onClick={() => {
                setAppTarget("");
                setId("3");
              }}
              label="Skip"
            />
            {/* <Button
              className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
              onClick={() => {
                save().then(() => push(`/dashboard/${appId}`));
              }}
              label="Finish"
            /> */}
            <Button
              disabled={!isComplete}
              primary={true}
              isLoading={loading}
              label="Next"
              onClick={goNext}
            />
          </div>
        </div>
      )}
      {error && (
        <div className="ml-0 mt-2 md:ml-[260px]">
          <ErrorAlert
            title={"Error creating target, please try again or contact support"}
            isShowing={true}
          />
        </div>
      )}
    </>
  );
};

export function ExposureScanning(): JSX.Element {
  return (
    <div
      id="alert-additional-content-5"
      className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
      role="alert"
    >
      <div className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Info</span>
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
          Exposure Scanning
        </h3>
      </div>
      <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
        One of the great features that SecureStack has to offer, is the ability
        to scan application endpoints and determine if what you deployed, is
        what is actually accessible. To enable this, we need to know what
        endpoint your application has been deployed to.
      </div>
      <div>
        <span className="font-medium text-gray-700 dark:text-gray-300">
          This is usually in the form:
        </span>
        <ul className="mt-1.5 ml-4 dark:text-gray-400 text-gray-700 text-xs leading-5 list-disc list-inside ">
          <li>Of a domain name (e.g. app.niftybank.org)</li>
          <li>A url (e.g. https://app.niftybank.org)</li>
          <li>
            Or even a full path in the case of a app deployed to a sub-folder of
            the root directory of a webserver (e.g. https://niftybank.org/app/)
          </li>
        </ul>
      </div>
    </div>
  );
};
