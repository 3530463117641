import { useHistory } from "react-router-dom";
import { Button } from "./Button";

export function LogoutButton(): JSX.Element {
  const history = useHistory();
  const goToLogout = () => {
    history.push("/auth/logout");
  };

  return (
    <div className="flex"><Button outlined className="m-auto w-20 min-w-fit outline dark:outline-red-700 outline-red-600 outline-1" label="Logout" onClick={goToLogout} /></div>
  );
};
