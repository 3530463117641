import { Container, Link, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Suspense, lazy } from "react";
import { svgImport } from "../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../assets/small-logo.svg").then(svgImport));


export default function EmailVerificationRedirectPage() {
  return (
    <Container style={{ background: "#1B2634" }}>
      <Box sx={{ height: "50vh" }}>
        <Paper elevation={0} style={{ marginTop: 150 }}>
          <Typography
            align="center"
            variant="h2"
            style={{ padding: 150, background: "#1B2634" }}
          >
            <Suspense fallback={<></>}><SecureStackLogo /></Suspense>
            <Typography color="white" align="center" variant="h2">
              Please authorize SecureStack to access your private repositories.
            </Typography>
          </Typography>
        </Paper>
        <Typography
          align="center"
          variant="h3"
          style={{
            padding: 150,
            fontWeight: "bold",
          }}
        >
          <Link style={{ textDecoration: "none" }} href="/">
            Log In
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}
