import { gql } from "@apollo/client/core";

export const GET_LIST_CONTAINERS_BYORG = gql`
  query GetListOfContainersByOrg(
    $orgId: String
    $account: String
    $accountName: String
  ) {
    getListOfContainersByOrg(
      orgId: $orgId
      account: $account
      accountName: $accountName
    ) {
      name
      namespace
    }
  }
`;
