import { useState } from "react";
import { MdSecurity } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { joinClassNames } from "../../functions/utilities";

type ApplicationPathParams = {
  appId: string;
};

export function NoAssetData(): JSX.Element {
  const [isToggled] = useState(false);
  return (
    <>
      <div
        className={joinClassNames(
          "py-5 h-56 px-2 bg-white my-auto dark:bg-dark-main bg-opacity-75 rounded-2xl overflow-hidden"
        )}
      >
        <div className="text-right mr-4"></div>
        <NiftyBankScores isShowing={isToggled} />
        <CurrentAppNoData isShowing={!isToggled} />
      </div>
    </>
  );
};

export function CurrentAppNoData({ isShowing }: { isShowing: boolean }): JSX.Element {
  const { push } = useHistory();
  const { appId } = useParams<ApplicationPathParams>();
  if (!isShowing) return <></>;

  return (
    <div className="text-center">
      <MdSecurity className="w-7 h-7 mx-auto text-orange-400" />
      <h3 className="mt-2 text-lg font-medium text-gray-900 dark:text-gray-400">
        No Data Available
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by
        <button
          onClick={() => push(`/settings/applications/${appId}/general`)}
          className="text-sm font-medium text-indigo-400 hover:text-indigo-500 cursor-pointer"
        >
          {""} adding a target
          <span aria-hidden="true"> &rarr;</span>
        </button>
      </p>

      <p className="text-center dark:text-gray-500 py-10 max-w-sm  mx-auto">
        If you've recently setup a target, please run an exposure analysis to
        get up to date results.
      </p>
    </div>
  );
};

export function NiftyBankScores({ isShowing }: { isShowing: boolean }): JSX.Element {
  if (!isShowing) return <></>;
  return <div>nifty bank</div>;
};
