import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";

type DashHeadingProps = {
  refetch?: () => void;
  heading: string;
  padTop?: boolean;
  hasLink?: boolean;
  linkTo?: string;
};

export function DashboardHeading({
  refetch,
  heading,
  padTop,
  hasLink,
  linkTo,
}: DashHeadingProps): JSX.Element {
  const innerHeading = (
    <div className="flex flex-row flex-nowrap">
      <h2
        className={`text-xl font-bold text-black dark:text-gray-300 px-2 py-2 ${
          padTop ? "mt-4" : ""
        }`}
      >
        {heading}
      </h2>
      {hasLink && (
        <div className="my-auto">
          <FiExternalLink className="w-4 h-4 dark:text-white my-auto" />
        </div>
      )}
    </div>
  );

  const refresh = () => {
    refetch && refetch();
  };
  return (
    <div className="flex flex-row flex-nowrap mt-4 py-1">
      {hasLink ? (
        <>
          <Link to={linkTo}>{innerHeading}</Link>

          {refetch && (
            <div className="relative group">
              <IoMdRefresh
                className="mt-3 ml-2 w-5 h-5 my-auto dark:text-white hover:text-blue-800 cursor-pointer"
                onClick={() => refresh()}
              />
              <div className="absolute hidden mt-3 bg-white border border-gray-300 px-2 py-1 text-sm rounded-md group-hover:block">
                Refresh
              </div>
            </div>
          )}
        </>
      ) : (
        innerHeading
      )}
    </div>
  );
}
