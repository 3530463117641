import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { joinClassNames } from "../../functions/utilities";

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  id = "modal",
  icon,
  width = "max-w-2xl",
  bgColor = "dark:bg-dark-main bg-white ",
  zindex = "z-40",
  closeWhenClickingBackground = false,
  closeButtonVisible,
  noVerticalMargin
}: {
  width?: string;
  isOpen: boolean;
  title?: any;
  id?: string;
  onClose?: (value: boolean) => void;
  children: any;
  icon?: JSX.Element;
  bgColor?: string;
  zindex?: string;
  closeWhenClickingBackground?: boolean;
  closeButtonVisible?: boolean;
  noVerticalMargin?: boolean;
}): JSX.Element {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        id={id}
        as="div"
        className={joinClassNames("relative", zindex)}
        onClose={closeWhenClickingBackground ? (onClose ? onClose : () => null) : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className={`fixed inset-2 z-10 overflow-y-auto ${noVerticalMargin ? "" : "max-h-[700px]"}`}>
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={joinClassNames(
                  bgColor,
                  "w-full transform rounded-2xl p-6 text-left align-middle shadow-xl transition-all",
                  width
                )}
              >
                {title && <Dialog.Title className="text-lg px-2 flex font-medium leading-6 text-gray-900 dark:text-gray-300" as="h3">
                  <div className="flex flex-row flex-nowrap py-2">
                    {icon && icon}
                    <h1 className="flex-grow my-auto">{title}</h1>
                  </div>
                </Dialog.Title>}

                {(closeButtonVisible ?? true) && <div title="Close">
                  <div className="absolute top-2 right-1">
                    <AiOutlineClose
                      id="modal-close"
                      onClick={() => onClose && onClose(false)}
                      className="text-right w-7 h-7 text-red-600 cursor-pointer"
                    />
                  </div>
                </div>}

                <div className="md:px-4 md:py-2">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
