import { NavLink } from "react-router-dom";
import { joinClassNames } from "../../../functions/utilities";
import { SidebarNavigationItem } from "./types";

type SecondaryMenuNavProps = {
  secondaryNavItems: SidebarNavigationItem[];
  children?: React.ReactNode;
  showMinified?: boolean;
  currentPath: string;
  onItemClick?: () => void;
};

export function SecondaryNavBar({
  currentPath,
  secondaryNavItems,
  onItemClick,
}: SecondaryMenuNavProps): JSX.Element {
  return (
    <div className="pt-6 border-t border-gray-800 dark:border-t-gray-600">
      <div className="px-2 space-y-1">
        {secondaryNavItems.map((item) => (
          <NavLink
            to={item.appId ? `${item.href}/${item.appId}` : item.href}
            key={item.name}
            className={joinClassNames(
              currentPath.startsWith(item.href)
                ? "dark:bg-gradient-to-r dark:from-dark-primary   dark:border-l-red-600 dark:border-l-2 bg-opacity-10 dark:text-white  text-black bg-gradient-to-r from-gray-200 border-l-red-600 border-l-2 "
                : "dark:text-gray-400 text-black hover:bg-red-500  hover:text-white transition duration-300",
              "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
            )}
            aria-current={
              currentPath.startsWith(item.href) ? "page" : undefined
            }
            onClick={() => {
              onItemClick && onItemClick();
            }}
          >
            <item.icon
              className={joinClassNames(
                currentPath.startsWith(item.href)
                  ? "dark:text-gray-400 text-black"
                  : "dark:text-gray-400 text-black group-hover:text-white",
                "h-6 w-6"
              )}
              aria-hidden="true"
            />
            <span className="mt-2 text-center">{item.name}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
