import { BreadCrumbs } from "../breadcrumbs/BreadCrumbs";

type PageHeaderProps = {
  pageTitle?: any;
  icon?: JSX.Element | any;
  showBreadCrumbs: boolean;
};

export function PageHeader({
  icon,
  pageTitle,
  showBreadCrumbs,
}: PageHeaderProps): JSX.Element {
  return (
    <div className="md:flex md:items-center md:justify-between ">
      <div className="grid grid-rows-3 grid-flow-col gap-2 ">
        <div className="row-span-2">{icon}</div>

        <div className="row-span-2 col-span-2 my-auto space-y-1">
          {showBreadCrumbs ? <BreadCrumbs /> : null}
          {pageTitle}
        </div>
      </div>
    </div>
  );
};
