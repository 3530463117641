import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { useEffect, useState } from "react";
import { useHistoryState } from "../hooks";
import { useUser } from "./useUser";
import { GET_BB_WORKSPACES_QUERY } from "../../graphql/queries/GetOrgsOrWorkspacesOrGroups";
import { uniq } from "lodash";
import { WorkspaceState } from "../../views/create/categories/repository/SelectWorkspaceOrOrganization";
import { BranchValue } from "./useBranchSearchQuery";

export const useWorkspaceSearchQuery = (scm: string, pageNumber: number) => {
  const [appScm] = useHistoryState("appScm");
  const [workspaceData, setWorkspaceData] = useState<WorkspaceState[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const user = useUser();

  const [getWorkspace, { loading, error, refetch }] = useLazyQuery(
    GET_BB_WORKSPACES_QUERY,
    {
      variables: {
        scm: appScm || scm,
        page: pageNumber,
        orgId: user?.selectedOrg,
      },

      onCompleted: ({ orgsOrWorkspacesOrGroups }) => {
        let prevBranches = [...workspaceData];
        let currentWorkspaces = orgsOrWorkspacesOrGroups?.values?.map(
          (b: BranchValue) => {
            return { id: b.slug, name: b.name };
          }
        );
        let concatData = prevBranches.concat(currentWorkspaces);
        let results = concatData.filter((e) => {
          return e !== undefined;
        });

        setHasMore(
          orgsOrWorkspacesOrGroups?.values?.length >
          orgsOrWorkspacesOrGroups?.pagelen ||
          orgsOrWorkspacesOrGroups?.values?.length !== 0
        );

        setWorkspaceData(uniq(results));
      },
    }
  );

  useEffect(() => {
    getWorkspace();
  }, [getWorkspace, pageNumber]);

  return { loading, error, workspaceData, hasMore, refetch };
};
