import { DropDownSelect } from "../forms/DropDownSelect";
import { FormErrorMessage } from "./FormErrorMessage";

export function JiraProjects({
  setSelected,
  selected,
  projects,
}: {
  setSelected: any;
  selected: any;
  projects: any;
}): JSX.Element {
  return (
    <div className="mt-1 w-96">
      <p className="dark:text-gray-400">Project*</p>
      <DropDownSelect
        selected={selected}
        setSelected={setSelected}
        data={projects?.map((project: any) => project)}
      />
      <FormErrorMessage isShowing={selected.id === ""} />
    </div>
  );
};
