import { ElementType } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { joinClassNames } from "../../functions/utilities";

type ButtonProps<TTag extends ElementType> = {
  /**
   * Is the button disabled?
   */
  disabled?: boolean;

  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  /**
   * Is the button in a loading state?
   */
  isLoading?: boolean;

  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?:
  | "text-xs"
  | "text-sm"
  | "text-md"
  | "text-lg"
  | "text-xl"
  | "text-2xl"
  | "text-3xl"
  | "text-4xl";
  /**
   * Button contents
   */
  label?: string;
  loadingLabel?: string;
  /**
   * Optional icon
   */
  icon?: JSX.Element;
  /**
   * Optional hover effect
   */
  hoverEffect?: any;
  /**
   * OVERIDES other props
   */
  className?: string;
  /**
   * OVERIDES other props
   */

  as?: TTag;
} & React.ComponentPropsWithoutRef<TTag>;

/**
 * Primary UI component for user interaction
 */

const DEFAULT_ELEMENT_TYPE = "button" as const;

export function Button<TTag extends ElementType = typeof DEFAULT_ELEMENT_TYPE>({
  // disabled = false,
  outlined,
  primary,
  secondary,
  isLoading,
  size = "text-md",
  // backgroundColor,
  loadingLabel = "Processing",
  label,
  icon,
  className,
  onClick,
  hoverEffect = "hover:scale-110 transition",
  as,
  ...props
}: ButtonProps<TTag>): JSX.Element {
  const Component = as || DEFAULT_ELEMENT_TYPE;

  const defaultStyles = joinClassNames(
    "py-1.5 px-2.5",
    "border border-transparent",
    "inline-flex items-center ",
    "rounded-md shadow-sm",
    "text-sm font-medium "
  );

  const outlinedStyles = joinClassNames(
    "border-solid border",
    "dark:text-red-800 text-red-800",
    "border border-red-900",
    "hover:bg-red-50 dark:hover:bg-dark-primary"
  );

  const primaryStyles = joinClassNames(
    "text-white  dark:text-gray-800",
    "bg-[#af3734]",
    "hover:bg-red-700 dark:hover:bg-red-500",
    "focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
  );

  const secondaryStyles = joinClassNames(
    "text-red-700 dark:text:gray-800",
    "bg-red-100",
    "hover:bg-red-200",
    "focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
  );

  const disabledStyles = joinClassNames(
    "bg-gray-500  font-medium hover:bg-gray-400",
    "shadow-md shadow-gray-400 dark:shadow-none",
    "hover:cursor-not-allowed",
    "text-sm text-gray-300",
    "border-gray-700 rounded-md",
    "focus:outline-none",
    defaultStyles,
    className
  );

  const classNames = joinClassNames(
    defaultStyles,
    props.disabled && disabledStyles,
    secondary && secondaryStyles,
    primary && primaryStyles,
    outlined && outlinedStyles,
    size,
    hoverEffect,
    className
  );

  if (isLoading) {
    return (
      <Component
        id={props.id}
        disabled={true}
        className={joinClassNames("cursor-not-allowed ", classNames)}
        {...props}
      >
        <div className="mx-auto">
          <AiOutlineLoading
            className={
              "animate-spin inline-flex transition w-6 h-6 mr-3 text-gray-800"
            }
          />
          {loadingLabel}...
        </div>
      </Component>
    );
  }

  return (
    <Component
      onClick={onClick}
      className={props.disabled ? disabledStyles : classNames}
      {...props}
    >
      <div className="inline-flex mx-auto items-center">
        {icon}
        {label}
      </div>
    </Component>
  );
};
