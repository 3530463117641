import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { Suspense, lazy, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { supabaseClient } from "../../../auth/supabase";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { InputField } from "../../../components/forms/InputField";
import { Loader } from "../../../components/loading/Loader";
import { CREATE_AUTH0_ORG } from "../../../graphql/mutations/CreateAuth0Org";
import {
  createHubspotUser,
  sendProvider,
} from "../../../graphql/utils/sendProvider";
import { svgImport } from "../../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() =>
  import("../../../assets/small-logo.svg").then(svgImport),
);

function Header(): JSX.Element {
  return (
    <div className="pb-2">
      <Suspense fallback={<></>}>
        <SecureStackLogo className="h-16 w-auto" />
      </Suspense>
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-gray-300">
        Setup your account
      </h2>
    </div>
  );
}

interface FormData {
  org: string;
}

export function RegisterOrg(): JSX.Element {
  const history = useHistory();
  const { register, handleSubmit } = useForm<FormData>();
  const [loadingRedirect, setLoading] = useState(false);
  const [createOrg, { loading, error }] = useMutation(CREATE_AUTH0_ORG);

  const searchParams = new URLSearchParams(history.location.search);

  const handleRedirect = async (searchParams: URLSearchParams) => {
    setLoading(true);
    const { data } = await supabaseClient.auth.refreshSession();
    if (data && data?.session) {
      createHubspotUser(data?.session);
    }
    /* if (searchParams.has("redirect_to")) {
      const redirectTo = searchParams.get("redirect_to");
      history.push(redirectTo);
      return;
    } */

    history.push("/");
  };

  const handleCreateOrg: SubmitHandler<FormData> = async (data) => {
    if (data.org.trim() === "") {
      return;
    }

    await createOrg({
      variables: { name: data.org },
      onCompleted: async () => {
        const {
          data: { session },
        } = await supabaseClient.auth.getSession();
        if (session?.provider_token) {
          sendProvider(session);
        }
        handleRedirect(searchParams);
      },
    });
  };

  return (
    <>
      <Header />
      <div className="mt-2">
        <ErrorAlert title={error?.message} isShowing={Boolean(error)} />
      </div>
      {!loadingRedirect ? (
        <form className="space-y-4 mt-4 my-auto">
          <h2 className="text-gray-500 pb-1">Organization Name</h2>
          <InputField
            autoFocus={true}
            placeholder="Acme Corp Inc."
            type="org"
            required
            {...register("org")}
            name="org"
            disabled={loading}
          />

          <div className="flex flex-row gap-2">
            <Button
              isLoading={loading}
              primary
              className="w-full"
              label="Create Organization"
              type="submit"
              onClick={handleSubmit(handleCreateOrg)}
            />
          </div>
        </form>
      ) : (
        <Loader w={10} h={10} />
      )}
    </>
  );
}
