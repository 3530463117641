import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useState } from "react";
import { Application } from "../../__generated__/graphql";
import { APPLICATION_STATUS } from "../../graphql/queries/ApplicationStatus";
import { usePathAppId } from "../hooks";
import { useUser } from "./useUser";
import logGraphQLError from "../logGraphQLError";

export enum STATUS_TYPES_RECON {
  IN_PROGRESS = "in-progress",
  COMPLETED = "deepscan-complete",
  FAILED = "FAILED",
}

export enum ASSET_STATUS_TYPES {
  SIMPLE_COMPLETE = "simple-complete",
  STARTED = "asset-started",
  PASSIVE_COMPLETE = "passive-complete",
  IN_PROGRESS = "in-progress",
  COMPLETED = "asset-complete",
  ERROR = "asset-error",
}

export enum BETA_SCA {
  BETA_SCA_STARTED = "0",
  BETA_SCA_IN_PROGRESS = "in-progress",
  BETA_SCA_ERROR = "Code-osv analysis failed",
  BETA_SCA_COMPLETE = "code-osv analysis completed",
}

export enum STATUS_TYPES_CONTAINER {
  IN_PROGRESS = "in-progress",
  COMPLETED = "container-complete",
  FAILED = "container-error",
}

export enum STATUS_TYPES_SCA {
  IN_PROGRESS = "in-progress",
  COMPLETED = "sca-complete",
  FAILED = "FAILED",
}

export enum STATUS_TYPES_CLOUD {
  IN_PROGRESS = "in-progress",
  COMPLETED = "cloud-complete",
  FAILED = "FAILED",
}

export enum STATUS_TYPES_SBOM {
  IN_PROGRESS = "in-progress", //"Started",
  OTHER = "0",
  COMPLETED = "sbom-complete", //"Completed",
  ERROR = "sbom-error",
}

export function useApplicationStatus(
  applicationId?: string,
  dashboardRefetch?: Function,
) {
  const user = useUser();
  const appId = usePathAppId();
  const [appStatusData, setAppStatusData] = useState<Application>();
  const [isScanning, setIsScanning] = useState<{
    recon: boolean;
    asset: boolean;
    sca: boolean;
    bsca: boolean;
    sbom: boolean;
    cloud: boolean;
    container: boolean;
  }>({
    recon: false,
    asset: false,
    bsca: false,
    cloud: false,
    sbom: false,
    sca: false,
    container: false,
  });

  const { stopPolling, startPolling, refetch, error, loading } = useQuery(
    APPLICATION_STATUS,
    {
      variables: {
        applicationId: applicationId ?? appId,
        orgId: user?.selectedOrg,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
      onError: logGraphQLError,

      onCompleted: (data) => {
        let d = data.dashboard?.application;

        if (d == null) {
          stopPolling();
          return;
        }

        startPolling(6000);
        setAppStatusData(d);

        const reconInProgress: boolean =
          d.reconStatus === STATUS_TYPES_RECON.IN_PROGRESS;
        //const reconFailed: boolean = d.reconStatus === STATUS_TYPES_RECON.FAILED;
        //const reconCompleted: boolean = d.reconStatus === STATUS_TYPES_RECON.COMPLETED;

        const sbomInProgress: boolean =
          d.sbomStatus === STATUS_TYPES_SBOM.IN_PROGRESS;
        //const sbomFailed: boolean = d.sbomStatus === STATUS_TYPES_SBOM.ERROR;
        //const sbomCompleted: boolean = d.sbomStatus === STATUS_TYPES_SBOM.COMPLETED;

        const betaScaInProgress: boolean =
          d.status ===
          BETA_SCA.BETA_SCA_IN_PROGRESS; /* || d.status === BETA_SCA.BETA_SCA_STARTED */
        //const betaScaFailed: boolean = d.status === BETA_SCA.BETA_SCA_ERROR;
        //const betaScaCompleted: boolean = d.status === BETA_SCA.BETA_SCA_COMPLETE;

        const scaInProgress: boolean =
          d.scaStatus === STATUS_TYPES_SCA.IN_PROGRESS;
        //const scaFailed: boolean = d.scaStatus === STATUS_TYPES_SCA.FAILED;
        //const scaCompleted: boolean = d.scaStatus === STATUS_TYPES_SCA.COMPLETED;

        const cloudInProgress: boolean =
          d.cloudStatus === STATUS_TYPES_CLOUD.IN_PROGRESS;

        const containerInProgress: boolean =
          d.containerStatus === STATUS_TYPES_CONTAINER.IN_PROGRESS;

        const assetInProgress: boolean =
          d.assetStatus === ASSET_STATUS_TYPES.IN_PROGRESS ||
          d.assetStatus === ASSET_STATUS_TYPES.STARTED ||
          d.assetStatus === ASSET_STATUS_TYPES.PASSIVE_COMPLETE ||
          d.assetStatus === ASSET_STATUS_TYPES.SIMPLE_COMPLETE;
        //const assetFailed: boolean = d.assetStatus === ASSET_STATUS_TYPES.ERROR;
        //const assetCompleted: boolean = d.assetStatus === ASSET_STATUS_TYPES.COMPLETED;

        setIsScanning({
          recon: reconInProgress,
          asset: assetInProgress,
          bsca: betaScaInProgress,
          cloud: cloudInProgress,
          sbom: sbomInProgress,
          sca: scaInProgress,
          container: containerInProgress,
        });

        if (
          !betaScaInProgress &&
          !scaInProgress &&
          !reconInProgress &&
          !cloudInProgress &&
          !containerInProgress &&
          !sbomInProgress
        ) {
          if (
            dashboardRefetch != null &&
            typeof dashboardRefetch === "function"
          ) {
            dashboardRefetch();
          }
          stopPolling();
        }
      },
    },
  );

  return {
    isScanningSCA: isScanning.sca,
    isScanningBetaSca: isScanning.bsca,
    isScanningRecon: isScanning.recon,
    isScanningAsset: isScanning.asset,
    isScanningCloud: isScanning.cloud,
    isScanningSbom: isScanning.sbom,
    isContainerScanning: isScanning.container,
    startPolling,
    stopPolling,
    refetch,
    appStatusData,
    loading,
    error,
  };
}
