import { User } from "@auth0/auth0-react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { SideBarMenuNav } from "./MenuNav";
import { SidebarNavigationItem } from "./types";
import securestacklogo from "../../../assets/securestack-small.svg";

type MobileSidebarProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  navItems: SidebarNavigationItem[];
  secondaryNavItems: SidebarNavigationItem[];
  user?: User;
  currentPath: string;
};

export function MobileNavBar({
  isOpen,
  setOpen,
  navItems,
  currentPath,
  secondaryNavItems,
}: MobileSidebarProps): JSX.Element {
  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-50 md:hidden w-full"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs pt-5 pb-4 bg-white dark:bg-dark-main">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center justify-center px-4">
                <img
                  className="-mt-3 h-8 w-auto"
                  src={securestacklogo}
                  alt="Workflow"
                />{" "}
                <span className="dark:text-white">SecureStack</span>
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto sm:pb-10">
                <nav className="px-2 space-y-1">
                  <SideBarMenuNav
                    secondaryNavItems={secondaryNavItems}
                    navItems={navItems}
                    currentPath={currentPath}
                    onItemClick={() => setOpen(false)}
                  />
                  <div className="flex">
                    <hr className="my-5 w-8 m-auto" />
                  </div>
                  <SideBarMenuNav
                    secondaryNavItems={secondaryNavItems}
                    navItems={secondaryNavItems}
                    currentPath={currentPath}
                    onItemClick={() => setOpen(false)}
                  />
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
