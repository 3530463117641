import { Button } from "../buttons/Button";
import { Loader } from "../loading/Loader";

export default function EmptyStateSimple({
  title,
  subtitle,
  buttonText,
  isLoading,
  icon,
  type,
  as,
  to,
  onClick,
}: {
  title: string;
  type?: "button" | "submit" | "reset" | undefined;
  subtitle: string;
  buttonText?: string;
  as?: any;
  to?: string;
  icon?: JSX.Element;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler;
}) {
  return (
    <div className="text-center bg-white  dark:bg-dark-main rounded-2xl   px-4 sm:px-6 py-6">
      {icon}
      <h3
        title={title}
        id={title}
        className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {title}
      </h3>
      <p className="mt-1 text-sm text-gray-700 dark:text-gray-500">
        {subtitle}
      </p>
      <div className="mt-6">
        {(onClick || to) && (
          <Button
            primary
            as={as}
            to={to}
            isLoading={isLoading}
            label={buttonText}
            type={type}
            onClick={onClick}
          />
        )}
        {isLoading && <Loader w={6} h={6} />}
      </div>
    </div>
  );
}
