import { ChangeEvent } from "react";
import { InputField } from "../../../../components/forms/InputField";

export function ApplicationName({
  name,
  onChange,
}: {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  return (
    <div className="space-y-2">
      <InputField
        name={name}
        maxLength={256}
        defaultValue={name}
        type="text"
        onChange={onChange}
        placeholder="NiftyBank"
        required={true}
      />
    </div>
  );
};
