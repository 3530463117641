import { Dispatch, SetStateAction } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

// import { WizardStep1 } from "./wizard/Step1";
import { WizardStep2 } from "./wizard/Step2";
import { WizardStep3 } from "./wizard/Step3";
import { WizardStep4 } from "./wizard/Step4";

type CloudAccountWizardProps = {
  setCurrentStep: Dispatch<SetStateAction<string>>;
};

export function CloudAccountWizard({
  setCurrentStep,
}: CloudAccountWizardProps): JSX.Element {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${url}/1`} />
      </Route>
      <Route exact path={`${path}/:step`}>
        <Switch>
          {/* <Route exact path={`${path}/1`}>
            <WizardStep1 path={url} onActivated={() => setCurrentStep("1")} />
          </Route> */}
          <Route exact path={`${path}/1`}>
            <WizardStep2 path={url} onActivated={() => setCurrentStep("1")} />
          </Route>
          <Route exact path={`${path}/2`}>
            <WizardStep3 path={url} onActivated={() => setCurrentStep("2")} />
          </Route>
          <Route exact path={`${path}/3`}>
            <WizardStep4 path={url} onActivated={() => setCurrentStep("3")} />
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
};
