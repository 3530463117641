import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Button } from "../../../../components/buttons/Button";
import { Modal } from "../../../../components/dialogs/Modal";
import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { Loader } from "../../../../components/loading/Loader";
import { GiArchiveResearch } from "react-icons/gi";
import { useUser } from "../../../../functions/hooks/useUser";
import { LIST_TAGS } from "../../../../graphql/queries/ListTags";
import { uniq } from "lodash";
import logGraphQLError from "../../../../functions/logGraphQLError";

export function ApplicationTagsField({
  editTags,
  pushData,
}: {
  editTags?: string[];
  pushData: (data: any) => void;
}): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tags, setTags] = useState<any>(editTags ?? []);
  const [inputTags, setInputTags] = useState<string>();
  const [isViewingRbacInfo, setIsViewingRbacInfo] = useState(false);

  const handlePushedData = (data: any) => {
    const uniqueTags = uniq([...tags, ...data]);
    setTags(uniqueTags);
    setIsModalOpen(false);
    pushData(uniqueTags);
  };

  return (
    <div>
      <div className={`text-sm font-medium text-gray-400 truncate ${editTags && editTags?.length > 0 ? "pb-3" : ""}`}>
        <div className="flex flex-wrap gap-y-2">
          {tags?.map((item: any, idx: number) => (
            <span
              key={`tags-${idx}`}
              className="mr-1 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium dark:bg-dark-primary bg-gray-300 dark:text-green-400 text-black"
            >
              <MdCancel
                onClick={() => {
                  pushData((tags)?.filter((i: any) => i !== item && i !== ""));
                  setTags(tags?.filter((i: any) => i !== item && i !== ""));
                }}
                className="h-3 w-3 mr-1 text-gray-600" />
              <p className="dark:text-gray-300 text-black"> {item}</p>
            </span>
          ))}
        </div>
      </div>
      <div className="flex rounded-md shadow-sm space-x-3">
        <div className="relative flex items-stretch flex-grow focus-within:z-10 ">
          <input
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const tagsName = inputTags?.split(",");
                if (tagsName && tagsName?.length > 0) {
                  setTags(uniq([...tags, ...tagsName]));
                  setInputTags("");
                  pushData(uniq([...tags, ...tagsName]));
                }
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
              setInputTags(e.target.value);
            }}
            value={inputTags}
            type="tags"
            name="tags"
            id="tags"
            className="appearance-none relative block w-full px-3 py-2 border-gray-300 border placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-red-500 focus:red-indigo-500 focus:z-10 sm:text-sm dark:text-gray-300 rounded-lg transition text-base outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-primary outline-offset-2"
            placeholder="Add App tags here. Press enter to add another tag"
          />
        </div>

        <GiArchiveResearch
          title="find tags"
          onClick={() => setIsModalOpen(true)}
          className="h-6 w-6 text-gray-400 cursor-pointer items-center mt-1 hover:scale-110 transition"
          aria-hidden="true"
        />
      </div>

      <Modal
        noVerticalMargin
        isOpen={isViewingRbacInfo}
        onClose={() => setIsViewingRbacInfo(false)}
      >
        <div className="text-gray-800 dark:text-gray-300">
          Tags can also be used to group applications and give users special
          permissions. For example you may have multiple dev teams that should
          only have access to certain groups of apps. The Insurance dev team
          might have access to only your "insurance" tagged apps
        </div>
      </Modal>

      <Modal
        onClose={() => setIsModalOpen(false)}
        title=" Select from Tag-List"
        isOpen={isModalOpen}
      >
        <SelectTagsListModal pushData={handlePushedData} />
      </Modal>
    </div>
  );
};

export function SelectTagsListModal({ pushData }: { pushData: any }): JSX.Element {
  const [tagSelected, setTagSelected] = useState<string[]>([]);


  const user = useUser();

  const { data, error, loading } = useQuery(LIST_TAGS, {
    variables: {
      orgId: user?.selectedOrg,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onError: logGraphQLError,
  });

  if (loading && data) {
    return <Loader w={6} h={6} label="Loading all application changes" />;
  }

  if (error && data) {
    return <div>Error: {error.message}</div>;
  }

  const handleChange = (appName: string) => {
    setTagSelected([...tagSelected, appName]);
  };

  const handleSubmit = () => {
    const uniqueApplications = uniq(tagSelected);
    setTagSelected([]);
    pushData(uniqueApplications);
  };
  return (
    <div className="mt-10 first-letter:mx-auto">
      <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0 h-52 overflow-y-scroll">
        <fieldset className="space-y-5">
          {data?.listTags?.map((item: any, personIdx: any) => (
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  onChange={() => handleChange(item?.name)}
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 border-gray-300 rounded "
                />
              </div>

              <div className="ml-3 text-sm">
                <label className="font-medium dark:text-gray-400">
                  {item?.name}
                </label>
              </div>
            </div>
          ))}
        </fieldset>
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0 space-x-3 ">
        <Button
          className="mt-5"
          type="button"
          outlined
          label="Add"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
