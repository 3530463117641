import { gql } from "@apollo/client/core";

export const FIND_PACKAGE = gql(`
query FindPackage($packageName: String!, $orgId: String) {
  findPackage(packageName: $packageName, orgId: $orgId) {
    packageDetails {
      appId
      sbomId
      name
      purl
      type
      created
      bomRef
      version
      licenses {
        expression
      }
    }
    applications {
      name
      applicationId
    }
    CVEDetails {
      cve_name
      cvss2
      cvss3
      criticality
      description
      extra_details {
        cve {
          id
          sourceIdentifier
          published
          lastModified
          vulnStatus
          references {
            url
            source
            tags
          }
          configurations {
            nodes {
              operator
              negate
              cpeMatch {
                vulnerable
                versionEndExcluding
                matchCriteriaId
                criteria
              }
            }
          }
          weaknesses {
            source
            type
            description {
              lang
              value
            }
          }
          metrics {
            cvssMetricV31 {
              source
              type
              exploitabilityScore
              impactScore
              cvssData {
                version
                vectorString
                attackVector
                attackComplexity
                privilegesRequired
                userInteraction
                scope
                confidentialityImpact
                integrityImpact
                availabilityImpact
                baseScore
                baseSeverity
              }
            }
          }
          descriptions {
            lang
            value
          }
        }
      }
    }
    CWEDetails {
      cve_name
      cvss2
      cvss3
      criticality
      description
      extra_details {
        cve {
          id
          sourceIdentifier
          published
          lastModified
          vulnStatus
          references {
            url
            source
            tags
          }
          configurations {
            nodes {
              operator
              negate
              cpeMatch {
                vulnerable
                versionEndExcluding
                matchCriteriaId
                criteria
              }
            }
          }
          weaknesses {
            source
            type
            description {
              lang
              value
            }
          }
          metrics {
            cvssMetricV31 {
              source
              type
              exploitabilityScore
              impactScore
              cvssData {
                version
                vectorString
                attackVector
                attackComplexity
                privilegesRequired
                userInteraction
                scope
                confidentialityImpact
                integrityImpact
                availabilityImpact
                baseScore
                baseSeverity
              }
            }
          }
          descriptions {
            lang
            value
          }
        }
      }
    }
    GHAdvisoryDetails {
      ghsa_id
      cve_id
      url
      html_url
      summary
      description
      type
      severity
      repository_advisory_url
      source_code_location
      published_at
      updated_at
      github_reviewed_at
      nvd_published_at
      withdrawn_at
      credits {
        type
        user {
          login
          id
          node_id
          avatar_url
          gravatar_id
          url
          html_url
          followers_url
          following_url
          gists_url
          starred_url
          subscriptions_url
          organizations_url
          repos_url
          events_url
          received_events_url
          type
          site_admin
        }
      }
      cwes {
        cwe_id
        name
      }
      cvss {
        vector_string
        score
      }
      vulnerabilities {
        vulnerable_version_range
        first_patched_version
        vulnerable_functions
        package {
          ecosystem
          name
        }
      }
      references
      Identifiers {
        value
        type
      }
    }
  }
}
`);
