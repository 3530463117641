import { Chart, registerables } from "chart.js";
import { Suspense, lazy, useState, StrictMode } from "react";
import { Route, Switch } from "react-router-dom";
import { ApplicationStatusWrapper } from "./ApplicationStatusWrapper";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import GenericSuspenseFallback from "./components/GenericSuspenseFallback";
import { AmplitudeRegisterAuth0User } from "./components/data/Amplitude";
import {
  ParsedCloudIssueRow,
  ParsedCodeIssueRow,
  ParsedExposureIssueRow,
  ParsedSecretsIssueRow,
  ParsedTrivyIssueRow,
} from "./functions/hooks/useApplicationIssues";
import { isFeatureEnabled } from "./functions/utilities";
import { Auth } from "./views/AuthRouter";
import AuthorizationDeniedRedirectPage from "./views/AuthorizationDeniedRedirectPage";

const OnboardingStepper = lazy(
  () => import("./views/onboarding/OnboardingStepper"),
);
const EmailVerificationRedirectPage = lazy(
  () => import("./views/EmailVerificationRedirectPage"),
);
const BrowserNotifications = lazy(
  () => import("./components/data/BrowserNotifications"),
);

Chart.register(...registerables);

export type SelectedIssue =
  | ParsedExposureIssueRow
  | ParsedCodeIssueRow
  | ParsedCloudIssueRow
  | ParsedSecretsIssueRow
  | ParsedTrivyIssueRow
  | null;

export function App(): JSX.Element {
  const [selectedIssue, setSelectedIssue] = useState<SelectedIssue>(null);

  return (
    <StrictMode>
      <Suspense fallback={<GenericSuspenseFallback />}>
        <BrowserNotifications />
      </Suspense>
      <AmplitudeRegisterAuth0User />
      <Switch>
        <Route exact path="/unauthorised">
          <Suspense fallback={<GenericSuspenseFallback />}>
            <EmailVerificationRedirectPage />
          </Suspense>
        </Route>
        <Route
          exact
          path="/userdeniedaccess"
          component={AuthorizationDeniedRedirectPage}
        />
        <Route path="/auth" component={Auth} />
        {isFeatureEnabled("onboarding-stepper") && (
          <ProtectedRoute path="/onboarding">
            <Suspense fallback={<GenericSuspenseFallback />}>
              <OnboardingStepper />
            </Suspense>
          </ProtectedRoute>
        )}
        <ApplicationStatusWrapper
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
        />
      </Switch>
    </StrictMode>
  );
}
