import {
  Route,
  RouteProps,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { useUser } from "../functions/hooks/useUser";

export function Redirecting(): JSX.Element {
  return <CircularProgress
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      margin: "-25px 0 0 -25px",
    }}
  />;
};

// Taken from supabase
export function getHashParameterByName(name: string, url?: string) {
  if (!url) url = window?.location?.href || "";
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function ProtectedRoute({ children, ...args }: RouteProps): JSX.Element {
  const user = useUser();
  const changePasswordMatches = useRouteMatch("/auth/change-password");
  const loginMatches = useRouteMatch("/auth/login");
  //const authMatches = useRouteMatch("/auth");
  const history = useHistory();

  const status = user?.status;
  // Redirect when SIGNED_OUT
  const shouldRedirectToLogin = loginMatches == null && !user && (!status || status === "SIGNED_OUT");
  // REDIRECT to unauthorized when status is USER_DELETED
  const shouldRedirectToUnauthorized = status === "USER_DELETED";
  // REDIRECT when user is changing their password
  const shouldRedirectToChangePassword = status === "PASSWORD_RECOVERY";
  // User has not verified their email
  const shouldRedirectToVerifyEmail = status === "EMAIL_VERIFICATION";
  // User has no org, redirect
  const shouldRedirectToOrgSelector = status === "SIGNED_IN" &&
    ([undefined, null, ""].includes(user?.selectedOrg) || // User hasn't selected an org
      Object.keys(user?.roles ?? Object.create(null)).length < 1); // User isn't apart of any orgs

  const loadingAuth = [
    "access_token",
    "provider_token",
    "expires_in",
    "token_type",
    "refresh_token",
    "error_description",
  ]
    .map((check) => Boolean(getHashParameterByName(check)))
    .some((bool) => bool);

  // Show redirecting when waiting for user
  if (loadingAuth) {
    return <Redirecting />;
  }

  // Redirect if it's necessary first before we bother loading anything else
  /* const searchParams = new URLSearchParams(history.location.search);
  if (!authMatches && searchParams.has("redirect_to")) {
    console.log('searchParams.has("redirect_to")');
    const redirectTo = searchParams.get("redirect_to");
    if (redirectTo) {
      return <Redirect to={decodeURI(redirectTo)} />;
    }
  } */

  if (shouldRedirectToVerifyEmail) {
    return <Redirect to="/unauthorised" />;
  }

  if (shouldRedirectToChangePassword && !changePasswordMatches?.isExact) {
    return <Redirect to="/auth/change-password" />;
  }

  if (shouldRedirectToUnauthorized) {
    return <Redirect to="/userdeniedaccess" />;
  }

  if (
    shouldRedirectToOrgSelector &&
    !history.location.pathname.includes("/auth/orgs")
  ) {
    return <Redirect to="/auth/orgs" />;
  }

  if (shouldRedirectToLogin) {
    return <Redirect to="/auth/login" />;
  }

  return <Route {...args}>{children}</Route>;
};
