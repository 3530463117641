import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { useEffect, useState } from "react";
import { useHistoryState } from "../hooks";
import { useUser } from "./useUser";
import { GET_GH_ORGS_QUERY } from "../../graphql/queries/GetOrgsOrWorkspacesOrGroups";
import { uniq } from "lodash";

export type OrganizationElement = {
  __typename: string;
  login: string;
  url: string;
}

export const useOrganizationSearchQuery = (scm: string, pageNumber: number) => {
  const [appScm] = useHistoryState("appScm");
  const user = useUser();

  const [oganizationData, setOganizationData] = useState<string[]>([]);
  const [hasMoreOrgs, setHasMore] = useState(false);

  const [getOrgs, { loading: loadingOrgs, error: orgError, refetch: orgRefetch }] = useLazyQuery(
    GET_GH_ORGS_QUERY,
    {
      variables: {
        scm: appScm || scm,
        page: pageNumber,
        orgId: user?.selectedOrg,
      },

      onCompleted: ({ orgsOrWorkspacesOrGroups }) => {
        let prevBranches = [...oganizationData];
        let currentOrgs = orgsOrWorkspacesOrGroups?.organization?.map(
          (o: OrganizationElement) => o.login
        );
        let concatData = prevBranches.concat(currentOrgs);

        let results = concatData.filter((e) => {
          return e !== undefined;
        });

        // NOTE: github doesn't return the user's own "org" as a part of the list
        results.push(orgsOrWorkspacesOrGroups.user);

        let uniqueSet = uniq(results);

        setOganizationData(uniqueSet);
        setHasMore(false);
      },
    }
  );

  useEffect(() => {
    getOrgs();
  }, [getOrgs, pageNumber]);

  return { loadingOrgs, orgError, oganizationData, hasMoreOrgs, orgRefetch };
};
