import axios from "axios";

const sendProvider = (session: any) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session?.access_token,
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}/supabase/auth`,
      {
        user: session?.user,
        token: session?.provider_token,
        refresh_token: session?.provider_refresh_token,
      },
      options
    )
    .catch(function (error) {
    });
};

const createHubspotUser = (session: any) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session?.access_token,
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}/supabase/create/hubspot/user`,
      {
        user: session?.user,
        token: session?.provider_token,
        refresh_token: session?.provider_refresh_token,
      },
      options
    )
    .catch(function (error) {
    });
};

export { sendProvider, createHubspotUser };
