enum ExposureProblems {
  HSTS = "hsts is not enabled",
  CSP = "csp is not enabled",
  REFERRERPOLICY = "missing referrer policy",
  SECURITYTXT = "no security.txt file",
  SECURITYHEADERS = "missing security headers",
  NOLOADBALANCING = "no load balancing",
  NOWAF = "no waf detected",
  SERVICESLEAKING = "services leaking data",
  NOHTTPSREDIRECT = "no redirect to https",
  INSECURECONTENTS = "target has insecure content",
  URLNOTSECURE = "url not secure",
  SSHOPEN = "ssh open",
  DEPTLS = "deprecated tls identified",
  TLS12 = "tls 1.2 is supported",
  NOTHARDENED = "server not hardened",
  PHPOOD = "php out of date",
  CLIENTERROR403 = "http client error 403",
  CLIENTERROR404 = "http client error 404",
  CLIENTERROR500 = "http server error 500",
  RDPOPEN = "rdp open",
  CDNORIGINEXPOSED = "cdn origin exposed",
  EXCESSIVEREDIRECTS = "excessive redirects for url",
  EXCESSIVETARGETREDIRECTS = "excessive redirects for target url",
  NGINXOOD = "nginx out of date",
  POTSOVISSUE = "potential sovereignty issue",
  MYSQLOPEN = "mysql database open",
  SSLISSUE = "ssl certificate issue",
  DNSWILDCARD = "dns wildcard exists",
  DNSWILDCARDRECORD = "dns wildcard record exists",
  SERVERERRORRESPONSE = "webserver error response",
  WPHARDEN = "wordpress not hardened",
  INSECUREVER = "insecure version of site",
  DBOPEN = "database server open to public",
  NEEDSHARDENEDWINDOWSAWS = "deploy a hardened windows server ami in aws",
  NEEDSHARDENEDLINUXAWS = "deploy a hardened linux ami in aws",
  WINDOWSNOTHARDENED = "windows not hardened",
  NOWAFON = "no waf enabled",
  ASPVOOD = "asp.net very outdated",
  ASPOOD = "asp.net is outdated",
  APACHEOOD = "apache is outdated",
  OPENSSLOOD = "openssl is outdated",
  IISOOD = "iis is outdated",
  NGINXOOD2 = "nginx is outdated",
  SOVISSUE = "probable sovereignty issue",
  BUBBLEAPI = "bubble api leaking data",
  BUBBLENOTHARDENED = "bubble not hardened",
  JOOMLANOTHARDENED = "joomla not hardened",
  DRUPALNOTHARDENED = "drupal not hardened",
  SQUIZNOTHARDENED = "squiz not hardened",
  MAGENTONOTHARDENED = "magento not hardened",
  UNENCRYPTEDURLCONTENT = "unencrypted content in url",
  SECURITYIMPROVED = "security can be improved",
  SCALABILITYIMPROVED = "scalability can be improved",
}

type IssueObject = {
  details?: string;
  solution?: string;
};

const issueMap = new Map<string, IssueObject>([
  [
    ExposureProblems.HSTS,
    {
      details: "The web application you've scanned does not use HTTP Strict Transport Security (HSTS).\n\nHSTS helps protect websites against man-in-the-middle attacks (such as protocol downgrades and cookie hijacking).\n\nIt is a web security policy mechanism allows web servers to inform user agents (i.e web browsers) that they should only use secure HTTPS connections.\n\nHTTPS connections provide TLS/SSL (Transport Layer Security / Secure Socket Layer), unlike HTTP which is insecure.\n\nThis may affect compliance.",
      solution: "add_header Strict-Transport-Security: max-age=31536000",
    },
  ],
  [
    ExposureProblems.CSP,
    {
      details: "The web application you've scanned did not include any CSP (Content Security Policy).\n\nCSP is an added layer of security that helps to detect and mitigate certain types of attacks, including Cross Site Scripting (XSS) and data injection attacks.\n\nThese attacks are versatile and used by cyber criminals for everything from data theft to site defacement to distribution of malware and more.\n\nThis may affect compliance.",
      solution: "add_header Content-Security-Policy default-src self",
    },
  ],
  [
    ExposureProblems.REFERRERPOLICY,
    {
      details: "The web application you've scanned did not implement a Referrer Policy.\n\nWhen a web link is clicked, the user agent (i.e web browser) forwards certain information to the server to handle the request.\n\nA Referrer Policy tells the user agent which information it can send to send to which websites.\n\nA strong referrer policy protects against user or critical information being sent to a malicious site.",
      solution: "add_header 'Referrer-Policy' 'origin'\n\nOR\n\n<meta name=\"referrer\" content=\"origin\" />\n\nOR\n\n<a href=\"https://....com\" referrerpolicy=\"origin\">...</a>",
    },
  ],
  [ExposureProblems.SECURITYTXT,
  {
    details: "A security.txt file helps organizations define the process for security researchers to disclose security vulnerabilities in a standard way.\n\nsecurity.txt files have been implemented by Google, Facebook, GitHub, the UK government, and many others.\n\nThe UK's Ministry of Justice, the Cybersecurity and Infrastructure Security Agency (USA), the French government, the Italian government, the Dutch government, and the Australian Cyber Security Centre all endorse the use of security.txt files.",
    solution: "Create a text file called security.txt under the .well-known directory of your web root.",
  }
  ],
  [ExposureProblems.SECURITYHEADERS,
  {
    details: "Security headers such as CSP, Referrer Policy and HSTS work to protect both organizations and users from cyber attacks and hijacking and should be implemented tightly.",
    solution: undefined,
  }
  ],
  [ExposureProblems.NOLOADBALANCING,
  {
    details: "Load balancing can help protect you from DDOS attacks and ensure availability of your app.",
    solution: undefined,
  }
  ],
  [ExposureProblems.NOWAF,
  {
    details: "Web Application Firewalls can help protect you from attacks and ensure availability of your app.",
    solution: undefined,
  }],
  [ExposureProblems.NOWAFON,
  {
    details: "Web Application Firewalls can help protect you from attacks and ensure availability of your app.",
    solution: undefined,
  }],
  [ExposureProblems.SERVICESLEAKING,
  {
    details: "Servers often define headers which leak information about the service such as the version in use. It is recommended to remove these headers as they can inform attackers about your system.",
    solution: undefined,
  }],
  [ExposureProblems.NOHTTPSREDIRECT,
  {
    details: "HTTPS defines a secure protocol in which users can access your application. Without this protection, user data (including passwords) will be sent and received as readable text. This means that anyone can view and intercept that data, putting your users at risk of cyber attack and spying.",
    solution: "<VirtualHost *:80>\n\nServerName ....com\n\nServerAlias ....com\n\nRedirect permanent / https://....com/\n\n</VirtualHost>",
  }],
  [ExposureProblems.INSECURECONTENTS,
  {
    details: "Parts of your site were detected to serve resources without using HTTPS.\n\nHTTPS defines a secure protocol in which users can access your application. Without this protection, user data (including passwords) will be sent and received as readable text.\n\nThis means that anyone can view and intercept that data, putting your users at risk of cyber attack and spying.",
    solution: "<VirtualHost *:80>\n\nServerName ....com\n\nServerAlias ....com\n\nRedirect permanent / https://....com/\n\n</VirtualHost>",
  }],
  [ExposureProblems.URLNOTSECURE,
  {
    details: "HTTPS defines a secure protocol in which users can access your application. Without this protection, user data (including passwords) will be sent and received as readable text.\n\nThis means that anyone can view and intercept that data, putting your users at risk of cyber attack and spying. It was detected that your site didn't use HTTPS.",
    solution: "<VirtualHost *:80>\n\nServerName ....com\n\nServerAlias ....com\n\nRedirect permanent / https://....com/\n\n</VirtualHost>",
  }],
  [ExposureProblems.SSHOPEN,
  {
    details: "SSH is a commonly used protocol to allow remote connections between computers. Openly accessible SSH may allow an attacker to remotely gain privileged access to your system.",
    solution: undefined,
  }],
  [ExposureProblems.DEPTLS,
  {
    details: "HTTPS defines a secure protocol in which users can access your application. Without this protection, anyone can view and intercept that user data, putting your users at risk of cyber attack and spying.\n\nYour application was deemed to be using an outdated / insecure version of HTTPS (Transport Layer Security).",
    solution: undefined,
  }],
  [ExposureProblems.TLS12,
  {
    details: "HTTPS defines a secure protocol in which users can access your application. Without this protection, anyone can view and intercept that user data, putting your users at risk of cyber attack and spying.\n\nYour application was deemed to be using an outdated & insecure version of HTTPS (Transport Layer Security version 1.2).",
    solution: undefined,
  }],
  [ExposureProblems.NOTHARDENED,
  {
    details: "A web service behind an Nginx/Apache/IIS server was detected to use an AWS EC2 server.",
    solution: undefined,
  }],
  [ExposureProblems.PHPOOD,
  {
    details: "The version of PHP used in your application is outdated, this can potentially open you and your customers up to security vulnerabilities and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.CLIENTERROR403,
  {
    details: "Access to the server resulted in a 403 error to be returned (Permission denied).",
    solution: undefined,
  }],
  [ExposureProblems.CLIENTERROR404,
  {
    details: "Access to the server resulted in a 404 error to be returned (Not found).",
    solution: undefined,
  }],
  [ExposureProblems.CLIENTERROR500,
  {
    details: "Access to the server resulted in a 500 error to be returned (Internal server error).",
    solution: undefined,
  }],
  [ExposureProblems.RDPOPEN,
  {
    details: "RDP (Remote Desktop) is a technology that allows users to log in remotely and control the server.\n\nHaving this service exposed can allow attackers to utilize software bugs to gain remote access to your server.",
    solution: undefined,
  }],
  [ExposureProblems.CDNORIGINEXPOSED,
  {
    details: "A CDN (Content Delivery Network) is a technology that distributes your web assets globally so they load faster for your users.\n\nDisclosing the location of your CDN origin puts you at risk of attack and should not be disclosed.",
    solution: undefined,
  }],
  [ExposureProblems.EXCESSIVEREDIRECTS,
  {
    details: "Excessive redirects were encountered while using your app.\n\nExcessive redirects harm user experience and SEO.",
    solution: undefined,
  }],
  [ExposureProblems.EXCESSIVETARGETREDIRECTS,
  {
    details: "Excessive redirects were encountered while accessing your app.\n\nExcessive redirects harm user experience and SEO.",
    solution: undefined,
  }],
  [ExposureProblems.NGINXOOD,
  {
    details: "Use of an out of date NGINX has been detected.\n\nThis may affect compliance.",
    solution: undefined,
  }],
  [ExposureProblems.WPHARDEN,
  {
    details: "WordPress is a CMS platform that uses PHP to function.\n\nIf misconfigured, WordPress (via PHP) can expose information about WordPress itself and even about the host system where it is hosted.\n\nExposed information about your operating system environment and PHP version can be used by a remote attacker to gain access to your server.",
    solution: undefined,
  }],
  [ExposureProblems.POTSOVISSUE,
  {
    details: "Sovereignty for web applications means that all servers are hosted in one country.\n\nA potential sovereignty issue indicates that there may be offshore services you are unaware of, and this can affect compliance.",
    solution: "Please check these findings using the \"Assets\" tab in SecureStack.",
  }],
  [ExposureProblems.SOVISSUE,
  {
    details: "Sovereignty for web applications means that all servers are hosted in one country.\n\nA probable sovereignty issue indicates that there most likely are offshore services you may be unaware of, and this can affect compliance.",
    solution: "Please check these findings using the \"Assets\" tab in SecureStack.",
  }],
  [ExposureProblems.MYSQLOPEN,
  {
    details: "MySQL is a Structured Query Language (SQL) database server.\n\nOften critical application data and user information is stored in these databases. MySQL was detected which means it is accessible by an external attacker who can use vulnerabilities in the SQL server to gain control over your server.",
    solution: undefined,
  }],
  [ExposureProblems.SSLISSUE,
  {
    details: "This finding indicates that your server is using a SSL certificate that has been deemed invalid, expired, untrusted, self-signed, contains alt names that are invalid, etc. This may affect compliance.",
    solution: undefined,
  }],
  [ExposureProblems.DNSWILDCARD,
  {
    details: "A DNS Wildcard could allow a potential attacker to create a new domain and pretend to be you. Re-using your existing certificate on a new arbitrary subdomain is allowed due to the DNS wildcard.",
    solution: undefined,
  }],
  [ExposureProblems.DNSWILDCARDRECORD,
  {
    details: "A DNS Wildcard record could allow a potential attacker to create a new domain and pretend to be you. Re-using your existing certificate on a new arbitrary subdomain is allowed due to the DNS wildcard.",
    solution: undefined,
  }],
  [ExposureProblems.SERVERERRORRESPONSE,
  {
    details: "An error response was received from the server when requesting pages.",
    solution: undefined,
  }],
  [ExposureProblems.INSECUREVER,
  {
    details: "A HTTP only version of your site was detected. This may affect your compliance.",
    solution: "* Enable HTTPS\n\n* Redirect HTTP users to HTTPS",
  }],
  [ExposureProblems.DBOPEN,
  {
    details: "A database server of yours was detected on the public internet.\n\nDatabases typically hold critical application and user data.\n\nPublicly accessible database servers give attackers the opportunity to remotely access your server and potentially exploit software bugs allowing them to gain access to the data or to your server entirely.",
    solution: undefined,
  }],
  [ExposureProblems.NEEDSHARDENEDWINDOWSAWS,
  {
    details: "A standard instance of Windows Server was detected.\n\nIn this standard configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: "Deploy a hardened Windows Server AMI in AWS",
  }],
  [ExposureProblems.WINDOWSNOTHARDENED,
  {
    details: "A standard instance of Windows Server was detected.\n\nIn this standard configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: "Harden your Windows Server settings",
  }],
  [ExposureProblems.NEEDSHARDENEDLINUXAWS,
  {
    details: "A standard instance of Linux Server was detected.\n\nIn this standard configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: "Deploy a hardened Linux Server AMI in AWS",
  }],
  [ExposureProblems.UNENCRYPTEDURLCONTENT,
  {
    details: "Unencrypted pages were found while scanning your site.\n\nUse of HTTPS is critical for security and having unencrypted pages may affect compliance.",
    solution: "* Enable HTTPS\n\n* Redirect all HTTP requests to HTTPS",
  }],
  [ExposureProblems.SECURITYIMPROVED,
  {
    details: "SecureStack has detected security improvements.",
    solution: undefined,
  }],
  [ExposureProblems.SCALABILITYIMPROVED,
  {
    details: "SecureStack has detected scalability improvements.",
    solution: undefined,
  }],
  [ExposureProblems.BUBBLENOTHARDENED,
  {
    details: "Use of Bubble was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.BUBBLEAPI,
  {
    details: "Use of Bubble API was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.JOOMLANOTHARDENED,
  {
    details: "Use of Joomla! was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.DRUPALNOTHARDENED,
  {
    details: "Use of Drupal was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.SQUIZNOTHARDENED,
  {
    details: "Use of Squiz was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.MAGENTONOTHARDENED,
  {
    details: "Use of Magento was detected.\n\nWith this configuration, your server may be disclosing information about itself on the internet, making that information available to potential attackers.",
    solution: undefined,
  }],
  [ExposureProblems.ASPVOOD,
  {
    details: "Use of a legacy version of ASP.NET was detected.\n\nThis potentially opens the door for many types of software attacks and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.ASPOOD,
  {
    details: "Use of a previous version of ASP.NET was detected.\n\nThis potentially opens the door for many types of software attacks and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.APACHEOOD,
  {
    details: "Use of a previous version of Apache was detected.\n\nThis potentially opens the door for many types of software attacks and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.IISOOD,
  {
    details: "Use of a previous version of IIS was detected.\n\nThis potentially opens the door for many types of software attacks and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.NGINXOOD2,
  {
    details: "Use of a previous version of Nginx was detected.\n\nThis potentially opens the door for many types of software attacks and affects compliance.",
    solution: undefined,
  }],
  [ExposureProblems.OPENSSLOOD,
  {
    details: "Use of an old version of OpenSSL was detected.\n\nThis likely opens the door for software attacks and affects compliance.",
    solution: undefined,
  }],
]);

export function mapIntoIssue(issue?: string): IssueObject {
  if (!issue) {
    return { details: undefined, solution: undefined };
  }

  const lowercaseIssue = issue?.toLowerCase();
  const gotDetailsAndSolution = issueMap.get(lowercaseIssue);

  return {
    details: (gotDetailsAndSolution ? gotDetailsAndSolution.details : null) ?? "No details found.",
    solution: (gotDetailsAndSolution ? gotDetailsAndSolution.solution : null) ?? "No solution found.",
  };
}

export function useIssueMap(issue?: string): IssueObject {
  return mapIntoIssue(issue);
};
