import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRouteMatch, NavLink } from "react-router-dom";

export function BreadCrumbs({
  path,
}: {
  returnUrl?: string;
  crumbs?: string[];
  path?: string;
}): JSX.Element {
  const { url } = useRouteMatch();

  const urls = path
    ? path.split("/").filter(x => x)
    : url.split("/").filter(x => x);

  const titleCase = (title: string) => {
    return title[0]?.toUpperCase() + title.slice(1).toLowerCase();
  };

  const handleClickedRoute = (index: number, route: string) => {
    const recontructedUrl = urls.slice(0, index + 1).join("/");

    return `/${recontructedUrl}`;
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {urls.map((url, index: number) => (
          <li key={url} className="inline-flex items-center">
            <div
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              {urls.indexOf(urls[0]) === index ? null : (
                <ChevronRightIcon
                  className="mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <NavLink
                className=" text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                to={handleClickedRoute(index, url)}
              >
                {titleCase(url)}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
