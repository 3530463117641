import { Dispatch } from "react";
import { BsFolder2, BsSearch } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";
import { SelectedIssue } from "../../../App";
import { Button } from "../../../components/buttons/Button";
import { ParsedCodeIssueRow } from "../../../functions/hooks/useApplicationIssues";
import { copyToClipboardWithSnackbar } from "../../../functions/utilities";
import { CriticalityChip } from "../../CVEExplorer";
import { notEmpty } from "../../dashboard_2/dashboardDigest";
import { PopupBody, PopupHeading } from "../exposure/Details";

/* function Heading({ packageName, vulnerableVersion, cvssScore, currentPackage }: { packageName: string; vulnerableVersion: string; cvssScore: string; currentPackage: ParsedCodeIssueRow; }): JSX.Element {
  return <div className="justify-between flex mb-4 dark:bg-dark-primary">
    <h3 className="text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">
      {packageName}
    </h3>
  </div>
}; */


export function PackageCard({ currentPackage, selectedIssue, setSelectedIssue }: { currentPackage: ParsedCodeIssueRow; setSelectedIssue?: Dispatch<SelectedIssue>; selectedIssue?: ParsedCodeIssueRow; }): JSX.Element {
  return (
    <>
      {/*       <Heading currentPackage={currentPackage} packageName={currentPackage.packageName ?? ""} vulnerableVersion={currentPackage.vulnerableVersion ?? ""} cvssScore={currentPackage.cvssScore.toString() ?? ""} />*/}
      <PopupHeading s="Summary:" className="mb-2" />
      <div className="flex flex-col rounded-b-lg">
        <div className="flex flex-col gap-2">
          <PopupBody>
            {currentPackage.summary ? <p title={currentPackage.summary ?? selectedIssue?.summary ?? ""} className="font-medium">
              {selectedIssue?.summary || currentPackage.summary}
            </p> : "No summary available."}
          </PopupBody>
          <PopupHeading s="Description:" className="mt-4 mb-2" />
          {currentPackage.longIssueDescriptionFormatted ? <div className="mb-4">{currentPackage.longIssueDescriptionFormatted}</div> :
            <PopupBody className="mb-4">
              {(currentPackage.details || currentPackage.longIssueDescription) || (selectedIssue?.details || selectedIssue?.longIssueDescription) ? <p title={currentPackage.details ?? currentPackage.longIssueDescription ?? selectedIssue?.details ?? selectedIssue?.longIssueDescription ?? ""} className="font-medium">
                {currentPackage.details ?? currentPackage.longIssueDescription ?? selectedIssue?.details ?? selectedIssue?.longIssueDescription ?? "No description available."}
              </p> : "No description available."}
            </PopupBody>
          }
        </div>

        <PopupBody>
          {currentPackage.details ? <>
            <div className="flex gap-2 flex-col my-4">
              {currentPackage.details.split('\n').map((det, i) => <p key={i}>{det}</p>)}
            </div>
          </> : null}
        </PopupBody>

        <div className="my-2 flex items-center">
          <PopupHeading s="Fixed in:" className="w-fit pr-1" />
          <PopupBody className="flex-grow">
            <span>
              {currentPackage.fixedVersions && currentPackage.fixedVersions !== "Unavailable" ? currentPackage.fixedVersions : "No fix version available."}
            </span>
          </PopupBody>
        </div>

        {currentPackage.cvssVector && (
          <div className="my-2 justify-between flex items-center">
            <PopupHeading s="CVSS Vector:" className="w-fit pr-1" />
            <PopupBody className="flex-grow">
              <div className="flex flex-col gap-2">
                {currentPackage.cvssVector && currentPackage.cvssVector !== "Unavailable" ? currentPackage.cvssVector.split(', ').map((vectorRepresentation, i) => <p key={i}>{vectorRepresentation}</p>) : "No CVSS Vector available."}
              </div>
            </PopupBody>
          </div>
        )}

        {currentPackage.cveId && currentPackage.cveId !== "Unavailable" ? (
          <div className="my-2 flex justify-between">
            <div className="flex gap-2">
              <span className="flex items-center">
                <PopupHeading s="CVE ID:" className="w-fit pr-1" />
                <p className="dark:text-gray-300 flex-grow">
                  <Link onClick={() => void (setSelectedIssue && setSelectedIssue(null))} to={`/package-explorer?search=${encodeURIComponent(currentPackage.cveId)}`}>
                    <Button label={currentPackage.cveId} icon={<BsSearch width={5} height={5} className="mr-1" />} secondary />
                  </Link>
                </p>
              </span>
              <span className="my-auto">
                <Button icon={<FaCopy className="w-3 h-3 dark:text-white" />} onClick={() => void (currentPackage.cveId ? copyToClipboardWithSnackbar(currentPackage.cveId, "CVE ID") : null)} />
              </span>
            </div>
          </div>
        ) : null}

        {currentPackage.osvId && currentPackage.osvId !== "Unavailable" ? (
          <div className="my-2 flex justify-between">
            <div className="flex gap-2">
              <span className="flex items-center text-sm text-gray-400">
                <PopupHeading s="Advisory CVE ID:" className="w-fit pr-1" />
                <p className="dark:text-gray-300 flex-grow">
                  <Link onClick={() => void (setSelectedIssue && setSelectedIssue(null))} to={`/package-explorer?search=${encodeURIComponent(currentPackage.osvId)}`}>
                    <Button label={currentPackage.osvId} icon={<BsSearch width={5} height={5} className="mr-1" />} secondary />
                  </Link>
                </p>
              </span>
              <span className="my-auto">
                <Button icon={<FaCopy className="w-3 h-3 dark:text-white" />} onClick={() => void (currentPackage.osvId ? copyToClipboardWithSnackbar(currentPackage.osvId, "OSV ID") : null)} />
              </span>
            </div>
          </div>
        ) : null}

        {currentPackage.associatedCweIds.length > 0 ? (
          <div className="mb-4 mt-2 flex">
            <div className="flex items-center">
              <PopupHeading s={`Associated CWE ID${currentPackage.associatedCweIds.length > 0 ? "s" : ""}:`} className="w-fit mr-2" />
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              {
                currentPackage.associatedCweIds.map((cwe, i) => <div key={i} className="flex gap-2">
                  <div className="flex items-center text-sm text-gray-400 flex-row">
                    <div className="flex flex-wrap flex-row">
                      <p className="dark:text-gray-300 flex-grow">
                        <Link onClick={() => void (setSelectedIssue && setSelectedIssue(null))} to={`/package-explorer?search=${encodeURIComponent(cwe)}`}>
                          <Button label={cwe} icon={<BsSearch width={5} height={5} className="mr-1" />} secondary />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="my-auto">
                    <Button icon={<FaCopy className="w-3 h-3 dark:text-white" />} onClick={() => void (cwe ? copyToClipboardWithSnackbar(cwe, cwe) : null)} />
                  </div>
                </div>)
              }
            </div>
          </div>
        ) : null}

        {currentPackage.originalIssue?.rawData?.via == null || currentPackage.originalIssue?.rawData?.via?.filter(x => x?.cwe != null && x?.severity != null && x?.dependency != null && x?.range != null).length === 0 ? null : <div className="my-2 flex flex-col gap-2 px-2">
          <div className="flex items-center">
            <PopupHeading className="w-fit pr-2" s="Via:" />
          </div>
          {
            currentPackage.originalIssue?.rawData?.via?.filter(notEmpty).filter(x => x.cwe != null && x.severity != null && x.dependency != null && x.range != null)?.map((x, i) => <div key={i} className="flex flex-grow bg-light-seconday dark:bg-dark-nav rounded-lg w-full">
              {
                [
                  /* { title: "Name", value: x.name }, */
                  { title: "CWE", value: x.cwe?.join(", ") },
                  { title: "Severity", value: <CriticalityChip criticality={x.severity ?? null} /> },
                  { title: "Dependency", value: x.dependency },
                  { title: "Vulnerable Range", value: x.range },
                ].map((y, j) => <div title={y.title} key={i + j} className="flex flex-col flex-grow gap-2 py-2 xl:py-4">
                  <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">{y.title}</p>
                  <div className="mx-auto flex-grow text-sm font-semibold text-gray-600 dark:text-gray-400">{y.value}</div>
                </div>)
              }
            </div>)
          }
        </div>}

        {currentPackage.originalIssue.rawData?.nodes ? (
          <div className="my-2 flex flex-col px-2">
            <div className="flex mb-2 items-center">
              <PopupHeading className="w-fit pr-2" s={`Path${currentPackage.originalIssue.rawData?.nodes.length > 1 ? "s" : ""}:`} />
            </div>

            <div className="flex-grow flex gap-4 flex-col">
              {
                currentPackage.originalIssue.rawData?.nodes.map((node, i) => <div className="whitespace-break-spaces flex flex-row items-center text-sm ml-4" key={i}>
                  <BsFolder2 width={5} height={5} />
                  <PopupBody><p className="ml-2">{node}</p></PopupBody>
                </div>)
              }
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
