import { ExclamationIcon } from "@heroicons/react/outline";
import { CloudAssociatedResourceProblem } from "../../functions/hooks/useApplicationIssues";
import { joinClassNames } from "../../functions/utilities";
import { Dispatch } from "react";

type ProblemListProps = {
  allProblems: CloudAssociatedResourceProblem[];
  currentProblem: CloudAssociatedResourceProblem;
  setCurrentProblem: Dispatch<CloudAssociatedResourceProblem>;
};

export function ProblemList({
  setCurrentProblem,
  currentProblem,
  allProblems,
}: ProblemListProps): JSX.Element {
  return (
    <nav className="space-y-1" aria-label="Sidebar">
      {allProblems.map((item, index) => (
        <button
          key={item.resourceType + index.toString()}
          onClick={() => setCurrentProblem(item)}
          className={joinClassNames(
            item === currentProblem && "bg-gray-100 dark:bg-dark-primary",
            "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-dark-primary",
            "group flex items-center px-3 py-2 text-sm font-medium rounded-md"
          )}
        >
          <ExclamationIcon
            className={joinClassNames(
              "text-red-500 group-hover:text-red-500",
              "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            )}
            aria-hidden="true"
          />
          <span title={item.description} className="truncate dark:text-gray-300 cursor-pointer">
            {item.description}
          </span>
        </button>
      ))}
    </nav>
  );
};
