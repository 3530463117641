import { Badge } from "@material-ui/core";
import { IconButton } from "@mui/material/";
import { Dispatch, Suspense, lazy, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../functions/convertToLocalTime";
import { isFeatureEnabled } from "../../functions/utilities";
import { svgImport } from "../../partials/application/ApplicationComposition";
import { usePersistedState } from "../../views/onboarding/OnboardingStepper";
import { Button } from "../buttons/Button";
import { Modal } from "../dialogs/Modal";
//import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { BsDot, BsFillPatchExclamationFill, /* BsTerminal */ } from "react-icons/bs";
//import { ReactComponent as AzureDevOps } from "../../assets/integration-images/new/azure-devops.svg";
import { /* AiFillAppstore, AiOutlineBug, */ AiOutlineDashboard, AiOutlineFileSearch } from "react-icons/ai";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));

declare var window: any;

type PatchNoteType = {
  title: string;
  releaseDate: string;
};

function DisplayIconWithOrWithoutBadge({ userHasSeenNotes }: { userHasSeenNotes: boolean }): JSX.Element {
  if (userHasSeenNotes) {
    return (
      <BsFillPatchExclamationFill className="h-6 w-6 dark:text-gray-500 text-gray-900" />
    );
  } else {
    return (
      <Badge overlap="rectangular" color="secondary" variant="dot">
        <BsFillPatchExclamationFill className="h-6 w-6 dark:text-gray-500 text-gray-900" />
      </Badge>
    );
  }
};

export const appVersionString: string = "V8.4.0";

const latestPatchNotesHeader: PatchNoteType = {
  title: `${appVersionString} Release Notes`,
  releaseDate: formatDate(window.release_date),
};

type PatchDate = {
  date: Date;
  versionString: string;
};

export function PatchNotes(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [seenAppVer, setSeenAppVer] = usePersistedState<PatchDate>("patchnotes-app-ver", { date: new Date(), versionString: "" });

  // on close of modal => we update the ver and update the date seen to today
  const onModalClose = () => {
    setIsOpen(false);
  }
  const onModalOpen = () => {
    setIsOpen(true);
  }

  const userHasSeenNotes = seenAppVer.versionString !== "";

  // use effect:
  useEffect(() => {
    if (isFeatureEnabled("disable-patch-notes")) {
      return;
    }
    // IF appVersionString != the version saved or there is no version saved => should show patch notes
    if (seenAppVer.versionString !== appVersionString) {
      setSeenAppVer({ date: new Date(), versionString: appVersionString });
      onModalOpen();
    }
    // IF appVersionString == the version saved => no action
  }, [seenAppVer, setSeenAppVer, setIsOpen,]);

  return (
    <div>
      <div title="Patch notes">
        <IconButton
          onClick={() => onModalOpen()}
          size="large"
          aria-label="patch notes for the latest release"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <DisplayIconWithOrWithoutBadge userHasSeenNotes={userHasSeenNotes} />
        </IconButton>
      </div>
      <Modal
        width="max-w-4xl"
        noVerticalMargin={true}
        isOpen={isOpen}
        onClose={onModalClose}
        icon={<Suspense fallback={<></>}><SecureStackLogo className="h-7 w-7 mr-2" /></Suspense>}
        title={latestPatchNotesHeader.title}
        closeWhenClickingBackground={true}
      >
        <div className="flex flex-col">
          <Features setIsOpen={setIsOpen} />
          <Bugs bugs={bugfixes} />
        </div>
      </Modal>
    </div>
  );
};

const bugfixes: string[] = ["Security and Performance updates.", "Added a new Finish button on app creator stepper.", "Updates to template generation and formatting.", "Fixed SBOM scans for exposure and cloud only apps.", "Tags are now alphabetically sorted on the app list.", "Total counts have been added to insecure links, assets and other domains pages.", "Added a formatted title to code issue rows.", "Added a new SecureStack Compliance Module component on the Dashboard.", "Filter cloud issues by source.", "Software Supply Chain: View additional information and versions by clicking any icon in the SSC.", "Select Source Code components now available in the SSC. Search for vulnerabilities by package name."];

function PatchNoteFeatureCard({ children, feature }: { children: JSX.Element | JSX.Element[]; feature: string; }): JSX.Element {
  return <div className="flex flex-col">
    <div className="mx-auto font-bold text-xl mb-2 dark:text-white">{feature}</div>
    <div className="mx-auto">
      {children}
    </div>
  </div>;
}

const createItems: (setIsOpen: Dispatch<boolean>) => { feature: string; asset: JSX.Element }[] = (setIsOpen: Function) => [
  {
    feature: "SecureStack Cloud Scan",
    asset: <div className="flex flex-col justify-center dark:text-white">
      <p className="text-center">We've updated and improved our Cloud Scan service using the latest data from AWS GuardDuty and AWS Inspector.</p>
      <Link to="/dashboard" className="mt-[80px]">
        <Button className="w-full" label="To Dashboard" icon={<AiOutlineDashboard className="mr-2" />} primary onClick={() => void setIsOpen(false)} />
      </Link>
    </div>,
  }, {
    feature: "SecureStack Package Explorer",
    asset: <div className="flex flex-col justify-center dark:text-white gap-4">
      {/* <div className="flex flex-row flex-nowrap justify-center mb-2">
        <Suspense fallback={<></>}><SecureStackLogo width="56" height="56" /></Suspense>
      </div> */}
      <p className="mb-2 text-center">Package explorer now supports searching CVE, CWE and GitHub Advisory. Quickly find applications affected by CVEs, CWEs or GitHub Advisories from Code, Exposure and Cloud Issue views.</p>
      <Link to="/package-explorer?search=CVE-2021-44906" className="mt-2">
        <Button className="w-full" label="To Package Explorer" icon={<AiOutlineFileSearch className="mr-2" />} primary onClick={() => void setIsOpen(false)} />
      </Link>
    </div>,
  },
];

function Features({ setIsOpen }: { setIsOpen: Dispatch<boolean> }): JSX.Element {
  //const { theme: darkMode } = useContext(ThemeContext);

  const items = createItems(setIsOpen);

  if (items.length === 0) return <></>;

  return <div className="rounded-md px-2 py-4 h-fit">
    <div className="grid grid-cols-1 md:grid-cols-2 h-fit md:gap-2 lg:gap-4">
      {items.map((item, key) => {
        return <PatchNoteFeatureCard key={key} feature={item.feature}>
          {item.asset}
        </PatchNoteFeatureCard>
      })}
    </div>
  </div>;
};

const Bugs = ({ bugs }: { bugs: string[] }) => {
  if (bugs.length === 0) return null;
  return (
    <div>
      <p className="text-black dark:text-white">Bugfixes and Improvements</p>
      <ul className="dark:bg-dark-primary bg-light-primary dark:text-gray-300 text-gray-700 rounded-md px-4 py-4 mt-5">
        {bugs.map((bug: string, index: number) => (
          <li key={index} className="flex py-2 space-y-2">
            <BsDot key={index} className="w-7 h-7" />
            {bug}
          </li>
        ))}
      </ul>
    </div>
  );
};
