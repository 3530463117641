import { AiFillCloud } from "react-icons/ai";
import { BiCodeAlt } from "react-icons/bi";
import { titleCase } from "../../functions/utilities";
import { RiBuilding4Line } from "react-icons/ri";
import { AiFillHdd } from "react-icons/ai";
import { FaCodeBranch } from "react-icons/fa";
import { GoRepo } from "react-icons/go";
import { MdSecurity } from "react-icons/md";
import { Application } from "../../__generated__/graphql";

type AppStatusBarProps = {
  application: Application;
};

export function AppStatusBar({ application }: AppStatusBarProps): JSX.Element {
  return (
    <div className="flex">
      <div className="mt-1 flex flex-row flex-nowrap sm:mt-0 align-middle justify-between">
        <div className="flex flex-shrink align-middle my-auto text-md dark:text-gray-300 text-black space-x-2">
          <div className="p-1 pr-6 whitespace-nowrap text-ellipsis overflow-hidden max-w-[calc(40vw-68px)] max-h-[40px]" title={`App Name "${application?.name}"`}>
            {application?.name}
          </div>
        </div>

        <div className="lg:flex hidden flex-row flex-nowrap align-middle">
          <div className={`flex flex-shrink items-center text-sm text-gray-400 dark:text-gray-500 ${application?.target ? "" : "hidden"}`}>
            <div className="flex items-center" title={`URL: ${application?.target}`}>
              <MdSecurity className="flex-shrink-0 mr-1.5 h-5 w-5" />
              <p className="hidden xl:block pr-4 flex-shrink text-ellipsis overflow-hidden max-w-[260px]">{application?.target}</p>
            </div>
          </div>

          <div className={`flex flex-shrink items-center text-sm ${application?.scm != null ? "text-gray-400 dark:text-gray-500" : "hidden"}`}>
            <div className="flex items-center" title={`${titleCase(application?.scm ?? "")}: ${application?.organization || application?.workspace} → ${application?.reponame} → ${application?.branch}`}>
              <BiCodeAlt className="flex-shrink-0 flex mr-1.5 h-5 w-5" />
            </div>
          </div>

          <div title="SecureStack Cloud Integration" className={`flex flex-shrink items-center text-sm ${application?.f_account_id ? "text-gray-400 dark:text-gray-500" : "hidden"}`}>
            <span
              title={`AWS Account ID: ${application?.f_account_id}`}
              className="flex items-center"
            >
              <AiFillCloud className="flex-shrink-0 flex mr-1.5 h-5 w-5" />
            </span>
          </div>
        </div>

      </div>
    </div>
  );
};

export function RepoDetails({ application }: AppStatusBarProps): JSX.Element {
  const repoDetailsObj = [
    {
      title: application?.organization || application?.workspace,
      icon: application?.organization ? RiBuilding4Line : AiFillHdd,
    },
    { title: application?.reponame, icon: GoRepo },
    { title: application?.branch, icon: FaCodeBranch },
  ];

  return (
    <div className="mt-1 space-y-1">
      {repoDetailsObj.map((details, idx: number) => (
        <div key={idx} className="mr-auto items-center mx-1">
          <div className="flex">
            <details.icon className="text-gray-600 dark:text-gray-300 w-4 h-4" />
            <div className="text-sm">{titleCase(details.title ?? "")}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
