import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/buttons/Button";

export function CheckEmail(): JSX.Element {
  const { push } = useHistory();
  return (
    <div className="dark:bg-dark-primary bg-white min-h-screen">
      <div className="max-w-7xl   py-40 px-5 items-center mx-auto flex sm:flex-nowrap flex-wrap">
        <div className=" space-y-6 bg-gray-300 dark:bg-dark-main  rounded-lg overflow-hidden  p-10 mx-auto     ">
          <span className="dark:text-gray-300 text-gray-900 text-2xl flex items-center">
            <MdOutlineMarkEmailUnread className="w-10 h-10 text-gray-300 mr-2" />
            Check your email
          </span>
          <p className="dark:text-gray-500 text-gray-900">
            We sent an email to you. Please check your inbox and confirm your
            sign up.
          </p>
          <Button
            label="Sign in to a different account"
            primary
            onClick={() => push("/auth/login")}
          />
        </div>
      </div>
    </div>
  );
};

export function CheckEmailForMagicLink(): JSX.Element {
  const { push } = useHistory();
  return (
    <div className="dark:bg-dark-primary bg-white min-h-screen ">
      <div className="max-w-7xl   py-40 px-5 items-center mx-auto flex sm:flex-nowrap flex-wrap">
        <div className=" space-y-6 bg-gray-300 dark:bg-dark-main  rounded-lg overflow-hidden  p-10 mx-auto     ">
          <span className="dark:text-gray-300 text-gray-900 text-2xl flex items-center">
            <MdOutlineMarkEmailUnread className="w-10 h-10 text-gray-300 mr-2" />
            Check your email
          </span>
          <p className="dark:text-gray-500 text-gray-900">
            We sent a magic to you. Please check your inbox and follow the link.
          </p>
          <Button
            label="Sign in to a different account"
            primary
            onClick={() => push("/auth/login")}
          />
        </div>
      </div>
    </div>
  );
};
