import { useQuery } from "@apollo/client";
import { Dispatch, Suspense, lazy, useState } from "react";
import { IoCloudOutline } from "react-icons/io5";
import { usePathAppId } from "../../functions/hooks";
import { CloudAssociatedResourceProblem, ParsedCloudIssueRow } from "../../functions/hooks/useApplicationIssues";
import { ListFAWSAccounts } from "../../graphql/queries/ListFAWSCloudAccounts";
import { svgImport } from "../../partials/application/ApplicationComposition";
import { useAppSettings } from "../../views/dashboard_2/appSettingsDigest";
import { Tabber } from "../../views/issues/exposure/SelectedExposureIssueView";
import CreateIssue from "../jira/CreateIssue";
import { LoadingWheel } from "../loading/Loader";
import { Details } from "./Details";
import { ProblemList } from "./ProblemList";
import { Solution } from "./Solution";
import { SelectedIssue } from "../../App";

const AwsServicesLogo = lazy(() => import("../../assets/app-composition/aws.svg").then(svgImport));


const tabs = ["Details", "Solution", "Create Ticket"];
type SelectedCloudIssueViewTabs = typeof tabs[number];


export function CloudProblemSection({ currentProblem, setSelectedIssue }: { currentProblem: ParsedCloudIssueRow; setSelectedIssue: Dispatch<SelectedIssue> }): JSX.Element {
  const [tab, setTab] = useState<SelectedCloudIssueViewTabs>("Details");

  const appId = usePathAppId();
  const appSettings = useAppSettings(appId ?? "");
  const [awsAccountName, setAwsAccountName] = useState<string | null>(null);
  const [awsAccountRoleIAMId, setAwsAccountRoleIAMId] = useState<string | null>(null);

  const { loading: loadingAwsAccountInfo, error: errorAwsAccountInfo } = useQuery(ListFAWSAccounts, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const awsInfo = data.dbfCloudAccounts?.find(x => x?.id === appSettings?.cloud.accountId);
      setAwsAccountName(awsInfo?.name ?? null);
      setAwsAccountRoleIAMId(awsInfo?.role?.split("::")?.at(1)?.split(":")?.at(0) ?? null);
    },
  });

  const [currentResourceProblem, setCurrentResourceProblem] = useState<CloudAssociatedResourceProblem>(currentProblem.associatedResources.at(0) ?? {
    description: "Coming soon",
    longDescription: currentProblem.issueTitle,
    resourceLocation: "Coming soon",
    resourceType: "Coming soon",
    shortDescription: "Coming soon",
    remediation: "Coming soon",
    service: undefined,
    associatedServices: [],
  });

  return (
    <div className="dark:bg-dark-main rounded-lg bg-white py-4 px-4 space-y-2">
      <div className="my-auto flex flex-row flex-nowrap mb-2">
        <div className="pr-4 my-auto">
          <IoCloudOutline className="w-6 h-6 text-cloud-icon-active" />
        </div>
        <h3 className="text-xl font-semibold tracking-tight leading-6 text-gray-900 dark:text-gray-300">
          {currentProblem.issueTitle}
        </h3>
      </div>
      <div className="mb-4">
        {
          loadingAwsAccountInfo ?
            <LoadingWheel h={20} w={20} /> :
            <div className="flex flex-col md:flex-row gap-3">
              <Suspense fallback={<></>}>
                <div title="AWS Logo" className="my-auto">
                  <AwsServicesLogo height={20} />
                </div>
              </Suspense>
              <p title="AWS Account Name" className="dark:text-white my-auto">{awsAccountName}</p>
              <p title="AWS IAM ID" className="dark:text-white my-auto">{awsAccountRoleIAMId}</p>
            </div>
        }
        {
          errorAwsAccountInfo ? <p className="dark:text-white">Error retrieving AWS Account details</p> : null
        }
      </div>
      <Tabber tabs={tabs} currentTab={tab} setCurrentTab={setTab} displayStyle="cloud">
        <a href="https://console.aws.amazon.com/console/home" target="_blank" rel="noopener noreferrer nofollow external"> <div key="openAwsConsole" tabIndex={0} className="px-3 py-2 rounded-md dark:text-white dark:bg-dark-nav bg-light-primary select-none cursor-pointer focus:outline-1">
          Open AWS Console
        </div></a>
      </Tabber>
      {tab === "Details" && <div className="flex flex-col gap-2 pt-2">
        <div className="grid gap-4 xl:gap-6" style={{ gridTemplateColumns: currentProblem.associatedResources.length > 1 ? "0.8fr 1.1fr" : "1fr", gridTemplateRows: "1fr" }}>
          {currentProblem.associatedResources.length > 1 ? <div className="truncate">
            <ProblemList
              allProblems={currentProblem.associatedResources.length > 0 ? currentProblem.associatedResources : []}
              currentProblem={currentResourceProblem}
              setCurrentProblem={setCurrentResourceProblem}
            />
          </div> : null}
          <div className="overflow-auto">
            <Details setSelectedIssue={setSelectedIssue} outerProblem={currentProblem} problem={currentResourceProblem} time_created={currentProblem.timeCreated.date} />
          </div>
        </div>
      </div>
      }
      {tab === "Create Ticket" && <CreateIssue />}
      {tab === "Solution" && <Solution currentResourceProblem={currentResourceProblem} />}
    </div>
  );
};
