import { CheckIcon } from "@heroicons/react/solid";
import { joinClassNames } from "../../functions/utilities";

const steps = [
  {
    name: "AWS details",
    description: "Add your AWS account details.",
    href: "#",
  },
  {
    name: "AWS template",
    description: "Copy and paste the template.",
    href: "#",
  },

  {
    name: "Status",
    description: "Check your account status.",
    href: "#",
  },
];

type CloudWizardProgressProps = {
  step: string;
};

const getStepStatus = (stepIndex: number, currentStep: string) => {
  const step = parseInt(currentStep);

  if (stepIndex + 1 === step) {
    return "current";
  }

  if (stepIndex + 1 < step) {
    return "complete";
  }

  return "";
};

export function CloudWizardProgress({ step }: CloudWizardProgressProps): JSX.Element {
  if (!step) {
    return <></>;
  }

  return (
    <nav
      className="my-auto sm:max-w-md sm:rounded-lg sm:overflow-hidden px-4 py-8 mt-5  mx-auto "
      aria-label="Progress"
    >
      <ol className="overflow-hidden">
        {steps.map((item, stepIdx) => (
          <li
            key={item.name}
            className={joinClassNames(
              stepIdx !== steps.length - 1 && "pb-10",
              "relative"
            )}
          >
            {getStepStatus(stepIdx, step) === "complete" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-500"
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className="relative flex items-start group cursor-pointer"
                >
                  <span className="h-9 flex items-center">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-red-700 dark:bg-dark-primary  rounded-full group-hover:bg-red-800">
                      <CheckIcon
                        className="w-5 h-5 text-green-400"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-lg font-semibold tracking-wide uppercase dark:text-gray-400">
                      {item.name}
                    </span>
                    <span className="text-md text-gray-500">
                      {item.description}
                    </span>
                  </span>
                </div>
              </>
            ) : getStepStatus(stepIdx, step) === "current" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-500"
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className="relative flex items-start group cursor-pointer "
                  aria-current="step"
                >
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-dark-primary border-2 dark:border-dark-main  rounded-full">
                      <span className="h-2.5 w-2.5 bg-gray-400 rounded-full" />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-lg font-semibold tracking-wide uppercase text-gray-500">
                      {item.name}
                    </span>
                    <span className="text-md text-gray-500">
                      {item.description}
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-500"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start group cursor-pointer">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-dark-secondary border-2 border-gray-300  dark:border-none rounded-full group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-lg font-semibold tracking-wide uppercase text-gray-500">
                      {item.name}
                    </span>
                    <span className="text-md text-gray-500">
                      {item.description}
                    </span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
