import { useEffect, ReactNode } from "react";
import { ThemeContext } from "../usePrefersDarkMode";
import { useSafeLocalStorage } from "../useSafeLocalStorage";
const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

export function Themer({ children }: { children: ReactNode }): JSX.Element {
  const [localStorage, setLocalStorage] = useSafeLocalStorage("theme", mediaQuery.matches);

  useEffect(() => {
    const handler = () => setLocalStorage(mediaQuery.matches);
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [setLocalStorage]);

  useEffect(() => {
    if (window === undefined) return;
    const root = window.document.documentElement;
    root.classList.remove(localStorage ? "light" : "dark");
    root.classList.add(localStorage ? "dark" : "light");
  }, [localStorage]);

  return (
    <ThemeContext.Provider
      value={{
        theme: localStorage,
        toggleTheme: () => {
          setLocalStorage(!localStorage);
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
