import { useEffect } from "react";
import { AiFillCloud } from "react-icons/ai";
import { SiLinuxcontainers } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { MdSecurity } from "react-icons/md";
import { BreadCrumbs } from "../../../components/breadcrumbs/BreadCrumbs";
import { useHistoryState } from "../../../functions/hooks";
import { CloudSettings } from "../categories/CloudSettings";
import { ContainerSettings } from "../categories/GetContainerAccounts";
import { ExposureSettings } from "../categories/ExposureSettings";
import { GeneralSettings } from "../categories/GeneralSettings";
import { SecretSettings } from "../categories/SecretSettings";
import { CodeSettings } from "../categories/CodeSettings";
import { CreateApplicationNav } from "../CreateApplicationNav";
import GoGistSecret from "../../../assets/images/GoGistSecret";

enum IndexValues {
  GENERAL = "1",
  EXPOSURE = "2",
  CODE = "3",
  CLOUD = "4",
  CONTAINER = "5",
  SECRET = "6",
  DEFAULT_VALUE = "1",
  LAST_STEP = "6",
}

type SingleApplicationProps = {
  ConfigurationComponent: JSX.Element | undefined;
  setId: (id: string) => void;
  id: string;
};

export function CreateSingleApplication(): JSX.Element {
  const { GENERAL, EXPOSURE, CODE, CLOUD, CONTAINER, DEFAULT_VALUE, SECRET } =
    IndexValues;
  const [id, setId] = useHistoryState("id");

  const configurationOptionsMap = new Map<string, { component: JSX.Element }>([
    [GENERAL, { component: <GeneralSettings /> }],
    [EXPOSURE, { component: <ExposureSettings /> }],
    [CODE, { component: <CodeSettings /> }],
    [CLOUD, { component: <CloudSettings /> }],
    [CONTAINER, { component: <ContainerSettings /> }],
    [SECRET, { component: <SecretSettings /> }],
  ]);

  const ConfigurationComponent = configurationOptionsMap.get(id)?.component;

  useEffect(() => {
    if (!id) {
      setId(DEFAULT_VALUE);
    }
  }, [id, setId, DEFAULT_VALUE]);

  return (
    <div>
      <div className="ml-[263px] mb-2">
        <BreadCrumbs />
      </div>
      <DesktopView
        id={id}
        setId={setId}
        ConfigurationComponent={ConfigurationComponent}
      />
    </div>
  );
}

export function DesktopView({
  id,
  setId,
  ConfigurationComponent,
}: SingleApplicationProps): JSX.Element {
  const [appName] = useHistoryState("appName");
  return (
    <>
      <CreateApplicationNav
        navItems={navigationItems}
        currentId={id}
        setId={(id) => appName && setId(id)}
      />
      {ConfigurationComponent}
    </>
  );
}

const navigationItems = [
  {
    id: "1",
    name: "General",
    icon: IoMdSettings,
  },
  {
    id: "2",
    name: "Exposure",
    icon: MdSecurity,
  },
  {
    id: "3",
    name: "Code",
    icon: BiCodeAlt,
  },
  {
    id: "4",
    name: "Cloud",
    icon: AiFillCloud,
  },
  {
    id: "5",
    name: "Containers",
    icon: SiLinuxcontainers,
  },
  {
    id: "6",
    name: "Secret",
    icon: GoGistSecret,
  },
];
