import { gql } from "@apollo/client/core";
import makePersistedReactiveVar from "../functions/hooks/makePersistedReactiveVar";

export const typeDefs = gql`
  type User {
    id: ID
    status: String
    roles: JSON
    email: String
    selectedOrg: String
    token: String
    rawAppData: Object
  }

  extend type Query {
    user: User
  }
`;

/**
 * NOTE: We expect the user to need to verify their email if there is a User.id but not a User.token
 * This is because in the SignUp process, a user object is returned but the returned session object
 * (which has a session.user) will be null or undefined. Therefore we can safely say that:
 * if user.id && !user.token then verifying email
 * which is equivalent to the following based on the return object of the supabase signup function:
 * if retValue.user && !retValue.session then verifying email
 */
type UserRole = {
  // org_id => [roleA, roleB]
  [key: string]: string[];
};

export type User = {
  id?: string;
  email?: string;
  mfa?: boolean;
  selectedOrg?: string; // Supabase org id
  token?: string;
  status: string;
  roles: UserRole;
  rawAppData: any;
};

export const user = makePersistedReactiveVar<User | null>(null, "securestack-user");

export const typePolicies = {
  Query: {
    fields: {
      user: {
        read() {
          return user();
        },
      },
    },
  },
};
