import { useState } from "react";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useHistory, useParams } from "react-router-dom";
import { CloudForm } from "../../../app-types/AppTypes";
import { Button } from "./form/Button";
import { Footer } from "./form/Footer";
import { Input } from "./form/Input";
import { Form, useForm } from "./form/useForm";
import { SimpleError } from "../../../errors/SimpleError";
import { CreateFCloudAccount } from "../../../graphql/mutations/CreateFCloudAccount";
import { TheOneCombobox } from "../../buttons/TheOneCombobox";
import { useUser } from "../../../functions/hooks/useUser";

type WizardProps = {
  onActivated: () => void;
  path: string;
};

const initialState = {
  aws_account_name: "",
  aws_account_id: "",
  company_name: "",
  contact_name: "",
  application_name: "",
  application_id: "",
  organization_name: "",
  plan_id: "",
  phone_number: "",
  region: "",
};

const regions = [
  "af-south-1",
  "ap-east-1",
  "ap-south-1",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-northeast-3",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-southeast-3",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-south-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "me-south-1",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
  "us-gov-east-1",
  "us-gov-west-1",
];

export function NewAWSAccount({ onActivated, path }: WizardProps): JSX.Element {
  onActivated();
  const { push } = useHistory();
  const user = useUser();
  const { values, handleInputChange } = useForm<CloudForm>(initialState);
  const [region, setRegion] = useState<string>("");
  const { applicationId } = useParams<{ applicationId: string }>();

  const [createCloudAccount, { error }] = useMutation(CreateFCloudAccount, {
    onCompleted: (data) => {
      localStorage.setItem("cloud_account", data?.createFCloudAccount?.id);
      push(`${path}/2`);
    },
  });

  if (error) {
    return <SimpleError message={error} />;
  }

  const details = {
    name: values.aws_account_name,
    account_id: values.aws_account_id,
    org_id: user?.selectedOrg,
    role: `arn:aws:iam::${values.aws_account_id}:role/SecureStack-Audit-Role`,
    security_account_id: process.env.REACT_APP_F_DEFAULT_SECURITY_ID,
    region: region,
    applicationId: applicationId,
  };

  return (
    <div className="max-w-lg mx-auto overflow-auto">
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          createCloudAccount({
            variables: { details: details },
          });
        }}
      >
        <Input
          required={true}
          name="aws_account_id"
          value={values.aws_account_id}
          type="text"
          label="AWS Account ID"
          placeHolder="123456789"
          disabled={false}
          onChange={handleInputChange}
        />
        <Input
          required={true}
          name="aws_account_name"
          value={values.aws_account_name}
          type="text"
          label="AWS Account Name"
          placeHolder="NiftyBank"
          disabled={false}
          onChange={handleInputChange}
        />
        <TheOneCombobox
          key={region}
          defaultValue="Select AWS Region"
          items={regions}
          selected={region}
          isLoading={false}
          onSelection={(region: string) => {
            setRegion(region);
          }}
        />

        <Button amplitudeEventName={"using-new-aws-account"} />

        <Footer />
      </Form>
    </div>
  );
};
