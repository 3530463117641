import { gql } from "@apollo/client/core";

export const GetContainerAccounts = gql`
  query GetContainerAccounts {
    getAccountsByOrgForContainers {
      cloudAccounts {
        id
        is_active
        name
      }
      dockerHubUsername
    }
  }
`;
