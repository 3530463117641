import { useState } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useSnackbar } from "notistack";
import { GET_CLOUD_TEMPLATE } from "../../graphql/queries/GET_CLOUD_TEMPLATE";
import { SimpleError } from "../../errors/SimpleError";
import { DownloadIcon } from "@heroicons/react/solid";
import logGraphQLError from "../../functions/logGraphQLError";

const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.style.display = "none";
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.click();
  a.remove();
};

export function Template(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [template, setTemplate] = useState();

  const { loading, error, refetch } = useQuery(GET_CLOUD_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      cloud_account: localStorage.getItem("cloud_account"),
    },
    errorPolicy: "all",
    onError: logGraphQLError,
    onCompleted: (data) => {
      setTemplate(data?.cloudFormationTemplate?.template);
    },
  });

  if (loading) return <p className="dark:text-white">Loading...</p>;

  if (error) {
    return <SimpleError message={error} refetch={refetch} />;
  }

  return (
    <button
      onClick={() => {
        if (template) {
          downloadFile({
            data: JSON.stringify(template),
            fileName: "cloud-formation-template.json",
            fileType: "text/json",
          });
        }
        enqueueSnackbar("Downloaded!", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          persist: false,
          autoHideDuration: 1000,
        });
      }}
      className="mb-10 relative dark:text-gray-500 w-full mx-auto border-2 bg-grey-light hover:bg-grey text-grey-darkest h-28 font-bold p-4 rounded border-gray-400 dark:border-gray-400 border-dashed"
    >
      <div className="flex mx-auto items-center dark:text-gray-500 text-gray-400">
        <DownloadIcon className="w-5 h-5" />
        <p className="mx-auto">Click to download your template</p>
      </div>

    </button>
  );
};
