import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
} from "react";
import { joinClassNames } from "../../functions/utilities";

type InputFieldProps = {
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const InputField = forwardRef(
  function ({ placeholder, onChange, value, type, ...props }: InputFieldProps, ref): JSX.Element {
    return (
      <input
        value={value}
        type={type}
        className={joinClassNames(
          props.disabled && "cursor-not-allowed",
          "appearance-none relative block w-full px-3 py-2 border-gray-300 border placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-red-500 focus:red-indigo-500 focus:z-10 sm:text-sm dark:text-gray-300 rounded-lg transition text-base outline-1 bg-slate-50 dark:bg-dark-primary focus:bg-slate-50 dark:focus:bg-dark-primary outline-offset-2"
        )}
        placeholder={placeholder}
        onChange={onChange}
        ref={ref as LegacyRef<HTMLInputElement> | undefined}
        {...props}
      />
    );
  }
);
