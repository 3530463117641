import { gql } from "../../__generated__/gql";

export const STOP_ANALYSIS = gql(`
  mutation StopAnalyses(
    $orgId: String!
    $applicationId: String!
    $analysisType: String!
  ) {
    stopAnalyses(
      orgId: $orgId
      applicationId: $applicationId
      analysisType: $analysisType
    ) {
      scaStatus
      status
    }
  }
`);
