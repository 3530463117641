import { gql } from "@apollo/client/core";

export const GET_JIRA_PROJECTS = gql`
  query GetJiraProjects($orgId: String) {
    createJiraIssueMetaData(orgId: $orgId) {
      projects {
        self
        id
        key
        name
        issuetypes {
          self
          id
          description
          iconUrl
          name
          subtask
          fields {
            issuetype {
              required
              name
              key
              hasDefaultValue
              operations
            }
          }
        }
      }
    }
  }
`;
