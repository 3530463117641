import { gql } from "../../__generated__/gql";

export const DISPLAY_SBOM = gql(`
  query DisplaySBOM(
    $fSbomDisplayId: ID!
    $dataFormat: String!
    $orgId: String
    $type: String
  ) {
    fSBOMDisplay(id: $fSbomDisplayId, data_format: $dataFormat, orgId: $orgId,sbom_type: $type)
  }
`);
