import { gql } from "@apollo/client/core";

export const GITHUB_BRANCHES = gql`
  query Branches_GH(
    $page: Int!
    $githubReponame: String
    $organization: String
    $orgId: String
  ) {
    branches(
      page: $page
      githubReponame: $githubReponame
      organization: $organization
      orgId: $orgId
    ) {
      ... on GithubBranchResponse {
        pagelen
        size
        values {
          name
        }
        page
      }
    }
  }
`;

export const BITBUCKET_BRANCHES = gql`
  query Branches_BB(
    $page: Int!
    $bitbucketReponame: String
    $workspace: String
    $orgId: String
  ) {
    branches(
      orgId: $orgId
      page: $page
      bitbucketReponame: $bitbucketReponame
      workspace: $workspace
    ) {
      ... on BitbucketBranchResponse {
        pagelen
        size
        values {
          name
        }
        page
      }
    }
  }
`;

export const GITLAB_BRANCHES = gql`
  query Branches_GL(
    $page: Int!
    $group: String
    $orgId: String
    $gitlabProject: String
  ) {
    branches(
      orgId: $orgId
      page: $page
      group: $group
      gitlabProject: $gitlabProject
    ) {
      ... on GitlabBranchResponse {
        next
        page
        size
        pagelen
        values {
          name
        }
      }
    }
  }
`;

export const AZURE_DEVOPS_BRANCHES = gql`
query Branches_AZ($page: Int!, $azureRepo: String, $organizationAz: String, $orgId: String) {
  branches (page: $page, azureRepo: $azureRepo, organizationAZ: $organizationAz, orgId: $orgId) {
    ... on AzureBranchResponse {
      next
      page
      pagelen
      size
      values {
        aheadCount
        behindCount
        isBaseVersion
        name
        commit {
          comment
          commitId
          url
        }
      }
    }
  }
}
`;