import { ApolloError } from "@apollo/client/errors";

export function SimpleError({
  message,
  error,
  onClick,
  displayBtn,
  btnText,
}: {
  refetch?: any;
  displayBtn?: boolean;
  btnText?: string;
  onClick?: any;
  message?: ApolloError | undefined;
  error?: string;
}): JSX.Element {
  const { HubSpotConversations }: any = window;

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-transparent bg-white dark:bg-dark-main rounded-2xl">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex dark:text-gray-400">
              <span className="sr-only">{message?.name}</span>
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl dark:text-gray-400">
                {message?.name}
                {error}
              </h1>
              <p className="mt-2 text-base text-gray-500">{message?.message}</p>
              <div className="mt-6">
                {displayBtn && (
                  <button
                    onClick={onClick}
                    className="text-base cursor-pointer font-medium text-indigo-400 hover:text-indigo-500"
                  >
                    {btnText}
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <button
              onClick={() => HubSpotConversations.widget.open()}
              className="cursor-pointer text-sm font-medium text-gray-500 "
            >
              If this problem persists,{" "}
              <span className="font-bold text-indigo-400 hover:text-indigo-600">
                contact support
              </span>
            </button>
          </nav>
        </footer>
      </div>
    </>
  );
};
