import { CVEDetails, GHDetailsByGhId } from "../../__generated__/graphql";
import { notEmpty } from "../dashboard_2/dashboardDigest";

export type ParsedGHAdvisory = {
    credits: GHACredits[],
    cvss: GHACVSS,
    cveId: string | null;
    repositoryAdvisoryUrl: string | null;
    publishedAt: string | null;
    nvdPublishedAt: string | null;
    htmlUrl: string | null;
    severity: string | null;
    summary: string | null;
    withdrawnAt: string | null;
    githubReviewedAt: string | null;
    type: string | null;
    references: string[] | null;
    sourceCodeLocation: string | null;
    url: string | null;
    updatedAt: string | null;
    cwes: GHACWEName[];
    description: string | null;
    ghsaId: string | null;
    vulnerabilities: GHAVulnerabilities[],
};
export type GHAUser = {
    avatar_url: string | null,
    events_url: string | null;
    followers_url: string | null;
    following_url: string | null;
    gists_url: string | null;
    gravatar_id: string | null;
    html_url: string | null;
    id: number | null;
    login: string | null;
    node_id: string | null;
    organizations_url: string | null;
    received_events_url: string | null;
    repos_url: string | null;
    site_admin: boolean;
    starred_url: string | null;
    subscriptions_url: string | null;
    type: string | null;
    url: string | null;
};
export type GHACredits = {
    type: string;
    user: GHAUser;
};
export type GHACVSS = {
    score: number | null;
    vectorString: string | null;
};
export type GHACWEName = {
    cweId: string;
    cweName: string;
};
export type GHAPackage = {
    ecosystem: string | null;
    packageName: string | null;
};
export type GHAVulnerabilities = {
    firstPatchedVersion: string | null;
    package: GHAPackage;
    vulnerableFunctions: string[];
    vulnerableVersionRange: string | null;
};

export function parseGHAdvisoryData(data: GHDetailsByGhId): ParsedGHAdvisory {

    return {
        ghsaId: data.ghsa_id ?? null,
        description: data.description ?? null,
        cveId: data.cve_id ?? null,
        cvss: {
            score: data.cvss?.score ?? null,
            vectorString: data.cvss?.vector_string ?? null,
        },
        cwes: data.cwes?.flatMap(x => x?.cwe_id && x.name ? ({
            cweId: x.cwe_id,
            cweName: x.name,
        }) : []) ?? [],
        vulnerabilities: data.vulnerabilities?.map(x => ({
            firstPatchedVersion: x?.first_patched_version ?? null,
            package: { ecosystem: x?.package?.ecosystem ?? null, packageName: x?.package?.name ?? null },
            vulnerableFunctions: x?.vulnerable_functions?.filter(notEmpty) ?? [],
            vulnerableVersionRange: x?.vulnerable_version_range ?? null,
        })) ?? [],
        summary: data.summary ?? null,
        type: data.type ?? null,
        updatedAt: data.updated_at ?? null,
        url: data.url ?? null,
        sourceCodeLocation: data.source_code_location ?? null,
        githubReviewedAt: data.github_reviewed_at ?? null,
        htmlUrl: data.html_url ?? null,
        nvdPublishedAt: data.nvd_published_at ?? null,
        publishedAt: data.published_at ?? null,
        withdrawnAt: data.withdrawn_at ?? null,
        references: data.references?.filter(notEmpty) ?? [],
        severity: data.severity ?? "invalid",
        repositoryAdvisoryUrl: data.repository_advisory_url ?? null,
        credits: data.credits?.flatMap(x => x?.type && x.user ? ({
            user: {
                avatar_url: x.user.avatar_url ?? null,
                events_url: x.user.events_url ?? null,
                followers_url: x.user.followers_url ?? null,
                following_url: x.user.following_url ?? null,
                gists_url: x.user.gists_url ?? null,
                gravatar_id: x.user.gravatar_id ?? null,
                html_url: x.user.html_url ?? null,
                id: x.user.id ?? null,
                login: x.user.login ?? null,
                node_id: x.user.node_id ?? null,
                organizations_url: x.user.organizations_url ?? null,
                received_events_url: x.user.received_events_url ?? null,
                repos_url: x.user.repos_url ?? null,
                site_admin: x.user.site_admin ?? false,
                starred_url: x.user.starred_url ?? null,
                subscriptions_url: x.user.subscriptions_url ?? null,
                type: x.user.type ?? null,
                url: x.user.url ?? null,
            },
            type: x.type,
        }) : []) ?? [],
    };
}

export type CVEMetrics = {
    exploitScore: number,
    impactScore: number,
    source: string,
    type: string,
    cvssData: {
        attackComplexity: string | null,
        attackVector: string | null,
        availabilityImpact: string | null,
        baseScore: number | null,
        baseSeverity: string | null,
        confidentialityImpact: string | null,
        integrityImpact: string | null,
        privilegesRequired: string | null,
        scope: string | null,
        userInteraction: string | null,
        vectorString: string | null,
        version: string | null,
    }
};
export type CVEReferences = {
    url: string,
    source: string,
    tags: string[],
};
export type CVEWeaknesses = {
    type: string,
    source: string,
    description: {
        language: string;
        value: string;
    }[];
};

export type CVEDescriptions = {
    language: string,
    value: string
};

export type CVEConfigurationsCPEMatch = {
    vulnerable: boolean,
    criteria: string,
    matchCriteriaId: string,
    versionEndExcluding: string,
};

export type CVEConfigurations = {
    negate: boolean,
    operator: string,
    cpeMatch: CVEConfigurationsCPEMatch[]
};

export type ParsedCVEData = {
    criticality: string | null;
    cveName: string | null;
    cvss2: string | null;
    cvss3: string | null;
    description: string | null;
    cveId: string | null;
    cvePublished: string | null;
    cveSourceIdentifier: string | null;
    cveVulnStatus: string | null;
    cveLastModified: string | null;

    cveConfigurations: CVEConfigurations[] | null;
    cveDescriptions: CVEDescriptions[] | null;
    cveMetrics: CVEMetrics[] | null;
    cveReferences: CVEReferences[] | null;
    cveWeaknesses: CVEWeaknesses[] | null;
};

export function parseCVEData(data: CVEDetails): ParsedCVEData {
    return {
        criticality: data?.criticality ?? null,
        cveName: data?.cve_name ?? null,
        cvss2: data?.cvss2 ?? null,
        cvss3: data?.cvss3 ?? null,
        description: data?.description ?? null,
        cveId: data?.extra_details?.cve?.id ?? null,
        cvePublished: data?.extra_details?.cve?.published ?? null,
        cveSourceIdentifier: data?.extra_details?.cve?.sourceIdentifier ?? null,
        cveVulnStatus: data?.extra_details?.cve?.vulnStatus ?? null,
        cveLastModified: data?.extra_details?.cve?.lastModified ?? null,
        cveConfigurations: data?.extra_details?.cve?.configurations?.flatMap(x => x?.nodes)?.map(x => {
            if (x?.negate != null && x?.operator != null && x?.cpeMatch != null) {
                return {
                    negate: x.negate,
                    operator: x.operator,
                    cpeMatch: x.cpeMatch.flatMap(y => y).map(y => {
                        if (y?.criteria != null && y?.matchCriteriaId != null && y?.versionEndExcluding != null && y?.vulnerable != null) {
                            return {
                                vulnerable: y.vulnerable,
                                criteria: y.criteria,
                                matchCriteriaId: y.matchCriteriaId,
                                versionEndExcluding: y.versionEndExcluding,
                            }
                        }
                        return null;
                    }).filter(notEmpty),
                }
            }
            return null;
        })?.filter(notEmpty) ?? null,
        cveDescriptions: data?.extra_details?.cve?.descriptions?.map(x => {
            if (x?.lang && x.value) {
                return {
                    language: x.lang,
                    value: x.value
                };
            }
            return null;
        }).filter(notEmpty) ?? null,
        cveMetrics: data?.extra_details?.cve?.metrics?.cvssMetricV31?.flatMap(x => {
            if (x?.cvssData != null && x.exploitabilityScore != null && x.impactScore != null && x.source != null && x.type != null) {
                return {
                    exploitScore: x.exploitabilityScore,
                    impactScore: x.impactScore,
                    source: x.source,
                    type: x.type,
                    cvssData: {
                        attackComplexity: x?.cvssData.attackComplexity ?? null,
                        attackVector: x?.cvssData.attackVector ?? null,
                        availabilityImpact: x?.cvssData.availabilityImpact ?? null,
                        baseScore: x?.cvssData.baseScore ?? null,
                        baseSeverity: x?.cvssData.baseSeverity ?? null,
                        confidentialityImpact: x?.cvssData.confidentialityImpact ?? null,
                        integrityImpact: x?.cvssData.integrityImpact ?? null,
                        privilegesRequired: x?.cvssData.privilegesRequired ?? null,
                        scope: x?.cvssData.scope ?? null,
                        userInteraction: x?.cvssData.userInteraction ?? null,
                        vectorString: x?.cvssData.vectorString ?? null,
                        version: x?.cvssData.version ?? null,
                    },
                };
            } else {
                return [];
            }
        }).filter(notEmpty) ?? null,
        cveReferences: data?.extra_details?.cve?.references?.map(x => x?.url != null && x.source != null ? ({
            url: x.url,
            source: x.source,
            tags: x?.tags?.filter(notEmpty) ?? [],
        }) : null).filter(notEmpty) ?? null,
        cveWeaknesses: data?.extra_details?.cve?.weaknesses?.map(x => x?.source != null && x?.description != null && x.type != null ? ({
            description: x.description.map(y => y?.lang != null && y?.value != null ? ({ language: y?.lang, value: y?.value }) : null).filter(notEmpty) ?? null,
            type: x.type,
            source: x.source,
        }) : null).filter(notEmpty) ?? null,
    };
}
