import { joinClassNames } from "../../../../functions/utilities";

interface InputProps {
  value: string;
  type: string;
  name: string;
  placeHolder: string;
  disabled: boolean;
  required: boolean;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Input({
  value,
  type,
  name,
  placeHolder,
  disabled,
  required,
  label,
  onChange,
}: InputProps): JSX.Element {
  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor="email"
          className="mb-1 block text-sm font-medium text-gray-800 dark:text-gray-400"
        >
          {label}
        </label>
        {!required && (
          <span className="text-xs text-gray-500" id="email-optional">
            Optional
          </span>
        )}
      </div>
      <input
        disabled={disabled}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        id={name}
        className={joinClassNames(
          disabled && "cursor-not-allowed",
          "dark:bg-dark-primary dark:text-gray-300 block w-full shadow-sm py-2 px-4 placeholder-gray-500 focus:ring-red-500 focus:border-red-500 border rounded-md"
        )}
        placeholder={placeHolder}
      />
    </div>
  );
};
