import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/buttons/Button";
import { SetStateAction, useCallback, useState, Suspense, lazy } from "react";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { supabaseClient } from "../../auth/supabase";
import { useSnackbar } from "notistack";
import { InputField } from "../../components/forms/InputField";
import { useUser } from "../../functions/hooks/useUser";
import { svgImport } from "../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


type FormData = {
  password: string;
  confirmedPassword: string;
};

export const ChangePassword = ({
  onPasswordChange,
}: {
  onPasswordChange: (value: SetStateAction<boolean>) => void;
}) => {
  const user = useUser();
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit
  } = useForm<FormData>();

  const resetPassword: SubmitHandler<FormData> = useCallback(
    (formData) => {
      setLoading(true);

      if (formData.confirmedPassword !== formData.password) {
        setLoading(false);
        setError({ message: "Passwords don't match" });
        return;
      }

      if (!user?.token) {
        console.log("Error user has no token");
        return;
      }

      supabaseClient.auth
        .updateUser({ password: formData.password })
        .then(({ error, data }) => {
          try {
            if (error || !data) {
              setLoading(false);
              setError(error);
              return;
            }

            user.status = "SIGNED_IN";
            enqueueSnackbar("Successfully changed password", {
              autoHideDuration: 2000,
              variant: "success",
              persist: false,
            });
            setLoading(false);
            //Close change password modal
            typeof onPasswordChange === "function" && onPasswordChange(false);
            window.location.replace("/");
          } catch (e) {
            window.location.replace("/");
          }
        });
    },
    [setLoading, setError, enqueueSnackbar, user, onPasswordChange]
  );

  return (
    <div className="dark:bg-dark-main py-10 flex flex-col">
      <Suspense fallback={<></>}>
        <SecureStackLogo className="h-16 w-auto mx-auto" />
      </Suspense>
      <div className="bg-gray-300 mt-12 max-w-2xl dark:bg-dark-main dark:text-gray-300 rounded-lg md:p-10 mx-auto">
        <h2 className="dark:text-gray-400 text-gray-700 text-xl">
          Create new password
        </h2>
        <span className="py-2 text-sm text-gray-500">
          Your new password must be different from previously used passwords.
        </span>
        <div className="border-b dark:border-b-gray-400 pt-1" />
        <div className="py-2">
          <ErrorAlert title={error.message} isShowing={error} />
        </div>
        <form className="space-y-4 mt-2">
          <h2 className="dark:text-gray-300 text-gray-700 pb-1">Password</h2>
          <InputField
            autoComplete="current-password"
            id="password"
            type="password"
            autoFocus={true}
            required
            {...register("password")}
          />
          <h2 className="dark:text-gray-300 text-gray-700 pb-1">
            Confirm Password
          </h2>
          <InputField
            autoComplete="confirm-password"
            id="confirmedPassword"
            type="password"
            required
            {...register("confirmedPassword")}
          />
          <br />
          <Button
            isLoading={loading}
            primary
            className="w-full"
            label="Continue"
            type="submit"
            onClick={handleSubmit(resetPassword)}
          />
        </form>
      </div>
    </div>
  );
};
