import { NoAssetData } from "../empty-states/NoAssetData";

function GenerateRows({
  rows,
  rowNames,
}: {
  rows: any;
  rowNames: any;
}): JSX.Element {
  return (
    <>
      {rows.map((row: any, idx: number) => {
        let cells = Object?.keys(rowNames).map(
          (keyName: string) => row[keyName],
        );
        return <tr key={idx}>{cells}</tr>;
      })}
    </>
  );
}

export default function Table({
  rowNames,
  rows,
  isLoading,
  error,
}: {
  rowNames: { [k: string]: string | JSX.Element | boolean };
  rows: any;
  isLoading?: boolean;
  error?: boolean | undefined;
}) {
  if (!rows) return <NoAssetData />;
  if (isLoading) return null;

  return (
    <div className="xs:mx-0 overflow-x-auto overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-0 md:mx-0 rounded-xl">
      <table className="min-w-full min-h-full divide-y divide-gray-300 dark:divide-gray-700">
        <thead className="dark:bg-dark-main bg-gray-50">
          <tr>
            {Object.values(rowNames).map((row, idx) => (
              <th
                key={idx}
                scope="col"
                className="pl-2 py-2 text-left text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider"
              >
                {row}
              </th>
            ))}
          </tr>
        </thead>
        {!error && (
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-dark-main">
            {rows?.length === 0 ? (
              <tr
                key="table-no-data"
                className="px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                <td className="px-3 py-3">No data found</td>
                {Object.values(rowNames)
                  .slice(1)
                  .map((x, ind) => (
                    <td key={ind}></td>
                  ))}
              </tr>
            ) : (
              <GenerateRows rows={rows} rowNames={rowNames} />
            )}
          </tbody>
        )}
      </table>
    </div>
  );
}
