import { useCallback, useRef } from "react";

export const useLastElementRef = <T extends HTMLElement>(
  incrementCurrentPage: CallableFunction,
  loading: boolean,
  hasMore: boolean
) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastBranchElementRef = useCallback<(node: T) => void>(
    (node) => {
      if (loading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          incrementCurrentPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, incrementCurrentPage]
  );

  return lastBranchElementRef;
};
