import { Link, useHistory, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { joinClassNames } from "../../../functions/utilities";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useUser } from "../../../functions/hooks/useUser";
import { ListFAWSAccounts } from "../../../graphql/queries/ListFAWSCloudAccounts";
import { Button } from "../../buttons/Button";
import logGraphQLError from "../../../functions/logGraphQLError";

type WizardProps = {
  onActivated: () => void;
  path: string;
};

export function WizardStep4({ onActivated, path }: WizardProps): JSX.Element {
  onActivated();
  const { push } = useHistory();
  const location = useLocation();
  const user = useUser();

  const { loading, data } = useQuery(ListFAWSAccounts, {
    variables: {
      orgId: user?.selectedOrg,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: logGraphQLError,
    pollInterval: 5000,
  });

  const isNotificationVerified = () => {
    if (data && !loading) {
      return data.dbfCloudAccounts?.find(acc => acc?.id === location.state.cloudAccountId)?.is_active === 3;
    }
    return false;
  };

  return (
    <main className="flex-1">
      <div className="bg-white dark:bg-dark-main shadow-xl rounded-2xl sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden px-4 py-8 sm:px-10 mx-auto">
        <button
          type="button"
          className="py-2 px-2 inline-flex items-center text-sm leading-4 font-medium rounded-md text-black dark:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ArrowNarrowLeftIcon
            onClick={() => push(`${path}/2`)}
            className="hover:-translate-x-1 cursor-pointer h-8 w-8 mr-5 text-md font-medium text-black dark:text-gray-400"
          />
          back
        </button>
        <div className="bg-transparent min-h-full px-4 py-16 sm:px-6 sm:py-10 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex aws-status-selector">
              <p className="text-lg font-extrabold text-indigo-600 sm:text-lg px-6 mt-4">
                <div
                  className={joinClassNames(
                    isNotificationVerified()
                      ? "absolute h-4 w-4 animate-ping bg-green-700 rounded-full opacity-75"
                      : "absolute h-4 w-4 animate-ping bg-red-700 rounded-full opacity-75"
                  )}
                ></div>

                <div
                  className={joinClassNames(
                    "absolute h-4 w-4 rounded-full",
                    isNotificationVerified() ? "bg-green-700" : "bg-red-700"
                  )}
                ></div>
              </p>
              <div className="sm:ml-6 ">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-xl font-extrabold text-gray-900 tracking-tight sm:text-xl dark:text-gray-300">
                    Status
                  </h1>

                  <p className="mt-1 text-base text-gray-500">
                    {isNotificationVerified()
                      ? "Successfully verified cloud account"
                      : "Waiting for user to add cloud formation template."}
                  </p>
                </div>
                <div className="mt-4">
                  {isNotificationVerified() && <Transition
                    show={isNotificationVerified()}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Link to="/integrations/aws/view"><Button className="text-center w-32" primary label="Finish" /></Link>
                    {/* <div className="flex items-center mt-4 text-base text-gray-500">
                      <div className="loader" />
                      <h1 className="px-3">Redirecting...</h1>
                    </div> */}
                  </Transition>}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </main>
  );
};
