import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { Loader } from "../../../../../components/loading/Loader";
import { Button } from "../../../../../components/buttons/Button";

export function AutoFileDetection({
  files,
  viewExplorer,
  isLoading,
}: {
  files: string[];
  viewExplorer: () => void;
  isLoading: boolean;
}): JSX.Element {
  return (
    <div
      id="alert-additional-content-5"
      className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
      role="alert"
    >
      <div className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-blue-700 dark:text-blue-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Info</span>
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
          {isLoading ? "Detecting files..." : "Detected files"}
        </h3>
      </div>
      <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
        {isLoading ? (
          <Loader w={5} h={5} />
        ) : (!files || files?.length === 0) ? (
          <p className="ml-4 ">No files detected</p>
        ) : (
          files.map((file) => (
            <h1 key={file} className="flex mt-1.5 items-center">
              <BsFillFileEarmarkTextFill className="w-4 h-4 mr-1 dark:text-gray-500 text-gray-600" />
              {file}
            </h1>
          ))
        )}
      </div>
      {!isLoading && (
        <div className="flex">
          <Button label="Not the correct files? Locate files" className="font-medium text-xs bg-gray-300" data-dismiss-target="#alert-additional-content-5" aria-label="Browse" onClick={viewExplorer} />
        </div>
      )}
    </div>
  );
};
