import { gql } from "../../__generated__/gql";

export const GET_BITBUCKET_REPO_STRUCTURE = gql(`
  query FolderStructure_BB(
    $page: String
    $path: String
    $workspace: String
    $bitbucketReponame: String
    $bitbucketBranchname: String
    $orgId: String
  ) {
    folderStructure(
      path: $path
      page: $page
      workspace: $workspace
      bitbucketReponame: $bitbucketReponame
      bitbucketBranchname: $bitbucketBranchname
      orgId: $orgId
    ) {
      ... on BitbucketFolderStructureResponse {
        pagelen
        size
        values {
          path
          type
          size
          escapse_path
          attributes
        }
        page
        next
      }
    }
  }
`);

export const GET_AZURE_DEVOPS_REPO_STRUCTURE = gql(`
query AzureFolderStructureResponse($organizationAz: String, $azureRepo: String, $azureBranch: String, $orgId: String, $page: String, $path: String) {
  folderStructure(organizationAZ: $organizationAz, azureRepo: $azureRepo, azureBranch: $azureBranch, orgId: $orgId, page: $page, path: $path) {
    ... on AzureFolderStructureResponse {
      pagelen
      size
      values {
        objectId
        gitObjectType
        commitId
        path
        url
      }
      page
      next
    }
  }
}
`);

export const GET_GITHUB_REPO_STRUCTURE = gql(`
query FolderStructure_GH(
  $organization: String
  $githubReponame: String
  $githubBranchname: String
  $path: String
  $orgId: String
) {
  folderStructure(
    organization: $organization
    githubReponame: $githubReponame
    githubBranchname: $githubBranchname
    path: $path
    orgId: $orgId
  ) {
    ... on GitHubFolderStructureResponse {
      pagelen
      size
      values {
        path
        mode
        type
        sha
        size
        url
      }
      page
      next
    }
  }
}
`);

export const GET_GITLAB_REPO_STRUCTURE = gql(`
query FolderStructure_GL(
  $page: String
  $path: String
  $group: String
  $gitlabProject: String
  $gitlabBranchname: String
  $orgId: String
) {
  folderStructure(
    path: $path
    page: $page
    group: $group
    gitlabProject: $gitlabProject
    gitlabBranchname: $gitlabBranchname
    orgId: $orgId
  ) {
    ... on GitlabFolderStructureResponse {
      pagelen
      size
      values {
        path
        name
        type
      }
      page
      next
    }
  }
}
`);