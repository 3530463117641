import { Suspense, lazy, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineCheck } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { supabaseClient } from "../../auth/supabase";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { Button } from "../../components/buttons/Button";
import { InputField } from "../../components/forms/InputField";
import { svgImport } from "../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


type FormData = {
  email: string;
};

export function ForgotPassword(): JSX.Element {
  const { push } = useHistory();
  const [error, setError] = useState<any>(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm<FormData>();

  const resetPassword: SubmitHandler<FormData> = async (formData) => {
    setEmailSent(false);
    setError(false);
    setLoading(true);

    const { error } = await supabaseClient.auth.resetPasswordForEmail(
      formData.email,
      {
        redirectTo: window.location.origin,
      }
    );

    if (error) {
      setError(error);
      setLoading(false);
      return;
    }

    setEmailSent(true);
    setLoading(false);
  };

  return (
    <>
      <Suspense fallback={<></>}>
        <SecureStackLogo className="h-16 w-auto mx-auto" />
      </Suspense>
      <div className="bg-gray-300 mt-12 dark:bg-dark-main dark:text-gray-300 rounded-lg overflow-hidden p-10  mx-auto relative">
        <h2 className="text-gray-400 text-xl">Recover Password</h2>
        <span className="py-2 text-sm text-gray-500">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </span>
        <div className="border-b dark:border-b-gray-400 pt-1" />
        <div className="py-2">
          <ErrorAlert title={error.message} isShowing={error} />
        </div>
        <form className="space-y-4 mt-10 ">
          <h2 className="text-gray-900 dark:text-gray-300 pb-1">
            Work email address
          </h2>
          <InputField
            autoFocus={true}
            placeholder="person@company.co"
            type="email"
            required
            {...register("email")}
            name="email"
          />
          {emailSent ? (
            <>
              <p className="text-green-500 ">
                <AiOutlineCheck className="inline-flex mr-2 " />
                Email successfully sent
              </p>
              <Button
                isLoading={loading}
                primary
                label="Send Again"
                type="submit"
                onClick={handleSubmit(resetPassword)}
              />
            </>
          ) : (
            <Button
              isLoading={loading}
              primary
              className="w-full"
              label="Email me a recovery link"
              type="submit"
              onClick={handleSubmit(resetPassword)}
            />
          )}

          <Button
            className="w-full"
            outlined
            label="Login"
            onClick={() => push("/auth/login")}
          />
        </form>
      </div>
    </>
  );
};
