import { uniq } from "lodash";
import {
  Badlinks,
  Domains,
  GetDashboardResultsQuery,
  NewChanges,
  Changes,
  NewScasData,
  NucleiResponse,
  Otherdomains,
  SSCResponse,
  Techstacks,
  cloudAnalysisData,
  cloudAnalysisResource,
  sscCollaborator,
  sscComponent,
  sscPackage,
  SourceCodeAnalysis,
  TrivyResponse,
} from "../../__generated__/graphql";
import {
  bundleCloudIssues,
  createExposureArr,
  parseOneCloudIssue,
  parseOneConatinerIssue,
  parseOneCodeIssue,
  parseOneExposureIssue,
  parseOneNineProblem,
  parseOneSecretsIssue,
  upgradeLegacyScaObjects,
} from "../../functions/hooks/useApplicationIssues";
import { DashboardData, LastScanned } from "./dashboard2";
import { Tech } from "../../partials/application/ApplicationComposition";
import appSettingsDigest from "./appSettingsDigest";

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function dateIs24HPast(input: Date): boolean {
  const today = new Date();
  if (!today.getTime || !input.getTime) {
    return false;
  }
  return 86400000 < today.getTime() - input.getTime();
}
export function dateIs48HPast(input: Date): boolean {
  const today = new Date();
  if (!today.getTime || !input.getTime) {
    return false;
  }
  return 2 * 86400000 < today.getTime() - input.getTime();
}

export function cloudDateRaw(date: any) {
  const builtDate = new Date(date);
  return builtDate.toString() !== "Invalid Date"
    ? {
        date: builtDate,
        valid: true,
        olderThan24H: dateIs24HPast(builtDate),
        olderThan48H: dateIs48HPast(builtDate),
      }
    : {
        date: new Date(),
        valid: false,
        olderThan24H: false,
        olderThan48H: false,
      };
}

export function getLatestCloudDate(
  cloud: cloudAnalysisData | null | undefined,
): LastScanned {
  const foundCloudDate = cloud?.data?.time_created ?? NaN;
  return cloudDateRaw(foundCloudDate);
}

export function getLatestContainersDate(
  date: Date | null | undefined,
): LastScanned {
  const foundContainersDate = date ?? NaN;
  return cloudDateRaw(foundContainersDate);
}

export function dateToLastScanned(lastScannedDate?: Date): LastScanned {
  if (lastScannedDate) {
    if (lastScannedDate.toString() !== "Invalid Date") {
      return {
        date: lastScannedDate,
        valid: true,
        olderThan24H: dateIs24HPast(lastScannedDate),
        olderThan48H: dateIs48HPast(lastScannedDate),
      };
    }
  }
  return {
    valid: false,
    date: new Date(),
    olderThan24H: false,
    olderThan48H: false,
  };
}

export function exposureDate(lastScannedDate: Date): LastScanned {
  let lastScannedExposure: LastScanned;
  if (lastScannedDate) {
    const d = new Date(lastScannedDate);
    if (d && d.toString() !== "Invalid Date") {
      lastScannedExposure = {
        date: d,
        valid: true,
        olderThan24H: dateIs24HPast(d),
        olderThan48H: dateIs48HPast(d),
      };
    } else {
      lastScannedExposure = {
        date: new Date(),
        valid: false,
        olderThan24H: false,
        olderThan48H: false,
      };
    }
  } else {
    lastScannedExposure = {
      date: new Date(),
      valid: false,
      olderThan24H: false,
      olderThan48H: false,
    };
  }
  return lastScannedExposure;
}

export type ParsedSbomPackage = {
  licenses: { expression: string }[];
  name: string;
  purl: string | null;
  type: string | null;
  version: string | null;
};

export type ParsedSSC = {
  cloudResources: Tech[];
  collaborators: string[];
  continuousDeployment: Tech[];
  continuousIntegration: Tech[];
  localRequirements: Tech[];
  runtime: Tech[];
  services: Tech[];
  sourceCode: Tech[];
  sbomPackages: ParsedSbomPackage[];
  sbomDate: LastScanned;
  untagged: Tech[];
};

function blastMaybes(components: sscComponent[]): Tech[] {
  return components
    .map((x) =>
      x.component && (x.version || x.version === null)
        ? {
            component: x.component,
            version: x.version,
            sameComponentDifferentVersionBundle: [],
          }
        : [],
    )
    .flat();
}

export function parseSSC(rawSsc?: SSCResponse): ParsedSSC {
  return {
    sbomDate: dateToLastScanned(new Date(rawSsc?.packages?.date)),
    collaborators:
      rawSsc?.collaborators
        ?.flatMap((x) => x?.collaborators)
        ?.concat(
          rawSsc?.collaborators?.filter(notEmpty)?.flatMap((x) => x?.members) ??
            [],
        )
        ?.filter(notEmpty) ?? [],
    sbomPackages:
      rawSsc?.packages?.packages?.filter(notEmpty).map((x) => {
        return {
          licenses:
            x.licenses
              ?.filter(notEmpty)
              ?.flatMap((x) =>
                x == null || x?.expression == null
                  ? []
                  : { expression: x.expression },
              ) ?? [],
          name: x.name ?? "Unknown Package",
          purl: x.purl ?? null,
          type: x.type ?? null,
          version: x.version ?? null,
        };
      }) ?? [],
    cloudResources: blastMaybes(
      rawSsc?.cloud_resources?.filter(notEmpty) ?? [],
    ),
    continuousDeployment: blastMaybes(
      rawSsc?.continuous_deployment?.filter(notEmpty) ?? [],
    ),
    continuousIntegration: blastMaybes(
      rawSsc?.continuous_integration?.filter(notEmpty) ?? [],
    ),
    localRequirements: blastMaybes(
      rawSsc?.local_requirements?.filter(notEmpty) ?? [],
    ),
    runtime: blastMaybes(rawSsc?.runtime?.filter(notEmpty) ?? []),
    services: blastMaybes(rawSsc?.services?.filter(notEmpty) ?? []),
    sourceCode: blastMaybes(rawSsc?.source_code?.filter(notEmpty) ?? []),
    untagged: blastMaybes(rawSsc?.untitled?.filter(notEmpty) ?? []),
  };
}

export default function dashboardDigest(
  data: GetDashboardResultsQuery,
): DashboardData {
  const appSettings = appSettingsDigest(data);

  const isExposureConfigured: boolean =
    appSettings.exposure.isConfigured ?? data?.dashboard?.exposure != null;
  const isCodeConfigured: boolean =
    appSettings.code.isConfigured ??
    (data?.dashboard?.code != null || data?.dashboard?.betaSCA != null);
  const codeRan: boolean =
    isCodeConfigured && (data?.dashboard?.betaSCA?.length ?? -1) >= 0;
  const isCloudConfigured: boolean =
    appSettings.cloud.isConfigured ??
    (data?.dashboard?.cloud?.data?.resources?.length ?? 0) > 0;

  const isContainersConfigured: boolean =
    appSettings.containers.isConfigured ?? data?.dashboard?.container !== null;

  const isSecretsConfigured: boolean =
    (data?.dashboard?.secrets?.data?.length ?? 0) > 0;
  const openPorts = {
    "22": data?.dashboard?.exposure?.data?.port22 === 1,
    "443": data?.dashboard?.exposure?.data?.port443 === 1,
    "3306": data?.dashboard?.exposure?.data?.port3306 === 1,
    "3389": data?.dashboard?.exposure?.data?.port3389 === 1,
  };
  const exposureName: string =
    data?.dashboard?.exposure?.data?.name ?? "Unavailable";
  const exposureMurl: string =
    data?.dashboard?.exposure?.data?.murl ?? "Unavailable";
  const exposureScanType: string =
    data?.dashboard?.exposure?.data?.scantype ?? "Unavailable";
  const exposureNginxVersion: string =
    data?.dashboard?.exposure?.data?.nginxversion ?? "Unavailable";
  const exposureStageExists: boolean =
    data?.dashboard?.exposure?.data?.stageexists ?? false;
  const exposureTlsVersion: string =
    data?.dashboard?.exposure?.data?.tlsversion ?? "Unavailable";
  const exposureSovereignIssue: boolean =
    data?.dashboard?.exposure?.data?.sovereignissue === "1" ?? false;
  const exposureSslIssue: boolean =
    data?.dashboard?.exposure?.data?.sslissue === 1 ?? false;
  const exposureNoCdnSovereignIssue: boolean =
    data?.dashboard?.exposure?.data?.nocdnsovereignissue === 1 ?? false;
  const exposureNumUnencryptedPages: number =
    data?.dashboard?.exposure?.data?.numberunencryptedpages ?? 0;
  const exposureNumRedirects: number =
    (Number.isNaN(Number(data?.dashboard?.exposure?.data?.numberredirects))
      ? null
      : Number(data?.dashboard?.exposure?.data?.numberredirects)) ?? 0;
  const exposureNotSecure: boolean =
    data?.dashboard?.exposure?.data?.notsecure === "1" ?? false;
  const exposureLatitude: string =
    data?.dashboard?.exposure?.data?.latitude ?? "Unavailable";
  const exposureLongitude: string =
    data?.dashboard?.exposure?.data?.longitude ?? "Unavailable";
  const exposureLoadBalanced: boolean =
    data?.dashboard?.exposure?.data?.loadbalanced === 1 ?? false;
  const exposureMultiEnvExists: boolean =
    data?.dashboard?.exposure?.data?.multienvexists ?? false;
  const exposureWaf: boolean =
    data?.dashboard?.exposure?.data?.waf === 1 ?? false;
  const exposureWafLocation: string =
    data?.dashboard?.exposure?.data?.waflocation ?? "Unavailable";
  const exposureRedirectDestination: string =
    data?.dashboard?.exposure?.data?.redirectdest ?? "Unavailable";
  const exposureReferrerPolicy: boolean =
    data?.dashboard?.exposure?.data?.referrerpolicy === 1 ?? false;
  const exposureRootUrl: string =
    data?.dashboard?.exposure?.data?.rooturl ?? "Unavailable";
  const exposureApacheVersion: string =
    data?.dashboard?.exposure?.data?.apacheversion ?? "Unavailable";
  const exposureApplicationExists: boolean =
    data?.dashboard?.exposure?.data?.applicationexists === 1 ?? false;
  const exposureAwsAlb: boolean =
    data?.dashboard?.exposure?.data?.awsalb === 1 ?? false;
  const exposureCdn: boolean =
    data?.dashboard?.exposure?.data?.cdn === 1 ?? false;
  const exposureCsp: boolean =
    data?.dashboard?.exposure?.data?.csp === 1 ?? false;
  const exposureCspData: string =
    data?.dashboard?.exposure?.data?.cspdatafull ?? "Unavailable";
  const exposureCdnBypass: boolean =
    data?.dashboard?.exposure?.data?.cdnbypass === 1 ?? false;
  const exposureCdnVer: string =
    data?.dashboard?.exposure?.data?.cdnver ?? "Unavailable";
  const exposureCloudfrontCdn: boolean =
    data?.dashboard?.exposure?.data?.cloudfrontcdn === 1 ?? false;
  const exposureCloudVer: string =
    data?.dashboard?.exposure?.data?.cloudver ?? "Unavailable";
  const exposureCmsVer: string =
    data?.dashboard?.exposure?.data?.cmsver ?? "Unavailable";
  const exposureDevExists: boolean =
    data?.dashboard?.exposure?.data?.devexists ?? false;
  const exposureElapsedTime: string =
    data?.dashboard?.exposure?.data?.elapsedtime ?? "Unavailable";
  const exposureEmail: string =
    data?.dashboard?.exposure?.data?.email ?? "Unavailable";
  const exposureHttpsRedirect: boolean =
    data?.dashboard?.exposure?.data?.https_redirect === 1 ?? false;
  const exposureHsts: boolean =
    data?.dashboard?.exposure?.data?.hsts === 1 ?? false;
  const exposureGitConfigExists: boolean =
    data?.dashboard?.exposure?.data?.gitconfigexists ?? false;
  const exposureEnvFileExists: boolean =
    data?.dashboard?.exposure?.data?.envfileexists ?? false;
  const exposureSecurityTxtExists: boolean =
    data?.dashboard?.exposure?.data?.securitytxtexists ?? false;
  const exposureWordpress: boolean =
    data?.dashboard?.exposure?.data?.wordpress === "1" ?? false;
  const exposureWordpressVer: string =
    data?.dashboard?.exposure?.data?.wordpressver ?? "Unavailable";
  const exposureXCache: boolean =
    data?.dashboard?.exposure?.data?.xcache === 1 ?? false;
  const exposureXCacheData: string =
    data?.dashboard?.exposure?.data?.xcachedata ?? "Unavailable";
  const exposureAmazon: boolean =
    data?.dashboard?.exposure?.data?.amazon === "1" ?? false;
  const exposureAzure: boolean =
    data?.dashboard?.exposure?.data?.azure === "1" ?? false;
  const exposureCountryHost: string =
    data?.dashboard?.exposure?.data?.countryhost ?? "Unavailable";
  const exposureIsp: string =
    data?.dashboard?.exposure?.data?.isp ?? "Unavailable";
  //const exposureLinux: boolean = data.dashboard?.exposure.data.linux;

  const exposureProblems = [
    data?.dashboard?.exposure?.data?.Problem?.problem1 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem2 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem3 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem4 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem5 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem6 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem7 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem8 ?? "",
    data?.dashboard?.exposure?.data?.Problem?.problem9 ?? "",
  ].filter((x) => x !== "");

  const exposureIssues: NucleiResponse[] =
    data?.dashboard?.exposure?.data?.Issues?.filter<NucleiResponse>(notEmpty) ??
    [];
  const oldCodeResults: SourceCodeAnalysis[] =
    data?.dashboard?.code?.data?.filter<SourceCodeAnalysis>(
      (value): value is SourceCodeAnalysis => {
        return value !== null && value !== undefined;
      },
    ) ?? [];
  const codeResults: NewScasData[] =
    data?.dashboard?.betaSCA?.filter<NewScasData>(notEmpty) ?? [];

  // const containerResults: TrivyResponse = data?.dashboard?.container ?? [];

  const cloudResults: cloudAnalysisResource[] =
    data?.dashboard?.cloud?.data?.resources?.filter<cloudAnalysisResource>(
      notEmpty,
    ) ?? [];
  const bundledCloudResults = bundleCloudIssues(
    cloudResults.map((cloud) => parseOneCloudIssue(cloud)),
  );

  const bundledContainersResults = parseOneConatinerIssue(
    data?.dashboard?.container?.trivy_finding,
  );

  /* const processSecrets = (score: criticality | null | undefined): SecretsResult => ({
        critical: score?.critical ?? 0,
        high: score?.high ?? 0,
        medium: score?.medium ?? 0,
        low: score?.low ?? 0
    })
    const secretsResult: SecretsResult = processSecrets(data?.dashboard?.secrets?.score); */

  const numberOfExposureProblems: number =
    exposureProblems.length + exposureIssues.length;
  const numberOfCodeProblems: number = uniq(
    codeResults.map((codeResult) => codeResult.packageName).filter(notEmpty),
  ).length;
  const numberOfLegacyCodeProblems: number = oldCodeResults.length;
  const numberOfCloudProblems: number = bundledCloudResults.length;
  const numberOfContainersProblems: number = bundledContainersResults.length;
  const numberOfSecretsProblems: number =
    data?.dashboard?.secrets?.data?.length ?? 0;

  const secretsDate = data?.dashboard?.secrets?.data?.find(
    (x) => x?.scannedtime,
  )?.scannedtime;
  const sd: Date = new Date(secretsDate);
  const lastScannedSecrets: LastScanned = secretsDate
    ? {
        date: sd,
        valid: true,
        olderThan24H: dateIs24HPast(sd),
        olderThan48H: dateIs48HPast(sd),
      }
    : {
        date: new Date(),
        valid: false,
        olderThan24H: false,
        olderThan48H: false,
      };

  const lastScannedCloud: LastScanned = getLatestCloudDate(
    data.dashboard?.cloud,
  );
  const lastScannedContainers: LastScanned = getLatestContainersDate(
    data.dashboard?.container?.time_created,
  );

  const codeDate = data?.dashboard?.code?.data?.find((x) => x?.scannedtime)
    ?.scannedtime;
  const codeDDate = new Date(codeDate);
  const betaSCADate = data?.dashboard?.betaSCA?.find((x) => x?.scannedtime)
    ?.scannedtime;
  const betaDDate = new Date(betaSCADate);

  let lastScannedCode: LastScanned;
  if (
    codeDDate.toString() !== "Invalid Date" &&
    betaDDate.toString() !== "Invalid Date"
  ) {
    const oldestCodeDate = codeDDate < betaDDate ? betaDDate : codeDDate;
    if (
      oldestCodeDate instanceof Date &&
      oldestCodeDate.toString() !== "Invalid Date"
    ) {
      lastScannedCode = {
        date: oldestCodeDate,
        valid: true,
        olderThan24H: dateIs24HPast(oldestCodeDate),
        olderThan48H: dateIs48HPast(oldestCodeDate),
      };
    } else {
      lastScannedCode = {
        date: new Date(),
        valid: false,
        olderThan24H: false,
        olderThan48H: false,
      };
    }
  } else if (
    codeDDate.toString() === "Invalid Date" &&
    betaDDate.toString() !== "Invalid Date"
  ) {
    lastScannedCode = {
      date: betaDDate,
      valid: true,
      olderThan24H: dateIs24HPast(betaDDate),
      olderThan48H: dateIs48HPast(betaDDate),
    };
  } else if (
    betaDDate.toString() === "Invalid Date" &&
    codeDDate.toString() !== "Invalid Date"
  ) {
    lastScannedCode = {
      date: codeDDate,
      valid: true,
      olderThan24H: dateIs24HPast(codeDDate),
      olderThan48H: dateIs48HPast(codeDDate),
    };
  } else {
    lastScannedCode = {
      date: new Date(),
      valid: false,
      olderThan24H: false,
      olderThan48H: false,
    };
  }

  const exposureDate1 = data?.dashboard?.exposure?.data?.scannedtime;
  const lastScannedExposure = exposureDate(exposureDate1);

  const otherDomains: Otherdomains[] =
    data?.dashboard?.exposure?.data?.Otherdomains?.filter(notEmpty) ?? [];
  const badLinks: Badlinks[] =
    data?.dashboard?.exposure?.data?.Badlinks?.filter(notEmpty) ?? [];
  const techStacks: Techstacks[] =
    data?.dashboard?.exposure?.data?.Techstacks?.filter(notEmpty) ?? [];
  const endpointUrl: string | undefined =
    data.dashboard?.application?.target ?? undefined;
  const changes: Changes = data.dashboard?.exposure?.data?.Changes ?? {
    __typename: "Changes",
    fullurl: "",
    murl: "",
    NewChange: "",
    oldChange: "",
    scanreference: "",
    scannedtime: "",
  };
  const subdomains: Domains[] =
    data?.dashboard?.exposure?.data?.Domains?.filter(notEmpty) ?? [];
  const parsedExposureFinalScore = Number(
    data?.dashboard?.exposure?.data?.Grade?.finalscore,
  );
  const exposureFinalScore: number =
    parsedExposureFinalScore && !Number.isNaN(parsedExposureFinalScore)
      ? parsedExposureFinalScore
      : 0;
  const exposureSecurityScore: number =
    data?.dashboard?.exposure?.data?.Grade?.securityscore ?? 0;
  const exposureAvailabilityScore =
    data?.dashboard?.exposure?.data?.Grade?.scalabilityscore ?? 0;

  const gitMetaValid: boolean = data?.dashboard?.GitHubMetaForApp != null;
  const gitOrgMembers: string[] =
    data?.dashboard?.GitHubMetaForApp?.org_members?.filter<string>(notEmpty) ??
    [];
  const gitGpgKeysNumber: number =
    data?.dashboard?.GitHubMetaForApp?.no_of_gpg_keys ?? 0;
  const gitWorkflowNumber: number =
    data?.dashboard?.GitHubMetaForApp?.no_of_workflows ?? 0;
  const gitSecretsNumber: number =
    data?.dashboard?.GitHubMetaForApp?.no_of_secrets ?? 0;
  const gitOrgSecretsNumber: number =
    data?.dashboard?.GitHubMetaForApp?.no_of_org_secrets ?? 0;
  const gitCollaborators: string[] =
    data?.dashboard?.GitHubMetaForApp?.collaborators?.filter<string>(
      notEmpty,
    ) ?? [];
  const githubHasCICD: boolean = gitWorkflowNumber > 0;

  const bitbucketMetaValid: boolean = data.dashboard?.BitbucketMeta != null;
  const bitbucketPipelineNumber: number =
    data.dashboard?.BitbucketMeta?.no_of_pipelines ?? 0;
  const bitbucketSshKeyNumber: number =
    data.dashboard?.BitbucketMeta?.no_of_ssh_keys ?? 0;
  const bitbucketGroupsOnRepoNumber: number =
    data.dashboard?.BitbucketMeta?.no_of_groups_on_repo ?? 0;
  const bitbucketGroupsOnRepo: string[] =
    data.dashboard?.BitbucketMeta?.groups_on_repo?.filter<string>(notEmpty) ??
    [];
  const bitbucketWorkspaceMembers: string[] =
    data.dashboard?.BitbucketMeta?.workspace_members?.filter<string>(
      notEmpty,
    ) ?? [];
  const bitbucketUserEmails: string[] =
    data.dashboard?.BitbucketMeta?.userEmails?.filter<string>(notEmpty) ?? [];
  const bitbucketHasCICD: boolean = bitbucketPipelineNumber > 0;

  const gitlabMetaValid: boolean = data.dashboard?.GitlabMeta != null;
  const gitlabNumContributors: number =
    data.dashboard?.GitlabMeta?.no_of_contributors ?? 0;
  const gitlabNumGroupMembers: number =
    data.dashboard?.GitlabMeta?.no_of_group_members ?? 0;
  const gitlabNumPipelines: number =
    data.dashboard?.GitlabMeta?.no_of_pipelines ?? 0;
  const gitlabNumGpgKeys: number =
    data.dashboard?.GitlabMeta?.no_of_gpg_keys ?? 0;
  const gitlabNumSshKeys: number =
    data.dashboard?.GitlabMeta?.no_of_ssh_keys ?? 0;
  const gitlabContributors: string[] =
    data.dashboard?.GitlabMeta?.contributors?.filter(notEmpty) ?? [];
  const gitlabGroupMembers: string[] =
    data.dashboard?.GitlabMeta?.groupMembers?.filter(notEmpty) ?? [];
  const gitlabHasCICD: boolean = gitlabNumPipelines > 0;
  return {
    application: appSettings,
    exposure: {
      endpointUrl: endpointUrl,
      hasExposureRan: isExposureConfigured,
      parsedIssues: exposureIssues
        .map((exposure) => parseOneExposureIssue(exposure))
        .concat(
          createExposureArr(
            data.dashboard?.exposure?.data?.Problem ?? undefined,
          ).map((nineProblem) => parseOneNineProblem(nineProblem)),
        ),
      numberOfExposureProblems: numberOfExposureProblems,
      lastScannedExposure: lastScannedExposure,
      otherDomains: otherDomains,
      badLinks: badLinks,
      techStacks: techStacks,
      changes: changes,
      subdomains: subdomains,
      scores: {
        exposureFinalScore: exposureFinalScore,
        exposureSecurityScore: exposureSecurityScore,
        exposureAvailabilityScore: exposureAvailabilityScore,
      },
      ports: openPorts,
      exposureName,
      exposureMurl,
      exposureScanType,
      exposureNginxVersion,
      exposureStageExists,
      exposureTlsVersion,
      exposureSovereignIssue,
      exposureSslIssue,
      exposureNoCdnSovereignIssue,
      exposureNumUnencryptedPages,
      exposureNumRedirects,
      exposureNotSecure,
      exposureLatitude,
      exposureLongitude,
      exposureLoadBalanced,
      exposureMultiEnvExists,
      exposureWaf,
      exposureWafLocation,
      exposureRedirectDestination,
      exposureReferrerPolicy,
      exposureRootUrl,
      exposureApacheVersion,
      exposureApplicationExists,
      exposureAwsAlb,
      exposureCdn,
      exposureCsp,
      exposureCspData,
      exposureCdnBypass,
      exposureCdnVer,
      exposureCloudfrontCdn,
      exposureCloudVer,
      exposureCmsVer,
      exposureDevExists,
      exposureElapsedTime,
      exposureEmail,
      exposureHttpsRedirect,
      exposureHsts,
      exposureGitConfigExists,
      exposureEnvFileExists,
      exposureSecurityTxtExists,
      exposureWordpress,
      exposureWordpressVer,
      exposureXCache,
      exposureXCacheData,
      exposureAmazon,
      exposureAzure,
      exposureCountryHost,
      exposureIsp,
    },
    code: {
      hasCodeRan: codeRan,
      //codeResults: codeResults,
      //oldCodeResults: oldCodeResults,
      parsedIssues: codeResults
        .map((code) => parseOneCodeIssue(code))
        .concat(upgradeLegacyScaObjects(oldCodeResults)),
      numberOfCodeProblems: numberOfCodeProblems,
      numberOfLegacyCodeProblems: numberOfLegacyCodeProblems,
      lastScannedCode: lastScannedCode,
      scmMeta: {
        // GITHUB
        gitValid: gitMetaValid,
        gitCollaborators: gitCollaborators,
        gitNumberOfGPGKeys: gitGpgKeysNumber,
        gitOrgMembers: gitOrgMembers,
        gitWorkflowNumber: gitWorkflowNumber,
        gitOrgSecretsNumber: gitOrgSecretsNumber,
        gitSecretsNumber: gitSecretsNumber,
        githubCicd: githubHasCICD,
        // BB
        bitbucketValid: bitbucketMetaValid,
        bitbucketPipelineNumber: bitbucketPipelineNumber,
        bitbucketSshKeyNumber: bitbucketSshKeyNumber,
        bitbucketGroupsOnRepoNumber: bitbucketGroupsOnRepoNumber,
        bitbucketGroupsOnRepo: bitbucketGroupsOnRepo,
        bitbucketWorkspaceMembers: bitbucketWorkspaceMembers,
        bitbucketUserEmails: bitbucketUserEmails,
        bitbucketCicd: bitbucketHasCICD,
        // GL
        gitlabValid: gitlabMetaValid,
        gitlabContributorsCount: gitlabNumContributors,
        gitlabContributors: gitlabContributors,
        gitlabPipelines: gitlabNumPipelines,
        gitlabGPGKeys: gitlabNumGpgKeys,
        gitlabSSHKeys: gitlabNumSshKeys,
        gitlabGroupMembersCount: gitlabNumGroupMembers,
        gitlabGroupMembers: gitlabGroupMembers,
        gitlabCicd: gitlabHasCICD,
      },
    },
    cloud: {
      hasCloudRan: isCloudConfigured,
      numberOfCloudProblems: numberOfCloudProblems,
      parsedIssues: bundledCloudResults,
      lastScannedCloud: lastScannedCloud,
    },
    containers: {
      hasContainersRan: isContainersConfigured,
      numberOfContainersProblems: numberOfContainersProblems,
      parsedIssues: bundledContainersResults,
      lastScannedContainers: lastScannedContainers,
    },
    secrets: {
      hasSecretsRan: isSecretsConfigured,
      parsedIssues:
        data.dashboard?.secrets?.data
          ?.filter(notEmpty)
          .map((secret) => parseOneSecretsIssue(secret)) ?? [],
      numberOfSecretsProblems: numberOfSecretsProblems,
      lastScannedSecrets: lastScannedSecrets,
    },
    ssc: parseSSC(data.dashboard?.ssc ?? undefined),
  };
}
