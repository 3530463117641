import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { SnackbarKey, useSnackbar } from "notistack";
import { MouseEventHandler, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { ErrorAlert } from "../../../components/alerts/ErrorAlert";
import { Button } from "../../../components/buttons/Button";
import { Modal } from "../../../components/dialogs/Modal";
import { DropDownSelect } from "../../../components/forms/DropDownSelect";
import { useHistoryState, usePathAppId } from "../../../functions/hooks";
import { useUser } from "../../../functions/hooks/useUser";
import logGraphQLError from "../../../functions/logGraphQLError";
import { titleCase } from "../../../functions/utilities";
import { SCA_AUTO } from "../../../graphql/mutations/ScaAuto";
import { APPLICATION_SETTINGS } from "../../../graphql/queries/ApplicationSettingsQuery";
import { APPLICATION_STATUS } from "../../../graphql/queries/ApplicationStatus";
import { CREATE_OR_UPDATE_APP } from "../../../graphql/queries/CREATE_OR_UPDATE_APP";
import { FETCH_SCM_DETAILS } from "../../../graphql/queries/FetchSCMDetails";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";

import { CreateAppLayout } from "../single/Layout";
import { modalDataMap } from "../single/modalDataMap";
import { GetContainerAccounts } from "../../../graphql/queries/GetContainerAccounts";
import { SelectContainers } from "./repository/SelectContainers";
import { AnalyzeTrivyFE } from "../../../graphql/mutations/AnalyzeContainer";

export enum SCM_SELECTION {
  NOT_SELECTED = "Select Username",
  BITBUCKET = "bitbucket",
  GITHUB = "github",
  GITLAB = "gitlab",
  AZURE = "azure devops",
}

enum COMPLETION_STATE {
  SCM,
  WORKSPACE_OR_ORGANIZATION,
  REPOSITORY,
  BRANCH,
}

export const getScmProviderDetails = (data: any) => {
  let transformedData = [];
  let transformedDataNew = [];

  transformedData.push({
    id: 0,
    name: SCM_SELECTION.NOT_SELECTED,
    unavailable: true,
    account: "",
  });
  if (data?.getAccountsByOrgForContainers?.cloudAccounts) {
    transformedDataNew = data?.getAccountsByOrgForContainers?.cloudAccounts.map(
      (account: { name: any }, index: any) => ({
        id: index + 1,
        name: account.name,
        unavailable: false,
        account: "cloud",
      }),
    );
    transformedData.push(...transformedDataNew);
  }

  const dockerHubUsername =
    data?.getAccountsByOrgForContainers?.dockerHubUsername;
  if (dockerHubUsername) {
    transformedData.push({
      id: transformedData.length + 1,
      name: dockerHubUsername,
      unavailable: false,
      account: "docker",
    });
  }

  transformedData.push({
    id: transformedData.length + 1,
    name: "Other",
    unavailable: false,
    account: "Other",
  });

  return transformedData;
};

export function ContainerSettings({
  isEditing,
  closeEditModal,
  concierge,
  concierge_appId,
  concierge_readyToStep,
}: {
  isEditing?: boolean;
  closeEditModal?: () => void;
  concierge?: boolean;
  concierge_appId?: string;
  concierge_readyToStep?: Function;
}): JSX.Element {
  const user = useUser();
  const { NOT_SELECTED, BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;
  const [isInfoModalShowing, setIsInfoModalShowing] = useState(false);
  const { SCM, WORKSPACE_OR_ORGANIZATION, REPOSITORY } = COMPLETION_STATE;

  const { data, refetch: refetchFetchScmDetails } = useQuery(
    GetContainerAccounts,
    {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
      onError: logGraphQLError,
    },
  );

  const [appScm, setAppScm] = useHistoryState("appScm");
  const [info, setInfo] = useState("");
  const [appId] = useHistoryState("appId");
  const [packageManager, setPackageManager] = useHistoryState("packageManager");
  const appIdToEdit = usePathAppId();

  const [scm, setScm] = useState<{
    id: string;
    name: string;
    unavailable: boolean;
    account: string;
  }>({
    id: "0",
    name: SCM_SELECTION.NOT_SELECTED,
    unavailable: true,
    account: "none",
  });

  const [repository, setRepository] = useHistoryState("repository");
  const [branch, setBranch] = useHistoryState("branch");
  const [, setId] = useHistoryState("id");
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [codePath, setCodePath] = useState<string>("");
  const [containerList, setContainerList] = useState<string[]>([]);

  const [triggerContainerScan, { loading: scaLoader, error: scaError }] =
    useMutation(AnalyzeTrivyFE, {
      variables: {
        applicationId: appId ?? concierge_appId,
      },
      onCompleted: () => {
        setId("6");
      },
    });

  const action = (key: SnackbarKey | undefined) => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  if (scaError) {
    enqueueSnackbar(
      scaError?.message ?? "An error ocurred while initiating scan",
      {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        action,
      },
    );
  }

  const [createCodeSettings, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_APP,
    {
      refetchQueries: [
        APPLICATION_SETTINGS,
        APPLICATION_STATUS,
        GET_APPLICATIONS,
      ],
      onCompleted: () => {
        if (isEditing) {
          enqueueSnackbar("Successfully updated application", {
            variant: "success",
            persist: false,
          });
          push(`/settings/applications/${appIdToEdit}`);
          containerList.length > 0 && appScm
            ? triggerContainerScan({
                variables: {
                  applicationId: appId ?? concierge_appId ?? appIdToEdit,
                  orgId: user?.selectedOrg ?? "",
                },
              }).catch((e) => {
                enqueueSnackbar(
                  `Failed to start Container scan: ${e?.message}`,
                  {
                    variant: "error",
                    persist: false,
                  },
                );
              })
            : enqueueSnackbar(`Unable to Container scan`, {
                variant: "error",
                persist: false,
              });
          closeEditModal && closeEditModal();
        } else {
          if (containerList.length > 0 && appScm) {
            triggerContainerScan({
              variables: {
                applicationId: appId ?? concierge_appId ?? appIdToEdit,
                orgId: user?.selectedOrg ?? "",
              },
            }).catch((e) => {
              enqueueSnackbar(`Failed to start Container scan: ${e?.message}`, {
                variant: "error",
                persist: false,
              });
            });
          }
        }
      },
    },
  );

  const clearHistoryState = (index: number) => {
    if (index === -1) {
      setAppScm("");

      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === SCM) {
      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === WORKSPACE_OR_ORGANIZATION) {
      setRepository("");
      setPackageManager("");
      setBranch("");
    }

    if (index === REPOSITORY) {
      setBranch("");
      setPackageManager("");
    }
  };

  const isCompleted = (val: string[]) => {
    if (!val || val.length === 0) {
      return false;
    }
    return true;
  };

  const isCompletedUsername = (val: string) => {
    if (!val) {
      return false;
    }
    if (val === "other") {
      return "other";
    }
    return true;
  };

  const save = () => {
    return createCodeSettings({
      variables: {
        applicationDetails: {
          applicationId: appIdToEdit,
          containerlist: JSON.stringify(containerList),
        },
      },
    });
  };

  const goNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    save();
  };

  const [stringList, setStringList] = useState<string[]>([]);
  const [newString, setNewString] = useState("");

  const handleInputChange = (e: any) => {
    setNewString(e.target.value);
  };

  const modalData = modalDataMap.get(info);

  const openModal = (id: string) => {
    setInfo(id);
    setIsInfoModalShowing(true);
  };
  const handleAddString = () => {
    if (newString.trim() !== "") {
      setStringList([...stringList, newString]);
      setNewString("");
      setContainerList([...containerList, newString]);
    }
  };
  const handleClearString = () => {
    setStringList([]);
    setNewString("");
    setContainerList([]);
  };

  return (
    <>
      <CreateAppLayout isEditing={isEditing} concierge={concierge}>
        {!concierge && (
          <h2 className="pb-6 text-2xl tracking-tight text-gray-900 dark:text-white ">
            {!isEditing && "5."} Connect your Containers.
          </h2>
        )}
        {
          <IoReload
            title="Refresh SCM Providers"
            className={`dark:fill-white dark:stroke-white absolute cursor-pointer top-3 ${
              concierge ? "right-3" : "right-7"
            }`}
            width="50"
            height="50"
            onClick={() => void refetchFetchScmDetails()}
          />
        }
        <div className="space-y-2">
          <div className="flex">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Account Username
            </label>
            <AiOutlineInfoCircle
              title="More information"
              onClick={(e) => openModal(e.currentTarget.id)}
              id="scm"
              className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
            />
          </div>
          <DropDownSelect
            label="DockerHub Username"
            testId="DockerHub Username"
            selected={scm}
            setSelected={(scm) => {
              setScm(scm);
              setAppScm(
                scm.name === NOT_SELECTED ? "" : scm.name.toLowerCase(),
              );
              clearHistoryState(SCM);
            }}
            data={(data && getScmProviderDetails(data)) ?? []}
          />

          {isCompletedUsername(appScm) &&
            scm?.account !== "none" &&
            isCompletedUsername(appScm) !== "other" && (
              <>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    {(() => {
                      return "Containers";
                    })()}
                  </label>
                  <AiOutlineInfoCircle
                    title="more information"
                    onClick={(e) => openModal(e.currentTarget.id)}
                    id={(() => {
                      return "Containers";
                    })()}
                    className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                  />
                </div>
                <SelectContainers
                  account={scm.account}
                  accountName={scm.name}
                  containerList={containerList}
                  setContainerList={setContainerList}
                />
              </>
            )}
          {isCompletedUsername(appScm) === "other" &&
            scm?.account !== "none" && (
              <>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                    {(() => {
                      return "Containers list";
                    })()}
                  </label>
                  <AiOutlineInfoCircle
                    title="more information"
                    onClick={(e) => openModal(e.currentTarget.id)}
                    id={(() => {
                      return "Containers";
                    })()}
                    className="cursor-pointer text-gray-500 ml-1 w-5 h-5"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter a string or a list"
                    value={newString}
                    onChange={handleInputChange}
                    className="border border-gray-300 px-8 py-2 rounded"
                  />
                  <button
                    onClick={handleAddString}
                    className="ml-1 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:text-sm"
                  >
                    Add
                  </button>
                  <button
                    onClick={handleClearString}
                    className="ml-1 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900 sm:text-sm"
                  >
                    Clear
                  </button>
                  <ul>
                    {stringList?.map((item, index) => (
                      <li key={index} className="mt-2 text-gray-700">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
        </div>
        {scm?.account === "none" && <CodeScanning />}
      </CreateAppLayout>
      <Modal
        noVerticalMargin
        isOpen={isInfoModalShowing}
        onClose={() => setIsInfoModalShowing(false)}
      >
        <div
          id="alert-additional-content-5"
          className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
              Information
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
            {modalData?.info}
          </div>
        </div>
      </Modal>

      {!concierge ? (
        isEditing ? (
          <div className="text-right space-x-2">
            <Button
              outlined
              label="Cancel"
              onClick={() => {
                clearHistoryState(-1);
                closeEditModal && closeEditModal();
              }}
            />

            <Button
              primary
              label="Save"
              onClick={save}
              disabled={!isCompleted(containerList)}
              className="mb-20"
            />
          </div>
        ) : (
          <div className="mt-4 justify-between flex ml-0 md:ml-[260px] mb-20">
            <Button outlined={true} label="Back" onClick={() => setId("4")} />

            <div>
              <Button
                className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
                onClick={() => {
                  save().then(() =>
                    setTimeout(
                      () => window.location.assign(`/dashboard/${appId}`),
                      1000,
                    ),
                  );
                }}
                label="Finish"
              />
              <Button
                className="dark:text-gray-400 mr-4 text-gray-800 shadow-none"
                onClick={() => {
                  clearHistoryState(-1);
                  setId("6");
                }}
                label="Skip"
              />
              <Button
                primary={true}
                label="Next"
                disabled={!isCompleted(containerList)}
                isLoading={loading || scaLoader}
                onClick={goNext}
              />
            </div>
          </div>
        )
      ) : (
        <div className="flex">
          <Button
            label={isCompleted(containerList) ? "Next" : "Skip"}
            onClick={() => {
              if (isCompleted(containerList)) {
                createCodeSettings({
                  variables: {
                    applicationDetails: {
                      applicationId: concierge_appId ?? appId,
                      scm: (appScm === AZURE ? "azure" : appScm) ?? null,
                      language: packageManager ?? null,
                      reponame: repository ?? null,
                      branch: branch ?? null,
                      path: codePath ?? "",
                    },
                  },
                }).then((r) => {
                  if (r.errors) {
                    enqueueSnackbar("Could not save SCM settings", {
                      variant: "error",
                      persist: false,
                    });
                  } else {
                    enqueueSnackbar("Successfully saved SCM settings", {
                      variant: "success",
                      persist: false,
                    });
                    concierge_readyToStep && concierge_readyToStep();
                  }
                });
              } else {
                concierge_readyToStep && concierge_readyToStep();
              }
            }}
            primary={true}
            data-test-id="concierge-scm-next-btn"
            className="w-80 hover:animate-none h-16 mx-auto mt-10"
          />
        </div>
      )}

      {error && (
        <div className="mt-4 ml-0 md:ml-[260px]">
          <ErrorAlert
            title={
              "Error creating repository settings, please try again or contact support"
            }
            isShowing={true}
          />
        </div>
      )}
    </>
  );
}

export function CodeScanning(): JSX.Element {
  return (
    <div
      id="alert-additional-content-5"
      className="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-dark-primary"
      role="alert"
    >
      <div className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-5 h-5 mr-2 text-gray-700 dark:text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Info</span>
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">
          Container Scanning
        </h3>
      </div>
      {/* <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
        SecureStack provides integrations with common centralised git
        repositories such as Bitbucket and GitHub that allow us to find and
        analyse package dependency files for potential vulnerabilities.
        Depending on where your application code is stored, select your Source
        Code Manager (SCM) provider.
      </div> */}
    </div>
  );
}
