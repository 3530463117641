import { useState } from "react";
import { JiraProjects } from "./JiraProjects";
import { CreateIssueForm } from "./CreateIssueForm";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { AiOutlineLoading } from "react-icons/ai";
import { Button } from "../buttons/Button";
import { useUser } from "../../functions/hooks/useUser";
import { GET_JIRA_INTEGRATION } from "../../graphql/queries/GetJiraIntegration";
import { GET_JIRA_PROJECTS } from "../../graphql/queries/GetJiraProjects";
import logGraphQLError from "../../functions/logGraphQLError";
declare const window: any;

export default function CreateIssue({ issue }: { issue?: string }): JSX.Element {
  const user = useUser();
  const { data: jiraIntegration } = useQuery(GET_JIRA_INTEGRATION, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: logGraphQLError,
  });
  const { HubSpotConversations } = window;
  const { data, loading, error } = useQuery(GET_JIRA_PROJECTS, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onError: logGraphQLError,
    variables: {
      orgId: user?.selectedOrg,
    },
  });

  const [selectedProject, setSelectedProject] = useState({
    id: "",
    name: "Select Project",
    unavailable: false,
  });

  if (error) {
    return <p className="dark:text-white">An error ocurred while getting your Jira projects. If the issue persists after refreshing the page, please contact us for assistance.</p>;
  }

  if (!jiraIntegration?.hasJiraIntegration) {
    return (
      <div>
        <div className="text-right space-y-2 mt-5">
          <Button
            className="mr-2"
            outlined
            onClick={() => HubSpotConversations.widget.open()}
            label="Get Support"
          />

          <Button
            className=""
            primary={true}
            as={Link}
            label={
              jiraIntegration?.hasJiraIntegration
                ? "Create Jira ticket"
                : "Connect Jira"
            }
            to={{
              pathname: jiraIntegration?.hasJiraIntegration
                ? "/integrations/jira/create"
                : "/integrations/jira/connect",
              search: issue,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 bg-white dark:bg-dark-secondary rounded-lg">
      {loading ? (
        <div className="  py-7 px-7 mx-auto text-center  text-black ">
          <AiOutlineLoading className="animate-spin transition w-10 h-10 mx-auto text-red-600 " />
          Loading Projects...
        </div>
      ) : (
        <div className="dark:bg-dark-main px-4 py-4 rounded-lg">
          <JiraProjects
            projects={data?.createJiraIssueMetaData?.projects}
            setSelected={setSelectedProject}
            selected={selectedProject}
          />

          <CreateIssueForm project={selectedProject} issueName={issue} />
        </div>
      )}
    </div>
  );
};
