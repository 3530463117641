import { gql } from "@apollo/client/core";

export const CreateFCloudAnalysis = gql`
  mutation CreateFCloudAnalysis(
    $org_id: String!
    $domain: String!
    $cloud_account_id: ID!
    $applicationId: ID!
  ) {
    createFCloudAnalysis(
      orgId: $org_id
      domain: $domain
      cloud_account_id: $cloud_account_id
      applicationId: $applicationId
    ) {
      id
      environment_id
      state
      time_created
    }
  }
`;
