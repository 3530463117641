import { gql } from "../../__generated__/gql";

export const LIST_TAGS = gql(`
    query ListTags($orgId: String) {
      listTags(orgId: $orgId) {
        id
        name
        orgId
      }
    }
`);
