import { useHistoryState } from "../../../../functions/hooks";
import { GithubStructure } from "./structure/GitHubStructure";
import { BitbucketStructure } from "./structure/BitbucketStructure";
import { GitlabStructure } from "./structure/GitlabStructure";
import { SCM_SELECTION } from "../CodeSettings";
import AzureDevOpsFileStructure from "./structure/AzureDevOpsFileStructure";
import { Dispatch } from "react";

export function RepositoryStructure({ codePath, setCodePath }: { codePath: string, setCodePath: Dispatch<string> }): JSX.Element {
  const [appScm] = useHistoryState("appScm");
  const { BITBUCKET, GITHUB, GITLAB, AZURE } = SCM_SELECTION;

  return (
    <div>
      {((): JSX.Element => {
        if (appScm === BITBUCKET) { return <BitbucketStructure codePath={codePath} setCodePath={setCodePath} />; }
        if (appScm === GITHUB) { return <GithubStructure codePath={codePath} setCodePath={setCodePath} />; }
        if (appScm === GITLAB) { return <GitlabStructure codePath={codePath} setCodePath={setCodePath} />; }
        if (appScm === AZURE || appScm === "azure") { return <AzureDevOpsFileStructure codePath={codePath} setCodePath={setCodePath} />; }
        // default: github
        return <GithubStructure codePath={codePath} setCodePath={setCodePath} />;
      })()}
    </div>
  );
};
