import { Collapse } from "@material-ui/core";
import { Suspense, lazy, useCallback, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import GenericErrorBoundary from "../functions/GenericErrorBoundary";
import { svgImport } from "../partials/application/ApplicationComposition";
import { AffectedApps, AppNameAndId } from "./PackagesExplorer";
import { formatDateShort } from "./dashboard_2/HistoryPicker";
import { CVEConfigurations, CVEReferences, ParsedCVEData } from "./issues/cveDigest";

const SecureStackLogo = lazy(() => import('../assets/small-logo.svg').then(svgImport));

function truncate(s?: string | null): string {
    if (!s) { return "" }
    const slice = s.slice(0, 45);
    const didSlice = slice.length !== s.length;
    return didSlice ? slice + "..." : slice;
}

export function Line({ score }: { score: number }): JSX.Element {
    const getBackgroundColor = useCallback((score: number): string => {
        if (score >= 0 && score <= 33) {
            return "bg-green-500";
        } else if (score > 33 && score <= 66) {
            return "bg-yellow-500";
        } else if (score > 66 && score <= 88) {
            return "bg-orange-500";
        } else {
            return "dark:bg-red-700 bg-red-600";
        }
    }, []);

    return (
        <div className="mx-auto bg-gray-200 dark:bg-gray-700 rounded-full w-full">
            <div
                className={`${getBackgroundColor(score)} text-xs font-medium dark:text-gray-900 text-gray-200 text-center p-0.5 rounded-full`}
                style={{ width: `${score}%` }}
            ></div>
        </div>
    );
};

function CveReferences({ cveReferences }: { cveReferences: CVEReferences[] | null }): JSX.Element {
    return <>
        {cveReferences?.map((x, i) => {
            if (x.url.toLowerCase().includes('https')) {
                return <div key={i} className="flex flex-col gap-2">
                    <div className="w-fit text-gray-600 dark:text-gray-400">
                        <a href={x.url} title={x.url} rel="external help noopener noreferrer nofollow" target="_blank">
                            <div className="flex flex-row gap-2 text-sm">
                                <p>• <span className="break-words">{truncate(x.url)}</span> from {x.source}</p>
                                <FiExternalLink className="my-auto" />
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-row flex-wrap gap-2">
                        {[...x.tags, "External Resource", x.url.includes('github.com') ? "GitHub" : null].filter(x => x != null).map((y, i) => (
                            <div className="text-sm bg-light-seconday rounded-md dark:bg-dark-nav dark:text-gray-400 px-1 py-1" key={i}>
                                <p>{y}</p>
                            </div>))}
                    </div>
                </div>
            }
            return null;
        })}
    </>;
}

export function CriticalityChip({ criticality }: { criticality: string | null }): JSX.Element | null {
    if (criticality == null) {
        return null;
    }
    const lowercaseCriticality = criticality.toLowerCase();
    const determinedCriticality: "critical" | "high" | "medium" | "low" | "invalid" = lowercaseCriticality === "critical" ? "critical" : lowercaseCriticality === "high" ? "high" : (lowercaseCriticality === "medium" || lowercaseCriticality === "moderate") ? "medium" : lowercaseCriticality === "low" ? "low" : "invalid"

    return <div className={`${determinedCriticality === "critical" ? "bg-vuln-critical dark:bg-vuln-critical-dark" : determinedCriticality === "high" ? "bg-vuln-high dark:bg-vuln-high-dark" : determinedCriticality === "medium" ? "bg-vuln-med dark:bg-vuln-med-dark" : determinedCriticality === "low" ? "bg-vuln-low dark:bg-vuln-low-dark" : "bg-gray-500"} px-2 py-1 uppercase text-left text-xs font-semibold text-white tracking-wider rounded-full`}>
        {determinedCriticality}
    </div>;
}

function CpeCodeTag({ x }: { x: any }): JSX.Element {
    return <code className="py-1 px-2 bg-light-primary dark:bg-dark-nav rounded-md text-sm font-semibold text-gray-600 dark:text-gray-400">{x}</code>
}

function CpeConfiguration({ cpe }: { cpe: CVEConfigurations }): JSX.Element {
    return <div className="flex flex-col gap-3 text-sm font-semibold text-gray-600 dark:text-gray-400">
        <p>CPE Negation: <CpeCodeTag x={cpe.negate ? "CPE Negated" : "CPE Not Negated"} /></p>
        <p>CPE Operator: <CpeCodeTag x={cpe.operator} /></p>
        {
            cpe.cpeMatch.map((y, j) => <div className="flex flex-col gap-3" key={j}>
                <p>Criteria: <CpeCodeTag x={y.criteria} /></p>
                <p>Match Criteria Id: <CpeCodeTag x={y.matchCriteriaId} /></p>
                <p>Version End Excluding: <CpeCodeTag x={y.versionEndExcluding} /></p>
                <p>Vulnerable: <CpeCodeTag x={y.vulnerable ? "Vulnerable" : "Not Vulnerable"} /></p>
            </div>)
        }
    </div>;
}

export default function CVEExplorer({ loading, cveInfo, affectedApplications }: { searchTerm: string; loading: boolean; cveInfo: ParsedCVEData | null; affectedApplications: AppNameAndId[] | null }): JSX.Element {

    const [descriptionLanguage, setDescriptionLanguage] = useState<string>("default");
    const [showReferences, setShowReferences] = useState<boolean>(false);
    const [showCPEConfigurations, setShowCPEConfigurations] = useState<boolean>(false);

    return <GenericErrorBoundary fallback={<p className="dark:text-white">We're sorry but an error has ocurred while displaying the package / CVE viewer. Please refresh and try again. If the issue persists, please contact support.</p>}>
        {cveInfo ? <div className="mt-2 lg:mt-4 bg-white dark:bg-dark-secondary p-2 md:px-4 xl:px-6 xl:py-4 rounded-md">
            {cveInfo ? <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                        <div className="flex flex-row gap-4 justify-between">
                            <div className="flex flex-row gap-2">
                                <Suspense fallback={<></>}>
                                    <SecureStackLogo className="w-8 h-8 my-auto" />
                                </Suspense>
                                <p className="my-auto text-xl dark:text-white font-bold">{cveInfo.cveName}</p>
                            </div>
                            <div className="my-auto">
                                <CriticalityChip criticality={cveInfo.criticality} />
                            </div>
                        </div>
                        {/* <div>
                            <Button secondary label="Search your applications for this package" />
                            <p>* Requires previously run SBOM scans</p>
                        </div> */}
                    </div>
                    <div className="flex flex-row gap-4 text-gray-500">
                        {cveInfo.cvss2 != null ? <p>CVSS2: {cveInfo.cvss2}</p> : null}
                        {cveInfo.cvss2 != null ? <p>CVSS3: {cveInfo.cvss3}</p> : null}
                    </div>
                    <div className="flex flex-row gap-2 text-gray-500 text-sm justify-between">
                        <p>{cveInfo.cveId}</p>
                        <p>Last Modified: {formatDateShort(new Date(cveInfo.cveLastModified ?? ""))}</p>
                        <p>Published: {formatDateShort(new Date(cveInfo.cvePublished ?? ""))}</p>
                    </div>
                </div>
                {/* <p>CVE Source: {cveInfo.cveSourceIdentifier}</p> */}
                {/* <p>Vulnerability Status: {cveInfo.cveVulnStatus}</p> */}
                <div className="flex flex-col sm:flex-row justify-between">
                    <p className="my-auto text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">Description:</p>
                    <div className="flex flex-row flex-nowrap gap-2 justify-between text-black p-[6px] rounded-md dark:text-gray-300 bg-light-primary dark:bg-dark-nav">
                        <p>🌐</p>
                        <select className="bg-light-primary dark:bg-dark-nav" value={descriptionLanguage} onChange={(e) => setDescriptionLanguage(e.target.value ?? "default")}>
                            <option value="default">Default</option>
                            {cveInfo.cveDescriptions?.map((x, i) => <option key={i} value={x.language}>{x.language}</option>)}
                        </select>
                    </div>
                </div>

                <div className="flex flex-row flex-wrap gap-2 text-gray-700 dark:text-gray-300">
                    <p>{descriptionLanguage === "default" ? cveInfo.description : cveInfo.cveDescriptions?.find((x) => x.language === descriptionLanguage)?.value}</p>
                </div>

                <AffectedApps affectedApplications={affectedApplications} />

                {(cveInfo.cveMetrics?.length ?? 0) > 0 ? <div className="flex flex-col gap-2">
                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">Metrics:</p>
                    <div className="flex flex-row flex-wrap gap-2 mb-4">
                        {cveInfo.cveMetrics?.map((x, i) => <div className="w-full flex-grow flex flex-col" key={i}>
                            <div className="mt-2 p-2 bg-white dark:shadow-md dark:bg-dark-main bg-opacity-75 rounded-xl text-left flex lg:flex-row flex-shrink flex-grow flex-col gap-4 m-auto text-gray-900 dark:text-gray-200 font-medium justify-evenly align-middle w-full mb-2">
                                {x.cvssData.baseScore != null ? <div className="flex-shrink flex-grow flex flex-col">
                                    <div className="inline-flex pb-1 justify-between">
                                        <span className="flex-shrink pr-5 overflow-x-hidden text-ellipsis">CVSS Base Score</span>
                                        {x.cvssData.baseScore}
                                    </div>
                                    <Line score={Math.round(x.cvssData.baseScore * 10)} />
                                </div> : null}
                                {x.impactScore ? <div className="flex-shrink flex-grow flex flex-col">
                                    <div className="inline-flex pb-1 justify-between">
                                        <span className="flex-shrink pr-5 overflow-x-hidden text-ellipsis">Impact Score</span>
                                        {x.impactScore}
                                    </div>
                                    <Line score={Math.round(x.impactScore * 10)} />
                                </div> : null}
                                {x.exploitScore ? <div className="flex-shrink flex-grow flex flex-col">
                                    <div className="inline-flex pb-1 justify-between">
                                        <span className="flex-shrink pr-5 overflow-x-hidden text-ellipsis">Exploit Score</span>
                                        {x.exploitScore}
                                    </div>
                                    <Line score={Math.round(x.exploitScore * 10)} />
                                </div> : null}
                            </div>

                            <p title="cvss vector string" className="text-center text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider py-2">{x.cvssData.vectorString}</p>

                            <div className="flex flex-grow bg-light-seconday dark:bg-dark-nav rounded-lg w-full">
                                <div className="flex flex-grow flex-col lg:flex-row flex-wrap justify-around w-full">
                                    {
                                        [
                                            { title: "CVSS", value: "v" + x.cvssData.version },
                                            { title: "Attack Vector", value: x.cvssData.attackVector },
                                            { title: "Attack Complexity", value: x.cvssData.attackComplexity },
                                            { title: "Privileges Required", value: x.cvssData.privilegesRequired },
                                            { title: "User Interaction", value: x.cvssData.userInteraction },
                                            { title: "Scope", value: x.cvssData.scope },
                                            { title: "Confidentiality Impact", value: x.cvssData.confidentialityImpact },
                                            { title: "Integrity Impact", value: x.cvssData.integrityImpact },
                                            { title: "Availability Impact", value: x.cvssData.availabilityImpact },
                                        ].map((y, i) => <div title={y.title} key={i} className="flex flex-col flex-grow gap-2 py-2 xl:py-4">
                                            <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">{y.title}</p>
                                            <p className="mx-auto flex-grow text-sm font-semibold text-gray-600 dark:text-gray-400">{y.value}</p>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div> : null}

                {(cveInfo.cveWeaknesses?.length ?? 0) > 0 ? <div className="flex flex-col gap-2">
                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">Weaknesses:</p>
                    {cveInfo.cveWeaknesses?.map((x, i) => <div className="flex flex-grow bg-light-seconday dark:bg-dark-nav rounded-lg w-full">
                        <div className="flex flex-row flex-wrap flex-grow gap-2 py-2 text-center">
                            <div className="flex flex-grow flex-col md:flex-row gap-2 flex-nowrap justify-evenly" key={i}>
                                <div className="my-auto flex flex-col">
                                    <p className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase">Type</p>
                                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">{x.type}</p>
                                </div>
                                <div className="my-auto flex flex-col">
                                    <p className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase">Source</p>
                                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">{x.source}</p>
                                </div>
                                <div className="my-auto flex flex-col">
                                    <p className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase">Description</p>
                                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">{x.description.map((y, i) => <span key={i}>{y.language}: {y.value}</span>)}</p>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div> : null}

                {(cveInfo.cveConfigurations?.length ?? 0) > 0 ? <>
                    <p className="w-fit cursor-pointer text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider" title={showCPEConfigurations ? "Click to hide CPE configurations" : "Click to show CPE configurations"} onClick={() => void setShowCPEConfigurations(!showCPEConfigurations)}>{showCPEConfigurations ? "Hide " : "Show "}CPE Configurations</p>
                    <Collapse in={showCPEConfigurations}>
                        <div className="flex flex-col divide-y divide-light-secondary gap-2">
                            {cveInfo.cveConfigurations?.map((x, i) => x.cpeMatch.length > 0 ? <CpeConfiguration key={i} cpe={x} /> : null)}
                        </div>
                    </Collapse>
                </> : null}

                {(cveInfo.cveReferences?.length ?? 0) > 0 ? <>
                    <p className="w-fit cursor-pointer text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider" title={showReferences ? "Click to hide references" : "Click to show references"} onClick={() => void setShowReferences(!showReferences)}>{showReferences ? "Hide " : "Show "}References</p>
                    <Collapse in={showReferences}>
                        <div className="flex flex-col gap-4">
                            <CveReferences cveReferences={cveInfo.cveReferences} />
                        </div>
                    </Collapse>
                </> : null}
            </div> : null}
        </div> : <div>
            Please press "search" or press enter to begin the CVE search.
        </div>}
    </GenericErrorBoundary>;
}
