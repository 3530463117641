import { useState } from "react";
import { ListAWSAccounts } from "../../../graphql/queries/ListAWSAccountIds";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { NewAWSAccount } from "./NewAWSAccount";
import { SimpleError } from "../../../errors/SimpleError";
import logGraphQLError from "../../../functions/logGraphQLError";

type WizardProps = {
  onActivated: () => void;
  path: string;
};

export function WizardStep2({ onActivated, path }: WizardProps): JSX.Element {
  onActivated();
  const [selection, setSelection] = useState("");

  const {
    data: listAWSIdsData,
    loading: listAWSIdsLoading,
    error: listAWSIdsError,
  } = useQuery(ListAWSAccounts, { fetchPolicy: "cache-and-network", errorPolicy: "all", onError: logGraphQLError, });

  if (listAWSIdsData && !listAWSIdsLoading) {
    if (
      listAWSIdsData &&
      listAWSIdsData.listAWSAccounts &&
      listAWSIdsData.listAWSAccounts.length > 1
    ) {
      !selection && setSelection("new");
    }

    if (
      listAWSIdsData &&
      listAWSIdsData.listAWSAccounts &&
      listAWSIdsData.listAWSAccounts.length <= 1
    ) {
      !selection && setSelection("new");
    }
  }

  if (listAWSIdsError) {
    return <SimpleError message={listAWSIdsError} />;
  }

  return (
    <main className="flex-1">
      <div className="bg-white dark:bg-dark-main shadow-xl rounded-2xl sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden px-4 py-4 sm:px-10 mx-auto">
        {/* <button
          type="button"
          className="py-2 px-2 inline-flex items-center text-sm leading-4 font-medium rounded-md text-black  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ArrowNarrowLeftIcon
            onClick={() => {
              if (
                !selection ||
                selection === "old-account" ||
                selection === "new"
              ) {
                push(`/applications`);
              }
              setSelection("");
            }}
            className="hover:-translate-x-1 cursor-pointer h-8 w-8 mr-5 text-md font-medium text-black"
          />
          back
        </button> */}
        {/* <Image /> */}
        <br />
        <div className="max-w-lg mx-auto flex items-center space-x-2">
          {selection === "old-account" && (
            <>
              <button
                onClick={() => setSelection("old")}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  bg-red-700 text-white hover:bg-red-900 cursor-pointer"
              >
                Select from list
              </button>
              <button
                onClick={() => setSelection("new")}
                className="w-full flex justify-center py-2 px-4 border
            border-transparent rounded-md shadow-sm text-sm font-medium
            bg-red-700 text-white hover:bg-red-900 cursor-pointer"
              >
                Create new
              </button>
            </>
          )}
          {selection === "new" && (
            <NewAWSAccount onActivated={() => onActivated} path={path} />
          )}

          {/* {selection === "old" && (
            <FromAWSAccountList onActivated={() => onActivated} path={path} />
          )} */}
        </div>
      </div>
    </main>
  );
};
