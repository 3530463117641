import { useQuery } from "@apollo/client";
import { GET_LIST_CONTAINERS_BYORG } from "../../../../graphql/queries/getListOfContainersByOrg";
import { useUser } from "../../../../functions/hooks/useUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import logGraphQLError from "../../../../functions/logGraphQLError";

export function SelectContainers({
  accountName,
  account,
  containerList,
  setContainerList,
}: {
  accountName: string;
  account: string;
  containerList: string[];
  setContainerList: (value: string[]) => void;
}) {
  const user = useUser();

  const { loading, error, data, refetch } = useQuery(
    GET_LIST_CONTAINERS_BYORG,
    {
      variables: {
        orgId: user?.selectedOrg,
        account: account,
        accountName: accountName,
      },
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
      onError: logGraphQLError,
    },
  );

  if (error) {
    return (
      <p className="text-red-600 cursor-pointer" onClick={() => void refetch()}>
        Error retrieving containers. Please contact support?
      </p>
    );
  }
  if (loading) {
    return (
      <span className="Loader">
        <CircularProgress size={50} className="mr-[40%] ml-[40%] mt-2" />
      </span>
    );
  }

  function handleItemSelection(item: string) {
    // Check if the item is already in the selectedItems array
    const isSelected = containerList.includes(item);

    // If it's selected, remove it; otherwise, add it
    if (isSelected) {
      setContainerList(containerList.filter((selected) => selected !== item));
    } else {
      setContainerList([...containerList, item]);
    }
  }

  return (
    <fieldset>
      <div className="mt-4 mr-5 divide-y divide-gray-200 border-b border-t border-gray-200">
        {data?.getListOfContainersByOrg?.map(
          (
            container: { name: string; namespace: string },
            containerIdx: string,
          ) => (
            <div key={containerIdx} className="relative flex items-start py-4">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label
                  htmlFor={`person-${container.name}`}
                  className="select-none font-medium text-gray-900 dark:text-gray-400"
                >
                  {container.name}
                </label>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  onClick={() => handleItemSelection(container.namespace)}
                  id={`person-${container.name}`}
                  name={`person-${container.name}`}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
            </div>
          ),
        )}
      </div>
    </fieldset>
  );
}
