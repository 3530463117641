import { enqueueSnackbar } from "notistack";

type BoolOrString = string | boolean | null | undefined;

export function joinClassNames(...classes: BoolOrString[]) {
  return classes.filter((x) => x).join(" ");
}

export function titleCase(value: string) {
  if (!value) return value;
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

interface FeatureFlag {
  id: string;
  name: string;
  status: boolean;
}

export const getEnvironmentFeatureFlags = (): FeatureFlag[] => {
  const overriddenFlags =
    process.env.REACT_APP_FORCE_FF_ENABLE?.split(",") || [];
  const flags: FeatureFlag[] = overriddenFlags.map(
    (flag: string, index: number) => {
      return { id: String(index), name: flag, status: true };
    }
  );
  return flags;
};

export const isFeatureEnabled = (name: string, not: boolean = false) => {
  const shouldNot = not !== false;
  const featuresTemp: string | null = localStorage.getItem("flags");

  const features: FeatureFlag[] =
    featuresTemp &&
    (() => {
      try {
        return JSON.parse(featuresTemp);
      } catch {
        return [];
      }
    })();
  const overriddenFlags = getEnvironmentFeatureFlags();
  const feature =
    overriddenFlags?.find((feature: any) => feature.name === name) ||
    features?.find((feature: any) => feature.name === name);

  if (shouldNot && !feature?.status) {
    return true;
  }

  if (feature?.status) {
    return true;
  }
  return false;
};

enum Compliances {
  CIS8 = "CIS 8",
  // NIST218 = "NIST 218: SSDF",
  ISO27001 = "ISO 27001",
  SOC2 = "SOC 2",
  // NIST = "NIST 800-53b",
  ISM = "Australian ISM",
  ESSENTIAL8 = "Essential 8",
}

const paywallStrings = ["Pro", "Enterprise", "Starter", "Starter"];

export function shouldAllowComplianceView(compliance: string, plan: string) {
  if (!plan || !paywallStrings.includes(plan)) {
    return false;
  }

  return [
    Compliances.ISM,
    Compliances.CIS8,
    Compliances.ISO27001,
    Compliances.SOC2,
    Compliances.ESSENTIAL8,
  ].includes(compliance as Compliances);
}

export function copyToClipboardWithSnackbar(content?: string, contentTitle?: string) {
  if (content != null) {
    navigator.clipboard.writeText(content).then(() => {
      enqueueSnackbar(contentTitle != null ? `Copied ${contentTitle}` : "Copied", {
        variant: "success",
        persist: false,
      })
    });
  }
}
