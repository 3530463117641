import { isFeatureEnabled } from "../../functions/utilities";

type FeatureFlagProps = {
  name: string;
  children: JSX.Element | any;
  not?: boolean;
};

type SwitchFeatureProps = {
  name: string;
  component: JSX.Element;
  fallback?: JSX.Element | null;
  not?: boolean;
};

export function SwitchFeature({
  name,
  component,
  fallback = null,
  not = false,
}: SwitchFeatureProps): JSX.Element {
  if (isFeatureEnabled(name, not)) {
    return component;
  }

  return fallback ?? <></>;
};

export default function RenderFeature({
  name,
  children,
  not = false,
}: FeatureFlagProps): JSX.Element {
  /**
   * When wrapped around a component will only display that component if
   * the feature flag as specified by name exists and is set to true.
   *
   * Setting not to true will invert the logic and will only display the
   * component if the feature flag is set to false.
   */
  if (isFeatureEnabled(name, not)) {
    return <>{children}</>;
  }

  return <></>;
};
