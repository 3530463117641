import { SideBarMenuNav } from "./MenuNav";
import { AuthenticationButton } from "../../buttons/AuthenticationButton";
import { SidebarNavigationItem } from "./types";
import { SecondaryNavBar } from "./SecondaryNavBar";
import { lazy, Suspense } from "react";
import { svgImport } from "../../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../../assets/small-logo.svg").then(svgImport));

type DesktopSidebarProps = {
  navItems: SidebarNavigationItem[];
  secondaryNavItems: SidebarNavigationItem[];
  currentPath: string;
};

export function DesktopSidebar({
  navItems,
  currentPath,
  secondaryNavItems,
}: DesktopSidebarProps): JSX.Element {
  return (
    <div className="hidden md:flex md:w-[112px] md:flex-col md:fixed md:inset-y-0">
      <div className="flex border flex-col flex-grow overflow-y-auto w-[112px] bg-gray-200 dark:bg-dark-secondary dark:border-none border-none shadow-md">
        <div className="w-full pb-8 pt-2 flex flex-col items-center h-full">
          <div className="flex pt-2">
            <Suspense fallback={<></>}><SecureStackLogo className="mx-auto my-auto w-[42px] h-auto" /></Suspense>
          </div>
          <SideBarMenuNav
            navItems={navItems}
            currentPath={currentPath}
            secondaryNavItems={secondaryNavItems}
          />
        </div>
        <SecondaryNavBar
          currentPath={currentPath}
          secondaryNavItems={secondaryNavItems}
        />

        <div className="items-center p-4">
          <AuthenticationButton />
        </div>
      </div>
    </div>
  );
};
