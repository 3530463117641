import { MouseEvent } from "react";

type ButtonProps = {
  title?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  amplitudeEventName: string;
};

export function Button({ title, onClick, amplitudeEventName }: ButtonProps): JSX.Element {
  return (
    <button
      type="submit"
      onClick={onClick}
      data-amplitude-event-name={amplitudeEventName}
      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  bg-red-700 text-white hover:bg-red-900 cursor-pointer"
    >
      {title ?? "Continue"}
    </button>
  );
};
