import { Dispatch, useEffect, useState } from "react";
import { BsCodeSlash } from "react-icons/bs";
import { IoWarning } from "react-icons/io5";
import CreateIssue from "../../../components/jira/CreateIssue";
import { ParsedCodeIssueRow } from "../../../functions/hooks/useApplicationIssues";
import { Tabber } from "../exposure/SelectedExposureIssueView";
import { PackageCard } from "./PackageCard";
import { PackageVersionList } from "./PackageVersionList";
import { Solution } from "../exposure/Solution";
import { SelectedIssue } from "../../../App";
import { getBorderColor, getTextColor } from "../../../functions/severityFunctions";
import { joinClassNames, titleCase } from "../../../functions/utilities";

const tabs = ["Details", "Solution", "Create Ticket"];
type SelectedCodeIssueViewTabs = typeof tabs[number];
type SelectedCodeIssueViewProps = {
  selectedIssue: ParsedCodeIssueRow;
  setSelectedIssue: Dispatch<SelectedIssue>;
  allIssues: ParsedCodeIssueRow[];
};

// where selectedIssue is the item the user clicked "view" for, and the allIssues are the associated results
export function SelectedCodeIssueView({ selectedIssue, setSelectedIssue, allIssues }: SelectedCodeIssueViewProps): JSX.Element {
  const [currentPackage, setCurrentPackage] = useState<ParsedCodeIssueRow>();
  const [tab, setTab] = useState<SelectedCodeIssueViewTabs>("Details");
  useEffect(() => {
    if (allIssues.length > 0) {
      setCurrentPackage(allIssues.at(0))
    } else {
      setCurrentPackage(selectedIssue);
    }
  }, [selectedIssue, allIssues, setCurrentPackage]);

  if (!selectedIssue) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <IoWarning className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Issue not found. Please refresh and try again. If this issue persists, please contact support.
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dark:bg-dark-main rounded-lg bg-white py-4 px-4 space-y-2">
      <div className="flex flex-row justify-between my-auto items-center flex-nowrap mb-4">
        <div className="flex flex-row flex-nowrap items-center">
          <div className="pr-4 my-auto">
            <BsCodeSlash className="w-6 h-6 text-code-icon-active" />
          </div>
          {currentPackage?.packageName == null ? null : <h3 className="text-xl font-semibold tracking-tight leading-6 text-gray-900 dark:text-gray-300">
            {currentPackage.packageName}
          </h3>}
        </div>
        <div className="flex gap-2">
          {currentPackage?.vulnerableVersion == null ? null : <span className="py-1 px-2 rounded text-gray-400 text-xs font-medium tracking-widest my-auto">
            Version: {currentPackage.vulnerableVersion}
          </span>}
          {currentPackage?.severity == null ? null : <div className="flex flex-shrink-0">
            <span
              className={joinClassNames(
                getBorderColor(currentPackage.severity),
                getTextColor(currentPackage.severity),
                "inline-block py-1 px-4 text-sm font-medium border-l-2"
              )}
            >
              {titleCase(currentPackage.severity)}
            </span>
          </div>}
        </div>

      </div>
      <Tabber displayStyle="code" tabs={tabs} currentTab={tab} setCurrentTab={setTab} />
      {
        tab === "Details" && <div className="flex md:flex-row flex-col gap-3">
          {allIssues.length > 0 ? <div className="md:w-2/6 mt-2 truncate">
            <PackageVersionList
              currentPackage={currentPackage}
              setCurrentPackage={setCurrentPackage}
              items={allIssues}
              id={"pvl"}
            />
          </div> : null}
          {currentPackage ? <div className={`${allIssues.length > 0 ? "md:w-4/6" : "w-full"} mt-2 rounded-lg`}>
            <PackageCard selectedIssue={selectedIssue} currentPackage={currentPackage} setSelectedIssue={setSelectedIssue} />
          </div> : null}
        </div>
      }
      {
        tab === "Create Ticket" ? <CreateIssue issue={selectedIssue?.issueTitle ?? selectedIssue.packageName ?? ""} /> : null
      }
      {
        tab === "Solution" ? <Solution solution={currentPackage?.remediation ?? selectedIssue.remediation ?? ""} /> : null
      }
    </div>
  );
};
