import { AiOutlineCloud } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { MdSecurity } from "react-icons/md";
import GoGistSecret from "../assets/images/GoGistSecret";

export const getBorderColor = (severity: string) => {
  switch (severity?.toLowerCase()) {
    case "critical":
      return "border-l-red-700";
    case "high":
      return "border-red-500";
    case "moderate":
      return "border-orange-400";
    case "medium":
      return "border-orange-400";
    case "low":
      return "border-yellow-400";

    default:
      return "border-gray-500";
  }
};

export const getTextColor = (severity: any) => {
  switch (severity?.toLowerCase()) {
    case "critical":
      return "text-red-700 ";
    case "high":
      return "text-red-500";
    case "moderate":
      return "text-orange-400";
    case "medium":
      return "text-orange-400";
    case "low":
      return "text-yellow-400";

    default:
      return "text-gray-500";
  }
};

export const getBackgroundColor = (severity: any) => {
  switch (severity?.toLowerCase()) {
    case "critical":
      return "bg-red-700";
    case "high":
      return "bg-red-500";
    case "moderate":
      return "bg-orange-400";
    case "medium":
      return "bg-orange-400";
    case "low":
      return "bg-yellow-400";
    default:
      return "bg-gray-500";
  }
};

export const getIcon = (source: any) => {
  switch (source?.toLowerCase()) {
    case "exposure":
      return <MdSecurity className="w-5 h-5 text-orange-400" />;
    case "containers":
      return <MdSecurity className="w-5 h-5 text-orange-400" />;
    case "code":
      return <BsCodeSlash className="w-5 h-5 text-green-400" />;
    case "cloud":
      return <AiOutlineCloud className="w-5 h-5 text-blue-400" />;
    case "secret":
      return <GoGistSecret className="w-5 h-5 text-pink-400" />;
    case "secrets":
      return <GoGistSecret className="w-5 h-5 text-pink-400" />;
    case "aws_account":
      return <GoGistSecret className="w-5 h-5 text-pink-400" />;
    case "alb":
      return <GoGistSecret className="w-5 h-5 text-pink-400" />;
    case "ec2":
      return <GoGistSecret className="w-5 h-5 text-pink-400" />;
    default:
      return "No icon found";
  }
};
