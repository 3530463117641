import { useState } from "react";
import { Application } from "../../__generated__/graphql";
import { useQuery } from "@apollo/client";
import { APPLICATION_STATUS } from "../../graphql/queries/ApplicationStatus";
import { User } from "../../apollo/LocalizedSchema";
import logGraphQLError from "../logGraphQLError";
import {
  ASSET_STATUS_TYPES,
  BETA_SCA,
  STATUS_TYPES_CLOUD,
  STATUS_TYPES_CONTAINER,
  STATUS_TYPES_RECON,
  STATUS_TYPES_SBOM,
  STATUS_TYPES_SCA,
} from "./useApplicationStatus";

export type ApplicationStatus = {
  scanningRecon: boolean;
  scanningAsset: boolean;
  scanningSca: boolean;
  scanningBsca: boolean;
  scanningSbom: boolean;
  scanningCloud: boolean;
  scanningContainer: boolean;
};

// not intended to be used more than once, intended to be used only at a high level, i.e the ApplicationStatusWrapper level. Instead, pass the data down the react tree to the component you need to access the shared state, rather than using this hook. See DashboardView2 and AnalyzeButton for example
export default function useAppStatus(
  user: User | null,
  appId: string | undefined,
) {
  const [appStatusData, setAppStatusData] = useState<Application>(); // for backwards comp

  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(
    {
      scanningRecon: false,
      scanningAsset: false,
      scanningBsca: false,
      scanningCloud: false,
      scanningSbom: false,
      scanningContainer: false,
      scanningSca: false,
    },
  );

  const applicationStatusQuery = useQuery(APPLICATION_STATUS, {
    variables: {
      applicationId: appId,
      orgId: user?.selectedOrg,
    },
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onError: logGraphQLError,

    onCompleted: (data) => {
      let d = data.dashboard?.application;

      if (d == null) {
        applicationStatusQuery.stopPolling();
        return;
      }
      applicationStatusQuery.startPolling(6000);

      setAppStatusData(d);

      const reconInProgress: boolean =
        d.reconStatus === STATUS_TYPES_RECON.IN_PROGRESS;
      //const reconFailed: boolean = d.reconStatus === STATUS_TYPES_RECON.FAILED;
      //const reconCompleted: boolean = d.reconStatus === STATUS_TYPES_RECON.COMPLETED;

      const sbomInProgress: boolean =
        d.sbomStatus === STATUS_TYPES_SBOM.IN_PROGRESS;
      //const sbomFailed: boolean = d.sbomStatus === STATUS_TYPES_SBOM.ERROR;
      //const sbomCompleted: boolean = d.sbomStatus === STATUS_TYPES_SBOM.COMPLETED;

      const betaScaInProgress: boolean =
        d.status ===
        BETA_SCA.BETA_SCA_IN_PROGRESS; /* || d.status === BETA_SCA.BETA_SCA_STARTED */
      //const betaScaFailed: boolean = d.status === BETA_SCA.BETA_SCA_ERROR;
      //const betaScaCompleted: boolean = d.status === BETA_SCA.BETA_SCA_COMPLETE;

      const scaInProgress: boolean =
        d.scaStatus === STATUS_TYPES_SCA.IN_PROGRESS;
      //const scaFailed: boolean = d.scaStatus === STATUS_TYPES_SCA.FAILED;
      //const scaCompleted: boolean = d.scaStatus === STATUS_TYPES_SCA.COMPLETED;

      const cloudInProgress: boolean =
        d.cloudStatus === STATUS_TYPES_CLOUD.IN_PROGRESS;

      const containerInProgress: boolean =
        d.containerStatus === STATUS_TYPES_CONTAINER.IN_PROGRESS;

      const assetInProgress: boolean =
        d.assetStatus === ASSET_STATUS_TYPES.IN_PROGRESS ||
        d.assetStatus === ASSET_STATUS_TYPES.STARTED ||
        d.assetStatus === ASSET_STATUS_TYPES.PASSIVE_COMPLETE ||
        d.assetStatus === ASSET_STATUS_TYPES.SIMPLE_COMPLETE;
      //const assetFailed: boolean = d.assetStatus === ASSET_STATUS_TYPES.ERROR;
      //const assetCompleted: boolean = d.assetStatus === ASSET_STATUS_TYPES.COMPLETED;

      if (
        !betaScaInProgress &&
        !scaInProgress &&
        !reconInProgress &&
        !cloudInProgress &&
        !containerInProgress &&
        !sbomInProgress
      ) {
        applicationStatusQuery.stopPolling();
      }

      setApplicationStatus({
        scanningRecon: reconInProgress,
        scanningAsset: assetInProgress,
        scanningBsca: betaScaInProgress,
        scanningCloud: cloudInProgress,
        scanningSbom: sbomInProgress,
        scanningSca: scaInProgress,
        scanningContainer: containerInProgress,
      });
    },
  });

  return {
    appStatusData,
    setAppStatusData,
    applicationStatus,
    setApplicationStatus,
    applicationStatusQuery,
  };
}
