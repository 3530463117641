import { BsPlusLg } from "react-icons/bs";
import { Button } from "../../components/buttons/Button";
import dashboardImage from "../../assets/securestack-code-cloud-app-transparent.png";
import issuesImageDark from "../../assets/issues-dark.png";
import issuesImageWhite from "../../assets/issues-white.png";
import assetImageWhite from "../../assets/assets-white.png";
import assetImageDark from "../../assets/assets-dark.png";
import complianceImageDark from "../../assets/compliance-dark.png";
import complianceImageWhite from "../../assets/compliance-light.png";
import sbomImageDark from "../../assets/sbom-dark.png";
import { ThemeContext } from "../../functions/usePrefersDarkMode";
import { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

enum RouteNames {
  DASHBOARD = "dashboard",
  ISSUES = "issues",
  ASSETS = "assets",
  COMPLIANCE = "compliance",
  SBOM = "sbom",
  CHANGES = "changes",
}

export function NoApplications(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { push } = useHistory();

  const match = useRouteMatch<{ action?: string }>("/:action");

  const pathToImageMap = new Map<string, string>([
    [RouteNames.DASHBOARD, dashboardImage],
    [RouteNames.ISSUES, theme ? issuesImageDark : issuesImageWhite],
    [RouteNames.ASSETS, theme ? assetImageDark : assetImageWhite],
    [RouteNames.COMPLIANCE, theme ? complianceImageDark : complianceImageWhite],
    [RouteNames.SBOM, sbomImageDark],
    [RouteNames.CHANGES, dashboardImage],
  ]);

  const pathToFeatureInfo = new Map<
    string,
    { title: string; description: string }
  >([
    [
      RouteNames.DASHBOARD,
      {
        title: "Our Solution: Complete Security Coverage",
        description:
          "Analyze and protect your source code, cloud stack, and web assets with one solution. Gain visibility, meet compliance and solve complex problems with SecureStack.",
      },
    ],
    [
      RouteNames.ISSUES,
      {
        title: "Contextual insights from the whole SDLC",
        description:
          "Issues found are prioritized based on attackability and your company's  specific risk and threat landscape.",
      },
    ],
    [
      RouteNames.ASSETS,
      {
        title: "You can't secure what you can't see",
        description:
          "Our continuous asset discovery service means you always know what assets you have, and if they're vulnerable.",
      },
    ],
    [
      RouteNames.COMPLIANCE,
      {
        title: "Continuous compliance made easy",
        description:
          "Use SecureStack to jump start your compliance program.  Identify the areas where you're doing well, and get specific feedback on how to address any gaps found. ",
      },
    ],
    [
      RouteNames.SBOM,
      {
        title: "Generate an SBOM automatically",
        description:
          "SecureStack automates the collection of data and creates SBOMs that describe the whole application environment, not just one tiny part.",
      },
    ],
    [
      RouteNames.CHANGES,
      {
        title: "Track your Application Changes over time",
        description:
          "SecureStack monitors and detects changes in your environment to give you a view that shows the tangible ways your application has changed.",
      },
    ],
  ]);

  const img = pathToImageMap.get(match?.params?.action!);
  const info = pathToFeatureInfo.get(match?.params?.action!);

  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="dark:bg-dark-main bg-white rounded-2xl shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold dark:text-gray-300 sm:text-4xl">
              <span className="block">{info?.title}</span>
            </h2>
            <p className="mt-4 text-lg leading-6 dark:text-gray-400">
              {info?.description}
            </p>
            <div className="grid grid-cols-2 grid-rows-1 gap-5">
              <Button
                className="mt-8"
                primary
                onClick={() => push("/onboarding")}
                label="GUIDED ONBOARDING"
                icon={
                  <BsPlusLg
                    className="-ml-0.5 mr-2 h-4 w-4 dark:text-gray-800 text-white"
                    aria-hidden="true"
                  />
                }
              />
              <Button
                className="mt-8"
                primary
                onClick={() => push("/create")}
                label="ADD APP"
                icon={
                  <BsPlusLg
                    className="-ml-0.5 mr-2 h-4 w-4 dark:text-gray-800 text-white"
                    aria-hidden="true"
                  />
                }
              />

            </div>

          </div>
        </div>
        <div className=" -mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 ">
          <img
            className="transform  shadow-2xl px-4 py-4 translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-5 lg:translate-y-5"
            src={img}
            alt={`${match?.params?.action!} screenshot`}
          />
        </div>
      </div>
    </div>
  );
};
