export const modalDataMap = new Map<string, { info: string }>([
  [
    "name",
    {
      info: "Give your application a simple name to use across all of SecureStack. Examples of great names are 'Casino Royale' and 'Halloween Microsite'. Examples of bad names would be 'BE - Graphql API' or 'FE - React App'.",
    },
  ],
  [
    "tags",
    {
      info: "Tags are a great way to categorize your apps for example 'Backend', 'JavaScript', 'Production' etc. They can be used to group and filter your applications. Use the tag picker on the right of the tag input box to choose from tags that have been used before on your account. We recommend Service Providers add their customer as a tag on their Apps so you can easily search by customer.",
      // TODO: Add text once feature is verified
      // [Hide the following behind feature flag 'rbac']They can even be used to group applications and give users special permissions. For example you may have multiple dev teams that should only have access to certain groups of apps. The Insurance dev team might have access to only your 'insurance' tagged apps[End Hide].
    },
  ],
  [
    "scm",
    {
      info: "SecureStack provides integrations with common centralized git repositories such as Bitbucket, GitHub and GitLab that allow us to find and analyze package dependency files for potential vulnerabilities. Depending on where your application code is stored, select your Source Code Manager (SCM) provider.",
    },
  ],
  [
    "workspace",
    {
      info: "Repositories are often contained inside logical organizational groupings called workspaces/organizations or may simply be inside your personal workspace. If you cannot see your workspace or organization here, you may need to contact your organization's SCM administrator to request the correct access and re-integrate your SCM provider.",
    },
  ],
  [
    "organization",
    {
      info: "Repositories are often contained inside logical organizational groupings called workspaces/organizations or may simply be inside your personal workspace. If you cannot see your workspace or organization here, you may need to contact your organization's SCM administrator to request the correct access and re-integrate your SCM provider.",
    },
  ],
  [
    "repository",
    {
      info: "Select your repository name from the following list. Only repositories located in the above selected workspace or organization will be visible in the list below. If you cannot see your repository, it may not be in the workspace or organization you expect or you may need to ask your SCM administrator for permissions and re-integrate your SCM provider.",
    },
  ],
  [
    "branch",
    {
      info: "Select the branch of code you wish to scan from the following list. If you'd like to scan multiple branches you may setup an application for each branch that you wish to scan. Typically our customers will scan their 'main' branch as well as their 'release' and 'dev' branches.",
    },
  ],
  [
    "detected",
    {
      info: "We've detected the following package dependency listings inside your repository in the branch you selected. If we've been unable to detect any dependency files, or the files we've detected aren't the ones you expected, you can select 'Locate files' to manually navigate to the dependency files yourself. You may also need to check that you have selected the correct branch and that the files you expect are currently pushed to your SCM.",
    },
  ],
]);
