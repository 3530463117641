import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useState, MouseEvent, MouseEventHandler } from "react";
import { FiEdit } from "react-icons/fi";
import { Button } from "../../../../components/buttons/Button";
import { Modal } from "../../../../components/dialogs/Modal";
import { InputField } from "../../../../components/forms/InputField";
import { useUser } from "../../../../functions/hooks/useUser";
import { titleCase } from "../../../../functions/utilities";
import {
  CodeSettings,
  SCM_SELECTION,
} from "../../../create/categories/CodeSettings";
import { useHistory } from "react-router-dom";
import { FETCH_SCM_DETAILS } from "../../../../graphql/queries/FetchSCMDetails";
import { SCM_BRANCH_NAMES } from "../../../create/categories/repository/SelectRepository";
import { FetchSCMDetailsQuery } from "../../../../__generated__/graphql";
import logGraphQLError from "../../../../functions/logGraphQLError";

type CodeProps = {
  scm: string;
  workspace: string;
  group: string;
  organization: string;
  repository: string;
  language: string;
  branch: string;
  refetch: () => void;
};

type fetchSCMDetailsType = {
  ghowner?: string;
  bbusername?: string;
  azureUsername?: string;
  gitlabUsername?: string;
  patAZ?: string;
};

export type FetchSCMDetails = {
  userSCMDetails: fetchSCMDetailsType;
};

export const getScmProviderDetails = (
  data: FetchSCMDetailsQuery | undefined
) => {
  return [
    {
      id: "0",
      name: "Select SCM provider",
      unavailable: false,
    },
    {
      id: "1",
      name: "Bitbucket",
      unavailable:
        data?.userSCMDetails?.bbusername == null ||
        data?.userSCMDetails?.bbusername === "0",
    },
    {
      id: "2",
      name: "GitHub",
      unavailable:
        data?.userSCMDetails?.ghowner == null ||
        data?.userSCMDetails?.ghowner === "0",
    },
    {
      id: "3",
      name: "Gitlab",
      unavailable:
        data?.userSCMDetails?.gitlabUsername == null ||
        data?.userSCMDetails?.gitlabUsername === "0",
    },
    {
      id: "4",
      name: "Azure DevOps",
      unavailable:
        !data?.userSCMDetails?.azureUsername && !data?.userSCMDetails?.patAZ,
    },
  ];
};

export function Code({
  scm,
  group,
  workspace,
  organization,
  repository,
  language,
  branch,
  refetch,
}: CodeProps): JSX.Element {
  const user = useUser();
  const { push, replace } = useHistory();
  const { BITBUCKET, GITHUB, AZURE, GITLAB } = SCM_SELECTION;

  const { data } = useQuery(FETCH_SCM_DETAILS, {
    variables: {
      orgId: user?.selectedOrg ?? "",
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onError: logGraphQLError,
  });

  const [isEditModalShowing, setIsEditModalShowing] = useState(false);
  const [appScm] = useState(
    scm
      ? {
        id: (() => {
          if (scm === BITBUCKET) {
            return "1";
          }
          if (scm === GITHUB) {
            return "2";
          }
          if (scm === GITLAB) {
            return "3";
          }
          if (scm === AZURE) {
            return "4";
          }
          return "2";
        })(),
        name: titleCase(scm),
        unavailable: false,
      }
      : getScmProviderDetails(data)[0]
  );

  const getSCMProvider = (scm: string) => {
    if (scm === BITBUCKET) {
      return workspace;
    } else if (scm === GITHUB) {
      return organization;
    } else if (scm === GITLAB) {
      return group;
    } else if (scm === AZURE) {
      return organization;
    }
    return organization;
  };

  const isCodeSetupNotComplete = () => {
    return [scm, getSCMProvider(scm), repository, language, branch].includes(
      ""
    );
  };

  const { WORKSPACE, ORGANIZATION, GROUP } = SCM_BRANCH_NAMES;

  function openCodeSettingsDialogue(e: MouseEvent<HTMLElement>) {
    push({
      search: `?appScm=${scm.toString()}&${(() => {
        if (scm === BITBUCKET || appScm.name === BITBUCKET) {
          return WORKSPACE;
        }
        if (scm === GITHUB || appScm.name === GITHUB) {
          return ORGANIZATION;
        }
        if (scm === GITLAB || appScm.name === GITLAB) {
          return GROUP;
        }
        if (scm === AZURE || appScm.name === AZURE || scm === "azure") {
          return ORGANIZATION;
        }
        return ORGANIZATION;
      })()}=${workspace?.toString() ?? group?.toString() ?? organization?.toString()
        }&repository=${repository}&branch=${branch}&packageManager=${language}`,
    });
    setIsEditModalShowing(true);
  }

  function closeCodeSettingsDialogue(e: boolean) {
    setIsEditModalShowing(false);
    replace(window.location.pathname);
  }

  return (
    <div className="rounded-xl bg-white dark:bg-dark-main px-4 py-4">
      <div className="space-y-2">
        <div className="flex space-x-2 items-center">
          <h1 className="text-gray-800 dark:text-gray-200 text-lg">
            Code Settings
          </h1>
          <Button
            title="Edit this app's SCM settings (select your SCM provider, organization, repository, branch and package manager)"
            className="shadow-none"
            onClick={openCodeSettingsDialogue}
            icon={<FiEdit className="w-5 h-5 text-red-500" />}
          />
        </div>
        <CompletionAlert
          setIsEditModalShowing={openCodeSettingsDialogue}
          isShowing={isCodeSetupNotComplete()}
        />

        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          SCM Provider
        </label>
        <InputField disabled name="scm" type="text" value={appScm.name} />
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          {scm === SCM_SELECTION.BITBUCKET ? "Workspace" : "Organization"}
        </label>
        <InputField
          disabled
          name="orgOrWorkspace"
          type="text"
          value={scm === SCM_SELECTION.BITBUCKET ? workspace : organization}
        />
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          Repository
        </label>
        <InputField
          disabled
          name="repository name"
          type="text"
          value={repository}
        />
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          Branch
        </label>
        <InputField disabled name="branch" type="text" value={branch} />
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          Package Manager
        </label>
        <InputField
          disabled
          name="package manager"
          type="text"
          value={language}
        />
        <Modal noVerticalMargin isOpen={isEditModalShowing} onClose={closeCodeSettingsDialogue}>
          <CodeSettings
            isEditing={true}
            closeEditModal={() => {
              refetch();
              setIsEditModalShowing(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

export function CompletionAlert({
  isShowing,
  setIsEditModalShowing,
}: {
  isShowing: boolean;
  setIsEditModalShowing: MouseEventHandler<HTMLDivElement>;
}): JSX.Element {
  return (
    <div
      className={`${isShowing ? "flex" : "hidden"
        } py-2 px-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800 w-fit cursor-pointer`}
      role="alert"
      onClick={setIsEditModalShowing}
    >
      <svg
        aria-hidden="true"
        className="flex-shrink-0 inline w-5 h-5 mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Info</span>
      <div>
        <span className="font-medium">
          It looks like you're missing some settings.{" "}
        </span>{" "}
        Click to resolve now
      </div>
    </div>
  );
}
