import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { joinClassNames } from "../../functions/utilities";
import { Loader } from "../loading/Loader";

type StringCBProps = {
  defaultValue?: string;
  isLoading?: boolean;
  items: string[];
  lastIdxRef?: (node: HTMLElement) => void;
  selected: string;
  onSelection: (value: string) => void;
  Pagination?: JSX.Element | null;
  testId?: string;
};

type ObjectCBProps<T> = {
  defaultValue?: string;
  isLoading?: boolean;
  items: T[];
  lastIdxRef?: (node: HTMLElement) => void;
  selected: T;
  onSelection: (value: T) => void;
  primaryKey: keyof T;
  valueKey: keyof T;
  Pagination?: JSX.Element | null;
  testId?: string;
};

type RecordTuple = [string, string];

type GlobalComboProps = {
  defaultValue?: string;
  selected: any;
  Pagination?: JSX.Element | null;
  lastIdxRef?: (node: HTMLElement) => void;
  isLoading?: boolean;
  items: RecordTuple[];
  onSelection: (value: string | RecordTuple) => void;
  testId?: string;
};

export function TheOneCombobox(props: ObjectCBProps<any> | StringCBProps) {
  if ("primaryKey" in props) {
    return <ObjectComboBox {...props} />;
  }

  return <StringComboBox {...props} />;
}

export function ObjectComboBox<T extends unknown>(props: ObjectCBProps<T>) {
  const onSelection = (r: unknown) => {
    const item = props.items.find((i: T) => {
      return r === i[props.valueKey] || r == i;
    });

    if (!item) {
      return;
    }

    props.onSelection(item);
  };

  const items: RecordTuple[] = props.items.map((i: T) => [
    i[props.primaryKey] as string,
    i[props.valueKey] as string,
  ]);

  return (
    <GlobalCombo
      defaultValue={props.defaultValue}
      items={items}
      testId={props.testId}
      selected={props.selected[props.primaryKey] as string}
      onSelection={onSelection}
      isLoading={props.isLoading}
      lastIdxRef={props.lastIdxRef}
      Pagination={props.Pagination}
    />
  );
}

export function StringComboBox(props: StringCBProps) {
  const onSelection = (r: any) => {
    props.onSelection(r);
  };

  const items: RecordTuple[] = props.items.map((i: string) => {
    return [i, i];
  });

  return (
    <GlobalCombo
      defaultValue={props.defaultValue}
      items={items}
      testId={props.testId}
      selected={props.selected["id" as any] ?? props.selected}
      onSelection={onSelection}
      isLoading={props.isLoading}
      lastIdxRef={props.lastIdxRef}
      Pagination={props.Pagination}
    />
  );
}

function filterItems(query: string, items: RecordTuple[]): RecordTuple[] {
  // if no or empty query, show all items
  if (!query || query === "") {
    return items;
  }
  // find if we have selected any of the items
  /*const selectedItems = items.map((item: RecordTuple) => {
    var itemValue: string;
    if (!item) {
      return "";
    }
    if (typeof item === "string") {
      itemValue = item;
    } else {
      itemValue = item[1];
    }
    return itemValue?.toLowerCase() === query.toLowerCase();
  });
  // if we selected an item, don't search, instead show all items
  if (selectedItems.some((x) => x)) {
    return items;
  }*/
  // otherwise filter the items using simple includes search
  return items.filter((item: RecordTuple) => {
    var itemValue: string;
    if (typeof item === "string") {
      itemValue = item;
    } else {
      itemValue = item[1];
    }
    return itemValue?.toLowerCase().includes(query.toLowerCase());
  }).reverse();
}

function GlobalCombo({
  defaultValue,
  items,
  selected,
  onSelection,
  isLoading,
  lastIdxRef,
  Pagination,
  testId
}: GlobalComboProps) {
  const [query, setQuery] = useState<string>("");

  let filteredItems = filterItems(query, items);

  return (
    <Combobox
      defaultValue={defaultValue ?? query ?? ""}
      key={"name"}
      value={query !== "" ? query : selected}
      onChange={onSelection}
    >
      {({ open }) => (
        <div className="relative flex h-8">
          <Combobox.Button className="w-full absolute inset-y-0 right-0 items-center rounded-r-md focus:outline-none" data-test-id={`${testId}-button`}>
            <Combobox.Input
              className="w-full shadow-md h-9 rounded-md border border-gray-300 bg-white dark:bg-dark-primary py-2 pl-3 pr-10 text-gray-900 dark:text-gray-300 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
              data-test-id={`${testId}-input`}
              onChange={(event) => {
                setQuery(event.target.value);
                filteredItems = (filterItems(event.target.value, items));
              }}
              autoComplete="off"
              placeholder={defaultValue}
              onClick={(e: any) => {
                e?.target?.select();
              }}
              displayValue={(record: any) => {
                if (record == null) {
                  return "";
                }
                if (typeof record === "string") {
                  return record;
                } else {
                  return record[1];
                }
              }}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 mt-1"
                aria-hidden="true"
                style={{
                  transform: open ? "rotate(0deg)" : "rotate(90deg)",
                  transition: "transform ease-in-out 200ms 0ms",
                }}
              />
            </span>
            {isLoading && (
              <div className="absolute inset-y-0 right-0 top-1 flex items-center rounded-r-md focus:outline-none">
                <Loader w={4} h={4} />
              </div>
            )}
          </Combobox.Button>

          <Combobox.Options
            className={joinClassNames(
              "mt-8 absolute z-50 border-gray-500 border max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-main py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm list-none"
            )}
            data-test-id={`${testId}-options`}
          >
            {filteredItems?.length > 0 ? (
              filteredItems.map((record, idx) => (
                <Combobox.Option
                  key={record[0] as string + idx.toString()}
                  value={record[1]}
                  className={({ active }) =>
                    joinClassNames(
                      "list-none relative rounded cursor-default select-none py-2 pl-3 pr-9",
                      active
                        ? "bg-red-600 text-white"
                        : "text-gray-900 dark:text-gray-400"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      {filteredItems.length - 1 === idx ? (
                        <span
                          className={joinClassNames(
                            "block truncate ",
                            selected && "font-semibold"
                          )}
                          ref={lastIdxRef}
                        >
                          {record[1]}
                        </span>
                      ) : (
                        <span
                          className={joinClassNames(
                            "block truncate ",
                            selected && "font-semibold"
                          )}
                        >
                          {record[1]}
                        </span>
                      )}

                      {selected && (
                        <span
                          className={joinClassNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white " : "text-green-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            ) : (
              <p className="text-xs ml-2 pt-1 dark:text-gray-500 text-gray-700 ">
                No data found.
              </p>
            )}
            {isLoading && (
              <div
                className={joinClassNames(
                  "relative border-t border-t-gray-500 dark:border-t-gray-600  bg-white dark:bg-dark-main  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                )}
              >
                <Loader label="Loading more options..." w={2} h={2} />
              </div>
            )}
            {Pagination && (
              <div className="border-t-gray-500 dark:border-t-gray-600 border-t">
                {Pagination}
              </div>
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
}
