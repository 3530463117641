import { gql } from "../../__generated__/gql";

export const CREATE_JIRA_ISSUE = gql(`
  mutation CreateIssue($issue: MainIssueType!, $orgId: String) {
    createJiraIssue(orgId: $orgId, issue: $issue) {
      id
      key
      self
      transition {
        status
        errorCollection {
          errorMessages
        }
      }
    }
  }
`);
