import { XCircleIcon, XIcon } from "@heroicons/react/solid";

type ErrorAlertProps = {
  title?: string;
  description?: string;
  isShowing: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
};

export function ErrorAlert({
  title,
  description,
  onClose,
  isShowing,
  testId
}: ErrorAlertProps): JSX.Element {
  if (!isShowing) return <></>;
  return (
    <div className="rounded-md bg-red-50 dark:bg-dark-primary py-4 border-l border-l-red-600" data-test-id={testId}>
      <div className="flex ml-3">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-600" data-test-id="login-error">
            {title}
          </h3>
          <div className="text-sm text-red-700">{description}</div>
          <div className="absolute right-0 top-0">
            {onClose && (
              <button
                onClick={onClose}
                type="button"
                className="rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
