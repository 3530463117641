import { gql } from "../../__generated__/gql";

export const FetchUserCredBBGH = gql(`
  query FetchUserCredBBGH($sub: String!) {
    fetchUserCredBBGH(orgId: $sub) {
      data {
        ghowner
        sub
        bbusername
        createtime
        oauthGH
        oauthBB
        oauthGL
        gitlabUsername
        orgOrMemberIDAZ
        oauthAZ
        patAZ
        azureUsername
        dockerHubUsername
        patDH
      }
      message
      code
    }
  }
`);
