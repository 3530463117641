import { gql } from "../../__generated__/gql";

export const CREATE_SBOM_AUTOMATIC = gql(`
  mutation CreateSBOMAuto($applicationId: String!, $orgId: String) {
    createSBOMAuto(applicationId: $applicationId, orgId: $orgId) {
      id
      org_id
      app_id
      domain
      cloud_account_id
      state
      state_reason
      time_created
      time_updated
    }
  }
`);
