import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { Collapse } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  ChangeEventHandler,
  Dispatch,
  Suspense,
  lazy,
  useMemo,
  useState,
} from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { Package } from "../__generated__/graphql";
import { Button } from "../components/buttons/Button";
import { Loader } from "../components/loading/Loader";
import { Pagination } from "../components/pagination/Pagination";
import { SearchBar } from "../components/search/SearchBar";
import Table from "../components/table/Table";
import GenericErrorBoundary from "../functions/GenericErrorBoundary";
import { copyText } from "../functions/copyText";
import useTitle from "../functions/hooks/useTitle";
import { useUser } from "../functions/hooks/useUser";
import { FIND_PACKAGE } from "../graphql/queries/FindPackage";
import { svgImport } from "../partials/application/ApplicationComposition";
import { packageEnrichDataRowFactory } from "../partials/application/enriched-rows/PackageEnrichedDataRow";
import CVEExplorer, { CriticalityChip, Line } from "./CVEExplorer";
import { notEmpty } from "./dashboard_2/dashboardDigest";
import {
  ParsedCVEData,
  ParsedGHAdvisory,
  parseCVEData,
  parseGHAdvisoryData,
} from "./issues/cveDigest";
import { useSbomDownload } from "./sbom/History";
import { formatDateShort } from "./dashboard_2/HistoryPicker";
import { BsSearch } from "react-icons/bs";
import { AiOutlineDashboard } from "react-icons/ai";

const SecureStackLogo = lazy(() =>
  import("../assets/small-logo.svg").then(svgImport),
);

const rowHeaders = {
  name: "",
  sbomId: "Sbom id",
  appId: "App id",

  // purl: "Purl",
  // version: "Version",
  created: "Created",
  licenses: "License",
  action: "",
};

function truncate(s?: string | null): string {
  if (!s) {
    return "";
  }
  const slice = s.slice(0, 45);
  const didSlice = slice.length !== s.length;
  return didSlice ? slice + "..." : slice;
}

function GhaCveReferences({
  cveReferences,
}: {
  cveReferences: string[] | null;
}): JSX.Element {
  return (
    <>
      {cveReferences?.map((x, i) => (
        <div key={i} className="w-fit text-gray-600 dark:text-gray-400">
          <a
            href={x}
            title={x}
            rel="external help noopener noreferrer nofollow"
            target="_blank"
          >
            <div className="flex flex-row gap-2 font-semibold text-gray-600 dark:text-gray-400">
              <p>
                • <span className="break-words">{truncate(x)}</span>
              </p>
              <FiExternalLink className="my-auto" />
            </div>
          </a>
        </div>
      ))}
    </>
  );
}

function GHAExplorer({
  searchTerm,
  setSearchTerm,
  loading,
  ghaInfo,
  affectedApplications,
}: {
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
  loading: boolean;
  ghaInfo: ParsedGHAdvisory;
  affectedApplications: AppNameAndId[] | null;
}): JSX.Element {
  const [showReferences, setShowReferences] = useState<boolean>(false);
  const [showCredits, setShowCredits] = useState<boolean>(false);

  return loading ? (
    <></>
  ) : (
    <GenericErrorBoundary
      fallback={
        <p className="dark:text-white">
          We're sorry but an error has ocurred while displaying the GHA viewer.
          Please refresh and try again. If the issue persists, please contact
          support.
        </p>
      }
    >
      <div className="flex flex-col gap-4 mt-2 lg:mt-4 bg-white dark:bg-dark-secondary p-2 md:px-4 xl:px-6 xl:py-4 rounded-md">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex flex-row gap-4 justify-between">
              <div className="flex flex-row gap-2">
                <Suspense fallback={<></>}>
                  <SecureStackLogo className="w-8 h-8 my-auto" />
                </Suspense>
                <div className="ml-2 flex flex-col">
                  <p className="my-auto text-xl dark:text-white font-bold">
                    {ghaInfo.ghsaId}
                  </p>
                  {ghaInfo.cveId === null ? null : (
                    <div
                      onClick={() =>
                        void (ghaInfo.cveId && setSearchTerm(ghaInfo.cveId))
                      }
                      title={`Click to search for ${ghaInfo.cveId}`}
                      className="my-auto flex cursor-pointer flex-row flex-nowrap text-sm dark:text-white font-bold"
                    >
                      <p className="my-auto">{ghaInfo.cveId}</p>
                    </div>
                  )}
                </div>
                <div className="my-auto ml-2">
                  <CriticalityChip criticality={ghaInfo.severity} />
                </div>
              </div>
            </div>
            <div className="my-auto">
              {ghaInfo.cvss.score != null ? (
                <div className="flex-shrink flex-grow flex flex-col">
                  <div className="inline-flex pb-1 justify-between">
                    <p className="flex-shrink pr-5 overflow-x-hidden text-ellipsis text-gray-600 dark:text-gray-400">
                      CVSS Score
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                      {ghaInfo.cvss.score}
                    </p>
                  </div>
                  <Line score={Math.round(ghaInfo.cvss.score * 10)} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-row gap-2 text-gray-500 text-sm justify-between">
            {ghaInfo.updatedAt ? (
              <p>
                Last Modified:{" "}
                {formatDateShort(new Date(ghaInfo.updatedAt ?? ""))}
              </p>
            ) : null}
            {ghaInfo.publishedAt ? (
              <p>
                Published:{" "}
                {formatDateShort(new Date(ghaInfo.publishedAt ?? ""))}
              </p>
            ) : null}
            {ghaInfo.nvdPublishedAt ? (
              <p>
                NVD Published:{" "}
                {formatDateShort(new Date(ghaInfo.nvdPublishedAt ?? ""))}
              </p>
            ) : null}
            {ghaInfo.githubReviewedAt ? (
              <p>
                Reviewed:{" "}
                {formatDateShort(new Date(ghaInfo.githubReviewedAt ?? ""))}
              </p>
            ) : null}
            {ghaInfo.withdrawnAt ? (
              <p>
                Withdrawn:{" "}
                {formatDateShort(new Date(ghaInfo.withdrawnAt ?? ""))}
              </p>
            ) : null}
          </div>
        </div>

        <p className="my-auto font-semibold text-sm text-gray-600 dark:text-gray-400 uppercase tracking-wider">
          Description:
        </p>
        <div className="flex flex-row flex-nowrap gap-2 justify-between p-[6px] rounded-md">
          <p className="text-gray-700 dark:text-gray-300">
            {ghaInfo.description}
          </p>
        </div>

        <AffectedApps affectedApplications={affectedApplications} />

        <p className="text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">
          CVSS Vector String:
        </p>
        <p
          title="cvss vector string"
          className="text-center text-sm text-gray-600 dark:text-gray-400 uppercase tracking-wider py-2"
        >
          {ghaInfo.cvss.vectorString}
        </p>

        <p className="text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">
          Vulnerabilities:
        </p>
        <div className="text-gray-700 dark:text-gray-300 flex flex-col gap-4">
          {ghaInfo.vulnerabilities.map((x, i) => (
            <div
              key={i}
              className="flex flex-wrap flex-col md:flex-row justify-evenly bg-light-seconday dark:bg-dark-nav rounded-md gap-2"
            >
              <div className="flex flex-col p-2">
                <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">
                  Package Manager
                </p>
                <p className="mx-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                  {x.package.ecosystem}
                </p>
              </div>
              <div className="flex flex-col p-2">
                <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">
                  Package Name
                </p>
                <p className="mx-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                  {x.package.packageName}
                </p>
              </div>
              <div className="flex flex-col p-2">
                <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">
                  Vulnerable Function
                </p>
                <p className="mx-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                  {x.vulnerableFunctions}
                </p>
              </div>
              <div className="flex flex-col p-2">
                <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">
                  Vulnerable Version
                </p>
                <p className="mx-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                  {x.vulnerableVersionRange}
                </p>
              </div>
              <div className="flex flex-col p-2">
                <p className="mx-auto flex-grow text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1">
                  First Patched
                </p>
                <p className="mx-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                  {x.firstPatchedVersion}
                </p>
              </div>
            </div>
          ))}
        </div>

        <p className="text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">
          CWEs:
        </p>
        <div className="flex gap-4 flex-col text-gray-700 dark:text-gray-300">
          {ghaInfo.cwes.map((x) => (
            <div className="flex flex-col md:flex-row gap-4">
              <p className="my-auto text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase px-1 mr-2">
                <Button
                  className="w-32"
                  label={x.cweId}
                  onClick={() => setSearchTerm(x.cweId)}
                  secondary
                  icon={<BsSearch className="mr-2" width={5} height={5} />}
                />
              </p>
              <p className="my-auto flex-grow text-sm text-gray-600 dark:text-gray-400">
                {x.cweName}
              </p>
            </div>
          ))}
        </div>

        {(ghaInfo.references?.length ?? 0) > 0 ? (
          <div className="mt-4">
            <p
              className="w-fit cursor-pointer text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider"
              title={
                showReferences
                  ? "Click to hide references"
                  : "Click to show references"
              }
              onClick={() => void setShowReferences(!showReferences)}
            >
              {showReferences ? "Hide " : "Show "}References
            </p>
            <Collapse in={showReferences}>
              <div className="flex flex-col gap-4">
                <GhaCveReferences
                  cveReferences={[
                    ghaInfo.url,
                    ghaInfo.sourceCodeLocation,
                    ghaInfo.repositoryAdvisoryUrl,
                    ghaInfo.htmlUrl,
                    ...(ghaInfo.references ?? []),
                  ].filter(notEmpty)}
                />
              </div>
            </Collapse>
          </div>
        ) : null}

        {(ghaInfo.credits?.length ?? 0) > 0 ? (
          <div className="mt-4">
            <p
              className="w-fit cursor-pointer text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider"
              title={
                showCredits
                  ? "Click to hide references"
                  : "Click to show references"
              }
              onClick={() => void setShowCredits(!showCredits)}
            >
              {showCredits ? "Hide " : "Show "}Credits
            </p>
            <Collapse in={showCredits}>
              <div className="flex flex-col gap-4">
                <p>
                  {ghaInfo.credits.map((x, i) => (
                    <div
                      key={i}
                      className="flex flex-row gap-2 text-gray-700 dark:text-gray-300"
                    >
                      <p>
                        •{" "}
                        <span className="break-words">
                          {x.type} id: {x.user.id}
                        </span>
                      </p>
                    </div>
                  ))}
                </p>
              </div>
            </Collapse>
          </div>
        ) : null}
      </div>
    </GenericErrorBoundary>
  );
}

export type AppNameAndId = { appId: string; appName: string };

export function AffectedApps({
  affectedApplications,
}: {
  affectedApplications: AppNameAndId[] | null;
}): JSX.Element | null {
  const [showAffected, setShowAffected] = useState<boolean>(true);
  return affectedApplications ? (
    <div className="mt-2 mb-4">
      <p
        className="my-auto text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider cursor-pointer pb-2 w-fit"
        title={`Click to toggle showing affected applications. Affected applications are currently ${
          showAffected ? "shown" : "hidden"
        }`}
        onClick={() => void setShowAffected(!showAffected)}
      >
        {showAffected ? "Hide" : "Show"} Affected Applications
      </p>
      <Collapse in={showAffected}>
        <div className="flex flex-col md:flex-row flex-wrap gap-2">
          {affectedApplications.length === 0 ? (
            <></>
          ) : (
            affectedApplications.map((app, i) => (
              <Link key={i} to={`/dashboard/${app.appId}`}>
                <div
                  className="flex flex-row flex-nowrap outline outline-2 outline-vuln-critical rounded-md bg-light-primary px-2 py-1 dark:bg-dark-primary text-gray-700 dark:text-gray-300 cursor-pointer"
                  title={`Click to goto application dashboard for ${app.appName}`}
                >
                  <div className="my-auto mr-2">
                    <AiOutlineDashboard width={15} height={15} />
                  </div>
                  <div className="my-auto">
                    <p>{truncate(app.appName)}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </Collapse>
    </div>
  ) : null;
}

export default function PackagesExplorer(): JSX.Element {
  useTitle("Package Explorer", true);
  const [cveInfo, setCveInfo] = useState<ParsedCVEData[] | null>(null);
  const [ghadvisoryInfo, setGhadvisoryInfo] = useState<ParsedGHAdvisory | null>(
    null,
  );
  const [affectedApplications, setAffectedApplications] = useState<
    AppNameAndId[] | null
  >(null);
  let user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const querySearch = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search],
  );
  const [searchTerm, setSearchTerm] = useState(
    decodeURIComponent(querySearch.get("search") ?? ""),
  );
  const [queryData, setQueryData] = useState<Package[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResultsPerPage] = useState(20);
  const indexOfLastSubdomain = currentPage * searchResultsPerPage;
  const indexOfFirstSubdomain = indexOfLastSubdomain - searchResultsPerPage;

  const { loading, refetch: findPackage } = useQuery(FIND_PACKAGE, {
    variables: {
      packageName: searchTerm,
      orgId: user?.selectedOrg,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.findPackage?.packageDetails) {
        setQueryData(data.findPackage.packageDetails.filter(notEmpty));
      }

      if (data.findPackage?.applications) {
        setAffectedApplications(
          data.findPackage.applications.flatMap(
            (x: { applicationId: any; name: any }) =>
              x?.applicationId && x.name
                ? {
                    appId: x.applicationId,
                    appName: x.name,
                  }
                : [],
          ),
        );
      }

      if (data.findPackage?.CVEDetails) {
        setCveInfo([parseCVEData(data.findPackage.CVEDetails)]);
      } else if (data.findPackage?.CWEDetails) {
        const cveInfos = [];
        for (const cve of data.findPackage.CWEDetails) {
          if (cve) {
            cveInfos.push(parseCVEData(cve));
          }
        }
        setCveInfo(cveInfos);
      }

      if (data.findPackage?.GHAdvisoryDetails) {
        setGhadvisoryInfo(
          parseGHAdvisoryData(data.findPackage.GHAdvisoryDetails),
        );
        if (data.findPackage?.CVEDetails == null) {
          setCveInfo(null);
        }
      }
    },
  });

  const onSearch = () => {
    findPackage({ packageName: searchTerm, orgId: user?.selectedOrg });
    setCveInfo(null);
    setGhadvisoryInfo(null);
    setQueryData([]);
  };

  const searchPackage: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value ?? "");
    if (cveInfo != null) {
      // clear cwes when searching again
      setCveInfo(null);
    }
    if (ghadvisoryInfo != null) {
      // clear gha when searching again
      setGhadvisoryInfo(null);
    }
  };

  const onCopy = (appId: string) => {
    copyText(appId);
    enqueueSnackbar("Application ID copied to clipboard", {
      variant: "success",
    });
  };

  const onView = (appId: string) => {
    push(`/dashboard/${appId}`);
  };

  const [downloadSBOM] = useSbomDownload(
    searchTerm ?? "SecureStack Package Explorer SBOM",
    enqueueSnackbar,
  );

  const onDownload = (sbomId: string) => {
    downloadSBOM({
      variables: {
        fSbomDisplayId: sbomId,
        dataFormat: "json",
        type: "cyclonedx",
      },
    });
  };

  const PackageEnrichedDataRow = packageEnrichDataRowFactory(
    onView,
    onCopy,
    onDownload,
  );

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const rows = queryData
    ?.slice(indexOfFirstSubdomain, indexOfLastSubdomain)
    ?.map(PackageEnrichedDataRow);

  return (
    <GenericErrorBoundary
      fallback={
        <p className="dark:text-white">
          We're sorry but an error has ocurred while displaying the package
          viewer. Please refresh and try again. If the issue persists, please
          contact support.
        </p>
      }
    >
      <div>
        <div className="md:flex md:items-center md:justify-between space-x-4">
          <div className="w-full">
            <SearchBar
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" ||
                  e.key === "Meta" ||
                  e.key === "Control"
                ) {
                  // enter or paste or autofill
                  e.preventDefault();
                  onSearch();
                }
              }}
              onInput={searchPackage}
              onEnded={searchPackage}
              onChange={searchPackage}
              placeHolder="Search term, CVE ID, GHSA ID... (Case sensitive)"
              searchText={searchTerm}
              onClear={() => {
                setSearchTerm("");
                setQueryData([]);
                setCveInfo(null);
                setGhadvisoryInfo(null);
              }}
            />
          </div>
          <Button primary label="Search" onClick={() => void onSearch()} />
        </div>
        {loading && searchTerm.length > 0 ? (
          <Loader label="Searching..." w={5} h={5} />
        ) : null}
        {queryData == null ||
        (queryData.length === 0 && searchTerm.length !== 0) ? null : (
          <div>
            <div className="mt-4" />
            {loading && searchTerm.length > 0 ? (
              <Loader w={5} h={5} label="Finding packages..." />
            ) : null}
            {queryData && !loading ? (
              <>
                <Table isLoading={loading} rowNames={rowHeaders} rows={rows} />
                <Pagination
                  isFiltered={searchTerm.length > 0}
                  numFilteredItems={queryData?.length}
                  currentPage={currentPage}
                  itemsPerPage={searchResultsPerPage}
                  totalItems={queryData?.length}
                  paginate={paginate}
                  siblingCount={1}
                />
              </>
            ) : (
              !loading && (
                <h1 className="text-gray-800 dark:text-gray-400">
                  {searchTerm} was not found in your applications' SBOMs
                </h1>
              )
            )}
          </div>
        )}
        {cveInfo == null ? null : (
          <div className="flex flex-col gap-4">
            {cveInfo.map((cve, i) => (
              <CVEExplorer
                key={i}
                affectedApplications={affectedApplications}
                searchTerm={searchTerm}
                loading={loading}
                cveInfo={cve}
              />
            ))}
          </div>
        )}
        {ghadvisoryInfo == null ? null : (
          <div>
            <GHAExplorer
              affectedApplications={affectedApplications}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              loading={loading}
              ghaInfo={ghadvisoryInfo}
            />
          </div>
        )}
      </div>
    </GenericErrorBoundary>
  );
}
