import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import EmptyStateSimple from "../../components/empty-states/EmptyStateSimple";
import { Pagination } from "../../components/pagination/Pagination";
import Table from "../../components/table/Table";
import { downloadFile } from "../../functions/downloadFile";
import { usePathAppId } from "../../functions/hooks";
import { useApplicationStatus } from "../../functions/hooks/useApplicationStatus";
import { useNumOfApps } from "../../functions/hooks/useNumOfApps";
import { DISPLAY_SBOM } from "../../graphql/queries/DisplaySBOM";
import { SBOM_LIST } from "../../graphql/queries/SBOMList";
import { NoApplicationSelected } from "../../partials/application/NoApplicationSelected";
import { sbomEnrichDataRowFactory } from "../../partials/application/enriched-rows/SBOMEnrichedDataRow";
import { NoApplications } from "../../partials/onboarding/NoApplications";
import { NoSbomMessage } from "./NoSbomMessage";
import { prettifyXml } from "./create/Download";
import logGraphQLError from "../../functions/logGraphQLError";

const rowHeaders = {
  id: "ID (sbom)",
  domain: "Domain",
  cloud_account_id: "Cloud Account",
  state_reason: "State",
  time_created: "Created",
  action: "",
};

export function useSbomDownload(appName: string, enqueueSnackbar?: Function) {

  const downloadSbom = useLazyQuery(DISPLAY_SBOM, {
    onCompleted: (data) => {
      enqueueSnackbar && enqueueSnackbar("SBOM successfully generated and is ready for download", {
        variant: "success",
        autoHideDuration: 4000,
      });
      downloadFile({
        data: downloadSbom[1].variables?.dataFormat === "json" ? JSON.stringify(data?.fSBOMDisplay, null, 4).toString() : prettifyXml(data?.fSBOMDisplay.toString()),
        fileName: `sbom-${appName}.${downloadSbom[1].variables?.dataFormat === "json" ? "json" : "xml"}`,
        fileType: downloadSbom[1].variables?.dataFormat === "json" ? "text/json" : "text/xml",
      });
    },
    onError: () => {
      enqueueSnackbar && enqueueSnackbar("Error generating SBOM, please try again", {
        variant: "error",
        autoHideDuration: 4000,
      });
    },
  });

  return downloadSbom;

}

export function History(): JSX.Element {
  const { push } = useHistory();
  const numOfapps = useNumOfApps();
  const appId = usePathAppId();
  const { enqueueSnackbar } = useSnackbar();

  const { data, stopPolling, startPolling, refetch } = useQuery(SBOM_LIST, {
    variables: {
      fSbomListId: appId ?? "",
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: logGraphQLError,
    onCompleted(data) {
      if (data.fSBOMList?.find(sbom => sbom?.state !== 4) == null) {
        stopPolling();
      }
    },
  });
  useEffect(() => { startPolling(3000); return () => stopPolling() }, [startPolling, stopPolling]);
  const { appStatusData } = useApplicationStatus(appId, refetch);

  const [currentPage, setCurrentPage] = useState(1);
  const [applicationsPerPage] = useState(15);

  const [downloadSBOM] = useSbomDownload(appStatusData?.name ?? "", enqueueSnackbar);

  if (numOfapps === 0) {
    return <NoApplications />;
  }

  if (!appId) {
    return <NoApplicationSelected />;
  }

  if (data?.fSBOMList?.length === 0) {
    return <NoSbomMessage />;
  }

  if (!appStatusData?.target && !data) {
    return (
      <EmptyStateSimple
        icon={
          <IoWarningOutline className="mx-auto w-14 h-14 text-gray-300 opacity-70" />
        }
        title="Target needed"
        subtitle="SBOMs can only be created for applications that have a target."
        buttonText="Configure Now"
        onClick={(e) => {
          e.preventDefault();
          push(`/settings/applications/${appId}`);
        }}
      />
    );
  }

  const SbomEnrichedDataRow = sbomEnrichDataRowFactory(
    (sbomId, fileType, fileFormat) =>
      void downloadSBOM({
        variables: {
          fSbomDisplayId: sbomId,
          dataFormat: fileType,
          type: fileFormat,
        },
      })
  );

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const sortDesc = data?.fSBOMList?.slice().sort((a: any, b: any) => {
    return a.time_created > b.time_created ? -1 : 1;
  });

  const rows = sortDesc?.map(SbomEnrichedDataRow);

  return (
    <div className="bg-white dark:bg-dark-main rounded-2xl">
      {(rows?.length ?? 0) > 0 && (
        <>
          <Table rows={rows} rowNames={rowHeaders} />
          <Pagination
            currentPage={currentPage}
            itemsPerPage={applicationsPerPage}
            totalItems={data?.fSBOMList?.length ?? 0}
            paginate={paginate}
            siblingCount={1}
          />
        </>
      )}
    </div>
  );
}
