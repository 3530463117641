import { Component, ErrorInfo, lazy, Suspense } from "react";
import { Toggle } from "../../functions/toggle";
import { svgImport } from "../../partials/application/ApplicationComposition";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


// *** MUST *** be a class Component :(
export default class ConciergeErrorBoundary extends Component<{ fullReset?: () => void; }, { hasError: boolean; }> {
    constructor(props: { fullReset?: () => void; }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) { // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) { }

    render() {
        if (this.state.hasError) {
            return <div className={`flex ${this?.props?.fullReset ? "w-screen h-screen bg-light-seconday dark:bg-dark-secondary" : "w-full h-fit"}`}>
                <div className="absolute top-3 right-3"><Toggle /></div>
                <div className="flex m-auto overflow-scroll flex-col text-center pt-2">
                    <div className="mx-auto mb-2"><Suspense fallback={<></>}><SecureStackLogo width="60" height="60" /></Suspense></div>
                    <h1 className="text-4xl mb-2 dark:text-white">We're sorry.</h1>
                    <h2 className="text-2xl dark:text-white mb-7">An unexpected error has occurred that caused the page to stop working.</h2>
                    <button className="h-12 dark:text-white mb-4 border-red-600 border border-solid rounded-lg" onClick={() => window.location.replace('/onboarding')}>Refresh and retry</button>
                    {this?.props?.fullReset && <button className="h-12 dark:text-white mb-4 border-red-600 border border-solid rounded-lg" onClick={() => {
                        if (this?.props?.fullReset) {
                            this.props.fullReset();
                        }
                        window.location.replace('/onboarding');
                    }}>Full reset</button>}
                    <button className="h-12 dark:text-white mb-4 border-red-600 border border-solid rounded-lg" onClick={() => window.location.replace('/support')}>Contact support</button>
                    {this?.props?.fullReset && <button className="h-16 text-white bg-red-700 rounded-lg mb-8" onClick={() => window.location.replace('/dashboard')}>Exit tour and go to SecureStack App now</button>}
                </div>
            </div>;
        }
        return this.props.children;
    }
}