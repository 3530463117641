import { FaUserCircle } from "react-icons/fa";
import { DashboardHeading } from "../dashboard/DashboardHeading";
import { AppSettingsData, DashboardData } from "./dashboard2";
import { GiThreeKeys } from "react-icons/gi";
import { FcWorkflow } from "react-icons/fc";
import { VscGistSecret } from "react-icons/vsc";
import { BiLinkExternal } from "react-icons/bi";

function ChipInner({ title, data, icon, href }: { title: string; data: number | string; icon: JSX.Element; href?: string; }): JSX.Element {
    return <div title={title} className="px-4 py-2 dark:bg-dark-main bg-white rounded-md hover:bg-light-seconday dark:hover:bg-dark-nav">
        <div className="inline-flex flex-row flex-nowrap">
            <div className="flex my-auto">{icon}</div>
            <span className="px-4 dark:text-white tracking-wide">{data}</span>
        </div>
        <span className="my-auto float-right inline-flex text-gray-500">
            <span className="my-auto mr-1 tracking-tight">{title}</span>
            {href && <BiLinkExternal className="inline my-auto" />}
        </span>
    </div>
}

export function Chip({ title, data, icon, href }: { title: string; data: number | string; icon: JSX.Element; href?: string; }): JSX.Element | null {
    if (!data) {
        return null;
    }
    if (href) {
        return <a href={href} rel="noreferrer nofollow external noopener" className="cursor-pointer" target="_blank"><ChipInner title={title} data={data} icon={icon} href={href} /></a>;
    }
    return <ChipInner title={title} data={data} icon={icon} href={href} />
}

export function CollaboratorsBar({ data, app }: { data: DashboardData | undefined; app: AppSettingsData | undefined; }): JSX.Element | null {
    if (!data) {
        return null;
    }
    if (!app) {
        return null;
    }

    return <div className="w-full rounded-xl dark:bg-dark-main bg-white flex flex-row flex-nowrap gap-4 py-2 box-border group">
        <div className="text-center text-ellipsis overflow-hidden text-base whitespace-nowrap dark:text-gray-300 min-w-fit my-auto md:pl-4 pt-2 md:pt-0" aria-level={3} role="heading">
            <div className="group-hover:bg-light-seconday dark:group-hover:bg-dark-nav p-2 rounded-lg">Collaborators</div>
        </div>
        <div className="flex flex-row flex-grow flex-shrink flex-wrap gap-4 justify-evenly" role="list">
            {app?.code.scmInfo.platform.toLowerCase() === "github" ? data?.code?.scmMeta?.gitCollaborators?.map((x, index) => <div key={index} role="listitem" className="px-4 py-2 bg-light-primary dark:bg-dark-primary rounded-md flex flex-row flex-nowrap"><FaUserCircle className="fill-slate-500 my-auto" /><div className="pl-2 dark:text-white">{x}</div></div>) : null}

            {app?.code.scmInfo.platform.toLowerCase() === "gitlab" ? data?.code?.scmMeta?.gitlabContributors?.map((x, index) => <div key={index} role="listitem" className="px-4 py-2 bg-light-primary dark:bg-dark-primary rounded-md flex flex-row flex-nowrap"><FaUserCircle className="fill-slate-500 my-auto" /><div className="pl-2 dark:text-white">{x}</div></div>) : null}

            {app?.code.scmInfo.platform.toLowerCase() === "github" ? data?.code?.scmMeta?.gitOrgMembers?.map((x, index) => !data?.code?.scmMeta?.gitCollaborators.includes(x) ? <div key={index} role="listitem" className="px-4 py-2 bg-light-primary dark:bg-dark-primary dark:text-white rounded-md">{x}</div> : null) : null}

            {app?.code.scmInfo.platform.toLowerCase() === "gitlab" ? data?.code?.scmMeta?.gitlabGroupMembers?.map((x, index) => !data?.code?.scmMeta?.gitlabContributors.includes(x) ? <div key={index} role="listitem" className="px-4 py-2 bg-light-primary dark:text-white dark:bg-dark-primary rounded-md">{x}</div> : null) : null}

            {app?.code.scmInfo.platform.toLowerCase() === "bitbucket" ? data?.code?.scmMeta?.bitbucketWorkspaceMembers?.map((x, index) => <div key={index} role="listitem" className="px-4 py-2 bg-light-primary dark:bg-dark-primary rounded-md flex flex-row flex-nowrap"><FaUserCircle className="fill-slate-500 my-auto" /><div className="pl-2 dark:text-white">{x}</div></div>) : null}
        </div>
    </div>;
}

export function ScmMeta({ data, app, children }: { data: DashboardData | undefined; app: AppSettingsData | undefined; children?: JSX.Element | JSX.Element[] | null }): JSX.Element | null {
    if (!data || !app || (app?.code.scmInfo.platform.toLowerCase() !== "github" && data.code.scmMeta.gitValid) || (app?.code.scmInfo.platform.toLowerCase() !== "bitbucket" && data.code.scmMeta.bitbucketValid) || (app?.code.scmInfo.platform.toLowerCase() !== "gitlab" && data.code.scmMeta.gitlabValid)) {
        return null;
    }
    return <div className="flex flex-col md:flex-row gap-y-2 flex-nowrap items-center w-full justify-between rounded-xl group py-2 mt-[-18px] z-[-1] bg-white dark:bg-dark-main opacity-100 rounded-b-lg" role="list">
        <div role="listitem" className="flex flex-wrap flex-grow flex-row justify-center gap-3">
            <div className="flex flex-row flex-wrap justify-evenly gap-2">
                {
                    // git gpg keys
                    app?.code.scmInfo.platform.toLowerCase() === "github" && data.code.scmMeta.gitValid &&
                    <Chip
                        title="GPG KEYS"
                        data={data?.code?.scmMeta?.gitNumberOfGPGKeys}
                        icon={<GiThreeKeys className="fill-slate-500 my-auto" />}
                        href="https://github.com/settings/keys"
                    />
                }
                {
                    // git org secrets
                    app?.code.scmInfo.platform.toLowerCase() === "github" && data.code.scmMeta.gitValid &&
                    <Chip
                        title="ORG VARIABLES"
                        data={data?.code?.scmMeta?.gitOrgSecretsNumber}
                        icon={<VscGistSecret className="fill-slate-500 my-auto" />}
                        href={`https://github.com/${app?.code.scmInfo.org}/${app?.code.scmInfo.repo}/settings/secrets/actions`}
                    />
                }
                {
                    // git secrets
                    app?.code.scmInfo.platform.toLowerCase() === "github" && data.code.scmMeta.gitValid &&
                    <Chip
                        title="YOUR VARIABLES"
                        data={data?.code?.scmMeta?.gitSecretsNumber}
                        icon={<VscGistSecret className="fill-slate-500 my-auto" />}
                        href={`https://github.com/${app?.code.scmInfo.org}/${app?.code.scmInfo.repo}/settings/secrets/actions`}
                    />
                }
                {
                    // git workflows
                    app?.code.scmInfo.platform.toLowerCase() === "github" && data.code.scmMeta.gitValid &&
                    <Chip
                        title="WORKFLOWS"
                        data={data?.code?.scmMeta?.gitWorkflowNumber}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href={`https://github.com/${app?.code.scmInfo.org}/${app?.code.scmInfo.repo}/actions`}
                    />
                }
                {
                    // bb ssh keys
                    app?.code.scmInfo.platform.toLowerCase() === "bitbucket" && data.code.scmMeta.bitbucketValid &&
                    <Chip
                        title="SSH KEYS"
                        data={data?.code?.scmMeta?.bitbucketSshKeyNumber}
                        icon={<GiThreeKeys className="fill-slate-500 my-auto" />}
                        href="https://bitbucket.org/account/settings/ssh-keys/"
                    />
                }
                {
                    // bb repo groups
                    app?.code.scmInfo.platform.toLowerCase() === "bitbucket" && data.code.scmMeta.bitbucketValid &&
                    <Chip
                        title="REPO GROUPS"
                        data={data?.code?.scmMeta?.bitbucketGroupsOnRepoNumber}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href={`https://bitbucket.org/${app?.code.scmInfo.org}/workspace/members/`}
                    />
                }
                {
                    // bb pipelines
                    app?.code.scmInfo.platform.toLowerCase() === "bitbucket" && data.code.scmMeta.bitbucketValid &&
                    <Chip
                        title="PIPELINES"
                        data={data?.code?.scmMeta?.bitbucketPipelineNumber}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href={`https://bitbucket.org/${app?.code.scmInfo.org}/${app?.code.scmInfo.repo}/pipelines`}
                    />
                }
                {
                    // gl ssh keys
                    app?.code.scmInfo.platform.toLowerCase() === "gitlab" && data.code.scmMeta.gitlabValid &&
                    <Chip
                        title="SSH KEYS"
                        data={data?.code?.scmMeta.gitlabSSHKeys}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href="https://gitlab.com/-/profile/keys"
                    />
                }
                {
                    // gl pipelines
                    app?.code.scmInfo.platform.toLowerCase() === "gitlab" && data.code.scmMeta.gitlabValid &&
                    <Chip
                        title="PIPELINES"
                        data={data?.code?.scmMeta.gitlabPipelines}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href={`https://gitlab.com/${app?.code.scmInfo.org}/${app?.code.scmInfo.repo}/-/pipelines`}
                    />
                }
                {
                    // gl contributors
                    app?.code.scmInfo.platform.toLowerCase() === "gitlab" && data.code.scmMeta.gitlabValid &&
                    <Chip
                        title="CONTRIBUTORS"
                        data={data?.code?.scmMeta.gitlabContributorsCount}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href="https://gitlab.com/dashboard/groups"
                    />
                }
                {
                    // gl group members
                    app?.code.scmInfo.platform.toLowerCase() === "gitlab" && data.code.scmMeta.gitlabValid &&
                    <Chip
                        title="GROUP MEMBERS"
                        data={data?.code?.scmMeta.gitlabGroupMembersCount}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href="https://gitlab.com/dashboard/groups"
                    />
                }
                {
                    // gl gpg keys
                    app?.code.scmInfo.platform.toLowerCase() === "gitlab" && data.code.scmMeta.gitlabValid &&
                    <Chip
                        title="GPG KEYS"
                        data={data?.code?.scmMeta.gitlabGPGKeys}
                        icon={<FcWorkflow className="fill-slate-500 my-auto grayscale" />}
                        href="https://gitlab.com/-/profile/gpg_keys"
                    />
                }
                {children}
            </div>
        </div>
    </div>;
}

export default function PeopleView({ loading, data, hasTitle, app }: { loading: boolean; data: DashboardData | undefined; app: AppSettingsData | undefined; hasTitle?: boolean; internalPadding?: boolean }): JSX.Element {
    return loading ? <p>Loading</p> :
        data?.code?.scmMeta?.gitValid || data?.code.scmMeta.bitbucketValid ? <>
            {hasTitle && <DashboardHeading heading={`${(data?.code?.scmMeta?.gitValid && "GitHub") || (data?.code.scmMeta.bitbucketValid && "Bitbucket")} Details`} />}
            <CollaboratorsBar data={data} app={app} />
            <ScmMeta data={data} app={app} />
        </> : <></>;
}