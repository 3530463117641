import { useState } from "react";
import { ErrorAlert } from "../../../../components/alerts/ErrorAlert";
import { TheOneCombobox } from "../../../../components/buttons/TheOneCombobox";
import { useBranchSearchQuery } from "../../../../functions/hooks/useBranchSearchQuery";
import { useLastElementRef } from "../../../../functions/hooks/useLastElementRef";

type SelectBranchNameProps<T> = {
  selected: T;
  setSelected: (value: T) => void;
  testId?: string;
};

export function SelectBranchName({
  selected,
  setSelected,
  testId
}: SelectBranchNameProps<any>): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, branchData, hasMore, refetch } = useBranchSearchQuery(currentPage, setCurrentPage);

  const incrementPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const lastElementRef = useLastElementRef(incrementPage, loading, hasMore);

  if (error) {
    return <div className="cursor-pointer" onClick={() => void refetch()}><ErrorAlert title="Error getting branches. Click to retry" isShowing={true} /></div>;
  }

  return (
    <TheOneCombobox
      testId={testId}
      key={selected?.name ?? selected}
      defaultValue="Select your branch"
      lastIdxRef={lastElementRef}
      isLoading={loading}
      items={branchData}
      selected={selected}
      onSelection={setSelected}
    />
  );
};
