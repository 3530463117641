import { ExclamationIcon } from "@heroicons/react/solid";
import { Dispatch } from 'react';
import { ParsedCodeIssueRow } from "../../../functions/hooks/useApplicationIssues";
import { joinClassNames } from "../../../functions/utilities";

type PackageVersionListOptions = {
  id: string;
  items: ParsedCodeIssueRow[];
  currentPackage?: ParsedCodeIssueRow;
  setCurrentPackage?: Dispatch<ParsedCodeIssueRow>;
}

export function PackageVersionList({ id, items, currentPackage, setCurrentPackage }: PackageVersionListOptions): JSX.Element {
  return (
    <nav className="flex flex-col gap-1" aria-label="Sidebar">
      {items?.map((issueRow, idx) => (
        <button
          key={`${id}/${idx}`}
          onClick={() => setCurrentPackage && setCurrentPackage(issueRow)}
          className={joinClassNames(
            currentPackage === issueRow && "dark:bg-dark-primary bg-gray-50",
            "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-dark-primary group flex items-center px-3 py-2 text-sm font-medium rounded-md",
          )}
        >
          <ExclamationIcon className="text-red-500 group-hover:text-red-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" />
          <span className="truncate dark:text-gray-300 cursor-pointer">
            {issueRow.packageName} {issueRow.vulnerableVersion}
          </span>
        </button>
      ))}
    </nav>
  );
};
