import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ReactNode, useEffect } from "react";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { typeDefs, typePolicies } from "./LocalizedSchema";
import { SupabaseClient } from "@supabase/supabase-js";
import { useSnackbar } from "notistack";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useHistory } from "react-router";
import { sendProvider } from "../graphql/utils/sendProvider";

function ApolloWrapper({
  children,
  supabaseClient,
}: {
  children: ReactNode;
  supabaseClient: SupabaseClient;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const populateHeaders = async (
    _: any,
    { headers, ...rest }: { headers: any }
  ) => {
    /**
     * The session function will use the existing session information
     * available to us in the browser and if it's expired:
     * ASSUMPTION: should get a new access_token if it's expired using
     * the refresh token.
     */
    const { data: { session } } = await supabaseClient.auth.getSession();

    if (!session?.access_token) return { headers, ...rest };

    if (session?.provider_token) {
      sendProvider(session);
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${session.access_token}`,
        "x-authorization-type": "Supabase",
        applicationsource: process.env.REACT_APP_ID,
      },
    };
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, ...args }) => {
          locations?.forEach((location) => {
            console.trace(
              `[GraphQL error]: Message: ${message}, Location: Line ${location.line} Char ${location.column}, Path: ${path}`
            );
          });

          if (args.extensions?.code === "EXPIRED_TOKEN") {
            //console.log("RENEWING_EXPIRED_TOKEN");
            const oldHeaders = operation.getContext().headers;
            operation.setContext(async () => {
              try {
                const { error, data } =
                  await supabaseClient.auth.refreshSession();

                if (data.session?.provider_token) {
                  sendProvider(data);
                }
                if (error) {
                  //console.error(error);
                  push("/auth/logout");
                  return {};
                }

                return {
                  headers: {
                    ...oldHeaders,
                    authorization:
                      "Bearer " + data.session?.access_token,
                  },
                };
              } catch (e) {
                //console.error(e);
                push("/auth/logout");
                return {};
              }
            });
            // Retry the request, returning the new observable
            return forward(operation);
          }

          if (args.extensions?.code === "MISSING_PERMISSIONS") {
            // TODO: Do something about permission denied errors here
            enqueueSnackbar(
              `Permission Denied. Missing ${args.extensions.permissions.join(
                ", "
              )}`,
              {
                variant: "error",
              }
            );
          }

          // user has not selected an org or is not logged in correctly
          // pass to org selector to handle login / org selector redir
          if (graphQLErrors.some(x => x.message === "Not authenticated")) {
            push("/auth/orgs");
          }
        });
      }
    }
  );

  // BIG TODO!!!!
  // changing this breaks everything, but is a type error
  // @ts-expect-error ts(2345)
  const authLink = setContext(populateHeaders);

  // const httpLink = new HttpLink({
  //   uri: process.env.REACT_APP_URI,
  // });

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_URI as string,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_SUB_URL as string,
      connectionParams: async () => {
        return (await populateHeaders(null, { headers: {} })).headers;
      },
    })
  );

  useEffect(() => {
    return () => {
      uploadLink?.wsLink?.client?.dispose();
    };
  }, [uploadLink, uploadLink?.wsLink, uploadLink.wsLink?.client]);

  const linkSplit = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    uploadLink
  );
  const linkAuth = authLink.concat(linkSplit);
  const link = errorLink.concat(linkAuth);

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies,
    }),
    connectToDevTools: process.env.NODE_ENV === "development",
    link,
    typeDefs,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloWrapper;
