import { XIcon } from "@heroicons/react/outline";
import {
  ChangeEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
} from "react";
import { BsSearch } from "react-icons/bs";

type SearchBarProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeHolder: string;
  searchText: string;
  onClear: MouseEventHandler<SVGSVGElement> | undefined;
} & InputHTMLAttributes<HTMLInputElement>;

export function SearchBar({
  onChange,
  placeHolder,
  searchText,
  onClear,
  ...props
}: SearchBarProps): JSX.Element {
  return (
    <label htmlFor="issues-searchbar" className="flex flex-row flex-nowrap">
      <div className="flex flex-row flex-nowrap group py-3 rounded-lg outline-1 bg-slate-50 dark:bg-dark-main flex-grow">
        <BsSearch className="mx-2 text-base my-auto text-slate-400 dark:text-green-300 group-focus-within:text-slate-500 " />
        <input
          {...props}
          type="text"
          id="issues-searchbar"
          className="flex-grow dark:text-gray-300 text-black p-2 text-base w-full bg-none bg-transparent"
          placeholder={placeHolder}
          value={searchText}
          //autoFocus
          onChange={onChange}
        />
        {searchText && (
          <XIcon
            className="mx-2 my-auto w-5 h-5 text-gray-400 text-base cursor-pointer"
            onClick={onClear}
          />
        )}
      </div>
    </label>
  );
};
