import { Transition } from "@headlessui/react";
import { Provider } from "@supabase/supabase-js";
import { MouseEventHandler, lazy, useState, Suspense } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { supabaseClient } from "../../auth/supabase";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { Button } from "../../components/buttons/Button";
import { InputField } from "../../components/forms/InputField";
import { Loader } from "../../components/loading/Loader";
import { svgImport } from "../../partials/application/ApplicationComposition";
import { SSOSignin } from "./SignIn";

const SecureStackLogo = lazy(() => import("../../assets/small-logo.svg").then(svgImport));


type FormData = {
  email: string;
  firstName?: string;
  lastName?: string;
  password: string;
};

/* const onboarding = [
  {
    target: "Add your integrations",
    completed: false,
    subSteps: [
      {
        target: "Cloud",
        completed: false,
      },
      {
        target: "Source code management",
        completed: false,
      },
    ],
  },
  {
    target: "Create your first application",
    completed: false,
    subSteps: [],
  },
  {
    target: "Invite users to your team",
    completed: false,
    subSteps: [],
  },
]; */

export function Register(): JSX.Element {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const { register, handleSubmit } = useForm<FormData>();

  const showPasswordFieldOrRegister: SubmitHandler<FormData> = async (
    data,
    e
  ) => {
    setLoading(true);

    const { data: { user, session }, error } = await supabaseClient.auth.signUp(
      {
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: window.location.origin,
          data: {
            redirectTo: window.location.origin,
          }
        }
      },

    );

    if (error) {
      setLoading(false);
      setError(error);
      return;
    }

    if (user && !session) {
      // Email validation enabled, redirect
      history.push("/auth/check-email");
      return;
    }

    const searchParams = new URLSearchParams(history.location.search);
    handleRedirect(searchParams);
  };

  const registerWithSocials: MouseEventHandler = async (event) => {
    event.preventDefault();
    let social = event.currentTarget.id as Provider;

    let scopes = "";
    if (social === "github") {
      scopes = "repo user read:org read:packages";
    } else if (social === "gitlab") {
      scopes = "api read_api read_user read_repository profile email";
    } else if (social === "azure") {
      scopes = "email";
    }

    const { error } = await supabaseClient.auth.signInWithOAuth(
      {
        provider: social,
        options: {
          scopes,
          redirectTo: window.location.origin,
        },
      },
    );

    setLoading(true);

    if (error) {
      setLoading(false);
      setError(error);
      return;
    }
  };

  const handleRedirect = (searchParams: URLSearchParams) => {
    /* if (searchParams.has("redirect_to")) {
      const redirectTo = searchParams.get("redirect_to");
      history.push(redirectTo);
      return;
    } */

    history.push("/auth/orgs");
    return;
  };

  return (
    <>
      <Header />
      <div className="mt-2 mb-2">
        <ErrorAlert title={error.message} isShowing={error} />
      </div>
      {!loading ? (
        <form
          onSubmit={handleSubmit(showPasswordFieldOrRegister)}
          className="space-y-4 mt-4"
        >
          <h2 className="text-gray-900 dark:text-gray-300 pb-1">
            Work email address
          </h2>
          <InputField
            autoFocus={true}
            placeholder="person@company.co"
            type="email"
            required
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            {...register("email")}
            name="email"
          />
          <Transition
            show={true}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <h2 className="dark:text-gray-300 text-gray-900 pb-1">Password</h2>

            <InputField
              autoComplete="current-password"
              placeholder="Password"
              id="password"
              type="password"
              autoFocus={true}
              required
              {...register("password")}
            />
          </Transition>

          <div className="flex flex-row gap-4">
            <Button
              type="submit"
              className="w-full mt-5"
              isLoading={loading}
              primary
              label="Register with email"
              onClick={handleSubmit(showPasswordFieldOrRegister)}
            />
          </div>

          <SSOSignin registerWithSocials={registerWithSocials} />
        </form>
      ) : (
        <Loader w={10} h={10} />
      )}
    </>
  );
};

function Header(): JSX.Element {
  return (
    <div className="pb-2">
      <Suspense fallback={<></>}>
        <SecureStackLogo className="h-16 w-auto" />
      </Suspense>
      <h2 className="mt-6 text-3xl font-extrabold tracking-tighter text-gray-900 dark:text-gray-300">
        Register for a Free Account
      </h2>
      <p className="mt-2 text-sm text-gray-400">
        <h2 className="mt-4 font-extrabold text-gray-900 dark:text-gray-300 text-lg">
          Have an account?{" "}
          <Link
            to="/auth/login"
            className="font-medium ml-1 text-blue-700 hover:underline dark:text-indigo-400 hover:text-blue-800 hover:dark:text-indigo-500"
          >
            Login
          </Link>
        </h2>
      </p>
    </div>
  );
};
