import { gql } from "@apollo/client/core";

export const AnalyzeTrivyFE = gql`
  mutation AnalyzeTrivyFE($applicationId: String) {
    analyzeTrivyFE(applicationId: $applicationId) {
      app_id
      id
    }
  }
`;
