import { joinClassNames } from "../../functions/utilities";
import styles from "./Services.module.css";

type CreateApplicationNavProps = {
  navItems: NavItem[];
  currentId: string;
  setId: (id: string) => void;
};

interface NavItem {
  id: string;
  name: string;
  icon: any;
}

export function CreateApplicationNav({
  currentId,
  navItems,
}: CreateApplicationNavProps): JSX.Element {
  return (
    <div className={styles.navigation}>
      <ul className="hidden md:block">
        {navItems.map((i, index) => (
          <li
            key={index}
            className={joinClassNames(styles.list, "")}
          >
            <div
              className={joinClassNames(i.id === currentId && styles.active)}
            >
              {/* TODO: refactor use of <a> */}
              <a>
                <span className={styles.icon}>
                  <i.icon className="ml-10 mt-4 w-6 h-6 text-gray-500 dark:text-gray-400" />
                </span>
                <span className={styles.title}>{i.name}</span>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
