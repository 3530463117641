import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import { AppList } from "../../../partials/application/AppList";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { joinClassNames } from "../../../functions/utilities";
import { AppStatusBar } from "../../../partials/application/AppStatusBar";
import { useHistory } from "react-router-dom";
import { usePathAppId } from "../../../functions/hooks";
import { FaRegEdit, FaCopy } from "react-icons/fa";
import { useUser } from "../../../functions/hooks/useUser";
import { GET_APPLICATIONS } from "../../../graphql/queries/GetApplications";
import { AiOutlineHistory } from "react-icons/ai";
import { useSnackbar } from "notistack";
import { notEmpty } from "../../../views/dashboard_2/dashboardDigest";
import logGraphQLError from "../../../functions/logGraphQLError";
// import { ShareableLink } from "../../../partials/application/ShareableLink";

type ApplicationNavigationProps = {
  setCurrentApp: (appId: string) => void;
};

export function ApplicationNavigation({
  setCurrentApp,
}: ApplicationNavigationProps): JSX.Element {
  const user = useUser();
  const appId = usePathAppId();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const appQuery = useQuery(GET_APPLICATIONS, {
    variables: {
      sub: user?.selectedOrg ?? "",
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onError: logGraphQLError,
  });

  const getAppFromId = (appId: string) => {
    if (appQuery.loading) {
      return;
    }

    const app = appQuery?.data?.getApplications?.find(
      (app: any) => app?.applicationId === appId
    );

    return app;
  };

  return (
    <Popover
      id="app-nav"
      className="application-nav-selector"
    >
      {({ open, close }: { open: boolean; close: () => void }) => (
        <>
          <Popover.Button
            className={joinClassNames(open ? "text-gray-900 dark:text-white bg-white dark:bg-transparent" : "text-black dark:text-gray-300",
              "px-4 justify-between transition-colors duration-150 group h-full rounded-t-md inline-flex items-center text-base font-medium hover:text-gray-900 md:w-[40vw] application-navigation"
            )}
          >
            <div className={joinClassNames("flex h-16 z-10 w-full", open ? "open-nav" : "")}>
              <div className="flex align-middle justify-between w-full">
                {appId ? (
                  <div className="flex flex-row flex-nowrap justify-between w-full">
                    <AppStatusBar application={getAppFromId(appId as string) ?? {}} />
                    <div className="flex flex-row flex-nowrap justify-between">
                      <div title="Copy App ID" className="inline-flex pr-3">
                        <FaCopy
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(appId).then(() => void enqueueSnackbar("Copied App ID", {
                              variant: "success",
                              persist: false,
                            }));
                          }}
                          className="h-5 w-5 m-auto dark:text-gray-400 dark:hover:text-gray-300 text-gray-400 hover:text-gray-600"
                        />
                      </div>
                      <div title="App Settings" className="inline-flex pr-2">
                        <FaRegEdit
                          onClick={(e) => {
                            push(`/settings/applications/${appId}`);
                            e.stopPropagation();
                            close();
                          }}
                          className="h-5 w-5 m-auto dark:text-gray-400 dark:hover:text-gray-300 text-gray-400 hover:text-gray-600"
                        />
                      </div>
                      <div title="App History" className="inline-flex pr-5">
                        <AiOutlineHistory
                          onClick={(e) => {
                            push(`/history/${appId}`);
                            e.stopPropagation();
                            close();
                          }}
                          className="h-5 w-5 m-auto dark:text-gray-400 dark:hover:text-gray-300 text-gray-400 hover:text-gray-600"
                        />
                      </div>
                    </div>

                  </div>
                )
                  : (
                    <div className="my-auto">Choose Application...</div>
                  )
                }
              </div>

            </div>
            <ChevronDownIcon
              className={joinClassNames(
                open ? "text-gray-600" : "text-gray-400",
                "h-6 w-6 group-hover:text-gray-500"
              )}
              aria-hidden="true"
            />
          </Popover.Button>


          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="h-fit max-h-[calc(100vh-68px)] overflow-y-auto overflow-x-hidden top-16 absolute z-10 inset-x-0 dark:shadow-2xl shadow-lg transform rounded-b-lg open-nav box-content md:left-[8px] left-0 md:w-[43rem]">
              <AppList
                error={appQuery?.error ? true : false}
                applications={appQuery?.data?.getApplications?.filter(notEmpty) ?? []}
                setCurrentApp={(appId: string) => {
                  setCurrentApp(appId);
                  close();
                }}
                closeFn={close}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
