export function Footer(): JSX.Element {
  const { HubSpotConversations }: any = window;
  return (
    <p className="text-base text-gray-500 text-center">
      Looking for help?{" "}
      <button
        onClick={() => HubSpotConversations.widget.open()}
        className="font-medium dark:text-gray-500 text-gray-900 underline cursor-pointer"
      >
        Contact support
      </button>
      .
    </p>
  );
};
