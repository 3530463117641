import { gql } from "@apollo/client/core";

export const BITBUCKET_REPOSITORIES = gql`
  query Repositories_BB($workspace: String!, $page: Int!, $orgId: String) {
    repositories(workspace: $workspace, page: $page, orgId: $orgId) {
      ... on BitbucketRepositoryResponse {
        pagelen
        size
        values {
          name
          language
        }
        page
      }
    }
  }
`;

export const GITLAB_REPOSITORIES = gql`
  query Repositories_GL($group: String!, $page: Int!, $orgId: String) {
    repositories(group: $group, page: $page, orgId: $orgId) {
      ... on GitlabProjectsResponse {
        pagelen
        size
        values {
          name
          path
          id
        }
        page
      }
    }
  }
`;

export const GITHUB_REPOSITORIES = gql`
  query Repositories_GH($organization: String!, $page: Int!, $orgId: String) {
    repositories(organization: $organization, page: $page, orgId: $orgId) {
      ... on GithubRepoResponse {
        pagelen
        size
        values {
          id
          name
          language
        }
        page
      }
    }
  }
`;

export const AZURE_DEVOPS_REPOSITORIES = gql`
query Repositories($page: Int!, $organizationAz: String, $orgId: String) {
  repositories(page: $page, organizationAZ: $organizationAz, orgId: $orgId) {
... on AzureReposResponse {
    next
    page
    pagelen
    size
    values {
      id
      isDisabled
      isInMaintenance
      name
      project {
        id
        lastUpdateTime
        name
        revision
        state
        url
        visibility
      }
      remoteUrl
      size
      sshUrl
      url
      webUrl
      }
    }
  }
}
`;