import { ChangeEvent, useEffect, useState } from "react";

export default function Mfa(): JSX.Element {
  const [code] = useState([null]);
  const [currentNumber, setCurrentNumber] = useState("");

  useEffect(() => {
    const handleChange = (e: ChangeEvent<HTMLInputElement> | any) => {
      if (e.key === "Backspace") {
        setCurrentNumber(currentNumber.slice(0, -1));
        return;
      }

      if (e.key === "Enter") {
        if (currentNumber.length !== 6) {
          return;
        }
        //trigger api call here
      }

      if (currentNumber.length >= 6) {
        return;
      }

      setCurrentNumber((prevNum) => prevNum + e.key);
    };

    window.addEventListener("keydown", handleChange);

    return () => window.removeEventListener("keydown", handleChange);
  }, [currentNumber]);

  return (
    <div className="bg-white dark:bg-dark-main max-w-lg px-4 py-4 rounded-2xl mx-auto ">
      <div className="text-center">
        <h1 className="border-b border-b-gray-400 p-2  dark:text-gray-300 text-gray-900">
          Two-Factor Authentication
        </h1>
        <p className="text-gray-900 mt-3 dark:text-gray-500">
          Set up two-factor authentication.
        </p>
        <div>
          {code.map((c, idx) => {
            const isCurrentNumber =
              idx === code.findIndex((val) => val == null);
            return <Line number={isCurrentNumber ? currentNumber : c ?? ""} />;
          })}
        </div>
      </div>
    </div>
  );
};

export function Line({ number }: { number: string }): JSX.Element {
  const tiles = [];

  for (let i = 0; i < 6; i++) {
    const num = number[i];

    tiles.push(
      <div
        className="w-12 h-12 border py-2 border-gray-500 text-lg rounded dark:text-green-400"
        key={i}
      >
        {num}
      </div>
    );
  }

  return (
    <div className="px-2 flex mt-5">
      <div className="mx-auto flex space-x-2 bg-dark-primary">{tiles}</div>
    </div>
  );
};
