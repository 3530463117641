export const convertToLocalTime = (date?: Date) => {
  return new Date(date ? date : "").toString();
};

export const formatDate = (date?: string) => {
  let today = date ? new Date(date) : new Date();
  return today.toISOString().split("T")[0];
};

export const getTodaysDate = () => {
  let today = new Date().toString();
  return today;
};
