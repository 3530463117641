import { DropDownSelect } from "../../../../components/forms/DropDownSelect";
import { Dispatch, SetStateAction, lazy, Suspense } from "react";
import { svgImport } from "../../../../partials/application/ApplicationComposition";

const NpmLogo = lazy(() => import('../../../../assets/app-composition/npm_Logo.svg').then(svgImport));
const ApacheLogo = lazy(() => import('../../../../assets/app-composition/apache.svg').then(svgImport));
const GoLogo = lazy(() => import('../../../../assets/app-composition/Go_Logo.svg').then(svgImport));
const NugetLogo = lazy(() => import('../../../../assets/app-composition/Nuget_Logo.svg').then(svgImport));
const YarnLogo = lazy(() => import('../../../../assets/app-composition/Yarn_Logo.svg').then(svgImport));
const PythonLogo = lazy(() => import('../../../../assets/app-composition/Python_Logo.svg').then(svgImport));
const RustLogo = lazy(() => import('../../../../assets/app-composition/Rust_Logo.svg').then(svgImport));


type PackageManagerObjType = {
  id: string;
  name: string;
  icon: any;
  fileReq: string[];
};

const INITIAL_OBJ = {
  id: "0",
  name: "",
  icon: "",
  fileReq: [""],
};

const packageManagerOptionsMap = new Map<string, PackageManagerObjType>([
  [
    "npm",
    {
      id: "1",
      name: "npm",
      icon: <Suspense fallback={<></>}><NpmLogo className="w-3" /></Suspense>,
      fileReq: ["package-lock.json", "package.json"],
    },
  ],
  [
    "yarn",
    {
      id: "2",
      name: "yarn",
      icon: <Suspense fallback={<></>}><YarnLogo className="w-3" /></Suspense>,
      fileReq: ["yarn-lock.json", "package.json"],
    },
  ],
  ["go", { id: "3", name: "go", icon: <Suspense fallback={<></>}><GoLogo className="w-3" /></Suspense>, fileReq: ["go.mod", "go.sum"] }],
  ["maven", { id: "4", name: "maven", icon: <Suspense fallback={<></>}><ApacheLogo className="w-3" /></Suspense>, fileReq: ["pom.xml"] }],
  [
    "pypi",
    { id: "5", name: "pypi", icon: <Suspense fallback={<></>}><PythonLogo className="w-3" /></Suspense>, fileReq: ["requirements.txt"] },
  ],
  [
    "pipenv",
    {
      id: "6",
      name: "pipenv",
      icon: <Suspense fallback={<></>}><PythonLogo className="w-3" /></Suspense>,
      fileReq: ["Pipfile", "Pipfile.lock"],
    },
  ],
  [
    "nuget",
    {
      id: "7",
      name: "nuget",
      icon: <Suspense fallback={<></>}><NugetLogo className="w-3" /></Suspense>,
      fileReq: [".nuspec"],
    },
  ],
  [
    "cargo",
    {
      id: "8",
      name: "cargo",
      icon: <Suspense fallback={<></>}><RustLogo className="w-3" /></Suspense>,
      fileReq: ["cargo.lock"],
    },
  ],
]);


type SelectPackageManagerProps = {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  testId?: string;
};
export function SelectPackageManager({
  selected,
  setSelected,
  testId
}: SelectPackageManagerProps): JSX.Element {
  const selectedPackageManager = packageManagerOptionsMap.get(selected) || INITIAL_OBJ;

  const arr = Array.from(packageManagerOptionsMap, ([_, value]) => { return value; });

  return (
    <DropDownSelect
      testId={testId}
      label="Select package manager"
      selected={selectedPackageManager}
      setSelected={(val) => setSelected(val.name)}
      data={arr}
    />
  );
};
