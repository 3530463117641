export const hasMoreDataToQuery = (
  totalItems: number,
  pageLen: number,
  values: any,
  pageNumber: number
) => {
  if (Math.round(totalItems / pageLen) !== pageNumber) {
    if (values?.length === 0) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};
