import { useState } from "react";
import { IoWarning } from "react-icons/io5";
import GoGistSecret from "../../../assets/images/GoGistSecret";
import CreateIssue from "../../../components/jira/CreateIssue";
import { convertToLocalTime } from "../../../functions/convertToLocalTime";
import { ParsedSecretsIssueRow } from "../../../functions/hooks/useApplicationIssues";
import { Tabber } from "../exposure/SelectedExposureIssueView";
import { PopupBody, PopupHeading } from "../exposure/Details";

type SelectedSecretIssueViewProps = {
  selectedIssue: ParsedSecretsIssueRow;
};

const tabs = ["Details", "Create Ticket"];
type SelectedSecretsIssueViewTabs = typeof tabs[number];

export function SelectedSecretIssue({
  selectedIssue,
}: SelectedSecretIssueViewProps): JSX.Element {
  const [tab, setTab] = useState<SelectedSecretsIssueViewTabs>("Details");

  if (!selectedIssue || selectedIssue.issueTitle.length === 0) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <IoWarning className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Issue not found
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=" dark:bg-dark-main rounded-lg bg-white py-4 px-4 space-y-2">

      <div className="my-auto flex flex-row flex-nowrap">
        <div className="pr-4 my-auto">
          <GoGistSecret className="w-6 h-6 text-secrets-icon-active" />
        </div>
        <h3 className="text-xl font-semibold tracking-tight leading-6 text-gray-900 dark:text-gray-300">
          {selectedIssue.credentialRuleName}
        </h3>
      </div>
      <br />
      <Tabber tabs={tabs} currentTab={tab} setCurrentTab={setTab} displayStyle="secrets" />
      {
        tab === "Details" && <>
          <div className="grid grid-cols-1 gap-x-4 pt-5 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <PopupHeading s="Issue:" />
              <PopupBody className="mt-1">
                <span className="break-words">{selectedIssue.issueTitle}</span>
              </PopupBody>
            </div>
            <div className="sm:col-span-1">
              <PopupHeading s="Line Number:" />
              <PopupBody className="mt-1">
                <span className="break-words">{selectedIssue.filename}:{selectedIssue.lineNumber}</span>
              </PopupBody>
            </div>
            <div className="sm:col-span-1">
              <PopupHeading s="Commit Tags:" />
              <PopupBody className="mt-1">
                <div className="flex flex-row flex-wrap gap-2">
                  {selectedIssue.commitTags?.map((s: string) => (
                    <span
                      key={s}
                      className="inline-flex break-words items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-200 dark:bg-dark-primary text-green-600"
                    >
                      {s}
                    </span>
                  ))}
                </div>
              </PopupBody>
            </div>
            <div className="sm:col-span-1">
              <PopupHeading s="Scan Initiator IP:" />
              <PopupBody className="mt-1">
                <span>{selectedIssue.scanInitiatorIP}</span>
              </PopupBody>
            </div>
            <div className="sm:col-span-1">
              <PopupHeading s="Scanned Time:" />
              <PopupBody className="mt-1">
                <span className="break-words">{convertToLocalTime(selectedIssue.timestamp ?? undefined)}</span>
              </PopupBody>
            </div>
            <div className="pb-4 justify-between flex rounded-t-xl dark:border-b-gray-600 border-b-gray-600">
              <div className="flex flex-shrink-0 gap-2 items-center flex-row">
                <PopupHeading s="Offender Entropy:" />
                <PopupBody>
                  <span className="break-words">{selectedIssue.offenderEntropy}</span>
                </PopupBody>
              </div>
            </div>
          </div>
        </>
      }
      {
        tab === "Create Ticket" && <CreateIssue issue={`${selectedIssue?.credentialRuleName} found in ${selectedIssue.repoName}: ${selectedIssue.filename}`} />
      }
    </div>
  );
};
