import { useEffect } from "react"

export function pageTitle(title: string, appendSecurestack?: boolean) {
    const prevTitle = document.title
    document.title = `${appendSecurestack ? "SecureStack - " : ""}${title}`
    return () => {
        document.title = prevTitle
    }
}

export default function useTitle(title: string, appendSecurestack?: boolean) {
    useEffect(() => {
        return pageTitle(title, appendSecurestack);
    }, [appendSecurestack, title])
}