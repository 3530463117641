import { ExclamationIcon } from "@heroicons/react/solid";

export function NoSbomMessage(): JSX.Element {
  return (
    <div className="bg-white mt-6 dark:bg-dark-primary dark:shadow-lg rounded-lg border-l-4 border-yellow-600 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <span className="font-medium  text-gray-900 dark:text-gray-300 ">
            Please create an SBOM to view this page
          </span>
        </div>
      </div>
    </div>
  );
};
