import { gql } from "@apollo/client/core";

export const REQUEST_BB_USER_IDENTITY = gql`
  query requestBBUserIdentity($token: String!, $orgId: String) {
    requestBBUserIdentity(token: $token, orgId: $orgId)
  }
`;

export const REQUEST_GH_USER_IDENTITY = gql`
  query requestGHUserIdentity($token: String!, $orgId: String) {
    requestGHUserIdentity(token: $token, orgId: $orgId)
  }
`;

export const REQUEST_GL_USER_IDENTITY = gql`
  query requestGLUserIdentity($token: String!, $orgId: String) {
    requestGLUserIdentity(token: $token, orgId: $orgId)
  }
`;

export const REQUEST_AZ_USER_IDENTITY = gql`
  query requestAZDevopsUserIdentity($token: String!, $orgId: String) {
    requestAZDevopsUserIdentity(token: $token, orgId: $orgId)
  }
`;
