export const trimTargetUrl = (targetUrl?: string) => {
  //  To remove http:// or https:
  if (!targetUrl) return "";
  const targetValidation = /^https?:\/\//;
  let target = targetUrl;
  if (targetValidation.test(target)) {
    target = target.replace(targetValidation, "");
  }
  return target;
};
