import { useQuery } from "react-query";
import { supabaseClient } from "../../auth/supabase";
import Auth0ProviderWithHistory from "../../auth/Auth0ProviderWithHistory";
import { RegisterOrg } from "./org-selector/RegisterOrg";
import { Redirect, useHistory } from "react-router-dom";
import { Redirecting } from "../../auth/ProtectedRoute";
import { StackedList } from "../../components/lists/StackedList";
import { isFeatureEnabled } from "../../functions/utilities";
import { Loader } from "../../components/loading/Loader";
import { PostgrestError } from "@supabase/supabase-js";
import { AuthLayout } from "../../components/layouts/AuthLayout";
import { useUser } from "../../functions/hooks/useUser";

type UserOrg = {
  id: string;
  name: string;
  alias: string;
};

export function OrgSelector(): JSX.Element {
  // Find orgs for user
  const user = useUser();
  const history = useHistory();

  const { data, error, isLoading } = useQuery<UserOrg[], PostgrestError | null>(
    ["user_orgs", user?.id],
    async () => {
      const { data, error } = await supabaseClient
        .from("orgs")
        .select("id,alias,name");

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    {
      onSuccess: async (data) => {
        if (data.length < 1) {
          return;
        }

        if (user && data.length === 1) {
          user["selectedOrg"] = data[0].id;
          await supabaseClient.auth.refreshSession();
          history.push("/");
        }
      },
    }
  );

  if (!user || !user.id || user.status !== "SIGNED_IN") {
    return <Redirect to="/auth/login" />
  }

  if (isLoading) {
    return <Loader w={7} h={7} />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  const selectOrg = async (org: string) => {
    if (user) {
      user["selectedOrg"] = org;
      await supabaseClient.auth.refreshSession();
      history.push("/");
    }
  };

  if (data && data.length > 0) {
    if (isFeatureEnabled("multi-org") && data.length > 1) {
      return (
        <StackedList
          itemClick={async (item) => await selectOrg(item.id)}
          items={data}
          render={(data) => (
            <div className="ml-3 p-2">
              <p className="text-sm text-gray-500 dark:text-gray-500">
                {data.name}
              </p>
            </div>
          )}
        />
      );
    }

    return <Redirecting />;
  }

  return (
    <Auth0ProviderWithHistory>
      <AuthLayout>
        <RegisterOrg />
      </AuthLayout>
    </Auth0ProviderWithHistory>
  );
};
