import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { useEffect, useState } from "react";
import { useHistoryState } from "../hooks";
import { useUser } from "./useUser";
import { GET_GL_GROUP_QUERY } from "../../graphql/queries/GetOrgsOrWorkspacesOrGroups";
import { uniq } from "lodash";

type GroupState = {
  id: string;
  name: string;
}

type GroupValue = {
  __typename: string;
  path: string;
  name: string;
};

export const useGroupSearchQuery = (scm: string, pageNumber: number) => {
  const [appScm] = useHistoryState("appScm");
  const [groupData, setGroupData] = useState<GroupState[]>([]);
  const [hasMoreGroups] = useState(false);
  const user = useUser();

  const [getGroup, { loading: loadingGroups, error: groupError }] =
    useLazyQuery(GET_GL_GROUP_QUERY, {
      variables: {
        scm: appScm || scm,
        page: pageNumber,
        orgId: user?.selectedOrg,
      },

      onCompleted: ({ orgsOrWorkspacesOrGroups }) => {
        let prevBranches = [...groupData];
        let currentWorkspaces = orgsOrWorkspacesOrGroups?.values?.map(
          (b: GroupValue) => {
            return { id: b.path, name: b.name };
          }
        );
        let concatData = prevBranches.concat(currentWorkspaces);
        let results = concatData.filter((e) => {
          return e;
        });

        setGroupData(uniq(results));
      },
    });

  useEffect(() => {
    getGroup();
  }, [getGroup, pageNumber]);

  return { loadingGroups, groupError, groupData, hasMoreGroups };
};
