import { gql } from "../../__generated__/gql";

export const SCA_AUTO = gql(`
  mutation ScaAuto($orgId: String!, $applicationId: String!) {
    scaAuto(orgId: $orgId, applicationId: $applicationId) {
      id
      state
      state_reason
      app_id
      org_id
      time_created
    }
  }
`);