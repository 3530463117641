import { joinClassNames } from "../../../functions/utilities";

export function CreateAppLayout({
  children,
  isEditing,
  concierge,
}: {
  children: any;
  isEditing?: boolean;
  concierge?: boolean;
}): JSX.Element {
  return (
    <div
      className={
        concierge
          ? "bg-white dark:bg-dark-main relative ml-0 rounded-2xl"
          : joinClassNames(
              !isEditing && "ml-0 sm:ml-[260px]",
              "bg-white dark:bg-dark-main relative min-h-[425px] py-10 rounded-2xl"
            )
      }
    >
      <div className="px-14 space-y-4">{children}</div>
    </div>
  );
};
