import { gql } from "@apollo/client/core";

export const GET_BB_WORKSPACES_QUERY = gql`
  query GetOrgsOrWorkspacesOrGroups_3(
    $scm: String!
    $page: Int!
    $orgId: String
  ) {
    orgsOrWorkspacesOrGroups(scm: $scm, page: $page, orgId: $orgId) {
      ... on BitbucketWorkspaceResponse {
        pagelen
        size
        values {
          type
          is_private
          slug
          name
        }
        page
      }
    }
  }
`;

export const GET_GL_GROUP_QUERY = gql`
  query GetOrgsOrWorkspacesOrGroups_1(
    $scm: String!
    $page: Int!
    $orgId: String
  ) {
    orgsOrWorkspacesOrGroups(scm: $scm, page: $page, orgId: $orgId) {
      ... on GitlabGroupsResponse {
        next
        page
        pagelen
        size
        values {
          path
          id
          name
        }
      }
    }
  }
`;

export const GET_GH_ORGS_QUERY = gql`
query GetOrgsOrWorkspacesOrGroups_2(
  $scm: String!
  $page: Int!
  $orgId: String
) {
  orgsOrWorkspacesOrGroups(scm: $scm, page: $page, orgId: $orgId) {
    ... on GitHubOrganizationUser {
      organization {
        login
        url
      }
      user
    }
  }
}
`;

export const GET_AZURE_ORGS = gql`
query OrgsOrWorkspacesOrGroups_az($scm: String!, $page: Int!, $orgId: String) {
    orgsOrWorkspacesOrGroups(scm: $scm, page: $page, orgId: $orgId) {
      ... on AzureOrganizationResponse {
        pagelen
        size
        values {
          accountName
          accountId
        }
        page
        next
      }
    }
  }
`;
