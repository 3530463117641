import { gql } from "../../__generated__/gql";

export const SBOM_LIST = gql(`
  query FSBOMList($fSbomListId: ID!, $orgId: String) {
    fSBOMList(applicationId: $fSbomListId, orgId: $orgId) {
      id
      org_id
      app_id
      state
      state_reason
      domain
      cloud_account_id
      time_created
      time_updated
    }
  }
`);
