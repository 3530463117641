import { SelectedIssue } from "../../../App";
import {
  getBorderColor,
  getIcon,
  getTextColor,
} from "../../../functions/severityFunctions";
import { joinClassNames, titleCase } from "../../../functions/utilities";

export const codeIssueRegex = /\[[\@\{\"\'\w: ,\/\.\-\<\=\>\}\(\)]*\]/g;

/* function displayIssueTitle(row: SelectedIssue) {
  if (row?.source === "code") {
    const issues = row?.issues?.replaceAll("'", '"');
    if (!issues) {
      return `${row.issue} vulnerability`;
    }
    const matches = [...issues.matchAll(codeIssueRegex)];

    if (matches && matches.length === 2) {
      var vulnFrom, effected;
      try {
        vulnFrom = (() => { try { return JSON.parse(matches.at(0) ?? "") } catch { return "" } })();
        effected = (() => { try { return JSON.parse(matches.at(1) ?? "") } catch { return "" } })();
        var etext = effected.join(", ");

        if (vulnFrom?.length && vulnFrom.length > 0) {
          if (typeof vulnFrom[0] === "object") {
            if (effected?.length !== 0) {
              return `${vulnFrom[0]?.title}: vulnerability (effects ${etext})`;
            }
            return `${row.issue}: ${vulnFrom[0]?.title}`;
          }
        }

        return `${vulnFrom.join(", ")}: vulnerability (effects ${etext === "" ? "N/A" : etext
          })`;
      } catch {
        if (!vulnFrom) {
          vulnFrom = matches[0];
        }
        if (!effected) {
          effected = matches[1];
        }
      }
    } else if (row.advisory) {
      return `${row.issue} vulnerability`;
    }
  }

  return row.issue;
} */

export const allEnrichDataRowFactory = (viewIssue: Function) => {
  return (row: SelectedIssue, index: number) => {
    return {
      severity: (
        <td
          title={
            row?.severity?.toLowerCase() === "moderate"
              ? "Medium"
              : titleCase(row?.severity ?? "")
          }
          key={`severity-${index}`}
          /* px-6 py-4  */
          className="pl-3 whitespace-nowrap font-medium text-gray-900 dark:text-gray-400"
        >
          <span
            className={joinClassNames(
              getBorderColor(row?.severity as string),
              getTextColor(row?.severity as string),
              "inline-block py-1 px-4 text-sm font-medium border-l-2",
            )}
          >
            {row?.severity?.toLowerCase() === "moderate"
              ? "Medium"
              : titleCase(row?.severity ?? "")}
          </span>
        </td>
      ),
      //cve: (<td><p className="w-fit rounded-full px-2 py-1 bg-light-seconday dark:bg-dark-nav"><span className="m-auto">Critical</span></p></td>),
      issue: (
        <td
          key={`issues-${index}`}
          title={row?.issueTitle ?? ""}
          className="px-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-400"
        >
          {row?.formattedTitle == null ? (
            <p
              className="overflow-clip text-ellipsis sm:max-w-sm"
              style={{ maxWidth: "43vw" }}
            >
              {row?.issueTitle}
            </p>
          ) : (
            row.formattedTitle
          )}
        </td>
      ),
      source: (
        <td
          key={`source-${index}`}
          title={row?.source ?? ""}
          className="px-1 xl:px-3 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
        >
          <div className="flex items-center">
            <span className="mr-2">{getIcon(row?.source)}</span>
            {titleCase(row?.source ?? "")}
          </div>
        </td>
      ),
      action: (
        <td
          key={`problem-action-${index}`}
          /* py-4 pr-10 sm:pr-10 */
          className="relative whitespace-nowrap text-right text-sm font-medium"
        >
          <button
            onClick={() => void viewIssue(row)}
            className="py-4 pr-2 text-indigo-400 hover:text-indigo-900 cursor-pointer"
          >
            View
          </button>
        </td>
      ),
    };
  };
};
