import { Dispatch } from "react";

export const DOTS = "...";

export const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

function getPaginationRange(totalItems: number, itemsPerPage: number, currentPage: number, siblingCount: number): number[] {

  if (Number.isNaN(totalItems) || Number.isNaN(itemsPerPage) || Number.isNaN(currentPage)) {
    return [];
  }

  // number of numbers to return i.e: 5 becomes < [1] 2 3 4 5 6 > if there are enough items to have 6 pages
  const numbersToShow: number = 5;

  const totalPages: number = Math.ceil(totalItems / itemsPerPage); // max page number

  // look back if there's range
  var startNumber: number = (currentPage + numbersToShow) >= totalPages ? (totalPages - numbersToShow) : currentPage;
  if (startNumber < 1) {
    startNumber = 1;
  }
  // look forward if there's range
  var endNumber: number = (currentPage + numbersToShow) >= totalPages ? totalPages : (currentPage + numbersToShow);

  return range(0, totalItems).slice(startNumber, endNumber + 1);
}

export type PaginationProps = {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
  siblingCount?: number;
  numFilteredItems?: number;
  isFiltered?: boolean;
  filterBy?: "recently_added" | "name";
  setFilterBy?: Dispatch<"recently_added" | "name">;
};

export const usePagination = ({
  totalItems,
  itemsPerPage,
  siblingCount,
  currentPage,
}: PaginationProps) => {
  return getPaginationRange(totalItems, itemsPerPage, currentPage, siblingCount ?? 1);
};
