import { GitHubFolderStructure, BitbucketFolderStructure, AzureFolderStructure, GitlabFolderStructure } from "../__generated__/graphql";
import { notEmpty } from "../views/dashboard_2/dashboardDigest";

enum PackageManagerNames {
  NPM = "npm",
  MAVEN = "maven",
  YARN = "yarn",
  GO = "go",
  PYPI = "pypi",
  PIPENV = "pipenv",
  NUGET = "nuget",
  CARGO = "cargo",
}

export function deriveFilesFromPackageManager(
  folderStructure: GitHubFolderStructure[] | BitbucketFolderStructure[] | AzureFolderStructure[] | GitlabFolderStructure[],
  packageManager: string
): string[] {
  const { NPM, MAVEN, YARN, GO, PYPI, PIPENV, NUGET, CARGO } = PackageManagerNames;

  let fileArr = folderStructure?.map((f) => f.path);

  const packageMap = new Map<string, Array<string | RegExp>>([
    [NPM, ["package-lock.json", "package.json"]],
    [MAVEN, ["pom.xml"]],
    [YARN, ["package.json", "yarn.lock"]],
    [GO, ["go.mod", "go.sum"]],
    [PYPI, ["requirements.txt"]],
    [PIPENV, ["Pipfile.lock", "Pipfile"]],
    [NUGET, [".nuspec"]],
    [CARGO, ["cargo.lock"]],
    [NUGET, [/.+\.nuspec$/i]], // future support: /.+\.csproj$/i]],
  ]);

  const simpleFilterTypes = [NPM, MAVEN, YARN, GO, PYPI, PIPENV, NUGET, CARGO];

  if (simpleFilterTypes.includes(packageManager as PackageManagerNames)) {
    const packageFiles = packageMap.get(packageManager);

    if (packageFiles) {
      if (
        packageFiles.every((fileName: string | RegExp) => {
          return typeof fileName === "string";
        })
      ) {
        let array = fileArr.filter((fileName) => packageFiles.includes(fileName ?? ""));

        if (packageManager === "nuget") {
          array = fileArr.filter((fileName) => fileName?.endsWith(".nuspec"));
        }

        if (packageManager === "cargo") {
          array = fileArr.filter(
            (fileName) => fileName?.endsWith(".toml") || fileName?.endsWith(".lock")
          );
        }

        if (packageManager === "yarn" && array?.length === 1) {
          return [];
        }

        if (packageManager === "npm" && array?.length === 1) {
          return [];
        }

        return array.filter(notEmpty);
      } else {
        const matchedFilenames: string[] = [];
        for (const fileName of fileArr) {
          for (const packageNameCriteria of packageFiles) {
            if (packageNameCriteria instanceof RegExp && fileName) {
              if (packageNameCriteria.test(fileName)) {
                matchedFilenames.push(fileName);
              }
            }
          }
        }
        return matchedFilenames;
      }
    }

  }

  // return fileArr.filter((fileName) => {
  //   const packageFiles = packageMap.get(packageManager);
  //   if (!packageFiles) return false;

  //   return packageFiles.some((fileTest) => {
  //     if (typeof fileTest === "string") {
  //       return fileTest === fileName;
  //     }
  //     return fileTest.test(fileName);
  //   });
  // });
  return [];
};
