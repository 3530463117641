import { FunctionComponent, lazy as lazyLoad, Suspense } from "react";
import { BsFillCartFill } from "react-icons/bs";
import { ScanType } from "../../app-types/scanTypes";
import {
  Chip,
  CollaboratorsBar,
  ScmMeta,
} from "../../views/dashboard_2/PeopleView";
import {
  AppSettingsData,
  DashboardData,
} from "../../views/dashboard_2/dashboard2";
import { FaSquarespace } from "react-icons/fa";
import { Fragment, useState } from "react";
import { notEmpty, ParsedSSC } from "../../views/dashboard_2/dashboardDigest";
import { Loader } from "../../components/loading/Loader";
import { groupBy, uniq } from "lodash";
import { Modal } from "../../components/dialogs/Modal";
import { AiOutlineCloud, AiOutlineWarning } from "react-icons/ai";
import { formatDate } from "../../views/dashboard_2/HistoryPicker";
import { GoPackage, GoPackageDependencies } from "react-icons/go";
import { Button } from "../../components/buttons/Button";
import { Link } from "react-router-dom";
import "./ApplicationComposition.css";

export function svgImport(svg: typeof import("*.svg")) {
  return { default: svg.ReactComponent };
}

function pngImport(png: typeof import("*.png")): {
  default: FunctionComponent;
} {
  return {
    default: () => (
      <img alt="" src={png.default} className="app-composition-logo" />
    ),
  };
}

const AWSWAFLogo = lazyLoad(() =>
  import("../../assets/app-composition/AWS-WAF_Logo.svg").then(svgImport),
);
const AWSCertManager = lazyLoad(() =>
  import("../../assets/app-composition/AWS_Certificate_Manager.svg").then(
    svgImport,
  ),
);
const AddThisLogo = lazyLoad(() =>
  import("../../assets/app-composition/AddThis_Logo.svg").then(svgImport),
);
const AdobeDreamweaverLogo = lazyLoad(() =>
  import("../../assets/app-composition/Adobe-Dreamweaver_Logo.svg").then(
    svgImport,
  ),
);
const AdobeCloudExperienceLogo = lazyLoad(() =>
  import("../../assets/app-composition/Adobe-Experience-Cloud_Logo.svg").then(
    svgImport,
  ),
);
const TypekitLogo = lazyLoad(() =>
  import("../../assets/app-composition/Adobe-Fonts-typekit_Logo.svg").then(
    svgImport,
  ),
);
const AfterpayLogo = lazyLoad(() =>
  import("../../assets/app-composition/Afterpay_Logo.svg").then(svgImport),
);
const AlpineJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Alpine-js_Logo.svg").then(svgImport),
);
const AWSAuroraLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-Aurora_Logo.svg").then(svgImport),
);
const AWSCloudWatchLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-CloudWatch_Logo.svg").then(
    svgImport,
  ),
);
const AWSCognitoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-Cognito_Logo.svg").then(
    svgImport,
  ),
);
const AWSECSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-ECS_Logo.svg").then(svgImport),
);
const AWSEFSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-EFS_Logo.svg").then(svgImport),
);
const AwsS3OriginLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amazon-S3-Origin_Logo.svg").then(
    svgImport,
  ),
);
const AmazonAPIGatewayLogo = lazyLoad(() =>
  import("../../assets/app-composition/AmazonAPIGateway_Icon.svg").then(
    svgImport,
  ),
);
const AmexLogo = lazyLoad(() =>
  import("../../assets/app-composition/American_Express_Logo.svg").then(
    svgImport,
  ),
);
const AmplitudeLogo = lazyLoad(() =>
  import("../../assets/app-composition/Amplitude_Logo.svg").then(svgImport),
);
const TomcatLogo = lazyLoad(() =>
  import("../../assets/app-composition/Apache_Tomcat_Logo.svg").then(svgImport),
);
const ApplePayLogo = lazyLoad(() =>
  import("../../assets/app-composition/Apple_Pay_Logo.svg").then(svgImport),
);
const AquaLogo = lazyLoad(() =>
  import("../../assets/app-composition/AquaSec_Logo.svg").then(svgImport),
);
const AutomatticLogo = lazyLoad(() =>
  import("../../assets/app-composition/Automattic_Logo.svg").then(svgImport),
);
const AxoniusLogo = lazyLoad(() =>
  import("../../assets/app-composition/Axonius_Logo.svg").then(svgImport),
);
const AzureADLogo = lazyLoad(() =>
  import("../../assets/app-composition/Azure-Active-Directory_Logo.svg").then(
    svgImport,
  ),
);
const AzureCDNLogo = lazyLoad(() =>
  import("../../assets/app-composition/Azure-CDN_Logo.svg").then(svgImport),
);
const AzureFrontDoorLogo = lazyLoad(() =>
  import("../../assets/app-composition/Azure-Front-Door_Logo.svg").then(
    svgImport,
  ),
);
const AzureMonitorLogo = lazyLoad(() =>
  import("../../assets/app-composition/Azure-Monitor_Logo.svg").then(svgImport),
);
const AzureAutoScaleLogo = lazyLoad(() =>
  import("../../assets/app-composition/Azure_Auto-Scale_Logo.svg").then(
    svgImport,
  ),
);
const BabelLogo = lazyLoad(() =>
  import("../../assets/app-composition/Babel_Logo.svg").then(svgImport),
);
const BulmaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Bulma_Logo.svg").then(svgImport),
);
const BurpLogo = lazyLoad(() =>
  import("../../assets/app-composition/Burp_Logo.svg").then(svgImport),
);
const CarbonBuySellAdsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Carbon_Logo.svg").then(svgImport),
);
const ChartjsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Chartjs_Logo.svg").then(svgImport),
);
const ContentfulLogo = lazyLoad(() =>
  import("../../assets/app-composition/Contentful_Logo.svg").then(svgImport),
);
const CookiebotLogo = lazyLoad(() =>
  import("../../assets/app-composition/Cookiebot_Logo.svg").then(svgImport),
);
const CriteoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Criteo_Logo.svg").then(svgImport),
);
const DigiCertLogo = lazyLoad(() =>
  import("../../assets/app-composition/DigiCert_Logo.svg").then(svgImport),
);
const DjangoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Django_Logo.svg").then(svgImport),
);
const DriftLogo = lazyLoad(() =>
  import("../../assets/app-composition/Drift_Logo.svg").then(svgImport),
);
const JettyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Eclipse_Jetty_Logo.svg").then(svgImport),
);
const ElementorLogo = lazyLoad(() =>
  import("../../assets/app-composition/Elementor_Logo.svg").then(svgImport),
);
const EmberjsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Emberjs_Logo.svg").then(svgImport),
);
const ExpanseLogo = lazyLoad(() =>
  import("../../assets/app-composition/ExpanseCortex_Logo.svg").then(svgImport),
);
const FacebookLogo = lazyLoad(() =>
  import("../../assets/app-composition/Facebook_Logo.svg").then(svgImport),
);
const FancyboxLogo = lazyLoad(() =>
  import("../../assets/app-composition/Fancybox_Logo.svg").then(svgImport),
);
const FastlyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Fastly_Logo.svg").then(svgImport),
);
const FirebaseLogo = lazyLoad(() =>
  import("../../assets/app-composition/Firebase_Logo.svg").then(svgImport),
);
const FlaskLogo = lazyLoad(() =>
  import("../../assets/app-composition/Flask_Logo.svg").then(svgImport),
);
const FlywheelLogo = lazyLoad(() =>
  import("../../assets/app-composition/Flywheel_Logo.svg").then(svgImport),
);
const GatsbyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Gatsby_Logo.svg").then(svgImport),
);
const GitLogo = lazyLoad(() =>
  import("../../assets/app-composition/Git_Logo.svg").then(svgImport),
);
const GitguardianLogo = lazyLoad(() =>
  import("../../assets/app-composition/Gitguardian_Logo.svg").then(svgImport),
);
const GoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Go_Logo.svg").then(svgImport),
);
const GoogleAdsenseLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-AdSense_Logo.svg").then(
    svgImport,
  ),
);
const GoogleAdsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Ads_logo.svg").then(svgImport),
);
const GoogleCloudAppEngineLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-App-engine_Logo.svg").then(
    svgImport,
  ),
);
const GoogleCloudCDNLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Cloud-Cdn_Logo.svg").then(
    svgImport,
  ),
);
const GoogleCloudTraceLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Cloud-Trace_Logo.svg").then(
    svgImport,
  ),
);
const GoogleFormsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Forms_Logo.svg").then(svgImport),
);
const GoogleMyBusinessLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-My-Business_Logo.svg").then(
    svgImport,
  ),
);
const GoogleOptimizeLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Optimize_Logo.svg").then(
    svgImport,
  ),
);
const GooglePageSpeedLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-PageSpeed_Logo.svg").then(
    svgImport,
  ),
);
const GooglePayLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Pay_Logo.svg").then(svgImport),
);
const GoogleSitesLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Sites_Logo.svg").then(svgImport),
);
const GoogleWalletLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-Wallet_Logo.svg").then(svgImport),
);
const GoogleCloudStorageLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google-cloud_storage_Logo.svg").then(
    svgImport,
  ),
);
const GoogleAnalyticsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Analytics_Logo.svg").then(
    svgImport,
  ),
);
const GoogleCloudLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Cloud_Logo.svg").then(svgImport),
);
const GoogleFontsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Fonts_Logo.svg").then(svgImport),
);
const GoogleLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Logo.svg").then(svgImport),
);
const GoogleMapsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Maps_Logo_2020.svg").then(
    svgImport,
  ),
);
const GooglePlusLogo = lazyLoad(() =>
  import("../../assets/app-composition/Google_Plus_Logo.svg").then(svgImport),
);
const GraphQLLogo = lazyLoad(() =>
  import("../../assets/app-composition/GraphQL_Logo.svg").then(svgImport),
);
const GreensockGSAPLogo = lazyLoad(() =>
  import("../../assets/app-composition/Greensock_Logo.svg").then(svgImport),
);
const HandlebarsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Handlebarsjs_Logo.svg").then(svgImport),
);
const HerokuLogo = lazyLoad(() =>
  import("../../assets/app-composition/Heroku_Logo.svg").then(svgImport),
);
const HighchartsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Highcharts_Logo.svg").then(svgImport),
);
const HotjarLogo = lazyLoad(() =>
  import("../../assets/app-composition/Hotjar-2021_Logo.svg").then(svgImport),
);
const HugoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Hugo_Logo.svg").then(svgImport),
);
const IntercomLogo = lazyLoad(() =>
  import("../../assets/app-composition/Intercom_Logo.svg").then(svgImport),
);
const IonicLogo = lazyLoad(() =>
  import("../../assets/app-composition/Ionic_Logo.svg").then(svgImport),
);
const JQueryUILogo = lazyLoad(() =>
  import("../../assets/app-composition/JQuery_UI_Logo.svg").then(svgImport),
);
const JavaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Java_Logo.svg").then(svgImport),
);
const JenkinsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Jenkins_Logo.svg").then(svgImport),
);
const JiraLogo = lazyLoad(() =>
  import("../../assets/app-composition/Jira_Logo.svg").then(svgImport),
);
const JoomlaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Joomla_Logo.svg").then(svgImport),
);
const JsDelivrLogo = lazyLoad(() =>
  import("../../assets/app-composition/JsDelivr_Logo.svg").then(svgImport),
);
const KinstaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Kinsta_Logo.svg").then(svgImport),
);
const KubernetesLogo = lazyLoad(() =>
  import("../../assets/app-composition/Kubernetes_Logo.svg").then(svgImport),
);
const LessLogo = lazyLoad(() =>
  import("../../assets/app-composition/LESS_Logo.svg").then(svgImport),
);
const LightboxLogo = lazyLoad(() =>
  import("../../assets/app-composition/Lightbox_Logo.svg").then(svgImport),
);
const LuaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Lua_Logo.svg").then(svgImport),
);
const MagentoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Magento_Logo.svg").then(svgImport),
);
const MailchimpLogo = lazyLoad(() =>
  import("../../assets/app-composition/Mailchimp_Logo.svg").then(svgImport),
);
const MariaDBLogo = lazyLoad(() =>
  import("../../assets/app-composition/MariaDB_Logo.svg").then(svgImport),
);
const MarketoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Marketo_Logo.svg").then(svgImport),
);
const MatomoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Matomo_Logo.svg").then(svgImport),
);
const MavenLogo = lazyLoad(() =>
  import("../../assets/app-composition/Maven_Logo.svg").then(svgImport),
);
const MS365Logo = lazyLoad(() =>
  import("../../assets/app-composition/Microsoft-365_Logo.svg").then(svgImport),
);
const DotNETLogo = lazyLoad(() =>
  import("../../assets/app-composition/Microsoft_dotNET_Logo.svg").then(
    svgImport,
  ),
);
const MixpanelLogo = lazyLoad(() =>
  import("../../assets/app-composition/Mixpanel_Logo.svg").then(svgImport),
);
const ModernizrLogo = lazyLoad(() =>
  import("../../assets/app-composition/Modernizr_Logo.svg").then(svgImport),
);
const MomentJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Moment-js_Logo.svg").then(svgImport),
);
const MySQLLogo = lazyLoad(() =>
  import("../../assets/app-composition/MySQL_Logo.svg").then(svgImport),
);
const DotNETCoreLogo = lazyLoad(() =>
  import("../../assets/app-composition/NETcore_Logo.svg").then(svgImport),
);
const NewRelicLogo = lazyLoad(() =>
  import("../../assets/app-composition/New_Relic_Logo.svg").then(svgImport),
);
const NextjsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Nextjs_Logo.svg").then(svgImport),
);
const NodejsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Node_js_Logo.svg").then(svgImport),
);
const NugetLogo = lazyLoad(() =>
  import("../../assets/app-composition/Nuget_Logo.svg").then(svgImport),
);
const NuxtLogo = lazyLoad(() =>
  import("../../assets/app-composition/Nuxt_Logo.svg").then(svgImport),
);
const OctopusDeployLogo = lazyLoad(() =>
  import("../../assets/app-composition/Octopus-Deploy_Logo.svg").then(
    svgImport,
  ),
);
const OdooLogo = lazyLoad(() =>
  import("../../assets/app-composition/Odoo_Logo.svg").then(svgImport),
);
const OpenSSLLogo = lazyLoad(() =>
  import("../../assets/app-composition/OpenSSL_logo.svg").then(svgImport),
);
const OptimizelyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Optimizely_Logo.svg").then(svgImport),
);
const OsanoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Osano_Logo.svg").then(svgImport),
);
const PantheonLogo = lazyLoad(() =>
  import("../../assets/app-composition/Pantheon_Logo.svg").then(svgImport),
);
const PayPalLogo = lazyLoad(() =>
  import("../../assets/app-composition/PayPal_Logo.svg").then(svgImport),
);
const PostgresSqlLogo = lazyLoad(() =>
  import("../../assets/app-composition/Postgresql_Logo.svg").then(svgImport),
);
const PrebidLogo = lazyLoad(() =>
  import("../../assets/app-composition/Prebid_Logo.svg").then(svgImport),
);
const PrismLogo = lazyLoad(() =>
  import("../../assets/app-composition/Prism_Logo.svg").then(svgImport),
);
const PythonLogo = lazyLoad(() =>
  import("../../assets/app-composition/Python_Logo.svg").then(svgImport),
);
const QualysLogo = lazyLoad(() =>
  import("../../assets/app-composition/Qualys_Logo.svg").then(svgImport),
);
const QuillLogo = lazyLoad(() =>
  import("../../assets/app-composition/Quill_Logo.svg").then(svgImport),
);
const RecaptchaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Recaptcha_Logo.svg").then(svgImport),
);
const RequireJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/RequireJS_Logo.svg").then(svgImport),
);
const RubyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Ruby_Logo.svg").then(svgImport),
);
const RubyOnRailsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Ruby_On_Rails_Logo.svg").then(svgImport),
);
const RustLogo = lazyLoad(() =>
  import("../../assets/app-composition/Rust_Logo.svg").then(svgImport),
);
const SapperLogo = lazyLoad(() =>
  import("../../assets/app-composition/Sapper_Logo.svg").then(svgImport),
);
const SectigoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Sectigo_Logo.svg").then(svgImport),
);
const SectionioLogo = lazyLoad(() =>
  import("../../assets/app-composition/Sectionio_Logo.svg").then(svgImport),
);
const SegmentLogo = lazyLoad(() =>
  import("../../assets/app-composition/Segment_Logo.svg").then(svgImport),
);
const ShopifyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Shopify_Logo.svg").then(svgImport),
);
const SonarQubeLogo = lazyLoad(() =>
  import("../../assets/app-composition/SonarQube_Logo.svg").then(svgImport),
);
const StripeLogo = lazyLoad(() =>
  import("../../assets/app-composition/Stripe_Logo.svg").then(svgImport),
);
const SvelteLogo = lazyLoad(() =>
  import("../../assets/app-composition/Svelte_Logo.svg").then(svgImport),
);
const SwiperLogo = lazyLoad(() =>
  import("../../assets/app-composition/Swiper_Logo.svg").then(svgImport),
);
const TrufflehogLogo = lazyLoad(() =>
  import("../../assets/app-composition/TRFL_Logo.svg").then(svgImport),
);
const TaobaoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Taobao_Logo.svg").then(svgImport),
);
const TeamCityLogo = lazyLoad(() =>
  import("../../assets/app-composition/TeamCity_Logo.svg").then(svgImport),
);
const TwitterLogo = lazyLoad(() =>
  import("../../assets/app-composition/Twitter_Logo.svg").then(svgImport),
);
const UbuntuLogo = lazyLoad(() =>
  import("../../assets/app-composition/Ubuntu_Logo.svg").then(svgImport),
);
const VarnishLogo = lazyLoad(() =>
  import("../../assets/app-composition/Varnish_Logo.svg").then(svgImport),
);
const VercelLogo = lazyLoad(() =>
  import("../../assets/app-composition/Vercel_Logo.svg").then(svgImport),
);
const VisaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Visa_Logo.svg").then(svgImport),
);
const WPEngineLogo = lazyLoad(() =>
  import("../../assets/app-composition/WP_Engine_Logo.svg").then(svgImport),
);
const WebflowLogo = lazyLoad(() =>
  import("../../assets/app-composition/Webflow_Logo.svg").then(svgImport),
);
const WebpackLogo = lazyLoad(() =>
  import("../../assets/app-composition/Webpack_Logo.svg").then(svgImport),
);
const WoocommerceLogo = lazyLoad(() =>
  import("../../assets/app-composition/WooCommerce_Woo_Logo.svg").then(
    svgImport,
  ),
);
const YarnLogo = lazyLoad(() =>
  import("../../assets/app-composition/Yarn_Logo.svg").then(svgImport),
);
const YoastLogo = lazyLoad(() =>
  import("../../assets/app-composition/Yoast_Logo.svg").then(svgImport),
);
const YouTubeLogo = lazyLoad(() =>
  import("../../assets/app-composition/YoutTube_Logo.svg").then(svgImport),
);
const ZendeskLogo = lazyLoad(() =>
  import("../../assets/app-composition/Zendesk_Logo.svg").then(svgImport),
);
const ZeptoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Zepto_Logo.svg").then(svgImport),
);
const AddToAnyLogo = lazyLoad(() =>
  import("../../assets/app-composition/addtoany_logo.svg").then(svgImport),
);
const AkamaiLogo = lazyLoad(() =>
  import("../../assets/app-composition/akamai-logo-rgb.svg").then(svgImport),
);
const AlbLogo = lazyLoad(() =>
  import("../../assets/app-composition/alb.svg").then(svgImport),
);
const AngularLogo = lazyLoad(() =>
  import("../../assets/app-composition/angular.svg").then(svgImport),
);
const AntdesignLogo = lazyLoad(() =>
  import("../../assets/app-composition/ant-design_Logo.svg").then(svgImport),
);
const ApacheLogo = lazyLoad(() =>
  import("../../assets/app-composition/apache.svg").then(svgImport),
);
const ApolloLogo = lazyLoad(() =>
  import("../../assets/app-composition/apollographql_Logo.svg").then(svgImport),
);
const AwsServicesLogo = lazyLoad(() =>
  import("../../assets/app-composition/aws.svg").then(svgImport),
);
const AzureLogo = lazyLoad(() =>
  import("../../assets/app-composition/azure.svg").then(svgImport),
);
const BitbucketLogo = lazyLoad(() =>
  import("../../assets/app-composition/bitbucket_logo.svg").then(svgImport),
);
const BootStrapLogo = lazyLoad(() =>
  import("../../assets/app-composition/bootstrap.svg").then(svgImport),
);
const ChiliPiperLogo = lazyLoad(() =>
  import("../../assets/app-composition/chili-piper_Logo.svg").then(svgImport),
);
const CloudflareLogo = lazyLoad(() =>
  import("../../assets/app-composition/cloudflare.svg").then(svgImport),
);
const CloudfrontLogo = lazyLoad(() =>
  import("../../assets/app-composition/cloudfront.svg").then(svgImport),
);
const CodeIgniterLogo = lazyLoad(() =>
  import("../../assets/app-composition/code_igniter_Logo.svg").then(svgImport),
);
const DebianLogo = lazyLoad(() =>
  import("../../assets/app-composition/debian_Logo.svg").then(svgImport),
);
const DotnetLogo = lazyLoad(() =>
  import("../../assets/app-composition/dotnet-icon.svg").then(svgImport),
);
const DrupalLogo = lazyLoad(() =>
  import("../../assets/app-composition/drupal.svg").then(svgImport),
);
const DudaLogo = lazyLoad(() =>
  import("../../assets/app-composition/duda_Logo.svg").then(svgImport),
);
const Ec2Logo = lazyLoad(() =>
  import("../../assets/app-composition/ec2.svg").then(svgImport),
);
const EnvoyIcon = lazyLoad(() =>
  import("../../assets/app-composition/envoy_icon.svg").then(svgImport),
);
const ExpressLogo = lazyLoad(() =>
  import("../../assets/app-composition/express.svg").then(svgImport),
);
const ExpressionEngineLogo = lazyLoad(() =>
  import("../../assets/app-composition/expressionengine_Logo.svg").then(
    svgImport,
  ),
);
const FontAwesomeLogo = lazyLoad(() =>
  import("../../assets/app-composition/font-awesome_Logo.svg").then(svgImport),
);
const GoogleTagManagerLogo = lazyLoad(() =>
  import("../../assets/app-composition/google_tag_manager.svg").then(svgImport),
);
const GravityFormsLogo = lazyLoad(() =>
  import("../../assets/app-composition/gravity-forms.svg").then(svgImport),
);
const GWTLogo = lazyLoad(() =>
  import("../../assets/app-composition/gwtproject_Logo.svg").then(svgImport),
);
const HeapLogo = lazyLoad(() =>
  import("../../assets/app-composition/heapanalytics-icon.svg").then(svgImport),
);
const HubSpotLogo = lazyLoad(() =>
  import("../../assets/app-composition/hubspot.svg").then(svgImport),
);
const ImpervaLogo = lazyLoad(() =>
  import("../../assets/app-composition/imperva_Logo.svg").then(svgImport),
);
const JavascriptLogo = lazyLoad(() =>
  import("../../assets/app-composition/javascript.svg").then(svgImport),
);
const JQueryMobileLogo = lazyLoad(() =>
  import("../../assets/app-composition/jquery-mobile_Logo.svg").then(svgImport),
);
const JQueryLogo = lazyLoad(() =>
  import("../../assets/app-composition/jquery.svg").then(svgImport),
);
const LambdaLogo = lazyLoad(() =>
  import("../../assets/app-composition/lambda-logo.svg").then(svgImport),
);
const LaravelLogo = lazyLoad(() =>
  import("../../assets/app-composition/laravel.svg").then(svgImport),
);
const LeafletLogo = lazyLoad(() =>
  import("../../assets/app-composition/leaflet_Logo.svg").then(svgImport),
);
const LinuxLogo = lazyLoad(() =>
  import("../../assets/app-composition/linux.svg").then(svgImport),
);
const LiteSpeedLogo = lazyLoad(() =>
  import("../../assets/app-composition/litespeed.svg").then(svgImport),
);
const LodashLogo = lazyLoad(() =>
  import("../../assets/app-composition/lodash.svg").then(svgImport),
);
const MarkedJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/markedJs_Logo.svg").then(svgImport),
);
const MaterialDesignLiteLogo = lazyLoad(() =>
  import("../../assets/app-composition/material-design-lite_Logo.svg").then(
    svgImport,
  ),
);
const MaterializeLogo = lazyLoad(() =>
  import("../../assets/app-composition/materialize_Logo.svg").then(svgImport),
);
const MathjaxLogo = lazyLoad(() =>
  import("../../assets/app-composition/mathjax_Logo.svg").then(svgImport),
);
const MauticLogo = lazyLoad(() =>
  import("../../assets/app-composition/mautic_Logo.svg").then(svgImport),
);
const MediaelementLogo = lazyLoad(() =>
  import("../../assets/app-composition/mediaelement-js_Logo.svg").then(
    svgImport,
  ),
);
const WindowsLogo = lazyLoad(() =>
  import("../../assets/app-composition/microsoft-icon.svg").then(svgImport),
);
const MouseflowLogo = lazyLoad(() =>
  import("../../assets/app-composition/mouseflow_Logo.svg").then(svgImport),
);
const NetlifyLogo = lazyLoad(() =>
  import("../../assets/app-composition/netlify.svg").then(svgImport),
);
const NginxLogo = lazyLoad(() =>
  import("../../assets/app-composition/nginx.svg").then(svgImport),
);
const NitropackLogo = lazyLoad(() =>
  import("../../assets/app-composition/nitropack_Logo.svg").then(svgImport),
);
const NPMLogo = lazyLoad(() =>
  import("../../assets/app-composition/npm_Logo.svg").then(svgImport),
);
const OnetrustLogo = lazyLoad(() =>
  import("../../assets/app-composition/onetrust_Logo.svg").then(svgImport),
);
const PDFJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/pdfjs_Logo.svg").then(svgImport),
);
const PerconaLogo = lazyLoad(() =>
  import("../../assets/app-composition/percona_Logo.svg").then(svgImport),
);
const PhpLogo = lazyLoad(() =>
  import("../../assets/app-composition/php.svg").then(svgImport),
);
const PlyrLogo = lazyLoad(() =>
  import("../../assets/app-composition/plyr_Logo.svg").then(svgImport),
);
const PolyfillLogo = lazyLoad(() =>
  import("../../assets/app-composition/polyfill_Logo.svg").then(svgImport),
);
const PopperLogo = lazyLoad(() =>
  import("../../assets/app-composition/popper_Logo.svg").then(svgImport),
);
const QuantcastLogo = lazyLoad(() =>
  import("../../assets/app-composition/quantcast_Logo.svg").then(svgImport),
);
const RdstationLogo = lazyLoad(() =>
  import("../../assets/app-composition/rdstation_Logo.svg").then(svgImport),
);
const ReactLogo = lazyLoad(() =>
  import("../../assets/app-composition/react.svg").then(svgImport),
);
const S3Logo = lazyLoad(() =>
  import("../../assets/app-composition/s3.svg").then(svgImport),
);
const SalesforceLogo = lazyLoad(() =>
  import("../../assets/app-composition/salesforce_Logo.svg").then(svgImport),
);
const ScrollrevealLogo = lazyLoad(() =>
  import("../../assets/app-composition/scrollreveal_Logo.svg").then(svgImport),
);
const SentryLogo = lazyLoad(() =>
  import("../../assets/app-composition/sentry.svg").then(svgImport),
);
const SitefinityLogo = lazyLoad(() =>
  import("../../assets/app-composition/sitefinity_Logo.svg").then(svgImport),
);
const SitegroundLogo = lazyLoad(() =>
  import("../../assets/app-composition/siteground_Logo.svg").then(svgImport),
);
const SnapSVGLogo = lazyLoad(() =>
  import("../../assets/app-composition/snapsvg_Logo.svg").then(svgImport),
);
const SucuriLogo = lazyLoad(() =>
  import("../../assets/app-composition/sucuri_Logo_dark.svg").then(svgImport),
);
const Sweetalert2Logo = lazyLoad(() =>
  import("../../assets/app-composition/sweetalert2_Logo.svg").then(svgImport),
);
const TailwindCSSLogo = lazyLoad(() =>
  import("../../assets/app-composition/tailwindcss_Logo.svg").then(svgImport),
);
const TinymceLogo = lazyLoad(() =>
  import("../../assets/app-composition/tinymce_Logo.svg").then(svgImport),
);
const TrafficserverLogo = lazyLoad(() =>
  import("../../assets/app-composition/trafficserver_Logo.svg").then(svgImport),
);
const TrustarcLogo = lazyLoad(() =>
  import("../../assets/app-composition/trustarc_Logo.svg").then(svgImport),
);
const UnbounceLogo = lazyLoad(() =>
  import("../../assets/app-composition/unbounce_Logo.svg").then(svgImport),
);
const UserwayLogo = lazyLoad(() =>
  import("../../assets/app-composition/userway_Logo.svg").then(svgImport),
);
const VideoJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/videojs_Logo.svg").then(svgImport),
);
const VueLogo = lazyLoad(() =>
  import("../../assets/app-composition/vue.svg").then(svgImport),
);
const VisualWebsiteOptimiserLogo = lazyLoad(() =>
  import("../../assets/app-composition/vwo_Logo.svg").then(svgImport),
);
const WildflyLogo = lazyLoad(() =>
  import("../../assets/app-composition/wildfly_Logo.svg").then(svgImport),
);
const WixLogo = lazyLoad(() =>
  import("../../assets/app-composition/wix_Logo.svg").then(svgImport),
);
const WordPressLogo = lazyLoad(() =>
  import("../../assets/app-composition/wordpress.svg").then(svgImport),
);
const WPRocketLogo = lazyLoad(() =>
  import("../../assets/app-composition/wp-rocket_Logo.svg").then(svgImport),
);
const ZurbFoundationLogo = lazyLoad(() =>
  import("../../assets/app-composition/zurb-foundation_Logo.svg").then(
    svgImport,
  ),
);
const AzureDevOpsLogo = lazyLoad(() =>
  import("../../assets/integration-images/new/azure-devops.svg").then(
    svgImport,
  ),
);
const GithubLogo = lazyLoad(() =>
  import("../../assets/integration-images/new/github_logo.svg").then(svgImport),
);
const GitlabLogo = lazyLoad(() =>
  import("../../assets/integration-images/new/gitlab-logo.svg").then(svgImport),
);
const SecureStackLogo = lazyLoad(() =>
  import("../../assets/small-logo.svg").then(svgImport),
);
const ImmutableJsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Immutable-js_Logo.svg").then(svgImport),
);
const DynatraceLogo = lazyLoad(() =>
  import("../../assets/app-composition/Dynatrace_Logo.svg").then(svgImport),
);
const CrazyEggLogo = lazyLoad(() =>
  import("../../assets/app-composition/Crazy-egg_Logo.svg").then(svgImport),
);
const EmarsysLogo = lazyLoad(() =>
  import("../../assets/app-composition/SAP_Emarsys_Logo.svg").then(svgImport),
);
const AppnexusLogo = lazyLoad(() =>
  import("../../assets/app-composition/Appnexus_Logo.svg").then(svgImport),
);
const AlgoliaLogo = lazyLoad(() =>
  import("../../assets/app-composition/algolia_Logo.svg").then(svgImport),
);
const ChartbeatLogo = lazyLoad(() =>
  import("../../assets/app-composition/chartbeat_Logo.svg").then(svgImport),
);
const CrispLogo = lazyLoad(() =>
  import("../../assets/app-composition/crisp_Logo.svg").then(svgImport),
);
const D3Logo = lazyLoad(() =>
  import("../../assets/app-composition/d3_Logo.svg").then(svgImport),
);
const DocusaurusLogo = lazyLoad(() =>
  import("../../assets/app-composition/docusaurus_Logo.svg").then(svgImport),
);
const GoDaddyLogo = lazyLoad(() =>
  import("../../assets/app-composition/godaddylogo.svg").then(svgImport),
);
const LagooLogo = lazyLoad(() =>
  import("../../assets/app-composition/Lagoon_Logo.svg").then(svgImport),
);
const LitespeedLogo = lazyLoad(() =>
  import("../../assets/app-composition/LiteSpeed_Logo.svg").then(svgImport),
);
const LivechatLogo = lazyLoad(() =>
  import("../../assets/app-composition/livechat_Logo.svg").then(svgImport),
);
const MediumLogo = lazyLoad(() =>
  import("../../assets/app-composition/Medium_Logo.svg").then(svgImport),
);
const NetoLogo = lazyLoad(() =>
  import("../../assets/app-composition/neto_Logo.svg").then(svgImport),
);
const PhusionLogo = lazyLoad(() =>
  import("../../assets/app-composition/phusionpassenger_Logo.svg").then(
    svgImport,
  ),
);
const PlatformshLogo = lazyLoad(() =>
  import("../../assets/app-composition/platformsh_Logo.svg").then(svgImport),
);
const RewardfulLogo = lazyLoad(() =>
  import("../../assets/app-composition/rewardful_Logo.svg").then(svgImport),
);
const ShopPayLogo = lazyLoad(() =>
  import("../../assets/app-composition/ShopPay_Logo.svg").then(svgImport),
);
const StimulusLogo = lazyLoad(() =>
  import("../../assets/app-composition/stimulus_Logo.svg").then(svgImport),
);
const ThreeJsLogo = lazyLoad(() =>
  import("../../assets/app-composition/threejs_Logo.svg").then(svgImport),
);
const TrackJsLogo = lazyLoad(() =>
  import("../../assets/app-composition/trackjs_Logo.svg").then(svgImport),
);
const ViglinkLogo = lazyLoad(() =>
  import("../../assets/app-composition/viglink-sovrn_Logo.svg").then(svgImport),
);
const WeeblyLogo = lazyLoad(() =>
  import("../../assets/app-composition/weebly_Logo.svg").then(svgImport),
);
const WeglotLogo = lazyLoad(() =>
  import("../../assets/app-composition/weglot_Logo.svg").then(svgImport),
);
const ZipPayLogo = lazyLoad(() =>
  import("../../assets/app-composition/zippay_Logo.svg").then(svgImport),
);
const PrestashopLogo = lazyLoad(() =>
  import("../../assets/app-composition/prestashop_Logo.svg").then(svgImport),
);
const BigcommerceLogo = lazyLoad(() =>
  import("../../assets/app-composition/BigCommerce_Logo.svg").then(svgImport),
);
const GravatarLogo = lazyLoad(() =>
  import("../../assets/app-composition/gravatar_Logo.svg").then(svgImport),
);
const HCaptchaLogo = lazyLoad(() =>
  import("../../assets/app-composition/hcaptcha_Logo.svg").then(svgImport),
);
const IbmLogo = lazyLoad(() =>
  import("../../assets/app-composition/ibm_Logo.svg").then(svgImport),
);
const SilverstripeLogo = lazyLoad(() =>
  import("../../assets/app-composition/silverstripe_Logo.svg").then(svgImport),
);
const RiskifiedLogo = lazyLoad(() =>
  import("../../assets/app-composition/riskified_Logo.svg").then(svgImport),
);
const TypescriptLogo = lazyLoad(() =>
  import("../../assets/app-composition/typescript_Logo.svg").then(svgImport),
);
const CalendlyLogo = lazyLoad(() =>
  import("../../assets/app-composition/Calendly_Logo.svg").then(svgImport),
);
const AffirmLogo = lazyLoad(() =>
  import("../../assets/app-composition/Affirm_Logo.svg").then(svgImport),
);
const AppleLogo = lazyLoad(() =>
  import("../../assets/app-composition/Apple_logo_black_Logo.svg").then(
    svgImport,
  ),
);
const ChargebeeLogo = lazyLoad(() =>
  import("../../assets/app-composition/Chargebee_Logo.svg").then(svgImport),
);
const CookieInformationLogo = lazyLoad(() =>
  import("../../assets/app-composition/Cookie-Information_Logo.svg").then(
    svgImport,
  ),
);
const DatadogLogo = lazyLoad(() =>
  import("../../assets/app-composition/Datadog_Logo.svg").then(svgImport),
);
const UPSLogo = lazyLoad(() =>
  import("../../assets/app-composition/UPS_Logo.svg").then(svgImport),
);
const GTranslateLogo = lazyLoad(() =>
  import("../../assets/app-composition/GTranslate_Logo.svg").then(svgImport),
);
const InsiderLogo = lazyLoad(() =>
  import("../../assets/app-composition/Insider_Logo.svg").then(svgImport),
);
const LottiefilesLogo = lazyLoad(() =>
  import("../../assets/app-composition/LottieFiles_Logo.svg").then(svgImport),
);
const NortonLogo = lazyLoad(() =>
  import(
    "../../assets/app-composition/Norton-Shopping-Guarantee_Logo.svg"
  ).then(svgImport),
);
const PinterestAdsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Pinterest_Logo.svg").then(svgImport),
);
const RakutenLogo = lazyLoad(() =>
  import("../../assets/app-composition/Rakuten-Advertising_Logo.svg").then(
    svgImport,
  ),
);
const RankMathLogo = lazyLoad(() =>
  import("../../assets/app-composition/RankMath-SEO_Logo.svg").then(svgImport),
);
const SeomaticLogo = lazyLoad(() =>
  import("../../assets/app-composition/SEOmatic_Logo.svg").then(svgImport),
);
const WpNaviLogo = lazyLoad(() =>
  import("../../assets/app-composition/WP-PageNavi_Logo.svg").then(svgImport),
);
const WpFormsLogo = lazyLoad(() =>
  import("../../assets/app-composition/WPForms_Logo.svg").then(svgImport),
);
const WpmlLogo = lazyLoad(() =>
  import("../../assets/app-composition/WPML_Logo.svg").then(svgImport),
);
const SmashBalloonLogo = lazyLoad(() =>
  import("../../assets/app-composition/Smash-Balloon_Logo.svg").then(svgImport),
);
const TikTokLogo = lazyLoad(() =>
  import("../../assets/app-composition/TikTok_Logo.svg").then(svgImport),
);
const KlaviyoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Klaviyo_Logo.svg").then(svgImport),
);
const ReadspeakerLogo = lazyLoad(() =>
  import("../../assets/app-composition/ReadSpeaker_Logo.svg").then(svgImport),
);
const RoktLogo = lazyLoad(() =>
  import("../../assets/app-composition/Rokt_Logo.svg").then(svgImport),
);
const VenmoLogo = lazyLoad(() =>
  import("../../assets/app-composition/Venmo_Logo.svg").then(svgImport),
);
const UsercentricsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Usercentrics_Logo.svg").then(svgImport),
);
const SnowplowLogo = lazyLoad(() =>
  import("../../assets/app-composition/Snowplow_Logo.svg").then(svgImport),
);
const IubendaLogo = lazyLoad(() =>
  import("../../assets/app-composition/iubenda_Logo.svg").then(svgImport),
);
const SkaiLogo = lazyLoad(() =>
  import("../../assets/app-composition/Skai_Logo.svg").then(svgImport),
);
const VimeoLogo = lazyLoad(() =>
  import("../../assets/app-composition/vimeo_Logo.svg").then(svgImport),
);
const LinkedInLogo = lazyLoad(() =>
  import("../../assets/app-composition/LinkedIn_Logo.svg").then(svgImport),
);
const AuspostLogo = lazyLoad(() =>
  import("../../assets/app-composition/auspost_Logo.svg").then(svgImport),
);
const BlitzLogo = lazyLoad(() =>
  import("../../assets/app-composition/Blitz_Logo.svg").then(svgImport),
);
const CraftCMSLogo = lazyLoad(() =>
  import("../../assets/app-composition/craft-cms-logo_Logo.svg").then(
    svgImport,
  ),
);
const HTTP2Logo = lazyLoad(() =>
  import("../../assets/app-composition/HTTP2_Logo.svg").then(svgImport),
);
const HTTP3Logo = lazyLoad(() =>
  import("../../assets/app-composition/HTTP3_Logo.svg").then(svgImport),
);
const ViteLogo = lazyLoad(() =>
  import("../../assets/app-composition/vite_Logo.svg").then(svgImport),
);
const RSSLogo = lazyLoad(() =>
  import("../../assets/app-composition/RSS_Logo.svg").then(svgImport),
);
const UikitLogo = lazyLoad(() =>
  import("../../assets/app-composition/uikit_Logo.svg").then(svgImport),
);
const BoomerangLogo = lazyLoad(() =>
  import("../../assets/app-composition/boomerang_Logo.svg").then(svgImport),
);
const GlideJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Glide.js_Logo.svg").then(svgImport),
);
const SocketIOLogo = lazyLoad(() =>
  import("../../assets/app-composition/Socket-io_Logo.svg").then(svgImport),
);
const WebVitalsLogo = lazyLoad(() =>
  import("../../assets/app-composition/web-vitals_Logo.svg").then(svgImport),
);
const WisepopsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Wisepops_Logo.svg").then(svgImport),
);
const PwaLogo = lazyLoad(() =>
  import("../../assets/app-composition/PWA_Logo.svg").then(svgImport),
);
const PriorityHintsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Priority-Hints_Logo.svg").then(
    svgImport,
  ),
);
const CdnJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/cdnjs_Logo.svg").then(svgImport),
);
const ClipboardJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Clipboard-js_Logo.svg").then(svgImport),
);
const EssentialElementor = lazyLoad(() =>
  import(
    "../../assets/app-composition/Essential-Addons-for-Elementor_Logo.svg"
  ).then(svgImport),
);
const UltimateElementor = lazyLoad(() =>
  import(
    "../../assets/app-composition/Ultimate-Addons-Elementor_Logo.svg"
  ).then(svgImport),
);
const SliderRevolutionLogo = lazyLoad(() =>
  import("../../assets/app-composition/Slider-Revolution_Logo.svg").then(
    svgImport,
  ),
);
const DiviLogo = lazyLoad(() =>
  import("../../assets/app-composition/elegant-themes-divi_Logo.svg").then(
    svgImport,
  ),
);
const WpbakeryLogo = lazyLoad(() =>
  import("../../assets/app-composition/wpbakery_Logo.svg").then(svgImport),
);
const AWSCodebuildLogo = lazyLoad(() =>
  import("../../assets/app-composition/AWS-CodeBuild_Logo.svg").then(svgImport),
);
const CloudConformityLogo = lazyLoad(() =>
  import("../../assets/app-composition/Cloud-Conformity_Logo.png").then(
    pngImport,
  ),
);
const DockerLogo = lazyLoad(() =>
  import("../../assets/app-composition/Docker-Moby_Logo.png").then(pngImport),
);
const KasadaLogo = lazyLoad(() =>
  import("../../assets/app-composition/Kasada_Logo.png").then(pngImport),
);
const KnockoutLogo = lazyLoad(() =>
  import("../../assets/app-composition/Knockout_Logo.png").then(pngImport),
);
const OpenRestyLogo = lazyLoad(() =>
  import("../../assets/app-composition/OpenResty_Logo.png").then(pngImport),
);
const OwlCarouselLogo = lazyLoad(() =>
  import("../../assets/app-composition/Owl-Carousel_Logo.png").then(pngImport),
);
const PeakhourLogo = lazyLoad(() =>
  import("../../assets/app-composition/Peakhour_Logo.png").then(pngImport),
);
const Select2Logo = lazyLoad(() =>
  import("../../assets/app-composition/Select2_Logo.png").then(pngImport),
);
const SnykLogo = lazyLoad(() =>
  import("../../assets/app-composition/Snyk_Logo.png").then(pngImport),
);
const UnderscoreJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/Underscore-js_Logo.png").then(pngImport),
);
const AdobeExperienceLogo = lazyLoad(() =>
  import("../../assets/app-composition/adobe-experience-manager-logo.png").then(
    pngImport,
  ),
);
const AdRollLogo = lazyLoad(() =>
  import("../../assets/app-composition/adroll-logo.png").then(pngImport),
);
const AmazonLinuxLogo = lazyLoad(() =>
  import("../../assets/app-composition/amazon-linux-logo.png").then(pngImport),
);
const AnimatecssLogo = lazyLoad(() =>
  import("../../assets/app-composition/animatecss_Logo.png").then(pngImport),
);
const BackboneLogo = lazyLoad(() =>
  import("../../assets/app-composition/backbone_Logo.png").then(pngImport),
);
const ClickyLogo = lazyLoad(() =>
  import("../../assets/app-composition/clicky_Logo.png").then(pngImport),
);
const CowboyLogo = lazyLoad(() =>
  import("../../assets/app-composition/cowboy-logo.png").then(pngImport),
);
const DatatableLogo = lazyLoad(() =>
  import("../../assets/app-composition/datatable_Logo.png").then(pngImport),
);
const FirebladeLogo = lazyLoad(() =>
  import("../../assets/app-composition/fireblade_Logo.png").then(pngImport),
);
const FlickityLogo = lazyLoad(() =>
  import("../../assets/app-composition/flickity-logo.png").then(pngImport),
);
const FroalaLogo = lazyLoad(() =>
  import("../../assets/app-composition/froala_Logo.png").then(pngImport),
);
const LivepersonLogo = lazyLoad(() =>
  import("../../assets/app-composition/liveperson_Logo.png").then(pngImport),
);
const IISLogo = lazyLoad(() =>
  import("../../assets/app-composition/microsoft-iis-logo.png").then(pngImport),
);
const MootoolsLogo = lazyLoad(() =>
  import("../../assets/app-composition/mootools_Logo.png").then(pngImport),
);
const PhaserLogo = lazyLoad(() =>
  import("../../assets/app-composition/phaser_Logo.png").then(pngImport),
);
const PrettyphotoLogo = lazyLoad(() =>
  import("../../assets/app-composition/prettyPhoto_Logo.jpg").then(pngImport),
);
const SemanticUILogo = lazyLoad(() =>
  import("../../assets/app-composition/semantic-ui_Logo.png").then(pngImport),
);
const SlickJSLogo = lazyLoad(() =>
  import("../../assets/app-composition/slick-js-logo.png").then(pngImport),
);
const Sweetalert1Logo = lazyLoad(() =>
  import("../../assets/app-composition/sweetalert1_Logo_big.png").then(
    pngImport,
  ),
);
const TawkLogo = lazyLoad(() =>
  import("../../assets/app-composition/tawk_Logo.png").then(pngImport),
);
const TealiumLogo = lazyLoad(() =>
  import("../../assets/app-composition/tealium_Logo.png").then(pngImport),
);
const W3CacheLogo = lazyLoad(() =>
  import("../../assets/app-composition/w3-total-cache_Logo.png").then(
    pngImport,
  ),
);
const WebtrendsLogo = lazyLoad(() =>
  import("../../assets/app-composition/webtrends_Logo.png").then(pngImport),
);
const WhmcsLogo = lazyLoad(() =>
  import("../../assets/app-composition/whmcs_Logo.png").then(pngImport),
);
const Yui3Logo = lazyLoad(() =>
  import("../../assets/app-composition/yui3_Logo.png").then(pngImport),
);
const HighlightJsLogo = lazyLoad(() =>
  import("../../assets/app-composition/Highlightjs_Logo.png").then(pngImport),
);
const BossLogo = lazyLoad(() =>
  import("../../assets/app-composition/Guvnor-boss_Logo.png").then(pngImport),
);
const GhostLogo = lazyLoad(() =>
  import("../../assets/app-composition/ghost_Logo.png").then(pngImport),
);
const MobxJsLogo = lazyLoad(() =>
  import("../../assets/app-composition/mobx_Logo.png").then(pngImport),
);
const AMPLogo = lazyLoad(() =>
  import("../../assets/app-composition/AMP_Logo.png").then(pngImport),
);

type TechstackItem = {
  displayIcon: JSX.Element;
  source?: ScanType;
  displayName: string;
  alias: string[];
  search?: string;
};
type Techstack = { [technologyName: string]: TechstackItem };

// WANT: Runtime
// servers, OS, web engine, db
const runtimeTechnologies: Techstack = {
  openssl: {
    displayIcon: (
      <OpenSSLLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "OpenSSL",
    source: ScanType.Exposure,
    alias: [],
  },
  nginx: {
    displayIcon: (
      <NginxLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Nginx",
    alias: [],
  },
  "apache-tomcat": {
    displayIcon: (
      <TomcatLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Apache Tomcat",
    alias: [],
  },
  linux: {
    displayIcon: (
      <LinuxLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Linux",
    alias: [],
  },
  unix: {
    displayIcon: (
      <LinuxLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Unix",
    alias: [],
  },
  amazonlinux: {
    displayIcon: <AmazonLinuxLogo />,
    displayName: "Amazon Linux",
    alias: ["amazon-linux"],
    search: "linux",
  },
  apache: {
    displayIcon: (
      <ApacheLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Apache",
    alias: [],
  },
  "microsoft-asp-net": {
    displayIcon: (
      <DotnetLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "ASP.NET",
    alias: [],
  },
  "windows-server": {
    displayIcon: (
      <WindowsLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Windows Server",
    alias: [],
  },
  iis: {
    displayIcon: <IISLogo />,
    source: ScanType.Exposure,
    displayName: "IIS",
    alias: [],
  },
  openresty: {
    displayIcon: <OpenRestyLogo />,
    source: ScanType.Exposure,
    displayName: "OpenResty",
    alias: [],
  },
  mysql: {
    displayIcon: (
      <MySQLLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "MySQL",
    source: ScanType.Exposure,
    alias: [],
  },
  mariadb: {
    displayIcon: (
      <MariaDBLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "MariaDB",
    source: ScanType.Exposure,
    alias: [],
  },
  ubuntu: {
    displayIcon: (
      <UbuntuLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Ubuntu",
    source: ScanType.Exposure,
    alias: [],
  },
  django: {
    displayIcon: (
      <DjangoLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    displayName: "Django",
    source: ScanType.Exposure,
    alias: [],
  },
  varnish: {
    displayIcon: (
      <VarnishLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Varnish",
    source: ScanType.Exposure,
    alias: [],
  },
  wordpress: {
    displayIcon: (
      <WordPressLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "WordPress",
    alias: [],
  },
  magento: {
    displayIcon: (
      <MagentoLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Magento",
    source: ScanType.Exposure,
    alias: [],
  },
  drupal: {
    displayIcon: (
      <DrupalLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Drupal",
    alias: [],
  },
  "node-js": {
    displayIcon: (
      <NodejsLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    source: ScanType.Code | ScanType.Exposure,
    displayName: "Node.js",
    alias: ["nodejs"],
  },
  jetty: {
    displayIcon: (
      <JettyLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Eclipse Jetty",
    source: ScanType.Exposure,
    alias: ["eclipse-jetty"],
  },
  hugo: {
    displayIcon: (
      <HugoLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Hugo",
    source: ScanType.Exposure,
    alias: [],
  },
  "google-web-server": {
    displayName: "Google Web Server",
    alias: [],
    displayIcon: <GoogleCloudLogo className="app-composition-logo" />,
  },
  getflywheel: {
    displayIcon: (
      <FlywheelLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Flywheel",
    source: ScanType.Cloud,
    alias: ["flywheel"],
  },
  litespeed: {
    displayIcon: (
      <LiteSpeedLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Exposure,
    displayName: "LiteSpeed",
    alias: [],
  },
  shopify: {
    displayIcon: (
      <ShopifyLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Shopify",
    source: ScanType.Cloud,
    alias: [],
  },
  contentful: {
    displayIcon: (
      <ContentfulLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Contentful",
    source: ScanType.Exposure,
    alias: [],
  },
  kinsta: {
    displayIcon: (
      <KinstaLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Kinsta",
    source: ScanType.Cloud,
    alias: [],
  },
  kubernetes: {
    displayIcon: (
      <KubernetesLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Kubernetes",
    source: ScanType.Cloud,
    alias: [],
  },
  docker: {
    displayIcon: <DockerLogo />,
    displayName: "Docker",
    source: ScanType.Cloud,
    alias: [],
  },
  windows: {
    displayIcon: <WindowsLogo className="app-composition-logo" />,
    source: ScanType.Exposure,
    displayName: "Windows",
    alias: [],
  },
  tengine: {
    alias: [],
    displayName: "Tengine",
    displayIcon: <TaobaoLogo className="app-composition-logo" />,
  },
  postgresql: {
    alias: [],
    displayName: "Postgres",
    displayIcon: <PostgresSqlLogo className="app-composition-logo" />,
  },
  debian: {
    alias: [],
    displayIcon: <DebianLogo className="app-composition-logo" />,
    displayName: "Debian",
  },
  "microsoft-httpapi": {
    alias: [],
    displayIcon: <WindowsLogo className="app-composition-logo" />,
    displayName: "MS HTTPAPI",
  },
  "apache-traffic-server": {
    alias: [],
    displayIcon: <TrafficserverLogo className="app-composition-logo" />,
    displayName: "Traffic Server",
  },
  kestrel: {
    alias: [],
    displayIcon: <DotNETCoreLogo className="app-composition-logo" />,
    displayName: "Kestrel",
  },
  "java-servlet": {
    alias: [],
    displayIcon: <JavaLogo className="app-composition-logo" />,
    displayName: "Java Servlet",
  },
  "jboss-application-server": {
    alias: [],
    displayIcon: <WildflyLogo className="app-composition-logo" />,
    displayName: "Wildfly App Server",
  },
  "jboss-web": {
    alias: [],
    displayIcon: <WildflyLogo className="app-composition-logo" />,
    displayName: "Wildfly",
  },
  percona: {
    alias: [],
    displayIcon: <PerconaLogo className="app-composition-logo" />,
    displayName: "Percona",
  },
  squarespace: {
    alias: [],
    displayIcon: <FaSquarespace className="app-composition-logo" />,
    displayName: "Squarespace",
  },
  joomla: {
    alias: [],
    displayIcon: <JoomlaLogo className="app-composition-logo" />,
    displayName: "Joomla!",
  },
  expressionengine: {
    alias: [],
    displayIcon: <ExpressionEngineLogo className="app-composition-logo" />,
    displayName: "ExpressionEngine",
  },
  nitropack: {
    alias: [],
    displayIcon: <NitropackLogo className="app-composition-logo" />,
    displayName: "NitroPack",
  },
  criteo: {
    alias: [],
    displayIcon: <CriteoLogo className="app-composition-logo" />,
    displayName: "Criteo",
  },
  duda: {
    alias: [],
    displayIcon: <DudaLogo className="app-composition-logo" />,
    displayName: "Duda",
  },
  wix: {
    alias: [],
    displayIcon: <WixLogo className="app-composition-logo" />,
    displayName: "Wix",
  },
  sitefinity: {
    alias: [],
    displayIcon: <SitefinityLogo className="app-composition-logo" />,
    displayName: "Sitefinity",
  },
  siteground: {
    alias: [],
    displayIcon: <SitegroundLogo className="app-composition-logo" />,
    displayName: "Siteground",
  },
  pantheon: {
    displayIcon: <PantheonLogo className="app-composition-logo" />,
    displayName: "Pantheon",
    alias: [],
  },
  "boss-web": { displayIcon: <BossLogo />, alias: [], displayName: "Boss Web" },
  "phusion-passenger": {
    alias: [],
    displayName: "Phusion Passenger",
    displayIcon: <PhusionLogo className="app-composition-logo" />,
  },
  "litespeed-cache": {
    alias: [],
    displayName: "LiteSpeed Cache",
    displayIcon: <LiteSpeedLogo className="app-composition-logo" />,
  },
  lagoon: {
    alias: [],
    displayName: "Lagoon",
    displayIcon: <LagooLogo className="app-composition-logo" />,
  },
  "platform-sh": {
    alias: [],
    displayName: "Platform.sh",
    displayIcon: <PlatformshLogo className="app-composition-logo" />,
  },
  bigcommerce: {
    alias: [],
    displayName: "BigCommerce",
    displayIcon: <BigcommerceLogo className="app-composition-logo" />,
  },
  prestashop: {
    alias: [],
    displayName: "Prestashop",
    displayIcon: <PrestashopLogo className="app-composition-logo" />,
  },
  silverstripe: {
    alias: [],
    displayName: "Silverstripe",
    displayIcon: <SilverstripeLogo className="app-composition-logo" />,
  },
  blitz: {
    alias: [],
    displayName: "Blitz",
    displayIcon: <BlitzLogo className="app-composition-logo" />,
  },
  "craft-cms": {
    alias: [],
    displayName: "CraftCMSLogo",
    displayIcon: <CraftCMSLogo className="app-composition-logo" />,
  },
  "http-2": {
    alias: [],
    displayName: "HTTP2",
    displayIcon: <HTTP2Logo className="app-composition-logo" />,
  },
  "http-3": {
    alias: [],
    displayName: "HTTP3",
    displayIcon: <HTTP3Logo className="app-composition-logo" />,
  },
  vite: {
    alias: [],
    displayName: "Vite",
    displayIcon: <ViteLogo className="app-composition-logo" />,
  },
  vitepress: {
    alias: [],
    displayName: "Vitepress",
    displayIcon: <ViteLogo className="app-composition-logo" />,
  },
  rss: {
    alias: [],
    displayName: "RSS",
    displayIcon: <RSSLogo className="app-composition-logo" />,
  },
  "apache-http-server": {
    alias: [],
    displayName: "Apache HTTP Server",
    displayIcon: <ApacheLogo className="app-composition-logo" />,
  },
};

// WANT: Integrations
// git repos, scm providers, PRs
const integrationTechnologies: Techstack = {
  "atlassian-bitbucket": {
    displayIcon: (
      <BitbucketLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Bitbucket",
    alias: ["bitbucket"],
  },
  github: {
    displayIcon: (
      <GithubLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Github",
    alias: [],
  },
  gitlab: {
    displayIcon: <GitlabLogo className="app-composition-logo" />,
    displayName: "GitLab",
    alias: [],
  },
};

// WANT: deployments
// build, testing and deployment solutions
const deploymentTechnologies: Techstack = {
  "octopus-deploy": {
    displayIcon: (
      <OctopusDeployLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Octopus Deploy",
    source: ScanType.Cloud,
    alias: ["octopus"],
  },
  teamcity: {
    displayIcon: (
      <TeamCityLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "TeamCity",
    alias: [],
  },
  "azure-devops": {
    displayName: "Azure DevOps",
    displayIcon: <AzureDevOpsLogo className="app-composition-logo" />,
    alias: [],
  },
  jenkins: {
    displayIcon: (
      <JenkinsLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Jenkins",
    alias: [],
  },
  "bitbucket-cd": {
    displayIcon: (
      <BitbucketLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Bitbucket Pipelines",
    alias: [],
  },
  "github-cd": {
    displayIcon: (
      <GithubLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Github Actions",
    alias: [],
  },
  "gitlab-cd": {
    displayIcon: <GitlabLogo className="app-composition-logo" />,
    displayName: "GitLab Pipelines",
    alias: [],
  },
  securestack: {
    displayIcon: <SecureStackLogo className="app-composition-logo" />,
    displayName: "SecureStack",
    alias: [],
  },
  "amazon-codebuild": {
    displayIcon: <AWSCodebuildLogo className="app-composition-logo" />,
    displayName: "AWS CodeBuild",
    alias: [],
    search: "codebuild",
  },
};

// WANT: SERVICES
// saas, 3rd party apis, payment gateways, identity and analytics providers
const servicesTechnologies: Techstack = {
  hubspot: {
    displayIcon: (
      <HubSpotLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "HubSpot",
    alias: [],
  },
  "hubspot-chat": {
    displayIcon: (
      <HubSpotLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "HubSpot Chat",
    alias: [],
  },
  "wix-ecommerce": {
    alias: [],
    displayIcon: <WixLogo className="app-composition-logo" />,
    displayName: "Wix eCommerce",
  },
  marketo: {
    displayIcon: (
      <MarketoLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Marketo",
    source: ScanType.Exposure,
    alias: [],
  },
  osano: {
    displayIcon: (
      <OsanoLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Osano",
    source: ScanType.Exposure,
    alias: [],
  },
  "cart-functionality": {
    displayIcon: (
      <BsFillCartFill className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "eCommerce",
    source: ScanType.Exposure,
    alias: [],
  },
  optimizely: {
    displayIcon: (
      <OptimizelyLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Optimizely",
    source: ScanType.Exposure,
    alias: [],
  },
  drift: {
    displayIcon: (
      <DriftLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Drift",
    source: ScanType.Exposure,
    alias: [],
  },
  jsdelivr: {
    displayIcon: (
      <JsDelivrLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "jsDelivr",
    source: ScanType.Exposure,
    alias: [],
  },
  amplitude: {
    displayIcon: (
      <AmplitudeLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Amplitude",
    source: ScanType.Exposure,
    alias: [],
  },
  afterpay: {
    displayIcon: (
      <AfterpayLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "AfterPay",
    source: ScanType.Exposure,
    alias: [],
  },
  stripe: {
    displayIcon: (
      <StripeLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Stripe",
    source: ScanType.Exposure,
    alias: [],
  },
  paypal: {
    displayIcon: (
      <PayPalLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "PayPal",
    source: ScanType.Exposure,
    alias: [],
  },
  facebook: {
    displayIcon: (
      <FacebookLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Facebook Pixel",
    source: ScanType.Exposure,
    alias: [],
  },
  youtube: {
    displayIcon: (
      <YouTubeLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "YouTube",
    source: ScanType.Cloud,
    alias: ["yt"],
  },
  twitter: {
    displayIcon: (
      <TwitterLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Twitter",
    source: ScanType.Cloud,
    alias: ["tw"],
  },
  "twitter-emoji-twemoji": {
    displayIcon: (
      <TwitterLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Twemoji",
    source: ScanType.Cloud,
    alias: [],
  },
  snyk: {
    displayIcon: <SnykLogo />,
    displayName: "Snyk",
    source: ScanType.Cloud,
    alias: [],
  },
  "github-pages": {
    displayIcon: (
      <GithubLogo className="app-composition-logo should-highlight-code" />
    ),
    displayName: "GitHub Pages",
    source: ScanType.Code,
    alias: [],
  },
  aqua: {
    displayIcon: (
      <AquaLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "AquaSec",
    source: ScanType.Exposure,
    alias: ["aquasec", "aqua-sec"],
  },
  mailchimp: {
    displayIcon: (
      <MailchimpLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Mailchimp",
    source: ScanType.Exposure,
    alias: [],
  },
  webflow: {
    displayIcon: (
      <WebflowLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Webflow",
    source: ScanType.Exposure,
    alias: [],
  },
  axonius: {
    displayIcon: (
      <AxoniusLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Axonius",
    source: ScanType.Exposure,
    alias: [],
  },
  sentry: {
    displayIcon: (
      <SentryLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Sentry",
    alias: [],
  },
  sonarqubes: {
    displayIcon: (
      <SonarQubeLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "SonarQube",
    alias: ["sonar", "qube", "sonar-qube"],
  },
  "microsoft-365": {
    displayIcon: (
      <MS365Logo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Microsoft 365",
    source: ScanType.Cloud,
    alias: ["ms365", "ms-365", "microsoft365"],
  },
  "wp-engine": {
    displayIcon: (
      <WPEngineLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "WP Engine",
    source: ScanType.Exposure,
    alias: [],
  },
  "yoast-seo": {
    displayIcon: (
      <YoastLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Yoast",
    source: ScanType.Exposure,
    alias: ["yoast-seo-premium"],
  },
  "google-tag-manager-for-wordpress": {
    displayName: "GTM for WP",
    alias: [],
    displayIcon: <GoogleTagManagerLogo className="app-composition-logo" />,
  },
  "google-pay": {
    displayName: "Google Pay",
    alias: [],
    displayIcon: <GooglePayLogo className="app-composition-logo" />,
  },
  "google-ads-conversion-tracking": {
    displayName: "Google Ads Conversion Tracking",
    alias: [],
    displayIcon: <GoogleAdsLogo className="app-composition-logo" />,
  },
  "doubleclick-for-publishers-dfp": {
    displayName: "Doubleclick DFP",
    alias: [],
    displayIcon: <GoogleAdsLogo className="app-composition-logo" />,
  },
  "google-analytics-enhanced-ecommerce": {
    displayName: "Google Analytics Enhanced eCommerce",
    alias: [],
    displayIcon: <GoogleAnalyticsLogo className="app-composition-logo" />,
  },
  "google-call-conversion-tracking": {
    displayName: "Google Call Conversion Tracking",
    alias: [],
    displayIcon: <GoogleAnalyticsLogo className="app-composition-logo" />,
  },
  "amazon-pay": {
    displayName: "Amazon Pay",
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
  },
  "google-wallet": {
    displayName: "Google Wallet",
    alias: [],
    displayIcon: <GoogleWalletLogo className="app-composition-logo" />,
  },
  "google-charts": {
    displayName: "Google Charts",
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
  },
  "google-adsense": {
    displayName: "Google Adsense",
    alias: [],
    displayIcon: <GoogleAdsenseLogo className="app-composition-logo" />,
  },
  "google-ads": {
    displayName: "Google Ads",
    alias: [],
    displayIcon: <GoogleAdsLogo className="app-composition-logo" />,
  },
  "google-forms": {
    displayName: "Google Forms",
    alias: [],
    displayIcon: <GoogleFormsLogo className="app-composition-logo" />,
  },
  "google-sites": {
    displayName: "Google Sites",
    alias: [],
    displayIcon: <GoogleSitesLogo className="app-composition-logo" />,
  },
  "amazon-advertising": {
    displayName: "Amazon Advertising",
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
  },
  "amazon-associates": {
    displayName: "Amazon Associates",
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
  },
  "amazon-ses": {
    displayName: "Amazon Simple Email Service",
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
  },
  "amazon-webstore": {
    displayName: "Amazon Webstore",
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
  },
  "google-code-prettify,": {
    displayName: "Google Code Prettify",
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
  },
  "google-customer-reviews": {
    displayName: "Google Customer Reviews",
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
  },
  "google-hosted-libraries": {
    displayName: "Google Hosted Libraries",
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
  },
  "google-my-business,": {
    displayName: "Google My Business",
    alias: [],
    displayIcon: <GoogleMyBusinessLogo className="app-composition-logo" />,
  },
  "google-optimize": {
    displayName: "Google Optimize",
    alias: [],
    displayIcon: <GoogleOptimizeLogo className="app-composition-logo" />,
  },
  "google-pagespeed": {
    displayName: "Google PageSpeed",
    alias: [],
    displayIcon: <GooglePageSpeedLogo className="app-composition-logo" />,
  },
  "google-publisher-tag": {
    displayName: "Google Publisher Tag",
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
  },
  "amazon-cognito": {
    displayName: "Amazon Cognito",
    alias: [],
    displayIcon: <AWSCognitoLogo className="app-composition-logo" />,
    search: "cognito",
  },
  "google-workspace": {
    displayIcon: (
      <GoogleLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google Workspace",
    source: ScanType.Cloud,
    alias: [],
  },
  "doubleclick-floodlight": {
    displayIcon: <GoogleLogo className="app-composition-logo" />,
    displayName: "Doubleclick Floodlight",
    source: ScanType.Cloud,
    alias: [],
  },
  "google-analytics": {
    displayIcon: (
      <GoogleAnalyticsLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google Analytics",
    source: ScanType.Cloud,
    alias: [],
  },
  "google-tag-manager": {
    displayIcon: (
      <GoogleTagManagerLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google Tag Manager",
    source: ScanType.Cloud,
    alias: [],
  },
  "google-font-api": {
    displayIcon: (
      <GoogleFontsLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google Fonts",
    source: ScanType.Cloud,
    alias: [],
  },
  "google-sign-in": {
    displayIcon: (
      <GoogleLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google SSO",
    source: ScanType.Cloud,
    alias: [],
  },
  "google-maps": {
    displayIcon: (
      <GoogleMapsLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Google Maps",
    source: ScanType.Cloud,
    alias: [],
  },
  "facebook-sign-in": {
    displayIcon: (
      <FacebookLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Facebook SSO",
    source: ScanType.Exposure,
    alias: [],
  },
  envoy: {
    displayIcon: (
      <EnvoyIcon className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Envoy",
    source: ScanType.Exposure,
    alias: [],
  },
  "matomo-analytics": {
    displayIcon: (
      <MatomoLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Matomo",
    source: ScanType.Cloud,
    alias: [],
  },
  adroll: {
    displayIcon: <AdRollLogo />,
    displayName: "AdRoll",
    source: ScanType.Cloud,
    alias: [],
  },
  recaptcha: {
    displayIcon: (
      <RecaptchaLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Recaptcha",
    source: ScanType.Cloud,
    alias: [],
  },
  cloudflare: {
    displayIcon: (
      <CloudflareLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "CloudFlare",
    alias: [],
  },
  "cloudflare-rocket-loader": {
    displayIcon: (
      <CloudflareLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "CloudFlare Rocket Loader",
    alias: [],
  },
  netlify: {
    displayIcon: (
      <NetlifyLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "Netlify",
    alias: [],
  },
  digicert: {
    displayIcon: (
      <DigiCertLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "DigiCert",
    source: ScanType.Cloud,
    alias: [],
  },
  "new-relic": {
    displayIcon: (
      <NewRelicLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "New Relic",
    source: ScanType.Cloud,
    alias: [],
  },
  akamai: {
    displayIcon: (
      <AkamaiLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Akamai",
    source: ScanType.Cloud,
    alias: [],
  },
  "akamai-mpulse": {
    displayIcon: (
      <AkamaiLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Akamai mPulse",
    source: ScanType.Cloud,
    alias: [],
  },
  "akamai-bot-manager": {
    displayIcon: (
      <AkamaiLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Akamai Bot Manager",
    source: ScanType.Cloud,
    alias: [],
  },
  lambda: {
    displayIcon: (
      <LambdaLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "AWS Lambda",
    source: ScanType.Cloud,
    alias: [],
  },
  "adobe-experience-manager": {
    displayIcon: <AdobeExperienceLogo />,
    displayName: "Adobe Experience",
    source: ScanType.Cloud,
    alias: ["adobeexperience"],
  },
  "cloud-conformity": {
    displayIcon: <CloudConformityLogo />,
    displayName: "Conformity",
    source: ScanType.Cloud,
    alias: ["conformity"],
  },
  kasada: {
    displayIcon: <KasadaLogo />,
    displayName: "Kasada",
    source: ScanType.Cloud,
    alias: [],
  },
  peakhour: {
    displayIcon: <PeakhourLogo />,
    displayName: "Peakhour",
    source: ScanType.Cloud,
    alias: [],
  },
  expanse: {
    displayIcon: (
      <ExpanseLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Expanse",
    source: ScanType.Cloud,
    alias: ["cortex"],
  },
  hotjar: {
    displayIcon: (
      <HotjarLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Hotjar",
    source: ScanType.Cloud,
    alias: [],
  },
  qualys: {
    displayIcon: (
      <QualysLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Qualys",
    source: ScanType.Cloud,
    alias: [],
  },
  sectigo: {
    displayIcon: (
      <SectigoLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Sectigo SSL",
    source: ScanType.Cloud,
    alias: [],
  },
  gitguardian: {
    displayIcon: (
      <GitguardianLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "GitGuardian",
    source: ScanType.Cloud,
    alias: ["git-guardian"],
  },
  segment: {
    displayIcon: (
      <SegmentLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Segment",
    source: ScanType.Cloud,
    alias: [],
  },
  "section-io": {
    displayIcon: (
      <SectionioLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Section",
    source: ScanType.Cloud,
    alias: ["sectionio"],
  },
  zepto: {
    displayIcon: <ZeptoLogo className="app-composition-logo" />,
    displayName: "Zepto",
    alias: [],
  },
  woocommerce: {
    displayIcon: (
      <WoocommerceLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "WooCommerce",
    source: ScanType.Exposure,
    alias: ["woo"],
  },
  buysellads: {
    displayIcon: <CarbonBuySellAdsLogo className="app-composition-logo" />,
    displayName: "Carbon Ads",
    alias: ["carbon-ads"],
  },
  cookiebot: {
    alias: [],
    displayName: "Cookiebot",
    displayIcon: <CookiebotLogo className="app-composition-logo" />,
  },
  automattic: {
    alias: [],
    displayName: "Automattic",
    displayIcon: <AutomatticLogo className="app-composition-logo" />,
  },
  odoo: {
    alias: [],
    displayName: "Odoo",
    displayIcon: <OdooLogo className="app-composition-logo" />,
  },
  intercom: {
    alias: [],
    displayName: "Intercom",
    displayIcon: <IntercomLogo className="app-composition-logo" />,
  },
  "rd-station": {
    alias: [],
    displayIcon: <RdstationLogo className="app-composition-logo" />,
    displayName: "RD Station",
  },
  mautic: {
    alias: [],
    displayIcon: <MauticLogo className="app-composition-logo" />,
    displayName: "Mautic",
  },
  onetrust: {
    alias: [],
    displayIcon: <OnetrustLogo className="app-composition-logo" />,
    displayName: "Onetrust",
  },
  heap: {
    alias: [],
    displayIcon: <HeapLogo className="app-composition-logo" />,
    displayName: "Heap",
  },
  unbounce: {
    alias: [],
    displayIcon: <UnbounceLogo className="app-composition-logo" />,
    displayName: "Unbounce",
  },
  "adobe-analytics": {
    alias: [],
    displayIcon: <AdobeCloudExperienceLogo className="app-composition-logo" />,
    displayName: "Adobe Analytics",
  },
  "adobe-target": {
    alias: [],
    displayIcon: <AdobeCloudExperienceLogo className="app-composition-logo" />,
    displayName: "Adobe Target",
  },
  "quantcast-choice": {
    alias: [],
    displayIcon: <QuantcastLogo className="app-composition-logo" />,
    displayName: "Quantcast Choice",
  },
  "quantcast-measure": {
    alias: [],
    displayIcon: <QuantcastLogo className="app-composition-logo" />,
    displayName: "Quantcast Measure",
  },
  salesforce: {
    alias: [],
    displayIcon: <SalesforceLogo className="app-composition-logo" />,
    displayName: "Salesforce",
  },
  sucuri: {
    alias: [],
    displayIcon: <SucuriLogo className="app-composition-logo" />,
    displayName: "Sucuri",
  },
  webtrends: {
    alias: [],
    displayIcon: <WebtrendsLogo />,
    displayName: "Webtrends",
  },
  sizmek: {
    alias: [],
    displayIcon: <AwsServicesLogo className="app-composition-logo" />,
    displayName: "Sizmek",
  },
  "tawk-to": { alias: [], displayIcon: <TawkLogo />, displayName: "Tawk" },
  "zendesk-chat": {
    alias: [],
    displayIcon: <ZendeskLogo className="app-composition-logo" />,
    displayName: "Zendesk Chat",
  },
  tealium: { alias: [], displayIcon: <TealiumLogo />, displayName: "Tealium" },
  "tealium-consent-management": {
    alias: [],
    displayIcon: <TealiumLogo />,
    displayName: "Tealium Consent Management",
  },
  trustarc: {
    alias: [],
    displayIcon: <TrustarcLogo className="app-composition-logo" />,
    displayName: "Trustarc",
  },
  liveperson: {
    alias: [],
    displayIcon: <LivepersonLogo />,
    displayName: "Liveperson",
  },
  imperva: {
    alias: [],
    displayIcon: <ImpervaLogo className="app-composition-logo" />,
    displayName: "Imperva",
  },
  whmcs: { alias: [], displayIcon: <WhmcsLogo />, displayName: "WHMCS" },
  "chili-piper": {
    alias: [],
    displayIcon: <ChiliPiperLogo className="app-composition-logo" />,
    displayName: "Chili Piper",
  },
  mixpanel: {
    alias: [],
    displayIcon: <MixpanelLogo className="app-composition-logo" />,
    displayName: "Mixpanel",
  },
  "crazy-egg": {
    displayIcon: <CrazyEggLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Crazy Egg",
  },
  emarsys: {
    displayIcon: <EmarsysLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Emarsys",
  },
  appnexus: {
    displayName: "AppNexus",
    displayIcon: <AppnexusLogo className="app-composition-logo" />,
    alias: [],
  },
  ghost: { alias: [], displayName: "Ghost", displayIcon: <GhostLogo /> },
  viglink: {
    alias: [],
    displayName: "Viglink",
    displayIcon: <ViglinkLogo className="app-composition-logo" />,
  },
  weglot: {
    alias: [],
    displayName: "Weglot",
    displayIcon: <WeglotLogo className="app-composition-logo" />,
  },
  rewardful: {
    alias: [],
    displayName: "Rewardful",
    displayIcon: <RewardfulLogo className="app-composition-logo" />,
  },
  amp: { alias: [], displayName: "AMP", displayIcon: <AMPLogo /> },
  "amp-plugin": {
    alias: ["amp-for-wordpress"],
    displayName: "AMP Plugin",
    displayIcon: <AMPLogo />,
  },
  chartbeat: {
    alias: [],
    displayName: "Chartbeat",
    displayIcon: <ChartbeatLogo className="app-composition-logo" />,
  },
  medium: {
    alias: [],
    displayName: "Medium",
    displayIcon: <MediumLogo className="app-composition-logo" />,
  },
  "godaddy-website-builder": {
    alias: [],
    displayName: "GoDaddy Site Builder",
    displayIcon: <GoDaddyLogo className="app-composition-logo" />,
  },
  flexslider: {
    alias: [],
    displayName: "Flexslider",
    displayIcon: <WoocommerceLogo className="app-composition-logo" />,
  },
  weebly: {
    alias: [],
    displayName: "Weebly",
    displayIcon: <WeeblyLogo className="app-composition-logo" />,
  },
  "shop-pay": {
    alias: [],
    displayName: "Shop Pay",
    displayIcon: <ShopPayLogo className="app-composition-logo" />,
  },
  "salesforce-commerce-cloud": {
    alias: [],
    displayName: "Salesforce Commerce",
    displayIcon: <SalesforceLogo className="app-composition-logo" />,
  },
  pardot: {
    alias: [],
    displayName: "Pardot",
    displayIcon: <SalesforceLogo className="app-composition-logo" />,
  },
  "crisp-live-chat": {
    alias: [],
    displayName: "Crisp Chat",
    displayIcon: <CrispLogo className="app-composition-logo" />,
  },
  algolia: {
    alias: [],
    displayName: "Algolia",
    displayIcon: <AlgoliaLogo className="app-composition-logo" />,
  },
  livechat: {
    alias: [],
    displayName: "LiveChat",
    displayIcon: <LivechatLogo className="app-composition-logo" />,
  },
  zip: {
    alias: [],
    displayName: "Zip Pay",
    displayIcon: <ZipPayLogo className="app-composition-logo" />,
  },
  neto: {
    alias: [],
    displayName: "Neto",
    displayIcon: <NetoLogo className="app-composition-logo" />,
  },
  gravatar: {
    alias: [],
    displayName: "Gravatar",
    displayIcon: <GravatarLogo className="app-composition-logo" />,
  },
  "ibm-websphere-commerce": {
    alias: [],
    displayName: "IBM Websphere",
    displayIcon: <IbmLogo className="app-composition-logo" />,
  },
  hcaptcha: {
    alias: [],
    displayIcon: <HCaptchaLogo className="app-composition-logo" />,
    displayName: "hCaptcha",
  },
  riskified: {
    alias: [],
    displayIcon: <RiskifiedLogo className="app-composition-logo" />,
    displayName: "Riskified",
  },
  calendly: {
    alias: [],
    displayIcon: <CalendlyLogo className="app-composition-logo" />,
    displayName: "Calendly",
  },
  affirm: {
    alias: [],
    displayIcon: <AffirmLogo className="app-composition-logo" />,
    displayName: "Affirm",
  },
  "apple-sign-in": {
    alias: [],
    displayIcon: <AppleLogo className="app-composition-logo" />,
    displayName: "Apple Sign In",
  },
  chargebee: {
    alias: [],
    displayIcon: <ChargebeeLogo className="app-composition-logo" />,
    displayName: "Chargebee",
  },
  "cookie-information": {
    alias: [],
    displayIcon: <CookieInformationLogo className="app-composition-logo" />,
    displayName: "Cookie Information",
  },
  "cookie-information-plugin": {
    alias: [],
    displayIcon: <CookieInformationLogo className="app-composition-logo" />,
    displayName: "Cookie Information Plugin",
  },
  datadog: {
    alias: [],
    displayIcon: <DatadogLogo className="app-composition-logo" />,
    displayName: "Datadog",
  },
  ups: {
    alias: [],
    displayIcon: <UPSLogo className="app-composition-logo" />,
    displayName: "UPS",
  },
  gtranslate: {
    alias: [],
    displayIcon: <GTranslateLogo className="app-composition-logo" />,
    displayName: "GTranslate",
  },
  insider: {
    alias: [],
    displayIcon: <InsiderLogo className="app-composition-logo" />,
    displayName: "Insider",
  },
  lottiefiles: {
    alias: [],
    displayIcon: <LottiefilesLogo className="app-composition-logo" />,
    displayName: "Lottie Files",
  },
  "norton-shopping-guarantee": {
    alias: [],
    displayIcon: <NortonLogo className="app-composition-logo" />,
    displayName: "Norton Shopping Guarantee",
  },
  "pinterest-ads": {
    alias: [],
    displayIcon: <PinterestAdsLogo className="app-composition-logo" />,
    displayName: "Pinterest Ads",
  },
  "pinterest-conversion-tag": {
    alias: [],
    displayIcon: <PinterestAdsLogo className="app-composition-logo" />,
    displayName: "Pinterest Conversion Tag",
  },
  "rakuten-advertising": {
    alias: [],
    displayIcon: <RakutenLogo className="app-composition-logo" />,
    displayName: "Rakuten Advertising",
  },
  rakuten: {
    alias: [],
    displayIcon: <RakutenLogo className="app-composition-logo" />,
    displayName: "Rakuten",
  },
  "rankmath-seo": {
    alias: [],
    displayIcon: <RankMathLogo className="app-composition-logo" />,
    displayName: "RankMath SEO",
  },
  seomatic: {
    alias: [],
    displayIcon: <SeomaticLogo className="app-composition-logo" />,
    displayName: "SEOmatic",
  },
  "wp-pagenavi": {
    alias: [],
    displayIcon: <WpNaviLogo className="app-composition-logo" />,
    displayName: "WP-PageNavi",
  },
  wpforms: {
    alias: [],
    displayIcon: <WpFormsLogo className="app-composition-logo" />,
    displayName: "WP Forms",
  },
  wpml: {
    alias: [],
    displayIcon: <WpmlLogo className="app-composition-logo" />,
    displayName: "WPML",
  },
  "smash-balloon-instagram-feed": {
    alias: [],
    displayIcon: <SmashBalloonLogo className="app-composition-logo" />,
    displayName: "Smash Balloon Instagram Feed",
  },
  "tiktok-pixel": {
    alias: [],
    displayIcon: <TikTokLogo className="app-composition-logo" />,
    displayName: "TikTok Pixel",
  },
  klaviyo: {
    alias: [],
    displayIcon: <KlaviyoLogo className="app-composition-logo" />,
    displayName: "Klaviyo",
  },
  readspeaker: {
    alias: [],
    displayIcon: <ReadspeakerLogo className="app-composition-logo" />,
    displayName: "ReadSpeaker",
  },
  rokt: {
    alias: [],
    displayIcon: <RoktLogo className="app-composition-logo" />,
    displayName: "Rokt",
  },
  venmo: {
    alias: [],
    displayIcon: <VenmoLogo className="app-composition-logo" />,
    displayName: "Venmo",
  },
  usercentrics: {
    alias: [],
    displayIcon: <UsercentricsLogo className="app-composition-logo" />,
    displayName: "Usercentrics",
  },
  "snowplow-analytics": {
    alias: [],
    displayIcon: <SnowplowLogo className="app-composition-logo" />,
    displayName: "Snowplow Analytics",
  },
  iubenda: {
    alias: [],
    displayIcon: <IubendaLogo className="app-composition-logo" />,
    displayName: "Iubenda",
  },
  skai: {
    alias: [],
    displayIcon: <SkaiLogo className="app-composition-logo" />,
    displayName: "Skai",
  },
  zendesk: {
    alias: [],
    displayIcon: <ZendeskLogo className="app-composition-logo" />,
    displayName: "Zendesk",
  },
  "twitter-ads": {
    alias: [],
    displayIcon: <TwitterLogo className="app-composition-logo" />,
    displayName: "Twitter Ads",
  },
  "facebook-login": {
    alias: [],
    displayIcon: <FacebookLogo className="app-composition-logo" />,
    displayName: "Facebook Login",
  },
  "facebook-chat-plugin": {
    alias: [],
    displayIcon: <FacebookLogo className="app-composition-logo" />,
    displayName: "Facebook Chat Plugin",
  },
  "cloudflare-browser-insights": {
    alias: [],
    displayIcon: <CloudflareLogo className="app-composition-logo" />,
    displayName: "Cloudflare Browser Insights",
  },
  "cloudflare-bot-management": {
    alias: [],
    displayIcon: <CloudflareLogo className="app-composition-logo" />,
    displayName: "Cloudflare Bot Management",
  },
  "salesforce-interaction-studio": {
    alias: [],
    displayIcon: <SalesforceLogo className="app-composition-logo" />,
    displayName: "Salesforce Interaction Studio",
  },
  "hubspot-analytics": {
    alias: [],
    displayIcon: <HubSpotLogo className="app-composition-logo" />,
    displayName: "HubSpot Analytics",
  },
  "hubspot-wordpress-plugin": {
    alias: [],
    displayIcon: <HubSpotLogo className="app-composition-logo" />,
    displayName: "HubSpot WP Plugin",
  },
  "facebook-pixel": {
    alias: [],
    displayIcon: <FacebookLogo className="app-composition-logo" />,
    displayName: "Facebook Pixel",
  },
  "microsoft-clarity": {
    alias: [],
    displayIcon: <WindowsLogo className="app-composition-logo" />,
    displayName: "Microsoft Clarity",
  },
  "linkedin-insight-tag": {
    alias: [],
    displayIcon: <LinkedInLogo className="app-composition-logo" />,
    displayName: "LinkedIn Insight Tag",
  },
  "linkedin-ads": {
    alias: [],
    displayIcon: <LinkedInLogo className="app-composition-logo" />,
    displayName: "LinkedIn Ads",
  },
  "adobe-client-data-layer": {
    alias: [],
    displayIcon: <AdobeCloudExperienceLogo className="app-composition-logo" />,
    displayName: "Adobe Client Data Layer",
  },
  "adobe-audience-manager": {
    alias: [],
    displayIcon: <AdobeCloudExperienceLogo className="app-composition-logo" />,
    displayName: "Adobe Audience Manager",
  },
  "microsoft-advertising": {
    alias: [],
    displayIcon: <WindowsLogo className="app-composition-logo" />,
    displayName: "Microsoft Ads",
  },
  "site-kit": {
    alias: [],
    displayIcon: <GoogleLogo className="app-composition-logo" />,
    displayName: "Google Site Kit",
  },
  "squarespace-commerce": {
    alias: [],
    displayIcon: <FaSquarespace className="app-composition-logo" />,
    displayName: "Squarespace Commerce",
  },
  "cloudflare-zaraz": {
    alias: [],
    displayIcon: <CloudflareLogo className="app-composition-logo" />,
    displayName: "Cloudflare Zaraz",
  },
  "australia-post": {
    alias: [],
    displayIcon: <AuspostLogo className="app-composition-logo" />,
    displayName: "Australia Post",
  },
  vimeo: {
    alias: [],
    displayIcon: <VimeoLogo className="app-composition-logo" />,
    displayName: "Vimeo",
  },
};

// WANT: CODE
// languages, frameworks, libs, OSS, propr. code
const codeTechnologies: Techstack = {
  angular: {
    displayIcon: (
      <AngularLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Angular",
    alias: ["angularjs"],
  },
  "zone-js": {
    displayIcon: (
      <AngularLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Zone.js",
    alias: [],
  },
  laravel: {
    displayIcon: (
      <LaravelLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Laravel",
    alias: [],
  },
  flickity: {
    displayIcon: <FlickityLogo />,
    displayName: "Flickity",
    source: ScanType.Exposure,
    alias: [],
  },
  "font-awesome": {
    displayIcon: (
      <FontAwesomeLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Font Awesome",
    source: ScanType.Exposure,
    alias: [],
  },
  fancybox: {
    displayIcon: (
      <FancyboxLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Fancybox",
    source: ScanType.Exposure,
    alias: [],
  },
  "owl-carousel": {
    displayIcon: <OwlCarouselLogo />,
    displayName: "OwlCarousel",
    source: ScanType.Exposure,
    alias: [],
  },
  prism: {
    displayIcon: (
      <PrismLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Prism",
    source: ScanType.Exposure,
    alias: [],
  },
  svelte: {
    displayIcon: (
      <SvelteLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Svelte",
    source: ScanType.Exposure,
    alias: [],
  },
  sapper: {
    displayName: "Sapper",
    alias: [],
    displayIcon: <SapperLogo className="app-composition-logo" />,
  },
  addthis: {
    displayIcon: (
      <AddThisLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "AddThis",
    source: ScanType.Exposure,
    alias: [],
  },
  prebid: {
    displayIcon: (
      <PrebidLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Prebid",
    source: ScanType.Exposure,
    alias: [],
  },
  knockout: {
    displayIcon: <KnockoutLogo />,
    displayName: "Knockout",
    source: ScanType.Exposure,
    alias: ["knockoutjs", "knockout-js"],
  },
  select2: {
    displayIcon: <Select2Logo />,
    displayName: "Select2",
    source: ScanType.Exposure,
    alias: [],
  },
  slick: {
    displayIcon: <SlickJSLogo />,
    displayName: "Slick",
    source: ScanType.Exposure,
    alias: [],
  },
  "underscore-js": {
    displayIcon: <UnderscoreJSLogo />,
    displayName: "Underscore",
    source: ScanType.Exposure,
    alias: [],
  },
  gsap: {
    displayIcon: (
      <GreensockGSAPLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "GSAP",
    source: ScanType.Exposure,
    alias: [],
  },
  quill: {
    displayIcon: (
      <QuillLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Quill",
    source: ScanType.Exposure,
    alias: [],
  },
  "require-js": {
    displayIcon: (
      <RequireJSLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "RequireJS",
    alias: ["requirejs", "require"],
  },
  jquery: {
    displayIcon: (
      <JQueryLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "jQuery",
    alias: [],
  },
  "jquery-migrate": {
    displayIcon: (
      <JQueryLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "jQuery Migrate",
    alias: [],
  },
  "jquery-ui": {
    displayIcon: (
      <JQueryUILogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "jQuery UI",
    source: ScanType.Exposure,
    alias: [],
  },
  "jquery-mobile": {
    displayName: "jQuery Mobile",
    displayIcon: <JQueryMobileLogo className="app-composition-logo" />,
    alias: [],
  },
  bootstrap: {
    displayIcon: (
      <BootStrapLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Bootstrap",
    alias: [],
  },
  javascript: {
    displayIcon: (
      <JavascriptLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "JavaScript",
    alias: [],
  },
  lodash: {
    displayIcon: (
      <LodashLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "Lodash",
    alias: [],
  },
  modernizr: {
    displayIcon: (
      <ModernizrLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Modernizr",
    source: ScanType.Exposure,
    alias: [],
  },
  python: {
    displayIcon: (
      <PythonLogo className="app-composition-logo should-highlight-code" />
    ),
    displayName: "Python",
    source: ScanType.Code,
    alias: [],
  },
  react: {
    displayIcon: (
      <ReactLogo className="app-composition-logo should-highlight-exposure" />
    ),
    source: ScanType.Exposure,
    displayName: "React",
    alias: [],
  },
  "vue-js": {
    displayIcon: (
      <VueLogo
        className="app-composition-logo should-highlight-exposure"
        width={40}
        height={40}
        viewBox="0 0 64 64"
      />
    ),
    source: ScanType.Exposure,
    displayName: "Vue",
    alias: [],
  },
  "swiper-slider": {
    displayIcon: (
      <SwiperLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Swiper.js",
    source: ScanType.Exposure,
    alias: ["swiper"],
  },
  tailwindcss: {
    displayIcon: (
      <TailwindCSSLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Tailwind CSS",
    alias: ["tailwind"],
  },
  go: {
    displayIcon: (
      <GoLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Go",
    alias: ["golang"],
  },
  graphql: {
    displayIcon: (
      <GraphQLLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "GraphQL",
    alias: ["gql"],
  },
  java: {
    displayIcon: (
      <JavaLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Java",
    alias: [],
  },
  lua: {
    displayIcon: (
      <LuaLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Lua",
    alias: [],
  },
  dotnet: {
    displayIcon: (
      <DotNETLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: ".NET",
    alias: [],
  },
  "dotnet-core": {
    displayIcon: (
      <DotNETCoreLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: ".NET Core",
    alias: ["dotnetcore"],
  },
  "nuxt-js": {
    displayIcon: (
      <NuxtLogo
        className="app-composition-logo should-highlight-exposure"
        width={40}
        height={40}
      />
    ),
    displayName: "Nuxt",
    source: ScanType.Exposure,
    alias: ["nuxt"],
  },
  express: {
    displayIcon: (
      <ExpressLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    source: ScanType.Exposure | ScanType.Code,
    displayName: "Express",
    alias: [],
  },
  "next-js": {
    displayIcon: (
      <NextjsLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    source: ScanType.Exposure | ScanType.Code,
    displayName: "Next.js",
    alias: [],
  },
  "google-web-toolkit": {
    displayName: "Google Web Toolkit",
    alias: [],
    displayIcon: <GWTLogo className="app-composition-logo" />,
  },
  bulma: {
    displayName: "Bulma",
    alias: [],
    displayIcon: <BulmaLogo className="app-composition-logo" />,
  },
  ruby: {
    displayName: "Ruby",
    alias: [],
    displayIcon: <RubyLogo className="app-composition-logo" />,
  },
  "moment-js": {
    displayName: "Moment",
    alias: ["moment"],
    displayIcon: <MomentJSLogo className="app-composition-logo" />,
  },
  "ruby-on-rails": {
    displayIcon: (
      <RubyOnRailsLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    displayName: "Ruby on Rails",
    source: ScanType.Exposure,
    alias: [],
  },
  gatsby: {
    displayIcon: <GatsbyLogo className="app-composition-logo" />,
    displayName: "Gatsby",
    alias: [],
  },
  npm: {
    displayIcon: (
      <NPMLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "NPM",
    source: ScanType.Cloud,
    alias: [],
  },
  nuget: {
    displayIcon: (
      <NugetLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Nuget",
    source: ScanType.Cloud,
    alias: [],
  },
  webpack: {
    displayIcon: (
      <WebpackLogo className="app-composition-logo should-highlight-code" />
    ),
    displayName: "Webpack",
    source: ScanType.Code,
    alias: [],
  },
  babel: {
    displayIcon: (
      <BabelLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Babel",
    alias: ["babeljs", "babel-js"],
  },
  php: {
    displayIcon: (
      <PhpLogo className="app-composition-logo should-highlight-exposure should-highlight-code" />
    ),
    source: ScanType.Exposure | ScanType.Code,
    displayName: "PHP",
    alias: [],
  },
  cowboy: { displayIcon: <CowboyLogo />, displayName: "Cowboy", alias: [] },
  addtoany: {
    displayIcon: <AddToAnyLogo className="app-composition-logo" />,
    displayName: "AddToAny",
    alias: [],
  },
  flask: {
    displayIcon: <FlaskLogo className="app-composition-logo" />,
    displayName: "Flask",
    alias: [],
  },
  "chart-js": {
    displayIcon: <ChartjsLogo className="app-composition-logo" />,
    displayName: "Chart.js",
    alias: [],
  },
  "ember-js": {
    displayIcon: <EmberjsLogo className="app-composition-logo" />,
    displayName: "Ember",
    alias: [],
  },
  handlebars: {
    displayIcon: <HandlebarsLogo className="app-composition-logo" />,
    displayName: "Handlebars",
    alias: [],
  },
  mustache: {
    displayIcon: <HandlebarsLogo className="app-composition-logo" />,
    displayName: "Mustache",
    alias: [],
  },
  highcharts: {
    displayIcon: <HighchartsLogo className="app-composition-logo" />,
    displayName: "Highcharts",
    alias: [],
  },
  maven: {
    displayName: "Maven",
    alias: [],
    displayIcon: <MavenLogo className="app-composition-logo" />,
  },
  cargo: {
    displayName: "Cargo",
    alias: [],
    displayIcon: <RustLogo className="app-composition-logo" />,
  },
  pypi: {
    displayName: "Pypi",
    displayIcon: <PythonLogo className="app-composition-logo" />,
    alias: [],
  },
  pipenv: {
    displayName: "Pipenv",
    displayIcon: <PythonLogo className="app-composition-logo" />,
    alias: [],
  },
  yarn: {
    displayName: "Yarn",
    alias: [],
    displayIcon: <YarnLogo className="app-composition-logo" />,
  },
  "animate-css": {
    alias: [],
    displayIcon: <AnimatecssLogo />,
    displayName: "Animate CSS",
  },
  "rev-slider": {
    alias: ["revslider"],
    displayIcon: <SliderRevolutionLogo className="app-composition-logo" />,
    displayName: "Slider Revolution",
  },
  typekit: {
    alias: [],
    displayIcon: <TypekitLogo className="app-composition-logo" />,
    displayName: "Adobe Fonts",
  },
  yui: { alias: [], displayIcon: <Yui3Logo />, displayName: "Yui3" },
  less: {
    alias: [],
    displayIcon: <LessLogo className="app-composition-logo" />,
    displayName: "LESS",
  },
  "alpine-js": {
    alias: [],
    displayIcon: <AlpineJSLogo className="app-composition-logo" />,
    displayName: "Alpine.js",
  },
  polyfill: {
    alias: [],
    displayIcon: <PolyfillLogo className="app-composition-logo" />,
    displayName: "Polyfill",
  },
  "visual-website-optimizer": {
    alias: [],
    displayIcon: (
      <VisualWebsiteOptimiserLogo className="app-composition-logo" />
    ),
    displayName: "VWO",
  },
  "gravity-forms": {
    alias: [],
    displayIcon: <GravityFormsLogo className="app-composition-logo" />,
    displayName: "Gravity Forms",
  },
  "wp-rocket": {
    alias: [],
    displayIcon: <WPRocketLogo className="app-composition-logo" />,
    displayName: "WP Rocket",
  },
  mathjax: {
    alias: [],
    displayIcon: <MathjaxLogo className="app-composition-logo" />,
    displayName: "Mathjax",
  },
  "snap-svg": {
    alias: [],
    displayIcon: <SnapSVGLogo className="app-composition-logo" />,
    displayName: "Snap SVG",
  },
  "atlassian-jira-issue-collector": {
    alias: [],
    displayIcon: <JiraLogo className="app-composition-logo" />,
    displayName: "Jira Issue Collector",
  },
  "google-plus": {
    alias: [],
    displayIcon: <GooglePlusLogo className="app-composition-logo" />,
    displayName: "Google Plus",
  },
  phaser: { alias: [], displayIcon: <PhaserLogo />, displayName: "Phaser" },
  apollo: {
    alias: [],
    displayIcon: <ApolloLogo className="app-composition-logo" />,
    displayName: "Apollo",
  },
  codeigniter: {
    alias: [],
    displayIcon: <CodeIgniterLogo className="app-composition-logo" />,
    displayName: "CodeIgniter",
  },
  plyr: {
    alias: [],
    displayIcon: <PlyrLogo className="app-composition-logo" />,
    displayName: "Plyr",
  },
  userway: {
    alias: [],
    displayIcon: <UserwayLogo className="app-composition-logo" />,
    displayName: "Userway",
  },
  "backbone-js": {
    alias: [],
    displayIcon: <BackboneLogo />,
    displayName: "Backbone.js",
  },
  marked: {
    alias: [],
    displayIcon: <MarkedJSLogo className="app-composition-logo" />,
    displayName: "Marked",
  },
  "zurb-foundation": {
    alias: [],
    displayIcon: <ZurbFoundationLogo className="app-composition-logo" />,
    displayName: "Foundation",
  },
  "mediaelement-js": {
    alias: [],
    displayIcon: <MediaelementLogo className="app-composition-logo" />,
    displayName: "MediaElement.js",
  },
  mootools: {
    alias: [],
    displayIcon: <MootoolsLogo />,
    displayName: "MooTools",
  },
  lightbox: {
    alias: [],
    displayIcon: <LightboxLogo className="app-composition-logo" />,
    displayName: "Lightbox",
  },
  prettyphoto: {
    alias: [],
    displayIcon: <PrettyphotoLogo />,
    displayName: "PrettyPhoto",
  },
  "angular-material": {
    alias: [],
    displayIcon: <AngularLogo className="app-composition-logo" />,
    displayName: "Angular Material",
  },
  "pdf-js": {
    alias: [],
    displayIcon: <PDFJSLogo className="app-composition-logo" />,
    displayName: "PDF.js",
  },
  videojs: {
    alias: [],
    displayIcon: <VideoJSLogo className="app-composition-logo" />,
    displayName: "Video.js",
  },
  "american-express": {
    alias: [],
    displayIcon: <AmexLogo className="app-composition-logo" />,
    displayName: "AMEX",
  },
  "amex-express-checkout": {
    alias: [],
    displayIcon: <AmexLogo className="app-composition-logo" />,
    displayName: "AMEX Express Checkout",
  },
  visa: {
    alias: [],
    displayIcon: <VisaLogo className="app-composition-logo" />,
    displayName: "Visa",
  },
  "apple-pay": {
    alias: [],
    displayIcon: <ApplePayLogo className="app-composition-logo" />,
    displayName: "Apple Pay",
  },
  "semantic-ui": {
    alias: [],
    displayIcon: <SemanticUILogo />,
    displayName: "Semantic UI",
  },
  "w3-total-cache": {
    alias: [],
    displayIcon: <W3CacheLogo />,
    displayName: "W3 Total Cache",
  },
  "material-design-lite": {
    alias: [],
    displayIcon: <MaterialDesignLiteLogo className="app-composition-logo" />,
    displayName: "Material Design Lite",
  },
  "materialize-css": {
    alias: [],
    displayIcon: <MaterializeLogo className="app-composition-logo" />,
    displayName: "Materialize CSS",
  },
  scrollreveal: {
    alias: [],
    displayIcon: <ScrollrevealLogo className="app-composition-logo" />,
    displayName: "ScrollReveal",
  },
  "ant-design": {
    alias: [],
    displayIcon: <AntdesignLogo className="app-composition-logo" />,
    displayName: "Ant Design",
  },
  "twitter-typeahead-js": {
    alias: [],
    displayIcon: <TwitterLogo className="app-composition-logo" />,
    displayName: "Typeahead.js",
  },
  "mouse-flow": {
    alias: [],
    displayIcon: <MouseflowLogo className="app-composition-logo" />,
    displayName: "Mouseflow",
  },
  fireblade: {
    alias: [],
    displayIcon: <FirebladeLogo />,
    displayName: "Fireblade",
  },
  sweetalert: {
    alias: [],
    displayIcon: <Sweetalert1Logo />,
    displayName: "Sweet Alert",
  },
  sweetalert2: {
    alias: [],
    displayIcon: <Sweetalert2Logo className="app-composition-logo" />,
    displayName: "Sweet Alert 2",
  },
  ionicons: {
    alias: [],
    displayIcon: <IonicLogo className="app-composition-logo" />,
    displayName: "Ionic Icons",
  },
  clicky: { alias: [], displayIcon: <ClickyLogo />, displayName: "Clicky" },
  "froala-editor": {
    alias: [],
    displayIcon: <FroalaLogo />,
    displayName: "Froala",
  },
  datatables: {
    alias: [],
    displayIcon: <DatatableLogo />,
    displayName: "Datatables",
  },
  popper: {
    alias: [],
    displayIcon: <PopperLogo className="app-composition-logo" />,
    displayName: "Popper",
  },
  "kendo-ui": {
    alias: [],
    displayIcon: <SitefinityLogo className="app-composition-logo" />,
    displayName: "Kendo UI",
  },
  tinymce: {
    alias: [],
    displayIcon: <TinymceLogo className="app-composition-logo" />,
    displayName: "TinyMCE",
  },
  leaflet: {
    alias: [],
    displayName: "Leaflet",
    displayIcon: <LeafletLogo className="app-composition-logo" />,
  },
  ionic: {
    alias: [],
    displayName: "Ionic",
    displayIcon: <IonicLogo className="app-composition-logo" />,
  },
  "immutable-js": {
    alias: [],
    displayName: "Immutable.js",
    displayIcon: <ImmutableJsLogo className="app-composition-logo" />,
  },
  "highlight-js": {
    alias: [],
    displayName: "Highlight.js",
    displayIcon: <HighlightJsLogo />,
  },
  mobx: { alias: [], displayName: "MobX", displayIcon: <MobxJsLogo /> },
  trackjs: {
    alias: [],
    displayName: "TrackJS",
    displayIcon: <TrackJsLogo className="app-composition-logo" />,
  },
  d3: {
    alias: [],
    displayName: "D3",
    displayIcon: <D3Logo className="app-composition-logo" />,
  },
  "jquery-pjax": {
    alias: [],
    displayName: "jQuery PJAX",
    displayIcon: <JQueryLogo className="app-composition-logo" />,
  },
  docusaurus: {
    alias: [],
    displayName: "Docusaurus",
    displayIcon: <DocusaurusLogo className="app-composition-logo" />,
  },
  "three-js": {
    alias: [],
    displayName: "Three.js",
    displayIcon: <ThreeJsLogo className="app-composition-logo" />,
  },
  stimulus: {
    alias: [],
    displayName: "Stimulus",
    displayIcon: <StimulusLogo className="app-composition-logo" />,
  },
  uikit: {
    displayIcon: <UikitLogo className="app-composition-logo" />,
    alias: [],
    displayName: "UIKit",
  },
  boomerang: {
    displayIcon: <BoomerangLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Boomerang",
  },
  "glide-js": {
    displayIcon: <GlideJSLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Glide.js",
  },
  "socket-io": {
    displayIcon: <SocketIOLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Socket.io",
  },
  "web-vitals": {
    displayIcon: <WebVitalsLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Web Vitals",
  },
  wisepops: {
    displayIcon: <WisepopsLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Wisepops",
  },
  pwa: {
    displayIcon: <PwaLogo className="app-composition-logo" />,
    alias: [],
    displayName: "PWA",
  },
  "priority-hints": {
    displayIcon: <PriorityHintsLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Priority Hints",
  },
  "bootstrap-icons": {
    displayIcon: <BootStrapLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Bootstrap Icons",
  },
  "mailchimp-for-woocommerce": {
    displayIcon: <MailchimpLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Mailchimp for Woo",
  },

  // packages
  typescript: {
    alias: [],
    displayName: "TypeScript",
    displayIcon: <TypescriptLogo className="app-composition-logo" />,
  },
  axios: {
    alias: [],
    displayName: "Axios",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  jest: {
    alias: [],
    displayName: "Jest",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  auth0: {
    alias: [],
    displayName: "Auth0",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  supabase: {
    alias: [],
    displayName: "Supabase",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "react-redux": {
    alias: [],
    displayName: "Redux",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "react-router-dom": {
    alias: [],
    displayName: "React Router",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "react-query": {
    alias: [],
    displayName: "React Query",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  dotenv: {
    alias: [],
    displayName: "Dotenv",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  prettier: {
    alias: [],
    displayName: "Prettier",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  testcafe: {
    alias: [],
    displayName: "TestCafe",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  async: {
    alias: [],
    displayName: "Async",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  bonjour: {
    alias: [],
    displayName: "Bonjour",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "coffee-script": {
    alias: [],
    displayName: "CoffeeScript",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  electron: {
    alias: [],
    displayName: "Electron",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  eslint: {
    alias: [],
    displayName: "ESLint",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  terser: {
    alias: [],
    displayName: "Terser",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  django: {
    alias: [],
    displayName: "Django",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gunicorn: {
    alias: [],
    displayName: "Gunicorn",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pep8: {
    alias: [],
    displayName: "PEP8",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pillow: {
    alias: [],
    displayName: "Pillow",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  uvicorn: {
    alias: [],
    displayName: "Uvicorn",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  boto3: {
    alias: [],
    displayName: "BOTO3",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  requests: {
    alias: [],
    displayName: "Requests",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  certifi: {
    alias: [],
    displayName: "Certifi",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  wheel: {
    alias: [],
    displayName: "Wheel",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "google-api-core": {
    alias: [],
    displayName: "Google API Core",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  s3transfer: {
    alias: [],
    displayName: "S3 Transfer",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pip: {
    alias: [],
    displayName: "Pip",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  numpy: {
    alias: [],
    displayName: "Numpy",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pandas: {
    alias: [],
    displayName: "Pandas",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  tensorflow: {
    alias: [],
    displayName: "Tensorflow",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  keras: {
    alias: [],
    displayName: "Keras",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  syn: {
    alias: [],
    displayName: "Syn",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  quote: {
    alias: [],
    displayName: "Quote",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "proc-macro2": {
    alias: [],
    displayName: "proc-macro2",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  libc: {
    alias: [],
    displayName: "libc",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  rand: {
    alias: [],
    displayName: "rand",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  "cfg-if": {
    alias: [],
    displayName: "cfg-if",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  serde: {
    alias: [],
    displayName: "Serde",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  serdejson: {
    alias: [],
    displayName: "Serde JSON",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  itoa: {
    alias: [],
    displayName: "itoa",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  autocfg: {
    alias: [],
    displayName: "autocfg",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  flake8: {
    alias: [],
    displayName: "Flake8",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  flaky: {
    alias: [],
    displayName: "Flaky",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pybabel: {
    alias: [],
    displayName: "Babel (Python)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gin: {
    alias: [],
    displayName: "Gin (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  testify: {
    alias: [],
    displayName: "Testify (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gocrypto: {
    alias: [],
    displayName: "Crypto (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gogenproto: {
    alias: [],
    displayName: "Genproto (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gogen: {
    alias: [],
    displayName: "Gen (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gorm: {
    alias: [],
    displayName: "Gorm (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  goetcd: {
    alias: [],
    displayName: "Etcd (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  goimage: {
    alias: [],
    displayName: "Image (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  gonet: {
    alias: [],
    displayName: "Net (Go)",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  npmnetwork: {
    alias: [],
    displayName: "network",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pydiesel: {
    alias: [],
    displayName: "Diesel",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pypulsar: {
    alias: [],
    displayName: "Pulsar",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  pyzmq: {
    alias: [],
    displayName: "Pyzmq",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  twisted: {
    alias: [],
    displayName: "Twisted",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  napalm: {
    alias: [],
    displayName: "NAPALM",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  txzmq: {
    alias: [],
    displayName: "TxZMQ",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  rustix: {
    alias: [],
    displayName: "Rustix",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  socket2: {
    alias: [],
    displayName: "Socket2",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  rustbytes: {
    alias: [],
    displayName: "Bytes",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  tokio: {
    alias: [],
    displayName: "Tokio",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  rustls: {
    alias: [],
    displayName: "RustLS",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  hyper: {
    alias: [],
    displayName: "Hyper",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  h2: {
    alias: [],
    displayName: "H2",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  httparse: {
    alias: [],
    displayName: "Httparse",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
  vite: {
    alias: [],
    displayName: "Vite",
    displayIcon: <GoPackage className="app-composition-logo" />,
  },
};

// WANT: CLOUD
const cloudTechnologies: Techstack = {
  "amazon-waf": {
    displayName: "Amazon WAF",
    alias: [],
    displayIcon: <AWSWAFLogo className="app-composition-logo" />,
    search: "waf",
  },
  "amazon-origin-api-gateway": {
    displayName: "Amazon Origin API Gateway",
    alias: [],
    displayIcon: <AmazonAPIGatewayLogo className="app-composition-logo" />,
    search: "api",
  },
  "amazon-origin-unknown": {
    displayName: "Amazon Origin",
    alias: [],
    displayIcon: <AmazonAPIGatewayLogo className="app-composition-logo" />,
    search: "origin",
  },
  "amazon-aurora": {
    displayName: "Amazon Aurora",
    alias: [],
    displayIcon: <AWSAuroraLogo className="app-composition-logo" />,
    search: "aurora",
  },
  "amazon-cloudwatch-rum": {
    displayName: "Amazon Cloudwatch RUM",
    alias: [],
    displayIcon: <AWSCloudWatchLogo className="app-composition-logo" />,
    search: "cloudwatch",
  },
  "amazon-ecs": {
    displayName: "Amazon ECS",
    alias: [],
    displayIcon: <AWSECSLogo className="app-composition-logo" />,
    search: "ecs",
  },
  "amazon-efs": {
    displayName: "Amazon EFS",
    alias: [],
    displayIcon: <AWSEFSLogo className="app-composition-logo" />,
    search: "efs",
  },
  "google-app-engine": {
    displayName: "Google App Engine",
    alias: [],
    displayIcon: <GoogleCloudAppEngineLogo className="app-composition-logo" />,
  },
  "google-cloud": {
    displayName: "Google Cloud",
    alias: [],
    displayIcon: <GoogleCloudLogo className="app-composition-logo" />,
  },
  "google-cloud-cdn,": {
    displayName: "Google Cloud CDN",
    alias: [],
    displayIcon: <GoogleCloudCDNLogo className="app-composition-logo" />,
  },
  "google-cloud-storage": {
    displayName: "Google Cloud Storage",
    alias: [],
    displayIcon: <GoogleCloudStorageLogo className="app-composition-logo" />,
  },
  "google-cloud-trace": {
    displayName: "Google Cloud Trace",
    alias: [],
    displayIcon: <GoogleCloudTraceLogo className="app-composition-logo" />,
  },
  azure: {
    displayIcon: (
      <AzureLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Exposure | ScanType.Cloud,
    displayName: "Azure",
    alias: [],
  },
  "azure-arraffinity": {
    displayIcon: <AzureAutoScaleLogo className="app-composition-logo" />,
    displayName: "Azure ARR",
    alias: [],
  },
  "azure-ad-b2c": {
    displayName: "Azure AD B2C",
    alias: [],
    displayIcon: <AzureADLogo className="app-composition-logo" />,
  },
  "azure-cdn": {
    displayName: "Azure CDN",
    alias: [],
    displayIcon: <AzureCDNLogo className="app-composition-logo" />,
  },
  "azure-front-door": {
    displayName: "Azure Front Door",
    alias: [],
    displayIcon: <AzureFrontDoorLogo className="app-composition-logo" />,
  },
  "azure-monitor": {
    displayName: "Azure Monitor",
    alias: [],
    displayIcon: <AzureMonitorLogo className="app-composition-logo" />,
  },
  "amazon-web-services": {
    displayIcon: (
      <AwsServicesLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "AWS",
    alias: [],
    search: "aws",
  },
  "aws-waf-captcha": {
    displayIcon: (
      <AwsServicesLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "AWS WAF Captcha",
    alias: [],
  },
  "amazon-s3": {
    displayIcon: (
      <S3Logo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "S3",
    alias: ["amazon s3"],
    search: "s3",
  },
  "amazon-cloudfront": {
    displayIcon: (
      <CloudfrontLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "CloudFront",
    alias: [],
    search: "cloudfront",
  },
  "amazon-ec2": {
    displayIcon: (
      <Ec2Logo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "EC2",
    alias: [],
    search: "ec2",
  },
  "amazon-origin-ec2": {
    displayIcon: (
      <Ec2Logo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "Origin EC2",
    alias: [],
    search: "ec2",
  },
  "amazon-alb": {
    displayIcon: (
      <AlbLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "ALB",
    alias: [],
    search: "alb",
  },
  "amazon-elb": {
    displayIcon: (
      <AlbLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "ELB",
    alias: ["amazon-origin-elb"],
    search: "elb",
  },
  "amazon-origin-s3": {
    displayIcon: (
      <AwsS3OriginLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "Amazon Origin S3",
    alias: [],
    search: "s3",
  },
  "amazon-api-gateway": {
    displayIcon: (
      <AmazonAPIGatewayLogo className="app-composition-logo should-highlight-cloud" />
    ),
    source: ScanType.Cloud,
    displayName: "Amazon API Gateway",
    alias: ["amazon-apigateway"],
    search: "gateway",
  },
  "aws-certificate-manager": {
    displayIcon: (
      <AWSCertManager className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "AWS Certificate Manager",
    source: ScanType.Cloud,
    alias: [],
  },
  firebase: {
    displayIcon: (
      <FirebaseLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Firebase",
    source: ScanType.Cloud,
    alias: [],
  },
  vercel: {
    displayIcon: (
      <VercelLogo className="app-composition-logo should-highlight-cloud fill-slate-800" />
    ),
    displayName: "Vercel",
    source: ScanType.Cloud,
    alias: [],
  },
  fastly: {
    displayIcon: <FastlyLogo className="app-composition-logo" />,
    displayName: "Fastly",
    alias: [],
  },
  heroku: {
    displayIcon: <HerokuLogo className="app-composition-logo" />,
    displayName: "Heroku",
    alias: [],
  },
  "salesforce-service-cloud": {
    displayIcon: <SalesforceLogo className="app-composition-logo" />,
    displayName: "Salesforce Cloud",
    alias: [],
  },
  dynatrace: {
    displayIcon: <DynatraceLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Dynatrace",
  },
  cdnjs: {
    displayIcon: <CdnJSLogo className="app-composition-logo" />,
    alias: [],
    displayName: "cdnjs",
  },
  "clipboard-js": {
    displayIcon: <ClipboardJSLogo className="app-composition-logo" />,
    alias: [],
    displayName: "clipboard.js",
  },
  "jquery-cdn": {
    displayIcon: <JQueryLogo className="app-composition-logo" />,
    alias: [],
    displayName: "jQuery CDN",
  },
  "google-cloud-cdn": {
    displayIcon: <GoogleCloudLogo className="app-composition-logo" />,
    alias: [],
    displayName: "Google Cloud CDN",
  },
  "cloud-platform": {
    displayIcon: <AiOutlineCloud className="app-composition-logo" />,
    alias: [],
    displayName: "Cloud Platform",
  },
};

// WANT: Local requirements
const localRequirementsTechnologies: Techstack = {
  trufflehog: {
    displayIcon: (
      <TrufflehogLogo className="app-composition-logo should-highlight-cloud" />
    ),
    displayName: "Trufflehog",
    source: ScanType.Cloud,
    alias: [],
  },
  elementor: {
    displayIcon: (
      <ElementorLogo className="app-composition-logo should-highlight-exposure" />
    ),
    displayName: "Elementor",
    source: ScanType.Exposure,
    alias: ["elementor-pro", "hello-elementor"],
  },
  "adobe-dreamweaver": {
    displayIcon: <AdobeDreamweaverLogo className="app-composition-logo" />,
    displayName: "Adobe Dreamweaver",
    alias: ["dreamweaver"],
  },
  burp: {
    displayIcon: (
      <BurpLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Burp Suite",
    alias: ["burpsuite", "burp-suite"],
  },
  git: {
    displayIcon: (
      <GitLogo className="app-composition-logo should-highlight-code" />
    ),
    source: ScanType.Code,
    displayName: "Git",
    alias: [],
  },
  "essential-addons-for-elementor": {
    displayIcon: <EssentialElementor className="app-composition-logo" />,
    displayName: "Essential Elementor Addons",
    alias: [],
  },
  "ultimate-addons-for-elementor": {
    displayIcon: <UltimateElementor className="app-composition-logo" />,
    displayName: "Ultimate Elementor Addons",
    alias: [],
  },
  "twenty-fourteen": {
    displayIcon: <WordPressLogo className="app-composition-logo" />,
    displayName: "Twenty Fourteen Theme",
    alias: [],
  },
  civictheme: {
    displayIcon: <WordPressLogo className="app-composition-logo" />,
    displayName: "Civic Theme",
    alias: [],
  },
  "pwa-studio": {
    displayIcon: <AdobeCloudExperienceLogo className="app-composition-logo" />,
    displayName: "PWA Studio",
    alias: [],
  },
  divi: {
    displayIcon: <DiviLogo className="app-composition-logo" />,
    displayName: "Divi",
    alias: [],
  },
  wpbakery: {
    displayIcon: <WpbakeryLogo className="app-composition-logo" />,
    displayName: "WPBakery",
    alias: [],
  },
};

// every key is prefixed with the SecureStack "language" field we support
// so if we changed backend for "npm" to now be "node"
// `npm-react` would be invalid, and `node-react` would now be correct
// the value of the key points to the name to find an icon in source code or services
// so npm-react becomes react after being parsed through from SBOM and SBOM packages will be
// namespaced with the package manager that created them
// most of these still needing legit icons...
export const SbomTechToCodeTech: { [k: string]: string } = {
  // npm packages
  // apollo client ?
  "npm-react": "react",
  "npm-express": "express",
  "npm-axios": "axios",
  "npm-lodash": "lodash",
  "npm-@svgr/webpack": "webpack",
  "npm-babel-core": "babel",
  "npm-@babel/core": "babel",
  "npm-@testing-library/jest-dom": "jest",
  "npm-typescript": "typescript",
  "npm-@apollo/client": "apollo",
  "npm-@auth0/spa-js": "auth0",
  "npm-@stripe/stripe-js": "stripe",
  "npm-@supabase/supabase-js": "supabase",
  "npm-graphql": "graphql",
  "npm-react-redux": "react-redux",
  "npm-react-router-dom": "react-router-dom",
  "npm-tailwindcss": "tailwindcss",
  "npm-react-query": "react-query",
  "npm-dotenv": "dotenv",
  "npm-prettier": "prettier",
  "npm-testcafe": "testcafe",
  "npm-async": "async",
  "npm-bonjour": "bonjour", // js package bonjour
  "npm-coffee-script": "coffee-script",
  "npm-electron": "electron",
  "npm-eslint": "eslint",
  "npm-polyfill": "polyfill",
  "npm-terser": "terser",
  "npm-python": "python", // js package python
  "npm-ruby": "ruby", // js package ruby
  "npm-vite": "vite",
  // yarn packages
  "yarn-react": "react",
  "yarn-express": "express",
  "yarn-axios": "axios",
  "yarn-lodash": "lodash",
  "yarn-@svgr/webpack": "webpack",
  "yarn-babel-core": "babel",
  "yarn-@babel/core": "babel", // most of these still needing legit icons
  "yarn-@testing-library/jest-dom": "jest",
  "yarn-typescript": "typescript",
  "yarn-@apollo/client": "apollo",
  "yarn-@auth0/spa-js": "auth0",
  "yarn-@stripe/stripe-js": "stripe",
  "yarn-@supabase/supabase-js": "supabase",
  "yarn-graphql": "graphql",
  "yarn-react-redux": "react-redux",
  "yarn-react-router-dom": "react-router-dom",
  "yarn-tailwindcss": "tailwindcss",
  "yarn-react-query": "react-query",
  "yarn-dotenv": "dotenv",
  "yarn-prettier": "prettier",
  "yarn-testcafe": "testcafe",
  "yarn-async": "async",
  "yarn-bonjour": "bonjour",
  "yarn-coffee-script": "coffee-script",
  "yarn-electron": "electron",
  "yarn-eslint": "eslint",
  "yarn-polyfill": "polyfill",
  "yarn-terser": "terser",
  "yarn-python": "python", // js package python
  "yarn-ruby": "ruby", // js package ruby
  "yarn-vite": "vite",
  // pypi python pacakges
  "pypi-django": "django",
  "pypi-babel": "pybabel",
  "pypi-gunicorn": "gunicorn",
  "pypi-pep8": "pep8",
  "pypi-pillow": "pillow",
  "pypi-uvicorn": "uvicorn",
  "pypi-boto3": "boto3",
  "pypi-urllib3": "urllib3",
  "pypi-requests": "requests",
  "pypi-certifi": "certifi",
  "pypi-wheel": "wheel",
  "pypi-google-api-core": "google-api-core",
  "pypi-s3transfer": "s3transfer",
  "pypi-pip": "pip",
  "pypi-numpy": "numpy",
  "pypi-pandas": "pandas",
  "pypi-tensorflow": "tensorflow",
  "pypi-keras": "keras",
  "pypi-flake8": "flake8",
  "pypi-virtualenv": "virtualenv",
  "pypi-flaky": "flaky",
  "pypi-diesel": "pydiesel",
  "pypi-pulsar": "pypulsar",
  "pypi-pyzmq": "pyzmq",
  "pypi-twisted": "twisted",
  "pypi-txzmq": "txzmq",
  "pypi-napalm": "napalm",
  // pipenv python packages
  "pipenv-babel": "pybabel",
  "pipenv-django": "django",
  "pipenv-gunicorn": "gunicorn",
  "pipenv-pep8": "pep8",
  "pipenv-pillow": "pillow",
  "pipenv-uvicorn": "uvicorn",
  "pipenv-boto3": "boto3",
  "pipenv-urllib3": "urllib3",
  "pipenv-requests": "requests",
  "pipenv-certifi": "certifi",
  "pipenv-wheel": "wheel",
  "pipenv-google-api-core": "google-api-core",
  "pipenv-s3transfer": "s3transfer",
  "pipenv-pip": "pip",
  "pipenv-numpy": "numpy",
  "pipenv-pandas": "pandas",
  "pipenv-tensorflow": "tensorflow",
  "pipenv-keras": "keras",
  "pipenv-flake8": "flake8",
  "pipenv-virtualenv": "virtualenv",
  "pipenv-flaky": "flaky",
  "pipenv-diesel": "pydiesel",
  "pipenv-pulsar": "pypulsar",
  "pipenv-pyzmq": "pyzmq",
  "pipenv-twisted": "twisted",
  "pipenv-txzmq": "txzmq",
  "pipenv-napalm": "napalm",
  // rust cargo crates
  "cargo-syn": "syn",
  "cargo-quote": "quote",
  "cargo-proc-macro2": "proc-macro2",
  "cargo-libc": "libc",
  "cargo-rand": "rand",
  "cargo-cfg-if": "cfg-if",
  "cargo-serde": "serde",
  "cargo-serde_json": "serdejson",
  "cargo-itoa": "itoa",
  "cargo-autocfg": "autocfg",
  "cargo-rustix": "rustix",
  "cargo-socket2": "socket2",
  "cargo-bytes": "rustbytes",
  "cargo-tokio": "tokio",
  "cargo-rustls": "rustls",
  "cargo-hyper": "hyper",
  "cargo-h2": "h2",
  "cargo-httparse": "httparse",
  // go pacakges
  "go-gin": "gin",
  "go-mock": "mock",
  "go-crypto": "gocrypto",
  "go-testify": "testify",
  "go-genproto": "gogenproto",
  "go-gen": "gogen",
  "go-gorm": "gorm",
  "go-kubernetes": "kubernetes",
  "go-etcd": "goetcd",
  "go-net": "gonet",
  "go-image": "goimage",
  "npm-network": "npmnetwork",
};

const internalPackageNameToPacakgeName: { [k: string]: string } = {
  goetcd: "etcd",
  gogenproto: "genproto",
  gogen: "gen",
  gocrypto: "crypto",
  pybabel: "babel",
  gonet: "net",
  goimage: "image",
  npmnetwork: "network",
  pydiesel: "diesel",
  pypulsar: "pulsar",
  rustbytes: "bytes",
};

const sbomTechnologies: string[] = uniq(
  Object.keys(SbomTechToCodeTech)
    .concat(Object.keys(codeTechnologies))
    .concat(Object.keys(servicesTechnologies)),
);

type IconAndVersion = {
  icon: TechstackItem;
  version: string | null;
  packageName: string;
  associatedVersions?: SbomTech[];
};

function findIcon(dataset: Techstack, tech: Tech): IconAndVersion | null {
  // find `alias` or `displayName` that is === `techName` in `dataset`
  const foundComponent = dataset[tech.component];
  if (foundComponent) {
    return {
      icon: foundComponent,
      version: tech.version,
      packageName:
        internalPackageNameToPacakgeName[tech.component] ??
        SbomTechToCodeTech[tech.component] ??
        tech.component,
      associatedVersions: tech.sameComponentDifferentVersionBundle ?? [],
    };
  } else {
    for (const [stackName, stackData] of Object.entries(dataset)) {
      if (stackData.alias.includes(tech.component)) {
        return {
          icon: dataset[stackName],
          version: tech.version ?? null,
          packageName:
            internalPackageNameToPacakgeName[tech.component] ??
            SbomTechToCodeTech[tech.component] ??
            tech.component,
          associatedVersions: tech.sameComponentDifferentVersionBundle ?? [],
        };
      }
    }
  }

  return null;
}

export type Tech = {
  component: string;
  version: string | null;
  sameComponentDifferentVersionBundle: Array<SbomTech>;
};

type SbomTech = Tech & { namespacedName: string };

export function addIcon(
  techstacks: Tech[],
  technologies: Techstack,
): IconAndVersion[] {
  return techstacks.flatMap((x) => {
    const f = findIcon(technologies, x);
    return f ? f : [];
  });
}

export function NoAppCompDataToDisplay(): JSX.Element {
  return (
    <div className="dark:shadow-[0_35px_40px_-15px_rgba(0,0,0,0.2)] flex rounded-xl bg-light-seconday dark:bg-dark-secondary">
      <div className="m-auto py-10 dark:text-white">No data to display</div>
    </div>
  );
}

function SbomPackages({
  sbomData,
  appId,
}: {
  sbomData: ParsedSSC | undefined;
  appId: string;
}): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="flex flex-col py-2 mt-[-18px] z-[-1] bg-white dark:bg-dark-main opacity-100 rounded-b-lg">
      <div
        tabIndex={0}
        className="cursor-pointer flex justify-center"
        onClick={() => void setExpanded(!expanded)}
      >
        <Chip
          title="PACKAGE DEPENDENCIES"
          data={" "}
          icon={
            <GoPackageDependencies className="fill-slate-500 h-4 w-4 pt-[1px]" />
          }
        />
      </div>
      <Modal
        noVerticalMargin
        closeWhenClickingBackground
        onClose={() => void setExpanded(!expanded)}
        isOpen={expanded}
      >
        {(sbomData?.sbomPackages.length ?? 0) > 0 ? (
          <div className="flex flex-row justify-between mb-2">
            <p className="my-auto text-sm font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">
              Packages Generated:{" "}
              {sbomData?.sbomDate.date != null
                ? formatDate(sbomData?.sbomDate.date)
                : "Unable to determine a date for this scan."}
            </p>
            {/* <div className=""><Link to={`/sbom/${appId}`}><Button secondary label="To SBOM" icon={<BiLinkExternal />} /></Link></div> */}
          </div>
        ) : (
          <div className="flex flex-row flex-nowrap mb-4">
            <AiOutlineWarning className="my-auto w-4 h-4 text-gray-500" />
            <p className="ml-2 my-auto text-gray-500">
              Please run Code analysis to see code packages.
            </p>
          </div>
        )}
        <div className="overflow-auto">
          <table className="w-full">
            <thead className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wider">
              <tr>
                <td className="rounded-tl-lg dark:bg-dark-main bg-gray-50 py-2 pl-1">
                  Package Name
                </td>
                <td className="rounded-tr-lg dark:bg-dark-main bg-gray-50 py-2">
                  License
                </td>
                <td className="dark:bg-dark-main bg-gray-50 py-2">Version</td>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-dark-main text-sm font-medium text-gray-900 dark:text-gray-300">
              {(sbomData?.sbomPackages.length ?? 0) > 0 ? (
                sbomData?.sbomPackages.map((x, i) => (
                  <tr key={i} className="px-2 py-1 dark:text-gray-300">
                    <td className="py-1 w-1/3 break-words">
                      {x?.name
                        ?.replaceAll("%40", "@")
                        .replaceAll("%2B", "+")
                        .replaceAll("__", "/")}
                    </td>
                    {/* TODO needs support for historical case?? */}
                    <td className="py-1 w-1/3 break-words">
                      {x.licenses?.map((x) => x?.expression)}
                    </td>
                    <td className="py-1 w-1/3 break-words">
                      {x?.version ?? "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="px-2 py-1 dark:text-gray-300">
                  <td>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}

function StackModal({
  currentPackageModalPacakge,
  appId,
}: {
  currentPackageModalPacakge: IconAndVersion | null;
  appId: string;
}): JSX.Element {
  return currentPackageModalPacakge ? (
    <div>
      <div className="flex flex-row flex-nowrap gap-4">
        <div className="w-8 h-8 flex">
          {currentPackageModalPacakge.icon.displayIcon}
        </div>
        <p className="my-auto text-xl tracking-wide font-semibold dark:text-white">
          {currentPackageModalPacakge.icon.displayName}
        </p>
        {currentPackageModalPacakge.version != null &&
        currentPackageModalPacakge.version !== "" ? (
          <p className="my-auto text-xl tracking-wide font-semibold">
            <span className="px-2 bg-[#af3734] text-white rounded-full">
              {currentPackageModalPacakge.version}
            </span>
          </p>
        ) : null}
      </div>
      {(currentPackageModalPacakge?.associatedVersions?.length ?? 0) > 0 ? (
        <p className="py-4 dark:text-white">
          Other versions of "{currentPackageModalPacakge.icon.displayName}" were
          found in your project too:
        </p>
      ) : null}
      <div className="flex flex-row gap-2 pb-2 flex-wrap">
        {// border border
        currentPackageModalPacakge?.associatedVersions?.map((x, i) => (
          <p key={i} className="px-2 bg-[#af3734] text-white rounded-full">
            {x.version}
          </p>
        ))}
      </div>
      {currentPackageModalPacakge?.packageName != null ? (
        <div className="mt-4 text-center">
          <Link
            className="text-center"
            to={`/issues/${appId}?search=${
              currentPackageModalPacakge.icon?.search ??
              currentPackageModalPacakge.packageName.split("-")?.at(0) ??
              currentPackageModalPacakge.packageName
            }`}
          >
            <Button
              className="text-center"
              label={`Click here to search for vulnerabilities involving ${currentPackageModalPacakge.packageName}`}
              secondary
            />
          </Link>
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
}

export function ApplicationComposition({
  error,
  parsedSsc,
  app,
  dashData,
  sscLoading,
}: {
  dashData?: DashboardData;
  error?: boolean;
  sscLoading?: boolean;
  parsedSsc?: ParsedSSC;
  app?: AppSettingsData;
}): JSX.Element {
  const [pacakgeModalPackage, setPacakgeModalPackage] =
    useState<IconAndVersion | null>(null);

  if (sscLoading) {
    return <Loader w={6} h={6} label="Loading SSC" />;
  }

  if (!parsedSsc) {
    return <NoAppCompDataToDisplay />;
  }

  const foundAvailableSbomTechnologies: SbomTech[] =
    parsedSsc?.sbomPackages
      .map((x) => {
        const scmNamespacedName: string =
          app?.code.scmInfo.packageManager !== ""
            ? `${app?.code.scmInfo.packageManager}-${x.name}`
            : x.name;
        if (sbomTechnologies.includes(scmNamespacedName)) {
          return {
            component: x.name,
            namespacedName: scmNamespacedName,
            version: x.version,
            sameComponentDifferentVersionBundle: [],
          };
        }
        return null;
      })
      .filter(notEmpty) ?? [];

  const newSourceCode: Tech[] = parsedSsc.sourceCode
    .flatMap((x) => {
      if (x.version == null || x.version === "") {
        const existsInSbomAlready = foundAvailableSbomTechnologies.find(
          (y) => y.component === x.component,
        );
        return {
          component: existsInSbomAlready?.namespacedName ?? x.component,
          version: existsInSbomAlready?.version ?? null,
          sameComponentDifferentVersionBundle: [],
        };
      }
      return x;
    })
    .filter(notEmpty);

  const bundledFoundSbomTechnologies: SbomTech[] = [];
  for (const [namespacedPacakgeName, associatedTechs] of Object.entries(
    groupBy(foundAvailableSbomTechnologies, (x) => x.namespacedName),
  )) {
    const outerBundle: SbomTech = {
      component: associatedTechs.at(0)?.component ?? "",
      namespacedName: namespacedPacakgeName,
      sameComponentDifferentVersionBundle: [],
      version: associatedTechs.at(0)?.version ?? "",
    };
    for (let i = 1; i < associatedTechs.length; i++) {
      const foundTech = associatedTechs?.at(i);
      if (foundTech) {
        outerBundle.sameComponentDifferentVersionBundle.push(foundTech);
      }
    }
    bundledFoundSbomTechnologies.push(outerBundle);
  }

  for (const t of bundledFoundSbomTechnologies) {
    const foundElement = newSourceCode.find(
      (x) => x.component === t.component && x.version === t.version,
    );
    if (!foundElement) {
      newSourceCode.push({
        component:
          SbomTechToCodeTech[t.namespacedName] ??
          SbomTechToCodeTech[t.component] ??
          t.component,
        version: t.version,
        sameComponentDifferentVersionBundle:
          t.sameComponentDifferentVersionBundle ?? [],
      });
    }
  }

  //const sbomTech: Tech[] = uniqBy(sbomData?.components?.map(x => x?.name ? sbomTechnologies[x.name] != null ? ({ component: x.name, version: x.version ?? null }) : null : null).filter(notEmpty) ?? [], x => x.component);

  // add versions to code items from sbom
  // add other sbom items found but not already in there
  //const newMergedCodeStack: Tech[] = [];
  /* parsedSsc.sourceCode.concat(sbomTech.flatMap(x => {
    if (!foundComp) {
      return x;
    }
    return foundComp;
  })); */
  //sbomEnrichedCodeTech.concat(sbomTech.filter(x => !sbomEnrichedCodeTech.find(y => y.component === x.component)));
  //console.log("from BE", parsedSsc.sourceCode, "found sbomTech", sbomTech, "merged code to", newMergedCodeStack);

  const noTechstacksToDisplay =
    parsedSsc.continuousIntegration.length === 0 &&
    parsedSsc.continuousDeployment.length === 0 &&
    parsedSsc.runtime.length === 0 &&
    parsedSsc.services.length === 0 &&
    parsedSsc.sourceCode.length === 0 &&
    parsedSsc.cloudResources.length === 0 &&
    parsedSsc.localRequirements.length === 0;

  if (noTechstacksToDisplay) {
    return <NoAppCompDataToDisplay />;
  }

  /* const techstacks: Tech[] = [...parsedSsc.continuousIntegration, ...parsedSsc.continuousDeployment, ...parsedSsc.runtime, ...parsedSsc.services, ...parsedSsc.sourceCode, ...parsedSsc.cloudResources, ...parsedSsc.localRequirements]; */

  const integrationStack: IconAndVersion[] = addIcon(
    parsedSsc.continuousIntegration,
    integrationTechnologies,
  );
  const deploymentStack: IconAndVersion[] = addIcon(
    parsedSsc.continuousDeployment,
    deploymentTechnologies,
  );
  const runtimeStack: IconAndVersion[] = addIcon(
    parsedSsc.runtime,
    runtimeTechnologies,
  );
  const servicesStack: IconAndVersion[] = addIcon(
    parsedSsc.services,
    servicesTechnologies,
  );
  const codeStack: IconAndVersion[] = addIcon(
    newSourceCode,
    codeTechnologies,
  ).concat(addIcon(newSourceCode, servicesTechnologies));
  const cloudStack: IconAndVersion[] = addIcon(
    parsedSsc.cloudResources,
    cloudTechnologies,
  );
  const localRequirementsStack: IconAndVersion[] = addIcon(
    parsedSsc.localRequirements,
    localRequirementsTechnologies,
  );

  let hasIntegrationStack: boolean = integrationStack.length > 0;
  let hasDeploymentStack: boolean = deploymentStack.length > 0;
  let hasRuntimeStack: boolean = runtimeStack.length > 0;
  let hasServicesStack: boolean = servicesStack.length > 0;
  let hasCodeTech: boolean = codeStack.length > 0;
  let hasCloudTech: boolean = cloudStack.length > 0;
  let haslocalRequirementsTech: boolean = localRequirementsStack.length > 0;

  const d: {
    [title: string]: {
      hasTech: boolean;
      stack: IconAndVersion[];
      context: JSX.Element | null;
      tooltip?: string;
    };
  } = {
    Collaborators: {
      hasTech: false,
      stack: [],
      context:
        app?.code.isConfigured && parsedSsc.collaborators.length > 0 ? (
          <CollaboratorsBar data={dashData} app={app} />
        ) : null,
    },
    "Local Requirements": {
      hasTech: haslocalRequirementsTech,
      stack: localRequirementsStack,
      context: null,
    },
    "Source Code": {
      hasTech: hasCodeTech,
      stack: codeStack,
      context:
        app?.code.isConfigured && parsedSsc.sbomPackages ? (
          <SbomPackages appId={app.appId} sbomData={dashData?.ssc} />
        ) : null,
    },
    "Continuous Integration": {
      hasTech: hasIntegrationStack,
      stack: integrationStack,
      context: app?.code.isConfigured ? (
        <ScmMeta data={dashData} app={app} />
      ) : null,
      tooltip:
        "Continuous Integration (CI) is a development practice where developers integrate code into a shared repository frequently, preferably several times a day. Any tests associated with CI are generally to verify that the code meets security or functional requirements",
    },
    "Continuous Deployment": {
      hasTech: hasDeploymentStack,
      stack: deploymentStack,
      context: null,
    },
    Runtime: {
      hasTech: hasRuntimeStack,
      stack: runtimeStack,
      context: null,
    },
    Services: {
      hasTech: hasServicesStack,
      stack: servicesStack,
      context: null,
    },
    "Cloud Resources": {
      hasTech: hasCloudTech,
      stack: cloudStack,
      context: null,
    },
  };

  const untaggedResources: Tech[] = parsedSsc.untagged;

  return (
    <>
      <Modal
        closeWhenClickingBackground
        isOpen={pacakgeModalPackage != null}
        onClose={() => void setPacakgeModalPackage(null)}
      >
        <StackModal
          appId={app?.appId ?? ""}
          currentPackageModalPacakge={pacakgeModalPackage}
        />
      </Modal>
      <div className="dark:shadow-[0_35px_40px_-15px_rgba(0,0,0,0.2)]">
        {error ? (
          <p className="text-red-500 text-sm py-8 px-4 p-4 rounded-xl dark:bg-dark-main bg-white">
            Error generating application composition, please refresh the page to
            try again.
          </p>
        ) : (
          <div className="flex flex-col flex-nowrap h-full w-full gap-y-2">
            {Object.entries(d)?.map(([title, data], i) => {
              return (
                <Fragment key={i}>
                  {data.hasTech ? (
                    <div
                      key={i}
                      title={data.tooltip}
                      className={`${
                        data?.hasTech ? "flex" : "hidden"
                      } flex-col md:flex-row space-y-2 flex-nowrap items-center w-full justify-between ${
                        (title === "Source Code" &&
                          app?.code.isConfigured &&
                          parsedSsc.sbomPackages) ||
                        title === "Continuous Integration"
                          ? "rounded-t-xl"
                          : "rounded-xl"
                      } dark:bg-dark-main bg-white group`}
                      role="list"
                    >
                      <div
                        className="text-center text-ellipsis overflow-hidden text-base whitespace-nowrap dark:text-gray-300 min-w-fit md:pl-4 pt-2 md:pt-0"
                        aria-level={3}
                        role="heading"
                      >
                        <div className="group-hover:bg-light-seconday dark:group-hover:bg-dark-nav p-2 rounded-lg">
                          {title}
                        </div>
                      </div>
                      <div
                        role="listitem"
                        className="flex flex-wrap flex-grow flex-row justify-center align-top gap-3 max-w-[85%]"
                      >
                        {data?.stack?.map((stack, idx) => (
                          <div
                            onClick={() => void setPacakgeModalPackage(stack)}
                            key={idx}
                            className="cursor-pointer flex justify-center flex-col flex-nowrap app-composition-images p-2"
                            title={stack.icon.displayName}
                          >
                            <div className="flex flex-col">
                              <div className="flex m-auto w-10 h-10">
                                <Suspense
                                  fallback={
                                    <p className="dark:text-white">...</p>
                                  }
                                >
                                  {stack.icon.displayIcon}
                                </Suspense>
                              </div>
                              {/* {
                                // border border dark:text-gray-300
                                (stack?.associatedVersions?.length ?? 0) > 0 ? <div onClick={() => void setPacakgeModalPackage(stack)} className='cursor-pointer flex mt-1'><span className='box-border rounded-full mx-auto px-1 bg-[#af3734] capitalize text-xs text-white font-semibold'>+{stack.associatedVersions?.length} Version{(stack.associatedVersions?.length ?? 0) > 1 ? "s" : ""}</span></div> : null
                              } */}
                            </div>
                            {stack.version ? (
                              <div
                                title={`Detected ${stack.icon.displayName} version: ${stack.version}`}
                                className="text-sm mt-2 rounded-full outline outline-sky-300 outline-1 text-center mx-auto truncate bg-white dark:bg-[#1f2026] dark:text-white"
                              >
                                <span className="px-2">
                                  {stack.version
                                    ?.replaceAll("%40", "@")
                                    .replaceAll("%2B", "+")}
                                </span>
                              </div>
                            ) : null}
                            <p className="text-center dark:text-gray-400 text-gray-700 mt-1">
                              {stack.icon.displayName}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {data.context == null ? null : <div>{data.context}</div>}
                </Fragment>
              );
            })}
            <div
              title="These are the detected technologies that aren't in the above categories yet"
              className={`${
                untaggedResources?.length > 0 ? "flex" : "hidden"
              } flex-row space-y-2 flex-nowrap items-center w-full rounded-xl dark:bg-dark-main bg-white min-h-[92px] group `}
            >
              <div
                className="text-center text-ellipsis overflow-hidden text-base whitespace-nowrap dark:text-gray-300 min-w-fit md:pl-4 pt-2 md:pt-0"
                aria-level={3}
                role="heading"
              >
                <div className="group-hover:bg-light-seconday dark:group-hover:bg-dark-nav p-2 rounded-lg my-auto">
                  Untagged
                </div>
              </div>
              <div className="pl-4 flex flex-row flex-wrap justify-center flex-grow gap-2 pb-2">
                {untaggedResources?.map((x, idx) => {
                  return (
                    <p
                      key={idx}
                      className="px-4 py-2 bg-light-primary dark:bg-dark-nav dark:text-gray-300 rounded-lg m-0 align-middle"
                    >
                      {x.component}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
