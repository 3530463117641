import { joinClassNames } from "../../functions/utilities";
import styles from "./toolTip.module.css";

type OnHoverToolTipProps = {
  children: JSX.Element | JSX.Element[] | any;
  title: string | false | JSX.Element;
  className?: string;
};

export function OnHoverToolTip({
  children,
  title,
  className,
}: OnHoverToolTipProps): JSX.Element {
  return (
    <div className={joinClassNames(styles.tooltip)}>
      {children}
      <div className={joinClassNames(className, styles.tooltiptext)}>
        {title}
      </div>
    </div>
  );
};
