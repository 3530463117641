import { useHistory, useParams } from "react-router-dom";
import { Button } from "./form/Button";
import { Footer } from "./form/Footer";
import { Template } from "../Template";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { VerifyFCloudAccount } from "../../../graphql/mutations/VerifyFCloudAccount";

type WizardProps = {
  onActivated: () => void;
  path: string;
};

const continueButton = "[frontend][app] AWS Details Continue button clicked";


function Header(): JSX.Element {
  return (
    <div className="text-center py-2">
      <h1 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-xl md:text-xl dark:text-gray-400">
        Your generated AWS template
        {/* <span className="fyoutailwind px-7">
          <AwsLogo />
        </span> */}
      </h1>
    </div>
  );
};

export function WizardStep3({ onActivated, path }: WizardProps): JSX.Element {
  onActivated();
  const { push } = useHistory();

  const { applicationId } = useParams<{ applicationId: string }>();

  const [verifyCloudAccount] = useMutation(VerifyFCloudAccount, {
    onCompleted: (data) => {
      push({
        pathname: `${path}/3`,
        state: {
          cloudAccountId: data.verifyFCloudAccount.id,
        },
      });
    },
  });

  return (
    <>
      <div className="flex-1 bg-white dark:bg-dark-main shadow-xl rounded-2xl">
        <button
          type="button"
          className="inline-flex items-center px-5 py-5  text-sm leading-4 font-medium rounded-md text-black dark:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ArrowNarrowLeftIcon
            onClick={() => push(`${path}/1`)}
            className="hover:-translate-x-1 cursor-pointer h-8 w-8 mr-5 text-md font-medium text-black dark:text-gray-400"
          />
          back
        </button>

        <Header />
        <main className="max-w-lg mx-auto py-5">
          <div className="m-4 mb-2">
            <Template />
            <Button
              amplitudeEventName={continueButton}
              onClick={() => {
                verifyCloudAccount({
                  variables: {
                    id: localStorage.getItem("cloud_account"),
                    applicationId: applicationId,
                  },
                });
              }}
            />
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};
